import React from 'react'
import ExtendedLifecycle from '../../Components/ExtendedLifecycle/ExtendedLifecycle'
import LetsConnect from '../../Components/LetsConnect/LetsConnect'
import GlobalBrands from '../../Components/GlobalBrands/GlobalBrands'
import GlobalPresence from '../../Components/GlobalPresence/GlobalPresence'
import JoinCommunity from '../../Components/JoinCommunity/JoinCommunity'
import InsightSlider from '../../Components/InsightSlider/InsightSlider'

const StorageMaintenance = () => {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{ background: `url(images/storage-maintainance-banner.jpg)`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center bottom' }}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold text-capitalize'>Secure storage systems safeguard your data</h1>
              <p className="mt-5 text-warning" style={{ fontSize: '30px' }}>
                Storage Maintainance
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section>
        <div className="container-fluid text-muted mt-5 p-5 bg-light">
          <div className="row">
            <div className="col-lg-6 px-lg-4 px-0">
              <h3 className='text-center text-lg-start text-capitalize'>
                Ensure data safety with impeccable maintenance of storage systems for uninterrupted business operations</h3>
            </div>
            <div className="col-lg-6 px-lg-4 px-0">
              <p className="fs-5 my-lg-2">Rely on our manufacturer-independent storage maintenance service contract for immediate support, especially during urgent situations.</p>
              <p className="fs-5">Choose the level of support you need for SAN environments from major manufacturers, ensuring flexibility and transparency within your budget. Select Service Level Agreements tailored to your availability requirements.</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid text-muted mt-5 p-5">
          <div className="row text-center">
            <h3 className="my-lg-2 text-capitalize">Service-level agreements</h3>
            <p className='fs-5'>Customize SLAs to fit your specific storage needs.</p>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <h5 className="text-center h5 mt-3 fw-bold">Cost reduction</h5>
                  <p className='fs-5 px-2'>Significantly reduce costs with up to 70% savings compared to OEM support</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <h5 className="text-center h5 mt-3 fw-bold">Quality guarantee</h5>
                  <p className='fs-5 px-2'>Manufacturer-level support – even for legacy systems</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <h5 className="text-center h5 mt-3 fw-bold">Resilience</h5>
                  <p className='fs-5 px-2'>Prevent downtime caused by faults</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <h5 className="text-center h5 mt-3 fw-bold">24/7 avaibility</h5>
                  <p className='fs-5 px-2'>Rapid access to services and spare parts, even for legacy models</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <h5 className="text-center h5 mt-3 fw-bold">Single point of contact</h5>
                  <p className='fs-5 px-2'>Unified support for all manufacturers, simplifying your maintenance experience</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <h5 className="text-center h5 mt-3 fw-bold">Investment security</h5>
                  <p className='fs-5 px-2'>Extend the use of your hardware beyond its recommended service life</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <h5 className="text-center h5 mt-3 fw-bold">Relief</h5>
                  <p className='fs-5 px-2'>Free resources for strategic development</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <h5 className="text-center h5 mt-3 fw-bold">Expertise</h5>
                  <p className='fs-5 px-2'>Ensure the reliability of your systems with our extensive expertise</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 text-muted bg-light">
          <h3 className='my-3 text-center text-capitalize'>We provide support for various brands, models, and systems.</h3>
          <p className='fs-5 text-center text-capitalize'>Learn how to prevent downtime and ensure storage system reliability with our guidance.</p>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 text-muted">
          <div className="row">
            <div className="col-lg-3 my-3 col-md-4">
              <div className="card shadow-lg text-muted h-100">
                <div className="card-body">

                  <img src="images/hewlett-packard.png" className='card-img-top h-50' alt="" srcset="" />

                  <div>
                    <h5 className='text-center my-2 fw-bold'>Disk Storage</h5>
                    <p className='fs-5 px-2 mt-3 text-center'>
                      MSA, EVA, XP48-24000, LeftHand P Series, 3PAR StoreServ, Nimble CS Series, StoreVirtual</p>
                  </div>
                </div>
              </div>

            </div>
            <div className="col-lg-3 my-3 col-md-4">
              <div className="card shadow-lg text-muted h-100">
                <div className="card-body">

                  <img src="images/dellmec.png" className='card-img-top h-50' alt="" srcset="" />

                  <div>
                    <h5 className='text-center my-2 fw-bold'>Disk Storage</h5>
                    <p className='fs-5 px-2 mt-3 text-center'>

                      PowerVault, EqualLogic, Compellent, VNX/VNXe, Clariion CX, Celerra, Centera, Symmetrix - DMX, VMAX, VPLEX, Data Domain, Isilon, Unity</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 my-3 col-md-4">
              <div className="card shadow-lg text-muted h-100">
                <div className="card-body">

                  <img src="images/ibm.webp" className='card-img-top h-50' alt="" srcset="" />

                  <div>
                    <h5 className='text-center my-2 fw-bold'>Disk Storage</h5>
                    <p className='fs-5 px-2 mt-3 text-center'>

                      DS3xxx/4xxx/5xxx Series, DS6xxx Series, DS8xxx Series, XIV, N Series, SVC, Storwize V3xxx/5xxx/7xxx, FlashSystem</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 my-3 col-md-4">
              <div className="card shadow-lg text-muted h-100">
                <div className="card-body">

                  <img src="images/sun-oracle.png" className='card-img-top h-50' alt="" srcset="" />

                  <div>
                    <h5 className='text-center my-2 fw-bold'>Disk Storage</h5>
                    <p className='fs-5 px-2 mt-3 text-center'>StoreEdge 99x Systems
                      Storage / Storage Tek 6xxx Arrays
                      Storage 25xx Arrays
                      Storage J4xxx
                      StoreEdge 3xxx Arrays</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 my-3 col-md-4">
              <div className="card shadow-lg text-muted h-100">
                <div className="card-body">

                  <img src="images/fujitsu.png" className='card-img-top h-50' alt="" srcset="" />

                  <div>
                    <h5 className='text-center my-2 fw-bold'>Disk Storage</h5>
                    <p className='fs-5 px-2 mt-3 text-center'>

                      Fibrecat SX, Eternus DX</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 my-3 col-md-4">
              <div className="card shadow-lg text-muted h-100">
                <div className="card-body">

                  <img src="images/supermicr.png" className='card-img-top h-50' alt="" srcset="" />

                  <div>
                    <h5 className='text-center my-2 fw-bold'>Disk Storage</h5>
                    <p className='fs-5 px-2 mt-3 text-center'>SuperStorage</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 my-3 col-md-4">
              <div className="card shadow-lg text-muted h-100">
                <div className="card-body">

                  <img src="images/lenovo.png" className='card-img-top h-50' alt="" srcset="" />

                  <div>
                    <h5 className='text-center my-2 fw-bold'>Disk Storage</h5>
                    <p className='fs-5 px-2 mt-3 text-center'>

                      ThinkSystem DE Series, ThinkSystem DM Series, V3xxx/5xxx/7xxx Series</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 my-3 col-md-4">
              <div className="card shadow-lg text-muted h-100">
                <div className="card-body">

                  <img src="images/netapp.png" className='card-img-top h-50' alt="" srcset="" />

                  <div>
                    <h5 className='text-center my-2 fw-bold'>NetApp</h5>
                    <p className='fs-5 px-2 mt-3 text-center'>

                      FAS Series, E Series, AFF Series</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 my-3 col-md-4">
              <div className="card shadow-lg text-muted h-100">
                <div className="card-body">

                  <img src="images/hitachi.png" className='card-img-top h-50' alt="" srcset="" />

                  <div>
                    <h5 className='text-center my-2 fw-bold'>Disk Storage</h5>
                    <p className='fs-5  px-2 mt-3 text-center'>

                      VSP G Series, HUS / HUS-VM, AMS, Thunder 9200, 9500V, Tagmastore USP, USP-VM, NSC</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 my-3 col-md-4">
              <div className="card shadow-lg text-muted h-100">
                <div className="card-body">

                  <img src="images/synology.png" className='card-img-top h-50' alt="" srcset="" />

                  <div>
                    <h5 className='text-center my-2 fw-bold'>Disk Storage</h5>
                    <p className='fs-5 px-2 mt-3 text-center'>


                      XS+/XS Series, SA Series, FS Series, Plus Series, Expansions</p>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </section>
      <ExtendedLifecycle />
      <GlobalPresence />
      <JoinCommunity />
      <InsightSlider />
      <LetsConnect />
    </React.Fragment>

  )
}

export default StorageMaintenance