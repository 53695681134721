import React from 'react'

const ExtendedLifecycle = () => {
    return (
        <section>
            <div className="container-fluid text-muted bg-light">
                <div className="row mt-5 py-5">
                    <div className="col-lg-6 px-5">
                        <h3 className="text-center text-lg-start mt-lg-5 mt-0">Extend Hardware Lifecycle For Financial Peace Of Mind</h3>
                        <p className='fs-5 text-center text-lg-start'>Assess equipment condition for cost-effective maintenance strategies</p>
                    </div>
                    <div className="col-lg-6 px-5">
                        <div className="row mt-3">
                            <div className="col-lg-6 my-3">
                                <div className="card shadow-lg text-muted h-100 hover-metic">
                                    <div className="card-body text-center text-lg-start fs-5 mt-0">
                                        <h5 className="fw-bold mt-4 text-center">
                                            Reliable Hardware
                                        </h5>
                                        <p>Ensure high availability even for older equipment through our maintenance services</p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6  my-3">
                                <div className="card shadow-lg text-muted h-100 hover-metic">

                                    <div className="card-body text-center text-lg-start fs-5 mt-0">
                                        <h5 className="fw-bold mt-4 text-center">
                                            Sustainable operations
                                        </h5>
                                        <p>Extend the life of your hardware without premature replacements</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6  my-3">
                                <div className="card shadow-lg text-muted h-100 hover-metic">

                                    <div className="card-body text-center text-lg-start fs-5 mt-0">
                                        <h5 className="fw-bold mt-4 text-center">
                                            Cut costs
                                        </h5>
                                        <p> Delay the need for costly new equipment</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6  my-3">
                                <div className="card shadow-lg text-muted h-100 hover-metic">

                                    <div className="card-body text-center text-lg-start fs-5 mt-0">
                                        <h5 className="fw-bold mt-4 text-center">
                                            Fast support
                                        </h5>
                                        <p> Rapid response for spare parts</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ExtendedLifecycle