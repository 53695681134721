import React from 'react'
import EndOfServiceLife from '../EndOfServiceLife/EndOfServiceLife'
import LetsConnect from '../../Components/LetsConnect/LetsConnect'
import GlobalPresence from '../../Components/GlobalPresence/GlobalPresence'
import JoinCommunity from '../../Components/JoinCommunity/JoinCommunity'
import InsightSlider from '../../Components/InsightSlider/InsightSlider'
import GlobalBrands from '../../Components/GlobalBrands/GlobalBrands'

const WarrantyCheck = () => {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/warranty-check-banner.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold text-capitalize'>Get to know your hardware warranty status</h1>
              <p className="mt-5 text-warning" style={{fontSize:'30px'}}>
              Warranty Check
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands/>
      <section>
        <div className="container-fluid text-muted mt-5 p-5 bg-light">
          <div className="row">
            <div className="col-lg-6  px-lg-4">
              <h3 className='text-center text-lg-start text-capitalize'>
              Perform a single check to avoid double payments and ensure warranty coverage</h3>
            </div>
            <div className="col-lg-6 px-lg-4">
              <p className="fs-5 my-lg-2">Considering operating your systems post-warranty? Ensure no overlap or double payments by reviewing your manufacturer contract. Check your warranty status for peace of mind</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 text-muted p-5">
        <div className="row">
          <div className="col-lg-6 px-lg-4">
            <img src='images/warranty-check.webp' />
          </div>
          <div className="col-lg-6 px-lg-4 mt-5 mt-lg-0">
            <h3 className='text-center text-lg-start text-capitalize'>Where can you find up-to-date information on your hardware’s maintenance status?</h3>
            <p className='fs-5'>That's a helpful initiative! Having a list of major manufacturer websites where customers can easily check their hardware's warranty status can save time and effort. It's great to streamline the process for users by providing direct access to these portals. If you'd like, I can assist in compiling the list or provide any other support you may need.</p>
          </div>
        </div>
        </div>
      </section>
      <section>
        <div className="container-fluid text-muted mt-5 p-5 bg-light">
          <div className="row">
            <div className="col-lg-6">
            <h3 className="my-2 text-center text-lg-start text-capitalize">Skilled hardware maintenance services</h3>
            <p className='fs-5 text-center text-lg-start'>Maximize the benefits of our hardware services right from the start</p>
            </div>
            <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-6 my-3">
                <div className="card shadow-lg h-100 text-muted hover-metic">
                  <div className="card-body mt-3">
                    <p className='fs-5 text-center'>Ensured high availability, guaranteed</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 my-3">
              <div className="card shadow-lg h-100 text-muted hover-metic">
                  <div className="card-body mt-3">
                    <p className='fs-5 text-center'>On-site assistance available immediately, anytime</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 my-3">
              <div className="card shadow-lg h-100 text-muted hover-metic">
                  <div className="card-body mt-3">
                    <p className='fs-5 text-center'>Personal contact</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 my-3">
              <div className="card shadow-lg h-100 text-muted hover-metic">
                  <div className="card-body mt-3">
                    <p className='fs-5 text-center'>We have spare parts and replacement systems readily available in stock</p>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </section>
      <GlobalPresence/>
      <JoinCommunity/>
      <InsightSlider/>
      <LetsConnect/>
    </React.Fragment>
  )
}

export default WarrantyCheck