import React from "react";
import TalkToAnExpert from "../../Components/TalkToAnExpert/TalkToAnExpert";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";

const Salesforce = () => {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/salesforce-b-i.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Deliver Personalized Digital Service</h1>
              <p className="mt-5 text-warning text-capitalize" style={{fontSize:'30px'}}>
              Harness the power of Salesforce Service Cloud
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section className="wrapper mt-5 p-5 text-muted bg-light">
        <div className="container py-10 py-md-14">
          <div className="row">
            <div className="col-md-6 px-lg-5 px-0">
              <h3 className="mb-9 text-center text-md-start">Salesforce Cloud Automation</h3>
              <p className="fs-5">
                Businesses seek synchronization between their sales, marketing,
                and service automation systems to align with customer
                interactions and actions seamlessly.
              </p>
              <p className="fs-5">
              DataCentrix™'s Sales Cloud and Service Cloud practice provides
                industry-leading expertise to help organizations leverage
                multiple solutions with low total cost of ownership and high
                return on investment. Our Salesforce Consulting Partnership
                underscores our proficiency.
              </p>
            </div>
            <div className="col-md-6 mt-3 mt-lg-0">
              <div className="card shadow-lg me-lg-6 text-muted my-2">
                <div className="card-body p-3 d-flex align-items-center">
                  <div className="d-flex flex-row">
                    <div>
                      <h5 className="mb-1">
                        Fragmented processes and system architecture
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card shadow-lg mx-lg-6 mt-6 text-muted my-2">
                <div className="card-body p-3 d-flex align-items-center">
                  <div className="d-flex flex-row">
                    <div>
                      <h5 className="mb-1">
                        Customer-facing operations lack efficiency and
                        effectiveness.
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card shadow-lg ms-lg-13 mt-6 text-muted my-2">
                <div className="card-body p-3 d-flex align-items-center">
                  <div className="d-flex flex-row">
                    <div>
                      <h5 className="mb-1">Lack of 360-degree customer view</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card shadow-lg mx-lg-6 mt-6 text-muted my-2">
                <div className="card-body p-3 d-flex align-items-center">
                  <div className="d-flex flex-row">
                    <div>
                      <h5 className="mb-1">
                        Absence of essential KPI dashboards hinders decisions.
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container py-10 py-md-14">
          <div className="row">
            <div className="col-md-6">
              <h3 className="mb-9 text-center text-md-start mt-lg-3 mt-0">Salesforce Technologies</h3>
              <p className="fs-5 mt-3 mt-md-0">
                CRM technologies empower businesses to drive sales and nurture
                relationships effectively. Our Sales Cloud and Service Cloud
                Solutions leverage leading platforms to enhance customer
                engagement, streamline processes, and boost productivity.
              </p>
              <p className="fs-5">
              DataCentrix™'s Salesforce Sales Cloud and Service Cloud solutions empower
                organizations to engage with customers intelligently and
                proactively, fostering stronger relationships and driving
                business growth.
              </p>
            </div>
            <div className="col-md-5 offset-md-1">
              <h4 className="mb-5 mt-3 text-center text-md-start">
                We have capabilities in the following
              </h4>
              <figure>
                <img
                  src="images/salesforce-eco-system.jpg"
                  height={400}
                  width={500}
                  alt=""
                  srcset=""
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted bg-light">
        <div className="container py-10 py-md-14">
          <div className="row">
            <h3 className="mb-9 text-center mb-5 text-capitalize">Are you looking to</h3>
            <div className="col-md-6 d-flex">
              <div className="card shadow-lg mb-5 w-100 text-muted">
                <div className="card-body p-3 d-flex align-items-center">
                  <div className="d-flex flex-row">
                    <div>
                      <p className="mb-1 fs-5 d-flex flex-row">
                        <span className="text-warning fs-4 mx-2">1 </span> <span>Consolidate
                          consumer data from diverse systems for seamless access
                          and insights on a single page</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card shadow-lg mb-5 w-100 text-muted">
                <div className="card-body p-3 d-flex align-items-center">
                  <div className="d-flex flex-row">
                    <div>
                      <p className="mb-1 fs-5 d-flex flex-row">
                        <span className="text-warning fs-4 mx-2">2 </span> <span>Streamline
                          service agent workflows by minimizing manual processes
                          and enhancing automation for efficiency gains</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card shadow-lg mb-5 w-100 text-muted">
                <div className="card-body p-3 d-flex align-items-center">
                  <div className="d-flex flex-row">
                    <div>
                      <p className="mb-1 fs-5 d-flex flex-row">
                        <span className="text-warning fs-4 mx-2">3 </span> <span>Empower agents
                          with rapid access to centralized customer data for
                          enhanced service delivery and efficiency</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card shadow-lg mb-5 w-100 text-muted">
                <div className="card-body p-3 d-flex align-items-center">
                  <div className="d-flex flex-row">
                    <div>
                      <p className="mb-1 fs-5 d-flex flex-row">
                        <span className="text-warning fs-4 mx-2">4 </span><span>Leverage support
                          data and customer analytics to revolutionize the entire
                          customer experience journey</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card shadow-lg mb-5 w-100 text-muted">
                <div className="card-body p-3 d-flex align-items-center">
                  <div className="d-flex flex-row">
                    <div>
                      <p className="mb-1 fs-5 d-flex flex-row">
                        <span className="text-warning fs-4 mx-2">5 </span><span>Equip service
                          agents with AI-driven recommendations for enhanced
                          efficiency and personalized customer support</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card shadow-lg mb-5 w-100 text-muted">
                <div className="card-body p-3 d-flex align-items-center">
                  <div className="d-flex flex-row">
                    <div>
                      <p className="mb-1 fs-5 d-flex flex-row">
                        <span className="text-warning fs-4 mx-2">6 </span><span> Enhance agents'
                          productivity through streamlined processes, effective
                          tools, and continuous skill development initiatives</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container py-10 py-md-14">
          <div className="row gx-lg-8 gx-xl-12 gy-8">
            <h3 className="mb-5 text-center">
              Unlock The Capabilities of Salesforce Cloud
            </h3>
            <div className="col-md-6 col-lg-4 my-3">
              <div className="card shadow-lg h-100 text-muted main-card">
                <div className="card-body">
                <div>
                  <h5 className="mb-1 fw-bold text-center py-2">Case Management</h5>
                  <p className="mb-0 fs-5 mt-2">
                    Enhance service efficiency with customized cases and
                    automated escalation rules based on various criteria.
                  </p>{" "}
                </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 my-3">
            <div className="card shadow-lg h-100 text-muted main-card">
                <div className="card-body">
                <div>
                  <h5 className="mb-1 fw-bold text-center py-2">Omni-Channel Supervisor</h5>
                  <p className="mb-0 fs-5 mt-2">
                    Enable service managers to monitor team activity,
                    productivity, and distribute cases strategically.
                  </p>{" "}
                </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 my-3">
            <div className="card shadow-lg h-100 text-muted main-card">
                <div className="card-body">
                <div>
                  <h5 className="mb-1 fw-bold text-center py-2">Email-To-Case</h5>
                  <p className="mb-0 fs-5 mt-2">
                    Automatically convert support emails into cases for
                    efficient tracking and resolution.
                  </p>{" "}
                </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 my-3">
            <div className="card shadow-lg h-100 text-muted main-card">
                <div className="card-body">
                <div>
                  <h5 className="mb-1 fw-bold text-center py-2">Web-To-Case</h5>
                  <p className="mb-0 fs-5 mt-2">
                    Automate the mapping of website Contact Us form submissions
                    to Salesforce fields for streamlined data management.
                  </p>{" "}
                </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 my-3">
            <div className="card shadow-lg h-100 text-muted main-card">
                <div className="card-body">
                <div>
                  <h5 className="mb-1 fw-bold text-center py-2">Automation</h5>
                  <p className="mb-0 fs-5 mt-2">
                    Minimize manual tasks by implementing auto-reply emails to
                    customers upon new case creation.
                  </p>{" "}
                </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 my-3">
            <div className="card shadow-lg h-100 text-muted main-card">
                <div className="card-body">
                <div>
                  <h5 className="mb-1 fw-bold text-center py-2">Social Customer Service</h5>
                  <p className="mb-0 fs-5 mt-2">
                    Utilize social listening for customer cases, setting service
                    level agreements and response times across platforms.
                  </p>{" "}
                </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 my-3">
            <div className="card shadow-lg h-100 text-muted main-card">
                <div className="card-body">
                <div>
                  <h5 className="mb-1 fw-bold text-center py-2">Live Chat And Chat Bots</h5>
                  <p className="mb-0 fs-5 mt-2">
                    Provide real-time support through live chat or automated
                    chat bots for quick resolution of simple issues.
                  </p>{" "}
                </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 my-3">
            <div className="card shadow-lg h-100 text-muted main-card">
                <div className="card-body">
                <div>
                  <h5 className="mb-1 fw-bold text-center py-2">Einstein Ai</h5>
                  <p className="mb-0 fs-5 mt-2">
                    Empower service agents with Einstein Recommendations to
                    boost customer loyalty through personalized incentives like
                    order discounts and free shipping.
                  </p>{" "}
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted bg-light">
        <div className="container py-10 py-md-14">
          <div className="row">
            <h3 className="mb-5 text-center">Service Offerings</h3>
            <p className="fs-5">
              <strong>
                Integration of Sales Cloud and Service Cloud is pivotal for
                sales success in all organizations
              </strong>
              DataCentrix™ leverages its experience and capabilities to implement
              marketplace best practices across various industries through its
              comprehensive Advisory, Implementation, and Support services
              portfolio
            </p>
            <p className="fs-5">
              We deliver innovative, high-performance services and solutions in
              the following areas, driving accelerated business growth for our
              customers :
            </p>
          </div>
          <div className="row gx-lg-8 gx-xl-12 gy-8 mt-2">
            <div className="col-md-6 col-lg-4 my-3">
              <div className="d-flex flex-row">
                <div>
                  <i class="bi bi-laptop text-warning fs-4 mx-2"></i>
                </div>
                <div>
                  <p className="mb-0 fs-5">
                    Analyzing and enhancing customer-centric processes goes
                    beyond automation, focusing on optimization for maximum
                    effectiveness
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 my-3">
              <div className="d-flex flex-row">
                <div>
                  <i class="bi bi-piggy-bank text-warning fs-4 mx-2"></i>
                </div>
                <div>
                  <p className="mb-0 fs-5">
                    Boosting ROI through deeper comprehension and fulfillment
                    of end customer requirements
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 my-3">
              <div className="d-flex flex-row">
                <div>
                  <i class="bi bi-bar-chart-line text-warning fs-4 mx-2"></i>
                </div>
                <div>
                  <p className="mb-0 fs-5">
                    Enhancing new customer acquisition while retaining
                    existing customers for sustained growth
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container pb-18 pt-md-10 pb-md-22">
          <div className="row">
            <h3 className="mb-5 text-center">Advisory Service</h3>
            <p className="fs-5">
              Strategic initiatives benefit greatly from experienced assistance.
              Our services add value by refining vision, designing IT
              architecture, and optimizing systems.
            </p>
            <p className="fs-5">
              Our Advisory portfolio offers objective assessment, maximizing
              ROI, operational and strategic alignment, and business
              transformation best practices.
            </p>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted bg-light">
        <div className="container pt-10 pt-md-10 pb-10 pb-md-12">
          <div className="row gy-6">
            <h4 className="mt-2 mb-5 text-center">Services and Capabilities</h4>
            <div className="col-lg-4 col-md-6 d-flex my-3 my-lg-0">
              <div className="card shadow-lg text-muted w-100">
                <figure className="card-img-top">
                  <img
                    src="images/strategy.jpeg"
                    height={230}
                    width={364}
                    alt=""
                    srcset=""
                  />
                </figure>
                <div className="card-body">
                  <div className="post-header">
                    <h5 className="post-title  text-center mt-1 mb-3 fw-bold">STRATEGIC</h5>
                  </div>
                  <div className="post-content">
                    <ul className="icon-list bullet-bg bullet-soft-blue mb-0 list-unstyled">
                      <li className="d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Executive Alignment Forums</span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">
                          Sales Cloud and Service Cloud Program Charter
                          Definition
                        </span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Organizational Consulting</span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Visioning Workshops</span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Digital Engagement Strategy</span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Application Modernization Planning</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex my-3 my-lg-0">
              <div className="card shadow-lg text-muted w-100">
                <figure className="card-img-top">
                  <img
                    src="images/technical-img.webp"
                    height={230}
                    width={364}
                    alt=""
                    srcset=""
                  />
                </figure>
                <div className="card-body">
                  <div className="post-header">
                    <h5 className="post-title text-center mt-1 mb-3 fw-bold">TECHNICAL</h5>
                  </div>
                  <div className="post-content">
                    <ul className="icon-list bullet-bg bullet-soft-blue mb-0 list-unstyled">
                      <li className="d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Current State Technology Assessment</span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Ancillary Application Stack Review</span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">
                          Sales Cloud and Service Cloud Road Map Refinement
                        </span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Proof of Concepts</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex  my-3 my-lg-0">
              <div className="card shadow-lg text-muted w-100">
                <figure className="card-img-top">
                  <img
                    src="images/tactical-planning.webp"
                    height={230}
                    width={364}
                    alt=""
                    srcset=""
                  />
                </figure>
                <div className="card-body">
                  <div className="post-header">
                    <h5 className="post-title text-center mt-1 mb-3 fw-bold">TACTICAL</h5>
                  </div>
                  <div className="post-content">
                    <ul className="icon-list bullet-bg bullet-soft-blue mb-0 list-unstyled">
                      <li className="d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Project Planning and Phase Mapping</span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Project Preparedness Assessment</span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Project Risk/Mitigation Assessment</span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Change Management and Data Governance</span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Program Execution</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container pb-10 pt-md-10 pb-md-15">
          <div className="row">
            <h3 className="mb-4 text-center">Implementation Service</h3>
            <p className="fs-5">
              Technical deployment can be daunting. Our guided, structured
              approach ensures smooth implementation. With thousands of
              successful Sales Cloud and Service Cloud projects, we excel.
            </p>
            <p className="fs-5">
              We guide you from Sales Cloud and Service Cloud evaluation to
              swift implementation, ROI realization, and sustained productivity.
              Our team offers industry best practices through comprehensive
              projects or targeted solutions.
            </p>
            <h4 className="mt-2 mb-4">Capabilities and Services</h4>
          </div>
          <div className="row gy-5 mt-n6">
            <div className="col-md-6">
              <ul className="icon-list bullet-bg bullet-soft-blue mb-0 list-unstyled">
                <li className="d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    Comprehending the underlying business needs driving a
                    client's Sales Cloud and Service Cloud initiative.
                  </span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">Data management</span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    Offering hassle-free post-launch support with our 60 and
                    90-day Adoption Assurance programs.
                  </span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">Collecting functional requirements.</span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">Integrating systems and providing training.</span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    Implementing application configuration adjustments.
                  </span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">Solution designing</span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">Adoption Assurance</span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    Seamless end-user training and adoption transition.
                  </span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">Process improvement</span>
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <figure className="rounded">
                <img src="images/data-center-imgage.jpeg" alt="" srcset="" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted bg-light">
        <div className="container pb-5 pt-md-10 pb-md-5">
          <div className="row">
            <h3 className="mb-4 text-center">Support Service</h3>
            <p className="fs-5">
              A highly skilled safety net can be the difference between seamless
              transition and catastrophic meltdown. DataCentrix™ offers
              flexible support packages, including periodic health checks and
              performance monitoring.
            </p>
            <p className="fs-5">
              Our Certified Salesforce Consultants and Engagement Managers offer
              daily onsite or remote support for post-project assistance,
              scoping new projects, and providing qualified resources. Our
              support services save time, apply best practices, and reduce
              errors.
            </p>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-6 position-relative order-lg-2">
              <div className="shape rounded-circle rellax w-20 h-20" />
              <figure className="rounded">
                <img src="images/technical-capability.jpg" alt="" srcset="" />
              </figure>
            </div>
            <div className="col-lg-6">
              <h3 className="mt-2 mb-5 text-center text-lg-start">Capabilities and Services</h3>
              <div className="d-flex flex-row mb-6 align-items-center my-3">
                <div>
                  <span className="icon btn btn-circle btn-warning pe-none me-5">
                    <span className="number fs-18">1</span>
                  </span>
                </div>
                <p className="mb-1">
                  Flexible support available 5 days a week to meet your needs
                </p>
              </div>
              <div className="d-flex flex-row mb-6 align-items-center  my-3">
                <div>
                  <span className="icon btn btn-circle btn-warning pe-none me-5">
                    <span className="number fs-18">2</span>
                  </span>
                </div>
                <p className="mb-1">
                  Regular health checks to ensure system performance and
                  stability.
                </p>
              </div>
              <div className="d-flex flex-row mb-6 align-items-center  my-3">
                <div>
                  <span className="icon btn btn-circle btn-warning pe-none me-5">
                    <span className="number fs-18">3</span>
                  </span>
                </div>
                <p className="mb-1">
                  Onsite and remote support options for your convenience.
                </p>
              </div>
              <div className="d-flex flex-row mb-6 align-items-center  my-3">
                <div>
                  <span className="icon btn btn-circle btn-warning pe-none me-5">
                    <span className="number fs-18">4</span>
                  </span>
                </div>
                <p className="mb-1">
                  Dedicated post-project support for continued success.
                </p>
              </div>
              <div className="d-flex flex-row mb-6 align-items-center  my-3">
                <div>
                  <span className="icon btn btn-circle btn-warning pe-none me-5">
                    <span className="number fs-18">5</span>
                  </span>
                </div>
                <p className="mb-1">
                  Expert assistance in defining and scoping new projects for
                  success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LetsConnect />
    </React.Fragment>
  );
};

export default Salesforce;
