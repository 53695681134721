import React from 'react'
import TalkToAnExpert from '../../Components/TalkToAnExpert/TalkToAnExpert'
import GlobalBrands from '../../Components/GlobalBrands/GlobalBrands'
import JoinCommunity from '../../Components/JoinCommunity/JoinCommunity'
import LatestInsights from '../../Components/LatestInsights/LatestInsights'
import LetsConnect from '../../Components/LetsConnect/LetsConnect'

const ApplicationDevelopment = () => {
    return (
        <React.Fragment>
        <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/app-development-banner.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Application Development &amp; Support</h1>
              <p className="mt-5 text-warning text-capitalize" style={{fontSize:'30px'}}>
              Key enabler of digital transformation
              </p>
            </div>
          </div>
        </div>
      </section>
            <GlobalBrands />
            <section className="wrapper mt-5 p-5 text-muted">
                <div className="container pt-10 pt-md-10 pb-10 pb-md-12">
                    <div className="row gy-6">
                        <h2 className="mt-5 mt-md-5 mb-0 text-center">
                            Application Development and Support
                        </h2>
                        <p className='fs-5 mt-3'>
                        DataCentrix™'s Application Development & Support services integrate cloud, mobility, and web-based applications to craft efficient, business-oriented, and forward-looking digital solutions.
                        </p>
                        <div className="col-lg-4 col-md-6 d-flex my-3">
                            <div className="card shadow-lg text-muted">
                                <figure className="card-img-top">
                                    <img src="images/business-transformation.jpeg" height={230} width={364} alt="" srcset="" />
                                </figure>
                                <div className="card-body">
                                    <div className="post-header">
                                        <h5 className="mt-1 mb-3 text-center fw-bold">
                                            Business Transformation
                                        </h5>
                                    </div>
                                    <div className="post-content">
                                        <p>
                                            Business transformation involves strategic change to enhance performance and adaptability through innovation, technology adoption, and organizational evolution, ensuring competitiveness in dynamic markets.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 d-flex my-3">
                            <div className="card shadow-lg text-muted">
                                <figure className="card-img-top">
                                    <img src="images/app-modernization.png" height={230} width={364} alt="" srcset="" />
                                </figure>
                                <div className="card-body">
                                    <div className="post-header">
                                        <h5 className="mt-1 mb-3 text-center fw-bold">
                                            Application Modernization
                                        </h5>
                                    </div>
                                    <div className="post-content">
                                        <p>
                                            Revamping legacy applications for business process re-engineering and cloud migration is pivotal in clients' digital transformation. Leveraging full-stack expertise, we ensure technological agility aligns with evolving business models.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 d-flex my-3">
                            <div className="card shadow-lg text-muted">
                                <figure className="card-img-top">
                                    <img src="images/cloud-modernization.jpg" height={230} width={364} alt="" srcset="" />
                                </figure>
                                <div className="card-body">
                                    <div className="post-header">
                                        <h5 className="mt-1 mb-3 text-center fw-bold">Cloud Transformation</h5>
                                    </div>
                                    <div className="post-content">
                                        <p>
                                        DataCentrix™ leverages expertise in Microservices for simplifying complex business functions, Serverless computing for cost reduction and agile development, and Containerization with Orchestration for managing existing applications efficiently.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 d-flex my-3">
                            <div className="card shadow-lg text-muted">
                                <figure className="card-img-top">
                                    <img src="images/test-automation.jpeg" height={230} width={364} alt="" srcset="" />
                                </figure>
                                <div className="card-body">
                                    <div className="post-header">
                                        <h5 className="h5 mt-1 mb-3 text-center fw-bold">Test Automation</h5>
                                    </div>
                                    <div className="post-content">
                                        <p>
                                            We excel in crafting thorough test cases, continuously refining them to match evolving business processes. Our Test Automation Framework automates test execution across all stages of application development, ensuring robustness.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 d-flex my-3">
                            <div className="card shadow-lg text-muted">
                                <figure className="card-img-top">
                                    <img src="images/app-support.jpg" height={230} width={364} alt="" srcset="" />
                                </figure>
                                <div className="card-body">
                                    <div className="post-header">
                                        <h5 className="mt-1 mb-3 text-center fw-bold">Application Support</h5>
                                    </div>
                                    <div className="post-content">
                                        <p>
                                        DataCentrix™ utilizes the onshore-offshore model for round-the-clock application support, following a "follow the sun" approach. We prioritize automation for common incidents and service requests to enhance customer experience and support scalability while minimizing operational expenses.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <JoinCommunity />
            <LatestInsights />
            <LetsConnect />
        </React.Fragment>
    )
}

export default ApplicationDevelopment