import React from "react";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";

function GlobalWorkforce() {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/global-workforce-banner.webp)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Workforce Services</h1>
              <h1 className='fw-bold text-capitalize'>Rapid Recruitment : Top Talent in Just 2 Weeks</h1>
              <p className="mt-5 text-warning text-capitalize" style={{fontSize:'30px'}}>
              Delivering the right workforce services, right on time, is at the core of our approach. We collaborate closely with our clients, understanding their present requirements and anticipating future needs. Leveraging technology enables us to efficiently source and attract talent with the precise skills needed, ensuring our clients have the right people for the job when they need them
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section className="wrapper bg-light mt-5 p-5 text-muted">
        <div className="container py-12 py-md-14">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-6 position-relative order-lg-2">
              <div
                className="shape bg-dot primary rellax w-16 h-20"
                data-rellax-speed={1}
                style={{ top: "3rem", left: "5.5rem" }}
              />
              <div className="overlap-grid overlap-grid-2">
                <div className="item mt-2 p-lg-5 p-0">
                  <img src='images/gb1.jpg' className="" />
                </div>
                <div className="item mt-2 p-lg-5 d-none d-lg-block">
                  <img src='images/gb2.jpg' className="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 px-lg-5 px-0">
              <div className="text-center my-3">
                <i class="bi bi-globe text-warning fs-1"></i>
              </div>
              <h3 className="mb-3 text-capitalize">
                Simplifying Global Business Operations for Seamless Expansion and Success
              </h3>
              <p className="fs-5 text-center text-lg-start">
                With us on your side anything is possible
              </p>
              <p className="mb-6 fs-5">
                With a vast team of thousands of experts spanning over 80+ countries and 200+ metro cities, we cater to client needs on a global scale. We approach challenges with creativity and urgency, ensuring consistent and reliable outcomes. Through service offerings encompassing business process automation, infrastructure management, workforce optimization, technology services, vendor consolidation, and beyond, we are dedicated to transforming your business.
              </p>
              <p className="mb-6 fs-5">
                We prioritize cultivating enduring relationships, delivering excellence, and continually enhancing our services with a select group of clients. This ensures that when the pressure is on, we can consistently provide top-class service round the clock, globally.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h3 className="mb-3">
                Company Facts
              </h3>
              <h5 className="px-xl-10">
                We are proud of our works
              </h5>
            </div>
          </div>
          <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0 mt-5">
            <div className="col-lg-12 mt-lg-2">
              <div className="row align-items-center counter-wrapper gy-6">
                <div className="col-lg-4 col-md-6 mt-2">
                  <div className="card shadow-lg text-muted h-100 main-card">
                    <div className="card-body">
                      <div className="text-center">
                        <i class="bi bi-bookmark-star text-warning fs-2 mx-2"></i>
                      </div>
                      <h5 className="counter text-center">10+</h5>
                      <p className="fs-5 text-center fw-bold">Year history</p>
                      <p className="fs-5">
                        Since 2002, our foundational principle has been to be Passionate about placing People, providing a solution to the flaws of conventional recruitment
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mt-2">
                  <div className="card shadow-lg text-muted h-100 main-card">
                    <div className="card-body">
                      <div className="text-center">
                        <i class="bi bi-person-fill text-warning fs-2 mx-2"></i>
                      </div>
                      <h5 className="counter  text-center">100,000+</h5>
                      <p className="fs-5  text-center fw-bold">Candidates</p>
                      <p className="fs-5">
                        Over the years, we've partnered with over 100,000 talented individuals on their employment journey. Each year, we actively manage over 30,000 temporary jobs for our incredible flexible working team
                      </p>
                    </div></div>
                </div>
                <div className="col-lg-4 col-md-6 mt-2">
                  <div className="card shadow-lg text-muted h-100 main-card">
                    <div className="card-body">
                      <div className="text-center">
                        <i class="bi bi-people text-warning fs-2 mx-2"></i>
                      </div>
                      <h5 className="counter text-center">200+</h5>
                      <p className="fs-5 text-center fw-bold">Our Team</p>
                      <p className="fs-5">
                        Our experienced team of recruitment specialists is dedicated to providing unparalleled support every step of the way, ensuring your recruitment experience is exceptional
                      </p>
                    </div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted bg-light">
        <div className="container py-7 py-md-12">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h3 className="mb-3 text-center">
                What We Do?
              </h3>
              <h5 className="mb-10 px-xl-10 text-center">
                Our services are specifically tailored to meet your unique needs.
              </h5>
            </div>
          </div>
          <div className="position-relative">
            <div
              className="shape rounded-circle bg-soft-blue rellax w-16 h-16"
              style={{
                bottom: "-0.5rem",
                right: "-2.2rem",
                zIndex: 0,
                transform: "translate3d(0px, 6px, 0px)",
              }}
            />
            <div
              className="shape bg-dot blue rellax w-16 h-17"
              style={{
                top: "-0.5rem",
                left: "-2.5rem",
                zIndex: 0,
                transform: "translate3d(0px, 2px, 0px)",
              }}
            />
            <div className="row gx-md-5 gy-5 d-flex mt-3">
              <div className="col-md-6 col-xl-4 d-flex">
                <div className="card shadow-lg text-muted main-card">
                  <div className="card-body">
                    <div className="text-center">
                      <i class="bi bi-award text-warning fs-2 mx-2"></i>
                    </div>
                    <h5 className="text-center mt-1 fw-bold">MSP Staffing Solutions</h5>
                    <p className="mb-2 fs-5 mt-3">
                      We offer comprehensive management of the entire contingent worker life cycle, from requisition and sourcing to invoicing and payment.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-4 d-flex">
                <div className="card shadow-lg text-muted main-card">
                  <div className="card-body">
                    <div className="text-center">
                      <i class="bi bi-people text-warning fs-2 mx-2"></i>
                    </div>
                    <h5 className="text-center mt-1 fw-bold">Contingent Staffing</h5>
                    <p className="mb-2 fs-5 mt-3">
                      Our solution enhances efficiency and flexibility throughout the recruitment process, from talent sourcing to onboarding support for key job requisitions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-4 d-flex">
                <div className="card shadow-lg text-muted main-card">
                  <div className="card-body">
                    <div className="text-center">
                      <i class="bi bi-shield text-warning fs-2 mx-2"></i>
                    </div>
                    <h5 className="text-center mt-1 fw-bold">RPO</h5>
                    <p className="mb-2 fs-5 mt-3">
                      We offer strategic guidance, seamless delivery, efficiency, and flexibility throughout the recruitment process. From talent sourcing using an exclusive candidate pool to onboarding support, we alleviate administrative burdens for employers and HR professionals.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-4 d-flex">
                <div className="card shadow-lg text-muted main-card">
                  <div className="card-body">
                    <div className="text-center">
                      <i class="bi bi-zoom-in text-warning fs-2 mx-2"></i>
                    </div>
                    <h5 className="text-center mt-1 fw-bold">Recruiter On Demand</h5>
                    <p className="mb-2 fs-5 mt-3">
                      We assist in scaling your recruitment team up or down with almost immediate effect.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-4 d-flex">
                <div className="card shadow-lg text-muted main-card">
                  <div className="card-body">
                    <div className="text-center">
                      <i class="bi bi-people-fill text-warning fs-2 mx-2"></i>
                    </div>
                    <h5 className="text-center mt-1 fw-bold">Diversity &amp; Inclusion Recruiting</h5>
                    <p className="mb-2 fs-5 mt-3">
                      We collaborate with you to strategize how to integrate Diversity & Inclusion as a core feature of your recruitment strategy.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-4 d-flex">
                <div className="card shadow-lg text-muted main-card">
                  <div className="card-body">
                    <div className="text-center">
                      <i class="bi bi-journal-bookmark-fill text-warning fs-2 mx-2"></i>
                    </div>
                    <h5 className="text-center mt-1 fw-bold">Employer of Record</h5>
                    <p className="mb-2 fs-5 mt-3">
                      We facilitate global expansion by enabling you to hire employees in any country without the need to open an entity there.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="snippet-8" className="wrapper mt-5 p-5 text-muted">
        <div className="container pt-10 pt-md-13 pb-13 pb-md-15">
          <div className="row text-center">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h3 className="mb-3 px-xl-11">
                Why Choose DataCentrix™ Services
              </h3>
            </div>
          </div>
          <div className="row gx-lg-8 gx-xl-12 gy-8">
            <div className="col-md-6 col-lg-4 mt-3">
              <div className="card shadow-lg text-muted h-100">
              <div className="card-body">
              <div className="p-3">
                <div className="text-center">
                  <i class="bi bi-globe2 text-warning fs-2 mx-2"></i>
                </div>
                <div>
                  <h5 className="mb-1 text-center fw-bold mt-1">Global Scale Partner</h5>
                  <ul className="icon-list bullet-bg bullet-soft-blue mb-0 list-unstyled mt-3">
                    <li className="d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">
                        Your scaling partner for infrastructure and software technologies.
                      </span>
                    </li>
                    <li className="mt-3 d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">
                        End-to-end services across the entire spectrum.
                      </span>
                    </li>
                    <li className="mt-3 d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">
                        Automated operations for speed and agility.
                      </span>
                    </li>
                    <li className="mt-3 d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">Committed to addressing 90% of demands.</span>
                    </li>
                  </ul>
                </div>
              </div>
              </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mt-3">
            <div className="card shadow-lg text-muted h-100">
              <div className="card-body">
              <div className="p-3">
                <div className="text-center">
                  <i class="bi bi-diagram-3-fill text-warning fs-2 mx-2"></i>
                </div>
                <div>
                  <h5 className="mb-1 text-center fw-bold mt-1">Fulfillment Competency</h5>
                  <ul className="icon-list bullet-bg bullet-soft-blue mb-0 list-unstyled mt-3">
                    <li className="d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">
                        Technology Competency Units for proactive skill database.
                      </span>
                    </li>
                    <li className="mt-3 d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">Over 1 million active candidates in our database.</span>
                    </li>
                    <li className="mt-3 d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">
                        Dedicated recruiting teams for clients and key competencies.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mt-3">
            <div className="card shadow-lg text-muted h-100">
              <div className="card-body">
              <div className="p-3">
                <div className="text-center">
                  <i class="bi bi-currency-exchange text-warning fs-2 mx-2"></i>
                </div>
                <div>
                  <h5 className="mb-1 text-center fw-bold mt-1">Investments</h5>
                  <ul className="icon-list bullet-bg bullet-soft-blue mb-0 list-unstyled mt-3">
                    <li className="d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">Serviceo: Customized ATS Platform Solutions</span>
                    </li>
                    <li className="mt-3 d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">
                        Our Team: Delivery Head, Managers, US HR, Recruiting Lead, and COE Support
                      </span>
                    </li>
                    <li className="mt-3 d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">
                        Investment in Tools, Account Management, Service Delivery, and CoE
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mt-3">
            <div className="card shadow-lg text-muted h-100">
              <div className="card-body">
              <div className="p-3">
                <div className="text-center">
                  <i class="bi bi-box-seam-fill text-warning fs-2 mx-2"></i>
                </div>
                <div>
                  <h5 className="mb-1 text-center fw-bold mt-1">Delivery Excellence</h5>
                  <ul className="icon-list bullet-bg bullet-soft-blue mb-0 list-unstyled mt-3">
                    <li className="d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">
                        Managed Service Program: Ownership, Accountability, and SLA Commitment
                      </span>
                    </li>
                    <li className="mt-3 d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">
                        Proven Track Record: 10+ Years Industry Experience
                      </span>
                    </li>
                    <li className="mt-3 d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">
                        Governance: Management Dashboards and Reports
                      </span>
                    </li>
                    <li className="mt-3 d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">People at the Core of Everything We Do</span>
                    </li>
                  </ul>
                </div>
              </div>
              </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mt-3">
            <div className="card shadow-lg text-muted h-100">
              <div className="card-body">
              <div className="p-3">
                <div className="text-center">
                  <i class="bi bi-xbox text-warning fs-2 mx-2"></i>
                </div>
                <div>
                  <h5 className="mb-1 text-center fw-bold mt-1">Innovation and Collaboration</h5>
                  <ul className="icon-list bullet-bg bullet-soft-blue mb-0 list-unstyled mt-3">
                    <li className="d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">
                        Platform-Centric Approach: Total Visibility, Transparency, and Reporting
                      </span>
                    </li>
                    <li className="mt-3 d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">
                        Automation-First Approach: B2B Integration and Seamless Collaboration
                      </span>
                    </li>
                    <li className="mt-3 d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">
                        Optimized Efficiency: Integration of Team and Platform for Synerg
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mt-3">
            <div className="card shadow-lg text-muted h-100">
              <div className="card-body">
              <div className="p-3">
                <div className="text-center">
                  <i class="bi bi-diagram-3 text-warning fs-2 mx-2"></i>
                </div>
                <div>
                  <h5 className="mb-1 text-center fw-bold mt-1">Diversity &amp; Community</h5>
                  <ul className="icon-list bullet-bg bullet-soft-blue mb-0 list-unstyled mt-3">
                    <li className="d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">IT Services Provider: Minority-Owned, Major Impact</span>
                    </li>
                    <li className="mt-3 d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">NMSDC Corporate Plus Membership: Elevating Standards</span>
                    </li>
                    <li className="mt-3 d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">
                        Diverse Mentorship and Support Program: Building Strong Foundations
                      </span>
                    </li>
                    <li className="mt-3 d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">Tier-2 reporting &amp; analytics.</span>
                    </li>
                  </ul>
                </div>
              </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light mt-5 p-5 text-muted">
        <div className="container py-7 py-md-12">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
              <h3 className="mb-10 px-xl-10">
                Agile Contingent Workforce Service Agreements
              </h3>
            </div>
          </div>
          <div className="position-relative">
            <div
              className="shape rounded-circle bg-soft-blue rellax w-16 h-16"
              style={{
                bottom: "-0.5rem",
                right: "-2.2rem",
                zIndex: 0,
                transform: "translate3d(0px, 6px, 0px)",
              }}
            />
            <div
              className="shape bg-dot blue rellax w-16 h-17"
              style={{
                top: "-0.5rem",
                left: "-2.5rem",
                zIndex: 0,
                transform: "translate3d(0px, 2px, 0px)",
              }}
            />
            <div className="row gx-md-5 gy-5 d-flex mt-3">
              <div className="col-md-6 col-xl-4 d-flex">
                <div className="card shadow-lg text-muted hover-metic">
                  <div className="card-body w-100">
                    <div className="text-center">
                      <i class="bi bi-newspaper text-warning fs-2 mx-2"></i>
                    </div>
                    <h5 className="text-center fw-bold mt-1">Contract Staffing</h5>
                    <ul className="mb-0 list-unstyled mt-3">
                      <li className="d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Innovative Search Engine Technology</span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Domain Focus</span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">
                          Digital Transformation Technology Skill Development
                          Program
                        </span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Backup-Management</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-4 d-flex">
                <div className="card shadow-lg w-100  text-muted hover-metic">
                  <div className="card-body">
                    <div className="text-center">
                      <i class="bi bi-hand-thumbs-up text-warning fs-2 mx-2"></i>
                    </div>
                    <h5 className="text-center fw-bold mt-1">Contact To Hire</h5>
                    <ul className="mb-0 list-unstyled mt-3">
                      <li className="d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Fulfilment for C2H</span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Expectation Management</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-4 d-flex">
                <div className="card shadow-lg w-100  text-muted hover-metic">
                  <div className="card-body">
                    <div className="text-center text-warning fs-2 mx-2">
                      <i class="bi bi-zoom-in"></i>
                    </div>
                    <h5 className="text-center fw-bold mt-1">Professional Search</h5>
                    <ul className="mb-0 list-unstyled mt-3">
                      <li className="d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Resume Search Management</span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Quality Management</span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Volume Hiring</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row gx-md-5 gy-5 d-flex pt-5">
              <div className="col-md-6 col-xl-4 d-flex">
                <div
                  className="card shadow-lg w-100 text-white"
                  style={{ backgroundColor: "rgb(11, 119, 224)" }}
                >
                  <div className="card-body">
                    <ul className="m-0 p-0 list-unstyled">
                      <li>
                        <span className="fs-5">Fulfilment life cycle process</span>
                      </li>
                      <li className="mt-1">
                        <span className="fs-5">SLA Based delivery</span>
                      </li>
                      <li className="mt-1">
                        <span className="fs-5">Client Centric Approach</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-4 d-flex">
                <div
                  className="card shadow-lg w-100 text-white"
                  style={{ backgroundColor: "rgb(51, 63, 78)" }}
                >
                  <div className="card-body">
                    <ul className="m-0 p-0 list-unstyled">
                      <li>
                        <span className="fs-5">Fulfilment Analysis</span>
                      </li>
                      <li className="mt-1">
                        <span className="fs-5">Artificial Intelligence</span>
                      </li>
                      <li className="mt-1">
                        <span className="fs-5">Chatbot ATS</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-4 d-flex">
                <div
                  className="card shadow-lg w-100 text-white"
                  style={{ backgroundColor: "rgb(65, 178, 246)" }}
                >
                  <div className="card-body">
                    <ul className="m-0 p-0 list-unstyled">
                      <li className="d-none">
                        <span />
                      </li>
                      <li>
                        <span className="fs-5">Relationship and Account Management</span>
                      </li>
                      <li className="mt-1">
                        <span className="fs-5">Staffing Models including VMS</span>
                      </li>
                      <li className="mt-1">
                        <span className="fs-5">Immigration and Contractual Compliance</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default GlobalWorkforce;
