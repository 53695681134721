import React from "react";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";
import GlobalPresence from "../../Components/GlobalPresence/GlobalPresence";
import JoinCommunity from "../../Components/JoinCommunity/JoinCommunity";
import InsightSlider from "../../Components/InsightSlider/InsightSlider";

const DataCenterBackupService = () => {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/emmergency-services.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold text-capitalize'>Our emergency replacement system provides rapid assistance in
                  worst-case scenarios for minimal downtime and maximum
                  continuity</h1>
              <p className="mt-5 text-warning" style={{fontSize:'30px'}}>
              TG NES.i
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands/>
      <section>
        <div className="container-fluid text-muted mt-5 p-5 bg-light">
          <h3 className="text-center my-2">
            Powerful Protection: Our Emergency System Ensures Continuity in
            Critical Situations
          </h3>
          <p className="text-center fs-5">
            Our comprehensive protection package offers quick and reliable
            solutions in emergencies to restore system availability with utmost
            efficiency.
          </p>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 text-muted">
          <div className="row mt-lg-5">
            <h3 className="text-center my-lg-2">
              Utilize Our Ready-to-Deploy Emergency Replacement System for
              Instantaneous Recovery
            </h3>
            <div className="row mt-lg-5">
              <div className="col-lg-4 col-md-6 my-3">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className="text-center">
                      <i class="bi bi-stopwatch text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className="text-center my-2 fw-bold">
                        Always available
                      </h5>
                      <p className="fs-5">
                        We deliver the system precisely when you need it most,
                        ensuring uninterrupted operations.
                      </p>
                      </div>
                    </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 my-3">
              <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-geo-alt-fill text-warning fs-2 mx-2"></i>
                  </div>
                  <div>
                    <h5 className="text-center my-2 fw-bold">
                      Swift On-Site Deployment
                    </h5>
                    <p className="fs-5">
                      Receive Your Hardware Within Just 12 Hours for Rapid
                      Deployment.
                    </p>
                  </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 my-3">
              <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-cash-coin text-warning fs-2 mx-2"></i>
                  </div>
                  <div>
                    <h5 className="text-center my-2 fw-bold">Save Money</h5>
                    <p className="fs-5">
                      Take Steps to Minimize Financial Losses for Your Company.
                    </p>
                  </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 my-3">
              <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-person-fill-gear text-warning fs-2 mx-2"></i>
                  </div>
                  <div>
                    <h5 className="text-center my-2 fw-bold">Well prepared</h5>
                    <p className="fs-5">
                      We customize the system to fit your needs beforehand.
                    </p>
                  </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 my-3">
              <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-calculator text-warning fs-2 mx-2"></i>
                  </div>
                  <div>
                    <h5 className="text-center my-2 fw-bold">
                      Ensure Predictable Expenses
                    </h5>
                    <p className="fs-5">
                      Rest Assured with a Supply Contract, Avoiding Unexpected
                      Expenses.
                    </p>
                  </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 my-3">
              <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-laptop text-warning fs-2 mx-2"></i>
                  </div>
                  <div>
                    <h5 className="text-center my-2 fw-bold">Flexible systems</h5>
                    <p className="fs-5">
                      We maintain stock of components for nearly all
                      manufacturers.
                    </p>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted bg-light">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="shape bg-soft-primary rounded-circle rellax w-20 h-20" />
              <img src="images/emergency.jpg" />
            </div>
            <div className="col-lg-6 px-lg-5 px-0">
              <h3 className="my-3 text-center text-lg-start mt-3 mt-lg-0 text-capitalize">
                Emergencies can occur unexpectedly.
              </h3>
              <p className="fs-5">
                Ensuring the availability of IT systems is paramount for every
                company. Unforeseen events such as water damage, theft, or fire
                can disrupt operations, leading to heavy financial losses. While
                insurance may cover the costs for a new system, the process of
                getting everything up and running again can take weeks,
                prolonging downtime and exacerbating financial impacts.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="shape bg-soft-primary rounded-circle rellax w-20 h-20" />
              <img src="images/emergency-help.webp" />
            </div>
            <div className="col-lg-6 px-lg-5 px-0">
              <h3 className="my-3 text-center text-lg-start mt-3 mt-lg-0 text-capitalize">
                We're here to support you every step of the way
              </h3>
              <div className="fs-5">
                Our emergency replacement system, coupled with a maintenance
                contract, ensures swift resolution, minimizing disruptions to
                your business. With a 24/7 supply contract, we analyze your IT
                environment and tailor our system to your needs, delivering
                components within twelve hours. Our dedicated recovery center
                provides rapid assistance for urgent cases. Once operational, we
                retrieve replacement components unless you choose to retain
                them.
              </div>
            </div>
          </div>
        </div>
      </section>
      <GlobalPresence/>
      <JoinCommunity/>
      <InsightSlider/>
      <LetsConnect />
    </React.Fragment>
  );
};

export default DataCenterBackupService;
