import React from 'react'

const HardwareSales = () => {
  return (
    <div
    id="wrapper"
    className="hide-title-section no-mobile-animation"
    style={{ marginBottom: "559.667px" }}
  >
    <div id="primary" className="content-area">
      <main id="main" className="site-main" role="main">
        <section id="single-page" className="section it-hardware" style={{}}>
          <div className="container">
            <div className="row single-page-content">
              <section className="wpb-content-wrapper">
                <div className="vc_row wpb_row vc_row-fluid">
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        {/* START TG Header ITHardware EN REVOLUTION SLIDER 6.6.4 */}
                        <rs-fullwidth-wrap
                          id="rev_slider_242_1_forcefullwidth"
                          style={{ marginTop: 0, marginBottom: 0 }}
                        >
                          <rs-module-wrap
                            id="rev_slider_242_1_wrapper"
                            data-source="gallery"
                            style={{
                              visibility: "visible",
                              background: "transparent",
                              padding: 0,
                              margin: "0px auto",
                              height: 600,
                              display: "block",
                              width: 1263,
                              position: "absolute",
                              overflow: "visible",
                              transform: "translate(0px, 0px)",
                              top: 0,
                              left: "-27px"
                            }}
                          >
                            <rs-module
                              id="rev_slider_242_1"
                              style={{ height: 600, width: 1263, maxHeight: 600 }}
                              data-version="6.6.4"
                              data-idcheck="true"
                              className="revslider-initialised rev_redraw_on_blurfocus"
                              data-slideactive="rs-272"
                            >
                              <rs-loader
                                className="spinner0 "
                                style={{ display: "none" }}
                              >
                                <div className="dot1" />
                                <div className="dot2" />
                                <div className="bounce1" />
                                <div className="bounce2" />
                                <div className="bounce3" />
                              </rs-loader>
                              <rs-progress style={{ visibility: "hidden" }}>
                                <rs-progress-bar
                                  style={{
                                    background: "rgba(255, 255, 255, 0.5)"
                                  }}
                                />
                              </rs-progress>
                              <rs-slides
                                style={{
                                  overflow: "hidden",
                                  position: "absolute",
                                  visibility: "visible",
                                  maxHeight: "none",
                                  height: "100%",
                                  width: "100%"
                                }}
                              >
                                <rs-slide
                                  style={{
                                    position: "absolute",
                                    display: "block",
                                    overflow: "hidden",
                                    height: "100%",
                                    width: "100%",
                                    zIndex: 20,
                                    opacity: 1,
                                    visibility: "inherit"
                                  }}
                                  data-key="rs-272"
                                  data-title="Slide"
                                  data-anim="adpr:false;"
                                  data-in="o:0;"
                                  data-out="a:false;"
                                  data-originalindex={1}
                                  data-origindex={0}
                                  data-description=""
                                  data-sba=""
                                  data-scroll-based="false"
                                  data-owidth={1920}
                                  data-oheight={800}
                                  data-rspausetimeronce={0}
                                  data-isactiveslide="true"
                                >
                                  <rs-sbg-px>
                                    <rs-sbg-wrap
                                      data-key="rs-272"
                                      data-owidth={1920}
                                      data-oheight={800}
                                    >
                                      {/*Runtime Modification - Img tag is Still Available for SEO Goals in Source - <img fetchpriority="high" decoding="async" src="//www.technogroup.com/wp-content/plugins/revslider/public/assets/assets/dummy.png" alt="technogroup_it-hardware" title="TG_header_it_hardware (2)" width="1920" height="800" class="rev-slidebg tp-rs-img rs-lazyload" data-lazyload="//www.technogroup.com/wp-content/uploads/2023/02/TG_header_it_hardware-2.png" data-bg="p:center top;" data-no-retina="">*/}
                                      <rs-sbg
                                        data-lazyload="//www.technogroup.com/wp-content/uploads/2023/02/TG_header_it_hardware-2.png"
                                        className=""
                                        data-bgcolor="transparent"
                                        style={{ width: "100%", height: "100%" }}
                                        data-src-rs-ref="//www.technogroup.com/wp-content/uploads/2023/02/TG_header_it_hardware-2.png"
                                      >
                                        <canvas
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            backgroundColor: "transparent",
                                            opacity: 1,
                                            filter: "none"
                                          }}
                                          width={1263}
                                          height={600}
                                        />
                                      </rs-sbg>
                                    </rs-sbg-wrap>
                                  </rs-sbg-px>
                                  {/*
                                   */}
                                  <rs-layer-wrap
                                    className="rs-parallax-wrap"
                                    style={{
                                      position: "absolute",
                                      display: "block",
                                      pointerEvents: "auto",
                                      left: "13.5px",
                                      top: 467,
                                      zIndex: 8,
                                      visibility: "visible",
                                      perspective: 601
                                    }}
                                  >
                                    <h1
                                      id="slider-242-slide-272-layer-0"
                                      className="rs-layer"
                                      data-type="text"
                                      data-color="#f4bc01"
                                      data-rsp_ch="on"
                                      data-xy="x:c;xo:-197px;y:c;yo:203px;"
                                      data-text="w:normal;s:64;l:72;ls:0px;fw:700;"
                                      data-frame_999="o:0;st:w;"
                                      style={{
                                        zIndex: 8,
                                        fontFamily: '"Roboto Slab"',
                                        height: "auto",
                                        width: "auto",
                                        color: "rgb(244, 188, 1)",
                                        textDecoration: "none",
                                        whiteSpace: "nowrap",
                                        minHeight: 0,
                                        minWidth: 0,
                                        maxHeight: "none",
                                        maxWidth: "none",
                                        textAlign: "left",
                                        lineHeight: 72,
                                        letterSpacing: 0,
                                        fontWeight: 700,
                                        fontSize: 64,
                                        transformOrigin: "50% 50%",
                                        opacity: 1,
                                        transform: "translate(0px, 0px)",
                                        visibility: "visible"
                                      }}
                                      data-idcheck="true"
                                      data-stylerecorder="true"
                                      data-initialised="true"
                                    >
                                      DATA CENTRE HARDWARE
                                    </h1>
                                  </rs-layer-wrap>
                                  {/*
  
                */}
                                  <rs-layer-wrap
                                    className="rs-parallax-wrap"
                                    style={{
                                      position: "absolute",
                                      display: "block",
                                      pointerEvents: "auto",
                                      left: "11.5px",
                                      top: 341,
                                      zIndex: 9,
                                      visibility: "visible",
                                      perspective: 601
                                    }}
                                  >
                                    <h2
                                      id="slider-242-slide-272-layer-2"
                                      className="rs-layer"
                                      data-type="text"
                                      data-color="#ffffff"
                                      data-rsp_ch="on"
                                      data-xy="x:c;xo:-327px;y:c;yo:113px;"
                                      data-text="w:normal;s:42;l:52;fw:700;"
                                      data-dim="w:586px;"
                                      data-margin="b:40;"
                                      data-frame_999="o:0;st:w;"
                                      style={{
                                        zIndex: 9,
                                        fontFamily: '"Roboto Slab"',
                                        height: "auto",
                                        color: "rgb(255, 255, 255)",
                                        textDecoration: "none",
                                        whiteSpace: "normal",
                                        width: 586,
                                        minHeight: 0,
                                        minWidth: 0,
                                        maxHeight: "none",
                                        maxWidth: "none",
                                        textAlign: "left",
                                        lineHeight: 52,
                                        letterSpacing: "-1px",
                                        fontWeight: 700,
                                        fontSize: 42,
                                        marginBottom: 40,
                                        transformOrigin: "50% 50%",
                                        opacity: 1,
                                        transform: "translate(0px, 0px)",
                                        visibility: "visible"
                                      }}
                                      data-idcheck="true"
                                      data-stylerecorder="true"
                                      data-initialised="true"
                                    >
                                      Rent, buy, recycle: <br />
                                      economical and smart
                                    </h2>
                                  </rs-layer-wrap>
                                  {/*
                                   */}{" "}
                                </rs-slide>
                              </rs-slides>
                            </rs-module>
                          </rs-module-wrap>
                          <rs-fw-forcer style={{ height: 600 }} />
                        </rs-fullwidth-wrap>
                        {/* END REVOLUTION SLIDER */}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  data-vc-kd-parallax="1.5"
                  className="vc_row wpb_row vc_row-fluid vc_custom_1587041701292 vc_row-has-fill vc_row-has-padding-top vc_row-has-padding-bottom vc_general kd_vc_parallax kd_vc_parallax-content-moving"
                  style={{
                    position: "relative",
                    left: "-11.6667px",
                    boxSizing: "border-box",
                    width: 1263,
                    maxWidth: 1263,
                    paddingLeft: "11.6667px",
                    paddingRight: "11.3333px"
                  }}
                >
                  <div
                    className="parallax-overlay"
                    data-vc-kd-parallax="1.5"
                    style={{
                      height: "970.5px",
                      transform: "translate3d(0px, 582.222px, 0px)"
                    }}
                  />
                  <div className="wpb_column vc_column_container vc_col-sm-4">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <header
                          className="kd-section-title col-lg-12 text-center kd-animated fadeIn kd-animate"
                          data-animation-delay={200}
                        >
                          <h2 className="separator_off">
                            Flexible sourcing: more than just buying new
                          </h2>
                        </header>
                      </div>
                    </div>
                  </div>
                  <div className="wpb_column vc_column_container vc_col-sm-4">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <p
                          style={{ textAlign: "left" }}
                          className="vc_custom_heading wpb_animate_when_almost_visible wpb_fadeIn fadeIn wpb_start_animation animated"
                        >
                          Companies today have three key requirements when it
                          comes to procuring IT equipment: fast availability,
                          state-of-the-art technology and economic profitability.
                          To meet these requirements, variable sourcing models are
                          advantageous. It does not always have to be the purchase
                          of new equipment. Leasing and rental concepts as well as
                          the purchase of refurbished hardware offer flexible
                          possibilities to remain performant, to cover seasonal
                          performance peaks and to quickly adapt to new
                          requirements.With our customised solutions consisting of
                          hardware procurement and suitable financing, we support
                          you in optimising your IT operations technically and
                          economically. Our IT experts offer you flexible options:
                          Complete systems, upgrades or individual parts as
                          refurbished or new goods - individually according to
                          your needs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="wpb_column vc_column_container vc_col-sm-4">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <p
                          style={{ textAlign: "left" }}
                          className="vc_custom_heading wpb_animate_when_almost_visible wpb_fadeIn fadeIn wpb_start_animation animated"
                        >
                          Do you have a short-term procurement requirement and
                          want to keep your financial burden low? Then we will be
                          happy to advise you on an all-round solution consisting
                          of flexible financing for hardware, software and
                          services.
                        </p>
                        <a
                          href="https://www.technogroup.com/en/financing-solutions/"
                          target="_self"
                          title=""
                          className="tt_button tt_primary_button btn_primary_color  button-center button-action-link"
                        >
                          <span className="prim_text">
                            More about financing solutions
                          </span>
                        </a>
                        <p
                          style={{ textAlign: "left" }}
                          className="vc_custom_heading wpb_animate_when_almost_visible wpb_fadeIn fadeIn vc_custom_1659596674051 wpb_start_animation animated"
                        >
                          In addition, our worldwide spare parts service SPaaS
                          ensures that the right hardware reaches you at the right
                          place and at the right time - no matter where you need
                          it.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vc_row-full-width vc_clearfix" />
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  className="vc_row wpb_row vc_row-fluid vc_custom_1604418283086 vc_row-has-fill"
                  style={{
                    position: "relative",
                    left: "-11.6667px",
                    boxSizing: "border-box",
                    width: 1263,
                    maxWidth: 1263,
                    paddingLeft: "11.6667px",
                    paddingRight: "11.3333px"
                  }}
                >
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <div className="vc_empty_space" style={{ height: 62 }}>
                          <span className="vc_empty_space_inner" />
                        </div>
                        <header className="kd-section-title col-lg-12 text-center    ">
                          <h2 className="separator_off">
                            Your advantages at a glance
                          </h2>
                        </header>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vc_row-full-width vc_clearfix" />
                <div className="vc_row wpb_row vc_row-fluid vc_custom_1626962343925 vc_row-has-padding-top vc_row-has-padding-bottom">
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                          <div className="wpb_column vc_column_container vc_col-sm-4">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="key-icon-box icon-default icon-top cont-left ib-hover-1 kd-animated fadeInUp kd-animate"
                                  style={{ backgroundColor: "transparent" }}
                                  data-animation-delay={200}
                                >
                                  <div className="ib-wrapper">
                                    <i
                                      className="iconsmind-Server iconsmind-Hardware"
                                      style={{}}
                                    />{" "}
                                    <h5
                                      className="service-heading"
                                      style={{ color: "#f4bc01" }}
                                    >
                                      Sales
                                    </h5>
                                    <p>
                                      We sell refurbished or new hardware as
                                      complete systems, upgrades or individual
                                      spare parts - depending on what you require.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-4">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="key-icon-box icon-default icon-top cont-left ib-hover-1 kd-animated fadeInUp kd-animate"
                                  style={{ backgroundColor: "transparent" }}
                                  data-animation-delay={200}
                                >
                                  <div className="ib-wrapper">
                                    <i
                                      className="iconsmind-Arrow-Mix iconsmind-Arrow-View"
                                      style={{}}
                                    />{" "}
                                    <h5
                                      className="service-heading"
                                      style={{ color: "#f4bc01" }}
                                    >
                                      Rentals
                                    </h5>
                                    <p>
                                      If you need temporary support for order
                                      peaks, you can conveniently rent systems for
                                      short terms.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-4">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="key-icon-box icon-default icon-top cont-left ib-hover-1 kd-animated fadeInUp kd-animate"
                                  style={{ backgroundColor: "transparent" }}
                                  data-animation-delay={200}
                                >
                                  <div className="ib-wrapper">
                                    <i
                                      className="iconsmind-Financial iconsmind-Business-Finance"
                                      style={{}}
                                    />{" "}
                                    <h5
                                      className="service-heading"
                                      style={{ color: "#f4bc01" }}
                                    >
                                      Repurchase &amp; Recycling
                                    </h5>
                                    <p>
                                      Do you require a more efficient system or do
                                      you want to make space and get rid of unused
                                      hardware? We buy and collect your used,
                                      still working IT hardware and with the
                                      remaining value you’ll receive a part of
                                      your original investment in return.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                          <div className="wpb_column vc_column_container vc_col-sm-4">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div className="wpb_text_column wpb_content_element ">
                                  <div className="wpb_wrapper">
                                    <ul type="disc">
                                      <li className="m_2292248892676423878MsoListParagraph">
                                        Refurbished hardware as a sustainable
                                        alternative, for individual and complete
                                        systems
                                        <u />
                                        <u />
                                      </li>
                                      <li className="m_2292248892676423878MsoListParagraph">
                                        First-class quality thanks to the
                                        comprehensive know-how of our IT
                                        specialists
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-4">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div className="wpb_text_column wpb_content_element ">
                                  <div className="wpb_wrapper">
                                    <ul type="disc">
                                      <li>
                                        Safeguard operational continuity while
                                        protecting your budget
                                      </li>
                                      <li>
                                        Reliability without needing larger
                                        financial investments
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-4">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div className="wpb_text_column wpb_content_element ">
                                  <div className="wpb_wrapper">
                                    <ul type="disc">
                                      <li className="m_2292248892676423878MsoListParagraph">
                                        Receive a partial return on your original
                                        hardware investment
                                        <u />
                                        <u />
                                      </li>
                                      <li className="m_2292248892676423878MsoListParagraph">
                                        Get rid of unused hardware and create
                                        space in your data centre
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  className="vc_row wpb_row vc_row-fluid vc_custom_1629290988017 vc_row-has-fill vc_row-has-padding-top vc_row-has-padding-bottom"
                  style={{
                    position: "relative",
                    left: "-11.6667px",
                    boxSizing: "border-box",
                    width: 1263,
                    maxWidth: 1263,
                    paddingLeft: "11.6667px",
                    paddingRight: "11.3333px"
                  }}
                >
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner hide-on-mobile">
                      <div className="wpb_wrapper" />
                    </div>
                  </div>
                </div>
                <div className="vc_row-full-width vc_clearfix" />
                <div
                  id="sales"
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  className="vc_row wpb_row vc_row-fluid vc_custom_1635857700725 vc_row-has-fill vc_row-has-padding-top vc_row-has-padding-bottom _mPS2id-t"
                  style={{
                    position: "relative",
                    left: "-11.6667px",
                    boxSizing: "border-box",
                    width: 1263,
                    maxWidth: 1263,
                    paddingLeft: "11.6667px",
                    paddingRight: "11.3333px"
                  }}
                >
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                          <div className="wpb_column vc_column_container vc_col-sm-6">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <header className="kd-section-title col-lg-12 text-center    ">
                                  <h2 className="separator_off">
                                    Hardware sales: Guaranteed quality at fair
                                    conditions
                                  </h2>
                                </header>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-6">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <header className="kd-section-title col-lg-12 text-left    ">
                                  <h6 className="subtitle ">
                                    We offer you refurbished spare parts or new
                                    goods in accordance with your individual needs
                                    as complete systems, upgrades or single parts.
                                    We also provide maintenance for server,
                                    storage and network components, or for entire
                                    systems, as well as the installation of new
                                    software. Our sustainable spare parts or
                                    systems from refurbished hardware are also a
                                    quickly-available, environmentally-friendly
                                    and cost-effective alternative to
                                    manufacturers’ current products. Because
                                    professionally refurbished used hardware
                                    offers the highest quality and full
                                    functionality at considerably reduced costs
                                    compared to new goods.
                                  </h6>
                                </header>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                          <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner vc_custom_1626962841352">
                              <div className="wpb_wrapper">
                                <header className="kd-section-title col-lg-12 text-center    ">
                                  <h2 className="separator_off">
                                    Your hardware purchase benefits:
                                  </h2>
                                </header>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                          <div className="wpb_column vc_column_container vc_col-sm-3">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="key-icon-box icon-default icon-top cont-center ib-no-effect kd-animated fadeIn kd-animate"
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  <i
                                    className="iconsmind-Euro iconsmind-Business-Finance"
                                    style={{}}
                                  />{" "}
                                  <h5 className="service-heading">Low costs</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-3">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="key-icon-box icon-default icon-top cont-center ib-no-effect kd-animated fadeIn kd-animate"
                                  style={{ backgroundColor: "transparent" }}
                                  data-animation-delay={200}
                                >
                                  <i
                                    className="iconsmind-Medal-3 iconsmind-Sports"
                                    style={{}}
                                  />{" "}
                                  <h5 className="service-heading">
                                    First-class quality
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-3">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="key-icon-box icon-default icon-top cont-center ib-no-effect kd-animated fadeIn kd-animate"
                                  style={{ backgroundColor: "transparent" }}
                                  data-animation-delay={600}
                                >
                                  <i
                                    className="iconsmind-Clock-Forward iconsmind-Time"
                                    style={{}}
                                  />{" "}
                                  <h5 className="service-heading">
                                    Quick availability
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-3">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="key-icon-box icon-default icon-top cont-center ib-no-effect kd-animated fadeIn kd-animate"
                                  style={{ backgroundColor: "transparent" }}
                                  data-animation-delay={600}
                                >
                                  <i
                                    className="iconsmind-Diploma-2 iconsmind-Education"
                                    style={{}}
                                  />{" "}
                                  <h5 className="service-heading">
                                    New goods and licences e.g. from the CISCO and
                                    Synology portfolios
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1626963837372 vc_row-has-padding-top">
                          <div className="wpb_column vc_column_container vc_col-sm-3">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="key-icon-box icon-default icon-top cont-center ib-no-effect  kd-animated fadeIn"
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  <i
                                    className="iconsmind-Repair iconsmind-User-Interface"
                                    style={{}}
                                  />{" "}
                                  <h5 className="service-heading">
                                    Flexible adaptation to your corporate
                                    structure
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-3">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="key-icon-box icon-default icon-top cont-center ib-no-effect  kd-animated fadeIn"
                                  style={{ backgroundColor: "transparent" }}
                                  data-animation-delay={200}
                                >
                                  <i
                                    className="iconsmind-Servers iconsmind-Hardware"
                                    style={{}}
                                  />{" "}
                                  <h5 className="service-heading">
                                    Full protection when purchasing refurbished
                                    hardware including 30-day guarantee and
                                    additional hardware maintenance.
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-3">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="key-icon-box icon-default icon-top cont-center ib-no-effect  kd-animated fadeIn"
                                  style={{ backgroundColor: "transparent" }}
                                  data-animation-delay={600}
                                >
                                  <i
                                    className="iconsmind-Environmental-3 iconsmind-Nature"
                                    style={{}}
                                  />{" "}
                                  <h5 className="service-heading">
                                    Environmentally-friendly and sustainable
                                    alternative to new goods
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-3">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="key-icon-box icon-default icon-top cont-center ib-no-effect  kd-animated fadeIn"
                                  style={{ backgroundColor: "transparent" }}
                                  data-animation-delay={600}
                                >
                                  <i
                                    className="iconsmind-Light-Bulb2 iconsmind-Objects"
                                    style={{}}
                                  />{" "}
                                  <h5 className="service-heading">
                                    Comprehensive know-how: Our technicians are
                                    specially trained and certified.
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1629365257466 vc_row-has-padding-top">
                          <div className="wpb_column vc_column_container vc_col-sm-6">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="vc_empty_space"
                                  style={{ height: 50 }}
                                >
                                  <span className="vc_empty_space_inner" />
                                </div>
                                <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1630670680237">
                                  <figure className="wpb_wrapper vc_figure">
                                    <a
                                      href="https:%2F%2Fwww.technogroup.com%2Frefurbished-hardware%2F|"
                                      target="_self"
                                      className="vc_single_image-wrapper   vc_box_border_grey"
                                    >
                                      <img
                                        decoding="async"
                                        width={2560}
                                        height={1707}
                                        src="https://www.technogroup.com/wp-content/uploads/2021/08/Ref_HW_124242149-scaled.jpeg"
                                        className="vc_single_image-img attachment-full"
                                        alt="it-hardware_refurbished_hardware"
                                        title="People checking refurbished hardware"
                                        srcSet="https://www.technogroup.com/wp-content/uploads/2021/08/Ref_HW_124242149-scaled.jpeg 2560w, https://www.technogroup.com/wp-content/uploads/2021/08/Ref_HW_124242149-300x200.jpeg 300w, https://www.technogroup.com/wp-content/uploads/2021/08/Ref_HW_124242149-1024x683.jpeg 1024w, https://www.technogroup.com/wp-content/uploads/2021/08/Ref_HW_124242149-150x100.jpeg 150w, https://www.technogroup.com/wp-content/uploads/2021/08/Ref_HW_124242149-768x512.jpeg 768w, https://www.technogroup.com/wp-content/uploads/2021/08/Ref_HW_124242149-1536x1024.jpeg 1536w, https://www.technogroup.com/wp-content/uploads/2021/08/Ref_HW_124242149-2048x1365.jpeg 2048w"
                                        sizes="(max-width: 2560px) 100vw, 2560px"
                                      />
                                    </a>
                                  </figure>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-6">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="vc_empty_space"
                                  style={{ height: 32 }}
                                >
                                  <span className="vc_empty_space_inner" />
                                </div>
                                <header className="kd-section-title col-lg-12 text-center    ">
                                  <h2 className="separator_off">
                                    Sustainability is written large with
                                    Third-Party Maintenance
                                  </h2>
                                  <h6 className="subtitle ">
                                    Using refurbished hardware means suitable
                                    components, and thus valuable raw materials,
                                    are put back into circulation and reused. In
                                    the process hard disks, RAM memory, CPUs,
                                    controllers etc. are checked and
                                    professionally prepared for further use.
                                    Protecting both the environment and your
                                    budget.
                                    <p />
                                    <p>
                                      With refurbished hardware you can also
                                      counter shortages such as the current lack
                                      of semi-conductors You’ll be on the safe
                                      side with our refurbished and in-stock
                                      hardware. You receive the hardware with all
                                      the manufacturer’s quality criteria and
                                      benefit from lower costs. In order to keep
                                      your IT equipment available for a long time,
                                      we recommend a combination of refurbished
                                      hardware and professional hardware
                                      maintenance. A TPM maintenance agreement
                                      will secure your systems against
                                      interruptions and malfunctions in full –
                                      transparently and without any hidden costs.
                                      Your benefits here: During the maintenance
                                      agreement components will be replaced free
                                      of charge so your systems will be available
                                      without interruptions at all times.
                                    </p>
                                  </h6>
                                </header>
                                <div
                                  className="vc_empty_space"
                                  style={{ height: 15 }}
                                >
                                  <span className="vc_empty_space_inner" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vc_row-full-width vc_clearfix" />
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  className="vc_row wpb_row vc_row-fluid vc_custom_1629290994773 vc_row-has-fill vc_row-has-padding-top vc_row-has-padding-bottom"
                  style={{
                    position: "relative",
                    left: "-11.6667px",
                    boxSizing: "border-box",
                    width: 1263,
                    maxWidth: 1263,
                    paddingLeft: "11.6667px",
                    paddingRight: "11.3333px"
                  }}
                >
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner hide-on-mobile">
                      <div className="wpb_wrapper" />
                    </div>
                  </div>
                </div>
                <div className="vc_row-full-width vc_clearfix" />
                <div
                  id="renting"
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  className="vc_row wpb_row vc_row-fluid vc_custom_1635857759088 vc_row-has-padding-top vc_row-has-padding-bottom _mPS2id-t"
                  style={{
                    position: "relative",
                    left: "-11.6667px",
                    boxSizing: "border-box",
                    width: 1263,
                    maxWidth: 1263,
                    paddingLeft: "11.6667px",
                    paddingRight: "11.3333px"
                  }}
                >
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                          <div className="wpb_column vc_column_container vc_col-sm-6">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <header className="kd-section-title col-lg-12 text-center    ">
                                  <h2 className="separator_off">
                                    Renting hardware: Short terms with subsequent
                                    purchase option
                                  </h2>
                                </header>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-6">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <header className="kd-section-title col-lg-12 text-left    ">
                                  <h6 className="subtitle ">
                                    Another concept established in the electronics
                                    sector is loaning or renting hardware.
                                    Hardware rental is primarily a sensible
                                    alternative if the usage time will not be so
                                    long or if additional capacities have to be
                                    created for peak times. In this way you can
                                    cut costs, because purchasing hardware is also
                                    frequently associated with considerable
                                    expenses. We take care of installation and
                                    will also be there quickly in the event of any
                                    malfunctions, thus ensuring faults are fixed
                                    quickly, reliably and without any
                                    complications.
                                  </h6>
                                </header>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                          <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner vc_custom_1626962841352">
                              <div className="wpb_wrapper">
                                <header className="kd-section-title col-lg-12 text-center    ">
                                  <h2 className="separator_off">
                                    Your hardware rental benefits:
                                  </h2>
                                </header>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                          <div className="wpb_column vc_column_container vc_col-sm-3">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="key-icon-box icon-default icon-top cont-center ib-no-effect  kd-animated fadeIn"
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  <i
                                    className="iconsmind-Add-Cart iconsmind-Shopping-E-commerce"
                                    style={{}}
                                  />{" "}
                                  <h5 className="service-heading">
                                    Possible purchase option at the end of the
                                    rental period
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-3">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="key-icon-box icon-default icon-top cont-center ib-no-effect  kd-animated fadeIn"
                                  style={{ backgroundColor: "transparent" }}
                                  data-animation-delay={200}
                                >
                                  <i
                                    className="iconsmind-Financial iconsmind-Business-Finance"
                                    style={{}}
                                  />{" "}
                                  <h5 className="service-heading">
                                    Manageable monthly financial outgoings and
                                    cost benefits thanks to individual offers and
                                    financing possibilities
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-3">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="key-icon-box icon-default icon-top cont-center ib-no-effect  kd-animated fadeIn"
                                  style={{ backgroundColor: "transparent" }}
                                  data-animation-delay={600}
                                >
                                  <i
                                    className="iconsmind-Time-Backup iconsmind-Time"
                                    style={{}}
                                  />{" "}
                                  <h5 className="service-heading">
                                    Short rental terms of 1 to 12 months to
                                    support seasonal or order peaks and in the
                                    event of unexpected performance shortages
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-3">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="key-icon-box icon-default icon-top cont-center ib-no-effect  kd-animated fadeIn"
                                  style={{ backgroundColor: "transparent" }}
                                  data-animation-delay={600}
                                >
                                  <i
                                    className="iconsmind-Arrow-Loop iconsmind-Arrow-View"
                                    style={{}}
                                  />{" "}
                                  <h5 className="service-heading">
                                    Change systems easily and at short notice
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vc_row-full-width vc_clearfix" />
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  data-vc-stretch-content="true"
                  className="vc_row wpb_row vc_row-fluid vc_custom_1629290902860 vc_row-has-fill vc_row-has-padding-top vc_row-has-padding-bottom vc_row-no-padding"
                  style={{
                    position: "relative",
                    left: "-11.6667px",
                    boxSizing: "border-box",
                    width: 1263,
                    maxWidth: 1263
                  }}
                >
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner hide-on-mobile">
                      <div className="wpb_wrapper" />
                    </div>
                  </div>
                </div>
                <div className="vc_row-full-width vc_clearfix" />
                <div
                  id="repurchase"
                  className="vc_row wpb_row vc_row-fluid vc_custom_1635857816559 vc_row-has-padding-top vc_row-has-padding-bottom"
                >
                  <div className="wpb_column vc_column_container vc_col-sm-4">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <header className="kd-section-title col-lg-12 text-left    ">
                          <h2 className="separator_off">
                            Hardware repurchase and collection
                          </h2>
                        </header>
                      </div>
                    </div>
                  </div>
                  <div className="wpb_column vc_column_container vc_col-sm-4">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <header className="kd-section-title col-lg-12 text-left    ">
                          <h6 className="subtitle ">
                            There can be many reasons why hardware is sold. The
                            hardware is obsolete and no longer meets the company’s
                            requirements, new developments demand a technical
                            change or the hardware is simply no longer needed and
                            is taking up valuable space in the data centre.
                          </h6>
                        </header>
                      </div>
                    </div>
                  </div>
                  <div className="wpb_column vc_column_container vc_col-sm-4">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <header className="kd-section-title col-lg-12 text-left    ">
                          <h6 className="subtitle ">
                            With our brokerage service you have the opportunity to
                            resell or recycle this hardware securely, in an
                            environmentally-friendly way and without any
                            complications. You’ll be paid the remaining value of
                            the hardware but won’t have to do anything in the
                            process yourself. Data protection has top priority for
                            us, so you can rest assured that your data will be
                            irrevocably and securely erased from your hardware.
                          </h6>
                        </header>
                      </div>
                    </div>
                  </div>
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner vc_custom_1627022769261">
                      <div className="wpb_wrapper">
                        <header className="kd-section-title col-lg-12 text-center    ">
                          <h2 className="separator_off">
                            Your hardware resale benefits:
                          </h2>
                        </header>
                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                          <div className="wpb_column vc_column_container vc_col-sm-4">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="key-icon-box icon-default icon-top cont-center ib-no-effect  kd-animated fadeIn"
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  <i
                                    className="iconsmind-Server-2 iconsmind-Hardware"
                                    style={{}}
                                  />{" "}
                                  <h5 className="service-heading">
                                    Sell obsolete hardware quickly and without any
                                    complications
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-4">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="key-icon-box icon-default icon-top cont-center ib-no-effect  kd-animated fadeIn"
                                  style={{ backgroundColor: "transparent" }}
                                  data-animation-delay={200}
                                >
                                  <i
                                    className="iconsmind-Arrow-Mix iconsmind-Arrow-View"
                                    style={{}}
                                  />{" "}
                                  <h5 className="service-heading">
                                    Invest the remaining financial value of your
                                    hardware in new hardware/upgrades
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-4">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="key-icon-box icon-default icon-top cont-center ib-no-effect  kd-animated fadeIn"
                                  style={{ backgroundColor: "transparent" }}
                                  data-animation-delay={600}
                                >
                                  <i
                                    className="iconsmind-Environmental iconsmind-Nature"
                                    style={{}}
                                  />{" "}
                                  <h5 className="service-heading">
                                    Ecologically and socially responsible, because
                                    reusing equipment will protect the climate and
                                    budgets
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                          <div className="wpb_column vc_column_container vc_col-sm-4">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="key-icon-box icon-default icon-top cont-center ib-no-effect  kd-animated fadeIn"
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  <i
                                    className="iconsmind-Financial iconsmind-Business-Finance"
                                    style={{}}
                                  />{" "}
                                  <h5 className="service-heading">
                                    Offset part of your original hardware
                                    investment with upgrades or new infrastructure
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-4">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="key-icon-box icon-default icon-top cont-center ib-no-effect  kd-animated fadeIn"
                                  style={{ backgroundColor: "transparent" }}
                                  data-animation-delay={200}
                                >
                                  <i
                                    className="iconsmind-Gears iconsmind-User-Interface"
                                    style={{}}
                                  />{" "}
                                  <h5 className="service-heading">
                                    Our full-service package will take over the
                                    collection of your hardware
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-4">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="key-icon-box icon-default icon-top cont-center ib-no-effect"
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  <i
                                    className="iconsmind-Gaugage iconsmind-Transportation"
                                    style={{}}
                                  />{" "}
                                  <h5 className="service-heading">
                                    Reduce your CO2 footprint, save energy and
                                    recycle raw materials
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="spaas"
                  className="vc_row wpb_row vc_row-fluid vc_custom_1659539871920 vc_row-has-padding-bottom _mPS2id-t"
                >
                  <div className="wpb_column vc_column_container vc_col-sm-6">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <header className="kd-section-title col-lg-12 text-center    ">
                          <h2 className="separator_off">
                            The right spare parts in the right place at the right
                            time
                          </h2>
                        </header>
                      </div>
                    </div>
                  </div>
                  <div className="wpb_column vc_column_container vc_col-sm-6">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <p
                          style={{ textAlign: "left" }}
                          className="vc_custom_heading"
                        >
                          With our international spare parts management system
                          SPare as a Service™ we ensure you have the required
                          spare parts worldwide, when and where you need them, in
                          order to make sure your data centres operate around the
                          clock without any service interruptions. If necessary,
                          we deliver the spare part you need in the shortest time
                          from one of our 330 stocking locations worldwide so that
                          your data centres work 24/7 trouble-free.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vc_row wpb_row vc_row-fluid vc_custom_1659539695932 vc_row-has-padding-bottom">
                  <div className="wpb_column vc_column_container vc_col-sm-6">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <div className="wpb_single_image wpb_content_element vc_align_left">
                          <figure className="wpb_wrapper vc_figure">
                            <div className="vc_single_image-wrapper   vc_box_border_grey">
                              <img
                                decoding="async"
                                width={1024}
                                height={683}
                                src="https://www.technogroup.com/wp-content/uploads/2022/07/Evernex_Technician_Rack_Closeup-1-1-1024x683.jpg"
                                className="vc_single_image-img attachment-large"
                                alt="it-hardware_austausch"
                                title="Evernex_Technician_Rack_Closeup (1)"
                                srcSet="https://www.technogroup.com/wp-content/uploads/2022/07/Evernex_Technician_Rack_Closeup-1-1-1024x683.jpg 1024w, https://www.technogroup.com/wp-content/uploads/2022/07/Evernex_Technician_Rack_Closeup-1-1-300x200.jpg 300w, https://www.technogroup.com/wp-content/uploads/2022/07/Evernex_Technician_Rack_Closeup-1-1-150x100.jpg 150w, https://www.technogroup.com/wp-content/uploads/2022/07/Evernex_Technician_Rack_Closeup-1-1-768x512.jpg 768w, https://www.technogroup.com/wp-content/uploads/2022/07/Evernex_Technician_Rack_Closeup-1-1-1536x1024.jpg 1536w, https://www.technogroup.com/wp-content/uploads/2022/07/Evernex_Technician_Rack_Closeup-1-1.jpg 1620w"
                                sizes="(max-width: 1024px) 100vw, 1024px"
                              />
                            </div>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wpb_column vc_column_container vc_col-sm-6">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <header className="kd-section-title col-lg-12 text-center    ">
                          <h2 className="separator_off">Fail-safe data centre</h2>
                        </header>
                        <p
                          style={{ textAlign: "left" }}
                          className="vc_custom_heading"
                        >
                          Your IT infrastructure is the “heart” of your company.
                          It is therefore all the more important that the systems
                          relevant for smooth business operations run without
                          disruptions. True to our motto “keep IT running”, we are
                          quick problem solvers and, through our maintenance
                          service, help to prevent and rectify hardware failures
                          and extend the useful life of your IT systems in a
                          cost-effective manner.
                        </p>
                        <a
                          href="https://www.technogroup.com/en/data-centre-maintenance/"
                          target="_self"
                          title=""
                          className="tt_button tt_primary_button btn_primary_color  button-center button-action-link"
                        >
                          <span className="prim_text">Learn more</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="eosl-form"
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  className="vc_row wpb_row vc_row-fluid vc_custom_1627022931488 vc_row-has-fill"
                  style={{
                    position: "relative",
                    left: "-11.6667px",
                    boxSizing: "border-box",
                    width: 1263,
                    maxWidth: 1263,
                    paddingLeft: "11.6667px",
                    paddingRight: "11.3333px"
                  }}
                >
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1627023240630 vc_row-has-padding-top">
                          <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <header className="kd-section-title col-lg-12 text-center    ">
                                  <h2 className="separator_off">
                                    3 steps to your hardware
                                  </h2>
                                </header>
                                <header className="kd-section-title col-lg-12 text-left    ">
                                  <h6 className="subtitle ">
                                    Are you uncertain about which of our ranges
                                    and which hardware meets your requirements?
                                    Together with you, we look at what fits best
                                    for your company and will accompany you
                                    through the decision-making process in three
                                    steps:
                                  </h6>
                                </header>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_text_column wpb_content_element ">
                          <div className="wpb_wrapper">
                            <ol>
                              <li>
                                <b>Advice</b>
                                <span style={{ fontWeight: 400 }}>
                                  : First, we identify your needs and answer your
                                  questions about which hardware and which offer
                                  will meet your requirements.
                                </span>
                              </li>
                              <li>
                                <b>Procurement</b>: We have more than 850,000
                                spare parts and complete systems in stock, no
                                matter whether you need a completely refurbished
                                system for servers, storage, network equipment or
                                libraries, or an expansion of your existing
                                system.
                              </li>
                              <li>
                                <b>Installation</b>: Once you have chosen the
                                right system, our trained and certified IT
                                specialists will implement the hardware in your
                                system. No matter whether it’s refurbished or new
                                goods – our systems run reliably.
                              </li>
                            </ol>
                          </div>
                        </div>
                        <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1627023231344 vc_row-has-padding-top vc_row-has-padding-bottom">
                          <div className="wpb_column vc_column_container vc_col-sm-2 vc_col-lg-3">
                            <div className="vc_column-inner hide-on-mobile">
                              <div className="wpb_wrapper" />
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-8 vc_col-lg-6">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <header
                                  className="kd-section-title col-lg-12 text-left  kd-animated fadeIn  "
                                  data-animation-delay={200}
                                >
                                  <h2
                                    className="separator_off"
                                    style={{ fontSize: 42 }}
                                  >
                                    We’ll be there for you – personally
                                  </h2>
                                </header>
                                <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                                  <div className="wpb_wrapper">
                                    <iframe
                                      loading="lazy"
                                      src="https://go.evernex.com/l/863882/2023-06-19/brdvph"
                                      width="100%"
                                      height={500}
                                      type="text/html"
                                      frameBorder={0}
                                      allowTransparency="true"
                                      style={{ border: 0 }}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="vc_empty_space"
                                  style={{ height: 50 }}
                                >
                                  <span className="vc_empty_space_inner" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-2 vc_col-lg-3">
                            <div className="vc_column-inner hide-on-mobile">
                              <div className="wpb_wrapper" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vc_row-full-width vc_clearfix" />
              </section>{" "}
            </div>
          </div>
        </section>
      </main>
      {/* #main */}
    </div>
    {/* #primary */}
  </div>
  
  )
}

export default HardwareSales