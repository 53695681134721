import React from "react";
import GlobalPresence from "../../Components/GlobalPresence/GlobalPresence";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";
import DeliveryMissionCritical from "../../Components/DeliveryMissionCritical/DeliveryMissionCritical";
import ContactForm from "../../Components/ContactForm/ContactForm";
import OpportunityEdgeContainer from "../../Components/OpportunityEdgeContainer/OpportunityEdgeContainer";
import LatestInsights from "../../Components/LatestInsights/LatestInsights";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";
import JoinCommunity from "../../Components/JoinCommunity/JoinCommunity";
import ServiceSlider from "../../Components/ServiceSlider/ServiceSlider";

const DataCenterInfrastructure = () => {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/data-center-infrastructure-banner.webp)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Data Center Infrastructure Solutions</h1>
              <p className="mt-5 text-warning" style={{fontSize:'30px'}}>
              At DataCentrix™, We Prioritize Close Collaboration With Our Clients To Tailor Our Data Center Engineering Services To Their Specific Needs. Our Offerings Encompass A Wide Range Of Design, Construction, And Support Solutions, All Aimed At Boosting Infrastructure Availability While Minimizing Operational Expenses
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section className="data-center-global-coverage-container wrapper bg-light mt-5 p-5 text-muted">
        <div className="container-fluid py-10">
          <div className="row gx-md-8 gx-xl-12 gy-10 pt-5 mb-10 mb-md-12 align-items-center">
            <div className="col-lg-6 order-lg-2">
              <div className="card shadow-lg me-lg-6 border-0 my-4 py-4 px-2 text-muted right-cross-card">
                <div className="card-body p-6">
                  <div className="d-flex flex-row">
                    <div>
                      <h5 className="mb-1 fw-bold">Uninterrupted Global Support: 24/7 Coverage</h5>
                      <p className="mb-0 fs-5">
                      Responsive and Agile 24/7 Customer Service Helpdesk: Efficient Ticket Management and Global Dispatch of Technical and Logistics Services
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card shadow-lg ms-lg-13 mt-6 border-0  my-4 py-4 px-2 ms-lg-5 text-muted right-cross-card">
                <div className="card-body p-6">
                  <div className="d-flex flex-row">
                    <div>
                      <h5 className="mb-1 fw-bold">
                      Managing Global Workforce and Programs
                      </h5>
                      <p className="mb-0 fs-5">
                      Access a Global Network of Over 6000+ Skilled L1 – L5 Engineers, Providing Trusted Local Resources for Last-Mile 4-Hour and Next Business Day (NBD) Repair Services.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card shadow-lg mx-lg-6 mt-6 border-0  my-4 py-4 px-2 text-muted right-cross-card">
                <div className="card-body p-6">
                  <div className="d-flex flex-row">
                    <div>
                      <h4 className="mb-1">
                      On-Demand Smart Hands Technicians Available
                      </h4>
                      <p className="mb-0 fs-5">
                      Access On-Demand Smart Hands Technicians Locally for Independent or Collaborative Last-Mile Tasks with Level 2 Support Techs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 my-5">
              <h2 className="mb-3 text-center text-lg-start">
                Global Coverage
              </h2>
              <h3 className="mb-5 text-center text-lg-start">
              Comprehensive Data Center Managed Services for Infrastructure Management
              </h3>
              <p className="fs-5">
              DataCentrix™ offers a comprehensive range of services and global capabilities for Datacenter clients, which can be seamlessly integrated to deliver a complete Turnkey Solution for your organization. Our clients demand fast, transparent, and high-quality support services, as any inefficiencies can impact their business negatively. We empower data center managers to access top-tier technical services without the need for expensive infrastructure investments in regions where resources are costly to maintain. With DataCentrix™ Turnkey Field Service Solution, data center organizations can offer agile services throughout the entire supply chain, from procurement and configuration to post-deployment support.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="data-center-end-to-end-service-container mt-5 p-5 text-muted">
        <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 p-5">
            <img
              src="/images/end-to-end.webp"
              className="img-fluid"
              alt=""
              srcset=""
            />
          </div>
          <div className="col-lg-6 p-4">
            <div className="row">
              <h1 className="text-center text-lg-start">
              End-to-End Services Engineered for Business Outcomes
              </h1>
            </div>
            <div className="row fs-5 text-muted">
              <p>
              We provide a distinctive end-to-end service, spanning from equipment configuration and shipping to rack power provisioning. Whether for a single site or a complex installation spanning hundreds of locations worldwide, we ensure timely and budget-friendly completion, all while maintaining stringent quality standards.
              </p>
            </div>
            <div className="row fs-5 text-muted mt-5">
              <div className="col-lg-6 col-12 my-3 my-lg-0">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                <ul className="list-unstyled">
                  <li className="my-3 d-flex flex-row">
                    <i class="bi bi-check2 text-warning fs-4 mx-2" />
                    <span className="fs-5">Rack and Stack Services: Server Builds & Configuration</span>
                  </li>
                  <li className="my-3 d-flex flex-row">
                    <i class="bi bi-check2 text-warning fs-4 mx-2" />
                    <span className="fs-5">Colocation and Containerization Solutions</span>
                  </li>
                  <li className="my-3 d-flex flex-row">
                    <i class="bi bi-check2 text-warning fs-4 mx-2" />
                    <span className="fs-5">Structured cabling</span>
                  </li>
                  <li className="my-3 d-flex flex-row">
                    <i class="bi bi-check2 text-warning fs-4 mx-2" />
                    <span className="fs-5">Cross connects and MMR</span>
                  </li>
                </ul>
                </div>
                </div>
              </div>
              <div className="col-lg-6 col-12 my-3 my-lg-0">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                <ul className="list-unstyled">
                  <li className="my-3 d-flex flex-row">
                    <i class="bi bi-check2 text-warning fs-4 mx-2" />
                    <span className="fs-5">Smart Hands Services: Break-Fix, Spares, and Repairs</span>
                  </li>
                  <li className="my-3 d-flex flex-row">
                    <i class="bi bi-check2 text-warning fs-4 mx-2" />
                    <span className="fs-5">Asset inventory</span>
                  </li>
                  <li className="my-3 d-flex flex-row">
                    <i class="bi bi-check2 text-warning fs-4 mx-2" />
                    <span className="fs-5">Maintenance and upgrades</span>
                  </li>
                  <li className="my-3 d-flex flex-row">
                    <i class="bi bi-check2 text-warning fs-4 mx-2" />
                    <span className="fs-5">Equipment disposal and certification</span>
                  </li>
                </ul>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
      <DeliveryMissionCritical />
      <OpportunityEdgeContainer />
      <GlobalPresence />
      <ServiceSlider />
      <JoinCommunity />
      <LatestInsights />
      <LetsConnect />
    </React.Fragment>
  );
};

export default DataCenterInfrastructure;
