import React, { useState } from 'react'
import BookMeeting from '../../Components/BookMeeting/BookMeeting'
import JoinCommunity from '../../Components/JoinCommunity/JoinCommunity'
import GlobalPresence from '../../Components/GlobalPresence/GlobalPresence'
import { customer_accordian, reduce_accordian, talentpool_accordian, talentcatalog_accordian, experience_accordian, contractsManagement_accordian, worker_accordian, comprehensive_accordian, rulebased_accordian } from '../../Services/ConstantVariables'
import Accordian from '../../Components/Accordian/Accordian'
import ServiceSlider from '../../Components/ServiceSlider/ServiceSlider'
import GlobalBrands from '../../Components/GlobalBrands/GlobalBrands'

const TalentMarketplace = () => {
    const [customer_accordianData, setCustomerAccordianData] = useState(customer_accordian);
    const [reduce_accordianData, setReduceAccordianData] = useState(reduce_accordian);
    const [talent_accordianData, setTalentAccordianData] = useState(talentcatalog_accordian);
    const [experience_accordianData, setExperienceAccordianData] = useState(experience_accordian);
    const [contract_accordianData, setContractAccordianData] = useState(contractsManagement_accordian);
    const [worker_accordianData, setWorkerAccordianData] = useState(worker_accordian);
    const [comprehensive_accordianData, setComprehensiveAccordianData] = useState(comprehensive_accordian);
    const [rulebased_accordianData, setRulebasedAccordianData] = useState(rulebased_accordian);
    const [talentpool_accordianData, setTalentpoolAccordianData] = useState(talentpool_accordian);


    // const _accordianHandler = (accordianIndex, data, updateFunction) => {
    //     let updatedAccordianData = data.map((element, i) => {
    //         if (accordianIndex == i) {
    //             element.isOpen = !element.isOpen;
    //         }
    //         else {
    //             element.isOpen = false;
    //         }
    //         return element;
    //     })
    //     updateFunction(updatedAccordianData)
    // }
    return (
        <React.Fragment>
            <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/talent-market-place-banner.webp)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Integrated Talent Management Cloud & Marketplace</h1>
              <p className="mt-5 text-warning text-capitalize" style={{fontSize:'30px'}}>
              Specialized IT Infrastructure Talent Marketplace: Easy access to vetted talent pool and vendor networks driven by proprietary AI technology
              </p>
            </div>
          </div>
        </div>
      </section>
            <GlobalBrands />
            <section className="wrapper mt-5 p-5 bg-light text-muted">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-xl-10 col-xxl-12 text-center">
                            <h3 className="mb-3">What We Do?</h3>
                            <h5 className="mb-9">
                                Our service is tailored to meet your talent needs.
                            </h5>
                        </div>
                    </div>
                    <div className="row gx-md-8 gy-8 mt-5">
                        <div className="col-md-6 col-lg-4 d-flex my-3">
                            <div className="card shadow-lg text-muted h-100 main-card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <i class="bi bi-hourglass-bottom text-warning fs-2 mx-2"></i>
                                    </div>
                                    <h5 className='my-2 text-center fw-bold'>Client Self-Help Portal</h5>
                                    <p className="mb-3 fs-5">
                                        Easily request and manage talent requirements, budgets, project progress, and costs from one central location at no additional cost.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 d-flex my-3">
                            <div className="card shadow-lg text-muted h-100 main-card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <i class="bi bi-diagram-3-fill text-warning fs-2 mx-2"></i>
                                    </div>
                                    <h5 className='my-2 text-center fw-bold text-capitalize'>Ready Talent Pipeline</h5>
                                    <p className="mb-3 fs-5">
                                        Access and search a large pool of qualified talent vendors and vetted candidates with ease, utilizing cutting-edge AI technology for seamless matching.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 d-flex my-3">
                            <div className="card shadow-lg text-muted h-100 main-card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <i class="bi bi-node-minus text-warning fs-2 mx-2"></i>
                                    </div>
                                    <h5 className='my-2 text-center fw-bold'>Global Talent Catalog - Ready Private Marketplace</h5>
                                    <p className="mb-3 fs-5">
                                        Curate and procure talent through a Build-Buy-Borrow-Bridge model, including early talent programs, internal capability development, lateral hiring, and resource onboarding via strategic vendors. Access our inclusive global service locator with 300,000+ direct and agency workers.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 d-flex my-3">
                            <div className="card shadow-lg text-muted h-100 main-card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <i class="bi bi-pc-display-horizontal text-warning fs-2 mx-2"></i>
                                    </div>
                                    <h5 className='my-2 text-center fw-bold'>Talent Supply Chain Management</h5>
                                    <p className="mb-3 fs-5">
                                        Curate and manage Geo Metro level resource pools, Demand Plan Management, access Global Talent-as-a-Service module, SKU Marketplace Catalog, and 1-click Recruitment (Source, vet, match, onboard & manage).
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 d-flex my-3">
                            <div className="card shadow-lg text-muted h-100 main-card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <i class="bi bi-suitcase-lg-fill text-warning fs-2 mx-2"></i>
                                    </div>
                                    <h5 className='my-2 text-center fw-bold'>Contracts Management</h5>
                                    <p className="mb-3 fs-5">
                                        Manage global service contracts and contingency contracts (SOW) for consultants, agency temporary workers, internal contractors, and internal temporary workers.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 d-flex my-3">
                            <div className="card shadow-lg text-muted h-100 main-card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <i class="bi bi-cloud-check-fill text-warning fs-2 mx-2"></i>
                                    </div>
                                    <h5 className='my-2 text-center fw-bold'>Worker &amp; Work Management</h5>
                                    <p className="mb-3 fs-5">
                                        Streamline contract management, amendments, timesheets, payments, and more through one integrated self-service portal (VMS) for vendors (agencies) and candidates/workers, ensuring seamless workflow and end-to-end visibility.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 d-flex my-3">
                            <div className="card shadow-lg text-muted h-100 main-card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <i class="bi bi-person-add text-warning fs-2 mx-2"></i>
                                    </div>
                                    <h5 className='my-2 text-center fw-bold'>Workforce Human Resource Operations</h5>
                                    <p className="mb-3 fs-5">
                                        Manage detailed resource skill profiles, performance management, execute upskilling & reskilling of talent, extensive self and project learning management system, HRIS & HCM system, and global human resource compliance planning and DE&I initiatives.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 d-flex my-3">
                            <div className="card shadow-lg text-muted h-100 main-card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <i class="bi bi-globe2 text-warning fs-2 mx-2"></i>
                                    </div>
                                    <h5 className='my-2 text-center fw-bold'>Global Compliance Management</h5>
                                    <p className="mb-3 fs-5">
                                        Rest assured, all compliance requirements are handled regardless of your workers' visa status, onsite/remote status, or geographical location, providing you with peace of mind.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 d-flex my-3">
                            <div className="card shadow-lg text-muted h-100 main-card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <i class="bi bi-person-vcard-fill text-warning fs-2 mx-2"></i>
                                    </div>
                                    <h5 className='my-2 text-center fw-bold'>Talent Pool Management</h5>
                                    <p className="mb-3 fs-5">
                                        Skillset Approved Metro-Level Worker Pools, Approved Project Vendor Pools, Worker Availability and Scheduling Manager, Global Talent Pool.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wrapper mt-5 p-5 text-muted">
                <div className="container">
                    <div className="row gy-10 gy-sm-13 gx-lg-3 align-items-center">
                        <div className="col-lg-6 position-relative">
                            <div
                                className="shape bg-dot primary rellax w-17 h-21"
                                style={{ top: "-2rem", left: "-1.9rem" }}
                            />
                            <div
                                className="shape rounded bg-soft-primary rellax d-md-block"
                                style={{
                                    bottom: "-1.8rem",
                                    right: "-1.5rem",
                                    width: "85%",
                                    height: "90%"
                                }}
                            />
                            <figure className="rounded">
                                <img src="images/success.jpg" alt="" srcset="" />
                            </figure>
                        </div>
                        <div className="col-lg-5 col-xl-4 offset-lg-1">
                            <h3 className="mb-3 text-center text-lg-start mt-5 mt-lg-0">How It Works?</h3>
                            <p className="fs-5 mb-7 text-center text-lg-start">
                                Here are the 3 working steps on success:
                            </p>
                            <div className="d-flex flex-row mb-6 mt-3">
                                <div>
                                    <span className="number fs-4 text-warning mx-3">1</span>
                                </div>
                                <div>
                                    <h5 className="mb-1">Post a Job Requisition</h5>
                                    <p className="mb-0 fs-5">
                                        Effortlessly craft job descriptions using our Job Catalog.
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex flex-row mb-6 mt-3">
                                <div>
                                    <span className="number fs-4 text-warning mx-3">2</span>
                                </div>
                                <div>
                                    <h5 className="mb-1">Receive auto-recommendation</h5>
                                    <p className="mb-0 fs-5">
                                        AI recommends matching talents, talent providers, and talent pools. Select, connect, and hire seamlessly
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex flex-row mt-3">
                                <div>
                                    <span className="number fs-4 text-warning mx-3">3</span>
                                </div>
                                <div>
                                    <h5 className="mb-1">Hire and manage talent</h5>
                                    <p className="mb-0 fs-5">
                                        Contract, onboard, manage talent. Track timesheets, milestones, payments, performance efficiently for seamless operations.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="wrapper mt-5 p-5 text-muted bg-light"
                id="customer-self-service"
            >
                <div className="container">
                    <div className="row gy-10 gy-sm-13 gx-lg-3 align-items-center">
                        <div className="col-lg-6 offset-lg-1 order-lg-2 position-relative">
                            <div
                                className="shape rounded-circle bg-line primary rellax w-18 h-18"
                                style={{ top: "-2rem", right: "-1.9rem" }}
                            />
                            <div
                                className="shape rounded bg-soft-primary rellax d-md-block"
                                style={{
                                    bottom: "-1.8rem",
                                    left: "-1.5rem",
                                    width: "85%",
                                    height: "90%"
                                }}
                            />
                            <figure className="rounded">
                                <img src="images/talent2.webp" alt="" srcset="" />
                            </figure>
                        </div>
                        <div className="col-lg-5">
                            <h3 className="mb-3 text-center text-lg-start mt-5 mt-lg-0">
                                Customer Self Service Portal
                            </h3>
                            <Accordian 
                            data={customer_accordianData} 
                            // accordianHandler={_accordianHandler} 
                            // updateFunction={setCustomerAccordianData} 
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="wrapper mt-5 p-5 text-muted" id="talent-pipeline">
                <div className="container">
                    <div className="row gy-10 gy-sm-13 gx-lg-3 align-items-center">
                        <div className="col-lg-6 position-relative">
                            <div
                                className="shape bg-dot primary rellax w-17 h-21"
                                style={{ top: "-2rem", left: "-1.9rem" }}
                            />
                            <div
                                className="shape rounded bg-soft-primary rellax d-md-block"
                                style={{
                                    bottom: "-1.8rem",
                                    right: "-1.5rem",
                                    width: "85%",
                                    height: "90%"
                                }}
                            />
                            <figure className="rounded">
                                <img src="images/talent-pipeline.webp" alt="" srcset="" />
                            </figure>
                        </div>
                        <div className="col-lg-5 col-xl-5 offset-lg-1">
                            <h3 className="mb-3 text-center text-lg-start mt-5 mt-lg-0">
                                Ready talent pipeline
                            </h3>
                            <p className="fs-5 mb-7 text-center text-lg-start">
                                Reduce hiring time from months to a few days or a few hours.
                            </p>
                            <div className="accordion accordion-wrapper" id="accordionExampleA">
                                <Accordian 
                                data={reduce_accordianData} 
                                // accordianHandler={_accordianHandler}
                                //  updateFunction={setReduceAccordianData} 
                                 />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="wrapper mt-5 p-5 text-muted bg-light"
                id="global-talent-as-a-catalog"
            >
                <div className="container">
                    <div className="row gy-10 gy-sm-13 gx-lg-3 align-items-center">
                        <div className="col-lg-6 offset-lg-1 order-lg-2 position-relative">
                            <div
                                className="shape rounded-circle bg-line primary rellax w-18 h-18"
                                style={{ top: "-2rem", right: "-1.9rem" }}
                            />
                            <div
                                className="shape rounded bg-soft-primary rellax d-md-block"
                                style={{
                                    bottom: "-1.8rem",
                                    left: "-1.5rem",
                                    width: "85%",
                                    height: "90%"
                                }}
                            />
                            <figure className="rounded">
                                <img src="images/talent-img-01.webp" alt="" srcset="" />
                            </figure>
                        </div>
                        <div className="col-lg-5">
                            <h3 className="mb-3 text-center text-lg-start mt-5 mt-lg-0 text-capitalize">
                                Global talent-as-a-catalog – Private Marketplace ready
                            </h3>
                            <p className="fs-5 mb-7 text-center text-lg-start">
                                We construct a comprehensive talent catalog, employing a holistic build-buy-borrow-bridge approach
                            </p>
                            <div className="accordion accordion-wrapper" id="accordionExampleB">
                            <Accordian 
                            data={talent_accordianData} 
                            // accordianHandler={_accordianHandler} 
                            // updateFunction={setTalentAccordianData} 
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="wrapper mt-5 p-5 text-muted"
                id="talent-supply-chain-management"
            >
                <div className="container">
                    <div className="row gy-10 gy-sm-13 gx-lg-3 align-items-center">
                        <div className="col-lg-6 position-relative">
                            <div
                                className="shape bg-dot primary rellax w-17 h-21"
                                style={{ top: "-2rem", left: "-1.9rem" }}
                            />
                            <div
                                className="shape rounded bg-soft-primary rellax d-md-block"
                                style={{
                                    bottom: "-1.8rem",
                                    right: "-1.5rem",
                                    width: "85%",
                                    height: "90%"
                                }}
                            />
                            <figure className="rounded">
                                <img src="images/supply-management.jpeg" height={500} width={600} alt="" srcset="" />
                            </figure>
                        </div>
                        <div className="col-lg-5 col-xl-5 offset-lg-1">
                            <h3 className="mb-3 text-center text-lg-start mt-5 mt-lg-0">
                                Talent Supply Chain Optimization
                            </h3>
                            <p className="fs-5 mb-7 text-center text-lg-start">
                                Explore Global Talent-as-a-Service Models Directly
                            </p>
                            <div className="accordion accordion-wrapper" id="accordionExampleC">
                            <Accordian 
                            data={experience_accordianData} 
                            // accordianHandler={_accordianHandler} 
                            // updateFunction={setExperienceAccordianData} 
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="wrapper mt-5 p-5 text-muted bg-light"
                id="contracts-management"
            >
                <div className="container">
                    <div className="row gy-10 gy-sm-13 gx-lg-3">
                        <div className="col-lg-6 offset-lg-1 order-lg-2 position-relative">
                            <div
                                className="shape rounded-circle bg-line primary rellax w-18 h-18"
                                style={{ top: "-2rem", right: "-1.9rem" }}
                            />
                            <div
                                className="shape rounded bg-soft-primary rellax d-md-block"
                                style={{
                                    bottom: "-1.8rem",
                                    left: "-1.5rem",
                                    // width: "85%",
                                    // height: "90%"
                                }}
                            />
                            <figure className="rounded">
                                <img src="images/contract-img.jpeg" alt="" srcset="" />
                            </figure>
                        </div>
                        <div className="col-lg-5 col-xl-5">
                            <h3 className="mb-3 text-center text-lg-start mt-5 mt-lg-0">
                                Contracts Management
                            </h3>
                            <p className="fs-5 mb-7 text-center text-lg-start">
                                Create Contracts Instantly with One Click!
                            </p>
                            <div className="accordion accordion-wrapper" id="accordionExampleD">
                            <Accordian 
                            data={contract_accordianData} 
                            // accordianHandler={_accordianHandler} 
                            // updateFunction={setContractAccordianData} 
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="wrapper mt-5 p-5 text-muted"
                id="worker-work-management"
            >
                <div className="container">
                    <div className="row gy-10 gy-sm-13 gx-lg-3">
                        <div className="col-lg-6 position-relative">
                            <div
                                className="shape bg-dot primary rellax w-17 h-21"
                                style={{ top: "-2rem", left: "-1.9rem" }}
                            />
                            <div
                                className="shape rounded bg-soft-primary rellax d-md-block"
                                style={{
                                    bottom: "-1.8rem",
                                    right: "-1.5rem",
                                    // width: "85%",
                                    // height: "90%"
                                }}
                            />
                            <figure className="rounded">
                                <img src="images/talent-management-img.webp" alt="" srcset="" />
                            </figure>
                        </div>
                        <div className="col-lg-5 col-xl-5 offset-lg-1">
                            <h3 className="mb-3 text-center text-lg-start mt-5 mt-lg-0">
                                Worker & Work Management Solutions
                            </h3>
                            <p className="fs-5 mb-7 text-center text-lg-start">
                                Comprehensive Talent Management Post-Hire.
                            </p>
                            <div className="accordion accordion-wrapper" id="accordionExampleE">
                            <Accordian 
                            data={worker_accordianData} 
                            // accordianHandler={_accordianHandler} 
                            // updateFunction={setWorkerAccordianData} 
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="wrapper mt-5 p-5 text-muted bg-light"
                id="workforce-human-resource-operations"
            >
                <div className="container">
                    <div className="row gy-10 gy-sm-13 gx-lg-3">
                        <div className="col-lg-6 offset-lg-1 order-lg-2 position-relative">
                            <div
                                className="shape rounded-circle bg-line primary rellax w-18 h-18"
                                style={{ top: "-2rem", right: "-1.9rem" }}
                            />
                            <div
                                className="shape rounded bg-soft-primary rellax d-md-block"
                                style={{
                                    bottom: "-1.8rem",
                                    left: "-1.5rem",
                                    // width: "85%",
                                    // height: "90%"
                                }}
                            />
                            <figure className="rounded">
                                <img src="images/hr-management.webp" alt="" srcset="" />
                            </figure>
                        </div>
                        <div className="col-lg-5 col-xl-5">
                            <h3 className="mb-3 text-center text-lg-start mt-5 mt-lg-0">
                                Workforce Human Resource Operations Solutions
                            </h3>
                            <p className="fs-5 mb-7 text-center text-lg-start">
                                Versatile HRIS and HCM Systems for Varied Talent Needs
                            </p>
                            <div className="accordion accordion-wrapper" id="accordionExampleF">
                            <Accordian 
                            data={comprehensive_accordianData} 
                            // accordianHandler={_accordianHandler} 
                            // updateFunction={setComprehensiveAccordianData} 
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="wrapper mt-5 p-5 text-muted"
                id="talent-pools-management"
            >
                <div className="container">
                    <div className="row gy-10 gy-sm-13 gx-lg-3">
                        <div className="col-lg-6 offset-lg-1 order-lg-2 position-relative">
                            <div
                                className="shape rounded-circle bg-line primary rellax w-18 h-18"
                                style={{ top: "-2rem", right: "-1.9rem" }}
                            />
                            <div
                                className="shape rounded bg-soft-primary rellax d-md-block"
                                style={{
                                    bottom: "-1.8rem",
                                    left: "-1.5rem",
                                    // width: "85%",
                                    // height: "90%"
                                }}
                            />
                            <figure className="rounded">
                                <img src="images/talent-pool-management.webp" alt="" srcset="" />
                            </figure>
                        </div>
                        <div className="col-lg-5 col-xl-5">
                            <h3 className="mb-3 text-center text-lg-start mt-5 mt-lg-0">
                                Talent Pool Management
                            </h3>
                            <p className="fs-5 mb-7 text-center text-lg-start">
                                Efficiently Group and Manage Workforce into Talent Pools
                            </p>
                            <div className="accordion accordion-wrapper" id="accordionExamplePool">
                            <Accordian 
                            data={talentpool_accordianData} 
                            // accordianHandler={_accordianHandler} 
                            // updateFunction={setTalentpoolAccordianData} 
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wrapper mt-5 p-5 text-muted">
                <div className="container">
                    <div className="row gy-10 gy-sm-13 gx-lg-3 align-items-center">
                        <div className="col-lg-6 position-relative">
                            <figure className="rounded">
                                <img src="images/career-growth.jpg" height={400} width={500} alt="" srcset="" />
                            </figure>
                        </div>
                        <div className="col-lg-5 col-xl-5 offset-lg-1">
                            <h3 className="mb-7 text-center text-lg-start mt-5 mt-lg-0">Career Growth</h3>
                            <p className='fs-5 text-center text-lg-start'>
                                Join our community of innovators where support and challenge thrive. Many open positions are filled internally, fostering career growth and new experiences.
                            </p>
                            <a
                                href="https://ironsystems-sites.secure.force.com/Jobs/ts2__Register?uc=add"
                                target="_blank"
                                className="btn btn-outline-warning rounded-pill mb-0"
                            >
                                Join Our Team
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <JoinCommunity />
            <GlobalPresence />
            <ServiceSlider />
        </React.Fragment>
    )
}

export default TalentMarketplace