import React from 'react'

const LongerServiceLife = () => {
    return (
        <section>
            <div className="container-fluid text-muted">
                <div className="row mt-5 py-5">
                    <div className="col-lg-6 px-lg-5">
                        <h3 className="text-center text-lg-start text-capitalize">Extend the lifespan of your equipment. Discover our end-of-life services for tape libraries.</h3>
                        <p className='fs-5 text-center text-lg-start text-capitalize'>We assess the economic viability of your tape libraries to ensure operational efficiency.</p>
                    </div>
                    <div className="col-lg-6 px-5">
                        <div className="row mt-3">
                            <div className="col-lg-6 my-3">
                                <div className="card border-0 my-md-2 shadow-lg text-muted h-100 hover-metic">
                                    
                                    <div className="card-body fs-5 mt-2">
                                    <h5 className="py-2 h5 fw-bold text-center">
                                    Dependable hardware solutions.
                                    </h5>
                                   <p className='text-start'> Even with older libraries, we ensure high availability remains a constant</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6  my-3">
                                <div className="card border-0 my-md-2 shadow-lg text-muted h-100 hover-metic">
                                    
                                    <div className="card-body fs-5 mt-2">
                                    <h5 className="py-2 h5 fw-bold text-center">
                                    Environmentally-conscious operations.
                                    </h5>
                                    <p className='text-start'>No need for premature replacement of functioning tape libraries</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6  my-3">
                                <div className="card border-0 my-md-2 shadow-lg text-muted h-100 hover-metic">
                                   
                                    <div className="card-body fs-5 mt-2">
                                    <h5 className="py-2 h5 fw-bold text-center">
                                        Savings
                                    </h5>
                                    <p className='text-start'>You can postpone investing in costly new equipment for now</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6  my-3">
                                <div className="card border-0 my-md-2 shadow-lg text-muted h-100 hover-metic">
                                    
                                    <div className="card-body fs-5 mt-2">
                                    <h5 className="py-2 h5 fw-bold text-center">
                                        Fast support
                                    </h5>
                                    <p className='text-start'>We're here round-the-clock, every day of the year, for all your spare parts needs</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LongerServiceLife