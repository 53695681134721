import React from "react";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";
import GlobalPresence from "../../Components/GlobalPresence/GlobalPresence";
import LatestInsights from "../../Components/LatestInsights/LatestInsights";
import ServiceSlider from "../../Components/ServiceSlider/ServiceSlider";
import JoinCommunity from "../../Components/JoinCommunity/JoinCommunity";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";

function DataInfrastructure() {
  return (
    <React.Fragment>
        <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(/images/digital-workplace-bannner.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Digital Infrastructure Solutions</h1>
              <p className="mt-5 text-warning" style={{fontSize:'30px'}}>
              With A Global Workforce and Advanced Logistics Capabilities, We Lead In Delivering Comprehensive Services Throughout Every Project Stage. Our Vital Expertise Aids In Building, Maintaining, and Expanding Global Digital Infrastructure</p>
            </div>
          </div>
        </div>
      </section>
        <GlobalBrands />
        <section className="wrapper bg-light mt-5 p-5 text-muted">
          <div className="container py-10">
            <div className="card my-5 text-muted">
              <div className="card-body p-0">
                <div className="row p-5">
                  <div className="col-lg-6">
                    <h3 className="mb-3">
                    Data Center Infrastructure Solutions
                    </h3>
                    <p className="fs-5">
                    We recognize the pivotal role data centers play in the modern economy. As the landscape of data center design and operations evolves, DataCentrix™ remains steadfast. From advancing network speeds to 400 Gbps+, enhancing compute density per server, deploying GPU-powered servers for AI/ML, and meeting rising power density demands, we continually adapt to exceed customer expectations.
                    </p>
                  </div>
                  <div className="col-lg-6 imgFullHeight my-5 my-lg-0">
                    <img src='images/data-center-hd.jpg' />
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-5 text-muted">
              <div className="card-body p-0">
                <div className="row p-5">
                  <div className="col-lg-6">
                    <h3 className="mb-3">
                    On-Premises Infrastructure Solutions
                    </h3>
                    <p className="fs-5">
                    For modern enterprise applications to scale rapidly and deliver fast user access, precise planning and execution are crucial. DataCentrix's Infrastructure services offer expertise, tools, and resources to scale and future-proof your data center architecture. Whether upgrading existing infrastructure, migrating to the cloud, or building a hybrid environment, we help you navigate the complexities and prepare for future demands.
                    </p>
                  </div>
                  <div className="col-lg-6 imgFullHeight my-5 my-lg-0">
                    <img src='images/on-premise-infra-hd.webp' />
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-5 text-muted">
              <div className="card-body p-0">
                <div className="row p-5">
                  <div className="col-lg-6">
                    <h3 className="mb-3">Cloud Infrastructure Solutions</h3>
                    <p className="fs-5">
                    Cloud is imperative for innovation, cost reduction, and market expansion. Whether embarking on a cloud journey or optimizing existing investments, our capabilities enable you to harness its power for productivity, competitiveness, and growth. Leveraging cloud engineering expertise, we facilitate tomorrow's success through diverse services, embracing modern DevOps practices like containerization.
                    </p>
                  </div>
                  <div className="col-lg-6 imgFullHeight my-5 my-lg-0">
                    <img src='images/cloud-infra-hd.jpg' />
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-5 text-muted">
              <div className="card-body p-0">
                <div className="row p-5">
                  <div className="col-lg-6">
                    <h3 className="mb-3">
                    Edge Computing and Infrastructure Solutions
                    </h3>
                    <p className="fs-5">
                    Leveraging hyperscale cloud providers, private 5G, and IoT, organizations can implement networks, operations, and edge computing for real-time automation and processing. Achieving this demands a specialized infrastructure, including devices, power systems, and cooling, along with efficient provisioning, management, and scalability. DataCentrix's team comprehends these intricate challenges and offers expertise in building and maintaining global edge infrastructure. With our global workforce and project management capabilities, we support organizations in achieving their edge infrastructure objectives.
                    </p>
                  </div>
                  <div className="col-lg-6 imgFullHeight my-5 my-lg-0">
                    <img src='images/edge-computing-img.jpeg' />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="card mb-5 text-muted">
                  <div className="card-body p-0">
                    <div className="row p-5">
                    <div className="col-lg-6">

                    <h3 className="mb-3">Connectivity</h3>
                    <p className="fs-5">
                    Leverage our global teams' extensive procurement and deployment experience of Last Mile Links in over 80+ countries to ensure service everywhere.
                    </p>
                    </div>
                    <div className="col-lg-6 my-5">
                  <img src='images/connectivity.jpg' />
                  </div>
                  </div>
                 
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card mb-5 text-muted">
                  <div className="card-body p-0">
                    <div className="row p-5">
                    <div className="col-lg-6">
                    <h3 className="mb-3">Network Operations</h3>
                    <p className="fs-5">
                    Maximize uptime and service levels by optimizing network performance. Minimize disruption with smart strategies ensuring availability and reliability.
                    </p>
                    </div>
                    <div className="col-lg-6 my-5">
                  <img src='images/network-operations.webp' />
                  </div>
                  </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
        <GlobalPresence />
        <ServiceSlider />
        <JoinCommunity />
        <LatestInsights />
        <LetsConnect />
    </React.Fragment>
  );
}

export default DataInfrastructure;
