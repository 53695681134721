import React from "react";
import TalkToAnExpert from "../../Components/TalkToAnExpert/TalkToAnExpert";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";
import DeliveryMissionCritical from "../../Components/DeliveryMissionCritical/DeliveryMissionCritical";
import GlobalPresence from "../../Components/GlobalPresence/GlobalPresence";
import JoinCommunity from "../../Components/JoinCommunity/JoinCommunity";
import ContactForm from "../../Components/ContactForm/ContactForm";
import LatestInsights from "../../Components/LatestInsights/LatestInsights";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";
import ServiceSlider from "../../Components/ServiceSlider/ServiceSlider";

const OnPremiseInfrastructure = () => {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/network-service.webp)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>On-Premises & Hybrid Infrastructure Services</h1>
              <p className="mt-5 text-warning" style={{fontSize:'30px'}}>
              DataCentrix™ Empowers Organizations With Cost-effective, Efficient Infrastructure That Underpins Their Next-generation Technological Endeavors. Our Infrastructure Services and Solutions Facilitate Seamless Transitions and Management Of Vital Components Like Cloud Technology, Software-defined Data Centers And Networks, And Security
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section class="premise-infra-globalcoverage-container wrapper bg-light mt-5 p-5 text-muted">
        <div class="container py-12 py-md-14">
          <div class="row">
            <div class="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h2 class="text-uppercase mb-3 text-center">
                Global Coverage
              </h2>
              <h3 class="fs-5 text-center">
                Comprehensive Data Center Managed Services for Infrastructure
                Management
              </h3>
              <p class="mb-9 fs-5 mt-2">
              DataCentrix's infrastructure services and solutions empower
                organizations to smoothly transition from traditional
                infrastructure models to modern, cost-efficient technology
                solutions. This enables seamless integration of cloud,
                software-defined data centers, networks, and a streamlined
                employee workplace designed for maximum productivity in an
                ever-evolving landscape.
              </p>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-6 col-lg-4 mt-2">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div class="card-body">
                  <div className="text-center mt-2 mt-lg-0">
                    <i class="bi bi-display-fill text-warning fs-2"></i>
                  </div>
                  <h5 class="mb-1 text-center my-2 fw-bold">Monitoring and Maintenance Solutions</h5>
                  <p class="mb-0 fs-5">
                    We oversee the performance of data center infrastructure and
                    conduct routine maintenance to ensure smooth operations.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 mt-2">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div class="card-body">
                  <div className="text-center mt-2 mt-lg-0">
                    <i class="bi bi-cloud-arrow-down-fill text-warning fs-2"></i>
                  </div>
                  <h5 class="mb-1 text-center my-2 fw-bold">Backup and Disaster Recovery Solutions</h5>
                  <p class="mb-0 fs-5">
                    Implementing backup and disaster recovery solutions to
                    safeguard enterprise data in the event of failures.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 mt-2">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div class="card-body">
                  <div className="text-center mt-2 mt-lg-0">
                    <i class="bi bi-shield-fill-plus text-warning fs-2"></i>
                  </div>
                  <h5 class="mb-1 text-center my-2 fw-bold">Security</h5>
                  <p class="mb-0 fs-5">
                    Deploying security services to safeguard data center
                    infrastructure and sensitive information stored within.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 mt-2">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div class="card-body">
                  <div className="text-center mt-2 mt-lg-0" >
                    <i class="bi bi-arrows-fullscreen  text-warning fs-2"></i>
                  </div>
                  <h5 class="mb-1 text-center my-2 fw-bold">Scalability</h5>
                  <p class="mb-0 fs-5">
                    Scalability of data center infrastructure as business grows,
                    ensuring resources match changing demands.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 mt-2">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div class="card-body">
                  <div className="text-center mt-2 mt-lg-0" >
                    <i class="bi bi-award-fill text-warning fs-2"></i>
                  </div>
                  <h5 class="mb-1 text-center my-2 fw-bold">Expertise</h5>
                  <p class="mb-0 fs-5">
                    Data center infrastructure management and offering guidance
                    to enterprises for informed infrastructure decisions.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 mt-2">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div class="card-body">
                  <div className="text-center mt-2 mt-lg-0" >
                    <i class="bi bi-cash-coin text-warning fs-2"></i>
                  </div>
                  <h5 class="mb-1 text-center my-2 fw-bold">Cost-effectiveness</h5>
                  <p class="mb-0 fs-5">
                    By outsourcing data center infrastructure management to
                    MSPs, enterprises can reduce the cost and complexity of
                    managing the infrastructure in-house.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="premise-infra-endtoend-container wrapper mt-5 p-5 text-muted">
        <div className="container py-15">
          <div className="container pb-10 pb-lg-15">
            <div className="row align-items-center">
              <div className="col-lg-5 col-md-12 px-3">
                <h3 className="mb-3">
                  End-to-End Infrastructure Management Services Engineered for
                  Business Outcomes
                </h3>
                <p className="mb-6 fs-5">
                DataCentrix's Infrastructure Management Services guarantee
                  optimized operations, enhanced user experiences, and
                  heightened enterprise mobility. Our comprehensive solutions
                  address diverse infrastructure needs, accelerating cloud
                  performance and fostering innovative capabilities through
                  integrated services and collaborative outcomes.
                </p>
                <figure>
                  <img
                    className="rounded"
                    src="images/end-to-end.webp"
                    // height={400}
                    // width={400}
                    alt=""
                    srcset=""
                  />
                </figure>
              </div>
              <div className="col-lg-7 col-md-12 px-5 mt-5">
                <ul className="icon-list bullet-bg bullet-soft-blue mb-0 list-unstyled">
                  <li className="d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span className="fs-5">
                      <strong>Network Management</strong> - Designing,
                      configuring, and maintaining network infrastructure,
                      including routers, switches, and firewalls.
                    </span>
                  </li>
                  <li className="mt-3 d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span className="fs-5">
                      <strong>Server Management</strong> -Managing servers,
                      encompassing installation, configuration, and maintenance.
                    </span>
                  </li>
                  <li className="mt-3 d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span className="fs-5">
                      <strong>Storage Management</strong> Managing storage
                      systems, covering disk arrays, backup systems, and cloud
                      storage.
                    </span>
                  </li>
                  <li className="mt-3 d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span className="fs-5">
                      <strong>Database Management</strong> - Managing databases,
                      which includes performance tuning, backup, and recovery.
                    </span>
                  </li>
                  <li className="mt-3 d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span className="fs-5">
                      <strong>Virtualization Management</strong> - Managing
                      virtual environments, which includes virtual machines,
                      virtual networks, and virtual storage.
                    </span>
                  </li>
                  <li className="mt-3 d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span className="fs-5">
                      <strong>Cloud Management</strong> - Managing cloud
                      infrastructure, covering deployment, configuration, and
                      management of cloud-based resources.
                    </span>
                  </li>
                  <li className="mt-3 d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span className="fs-5">
                      <strong>Security Management</strong> - Managing security
                      infrastructure, encompassing firewalls, intrusion
                      detection systems, and security information and event
                      management (SIEM) systems.
                    </span>
                  </li>
                  <li className="mt-3 d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span className="fs-5">
                      <strong>Monitoring and Reporting</strong> - Real-time
                      monitoring and reporting of infrastructure, including
                      performance, availability, and security.
                    </span>
                  </li>
                  <li className="mt-3 d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span className="fs-5">
                      <strong>IT Operations Management</strong> - Coordinating
                      IT operations, including incident management, change
                      management, and problem management.
                    </span>
                  </li>
                  <li className="mt-3 d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span className="fs-5">
                      <strong>Service Level Agreement (SLA) Management</strong>{" "}
                      - Managing service level agreements, including monitoring
                      and reporting of service levels.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <DeliveryMissionCritical />
      <section className="premise-infra-integrated-service-container wrapper p-5 text-muted">
        <div className="container py-14 py-md-16">
          <div className="row mb-10">
            <h2 className="mb-3 text-center">
            Facilitating Integrated Service Management And Intelligent Automation
            </h2>
            <p className="fs-5 mb-5">
            DataCentrix™ is empowering its customers to revolutionize data
              center infrastructure management with integrated service
              management and intelligent automation. Through these
              state-of-the-art tools, we assist organizations in crafting a
              streamlined user experience that propels innovation forward
            </p>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4  my-3">
              <div className="card shadow-lg text-muted h-100 main-card">
                <div class="card-body">
                  <div className="text-center mt-2 mt-lg-0">
                    <i class="bi bi-search text-warning fs-2 mx-2"></i>
                  </div>
                  <h5 class="mb-1 text-center my-2 fw-bold">Automated Monitoring and Maintenance Solutions</h5>
                  <p class="mb-0 fs-5 mt-3">
                    We utilize intelligent automation to monitor data center
                    infrastructure performance and automatically execute maintenance
                    tasks, preemptively resolving issues before they escalate into
                    major problems
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4  my-3">
              <div className="card shadow-lg text-muted h-100 main-card">
                <div class="card-body">
                  <div className="text-center mt-2 mt-lg-0">
                    <i class="bi bi-person-gear text-warning fs-2 mx-2"></i>
                  </div>
                  <h5 class="mb-1 text-center my-2 fw-bold">Integration of Service Management Tools for Seamless Operations</h5>
                  <p class="mb-0 fs-5 mt-3">
                    We integrate service management tools to oversee end-to-end
                    infrastructure, covering network, server, storage, database,
                    virtualization, cloud, security, and monitoring. This results in
                    a unified and streamlined management approach
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4  my-3">
              <div className="card shadow-lg text-muted h-100 main-card">
                <div class="card-body">
                  <div className="text-center mt-2 mt-lg-0">
                    <i class="bi bi-send-fill text-warning fs-2 mx-2"></i>
                  </div>
                  <h5 class="mb-1 text-center my-2 fw-bold">Automated Incident Response Solutions</h5>
                  <p class="mb-0 fs-5 mt-3">
                    We utilize intelligent automation to automate incident response
                    processes, minimizing downtime and ensuring swift issue
                    resolution
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4  my-3">
              <div className="card shadow-lg text-muted h-100 main-card">
                <div class="card-body">
                  <div className="text-center mt-2 mt-lg-0">
                    <i class="bi bi-bar-chart-line-fill text-warning fs-2 mx-2"></i>
                  </div>
                  <h5 class="mb-1 text-center my-2 fw-bold">Predictive Analytics</h5>
                  <p class="mb-0 fs-5 mt-3">
                    We employ predictive analytics to foresee infrastructure issues
                    and take proactive measures to address them, minimizing downtime
                    and ensuring uninterrupted operation of the infrastructure
                  </p>
                </div>
              </div>

            </div>
            <div className="col-md-6 col-lg-4  my-3">
              <div className="card shadow-lg text-muted h-100 main-card">
                <div class="card-body">
                  <div className="text-center mt-2 mt-lg-0">
                    <i class="bi bi-pc-display-horizontal text-warning fs-2 mx-2"></i>
                  </div>
                  <h5 class="mb-1 text-center my-2 fw-bold">Automated Security</h5>
                  <p class="mb-0 fs-5 mt-3">
                    We harness intelligent automation to automate security
                    processes, encompassing continuous monitoring and threat
                    detection, safeguarding the data center infrastructure against
                    cyber-attacks
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4  my-3">
              <div className="card shadow-lg text-muted h-100 main-card">
                <div class="card-body">
                  <div className="text-center mt-2 mt-lg-0">
                    <i class="bi bi-cloud-download text-warning fs-2 mx-2"></i>
                  </div>
                  <h5 class="mb-1 text-center my-2 fw-bold">Automated Backup and Disaster Recovery Solutions</h5>
                  <p class="mb-0 fs-5 mt-3">
                    We enable intelligent automation to streamline backup and
                    disaster recovery processes, mitigating the risk of data loss
                    and ensuring rapid recovery in the event of failures
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <GlobalPresence />
      <ServiceSlider />
      <JoinCommunity />
      <LatestInsights />
      <LetsConnect />
    </React.Fragment>
  );
};

export default OnPremiseInfrastructure;
