import React from "react";
import GlobalPresence from "../../Components/GlobalPresence/GlobalPresence";
import JoinCommunity from "../../Components/JoinCommunity/JoinCommunity";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";
import InsightSlider from "../../Components/InsightSlider/InsightSlider";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";

const NetworkServices = () => {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/network-services-banner-img.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold text-capitalize'>Empowering networks for enhanced mobility and reliability</h1>
              <p className="mt-5 text-warning" style={{fontSize:'30px'}}>
              Network Services
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section>
        <div className="container-fluid mt-5 p-5 text-muted bg-light">
          <div className="row">
            <div className="col-lg-4 mt-5">
              <h3 className="mt-3 mt-lg-0 text-center text-lg-start text-capitalize">
                Dependable network infrastructure fosters efficient work
                environments
              </h3>
            </div>
            <div className="col-lg-4 mt-5 fs-5">
              <p>
                With data traffic and terminal numbers on the rise, network
                needs evolve. We future-proof your networks, ensuring ongoing
                flexibility and adaptability. By equipping your systems for the
                future, we ensure seamless operation amidst changing demands,
                keeping your business agile and efficient.
              </p>
            </div>
            <div className="col-lg-4 mt-lg-5 fs-5">
              <p>
                We collaborate with you to enhance agility, security, and
                scalability. Our approach spans the entire lifecycle, from
                strategy to operations and management. Leveraging partnerships
                with industry leaders like Cisco and Palo Alto, we provide
                comprehensive expertise and customized services to fortify your
                network infrastructure. Together, we ensure your network meets
                evolving demands and drives business success.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 text-muted">
          <div className="row mt-lg-5">
            <h3 className="text-center my-lg-2 text-capitalize">
              Continuous services encompass Install, Move, Add, and Change tasks
            </h3>
            <div className="row mt-5">
              <div className="col-lg-4 col-md-6 my-3">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className="text-center">
                      <i class="bi bi-person-workspace text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className="my-2 text-center fw-bold mt-3">Operations</h5>
                      <p className="fs-5">
                        We prioritize reliability through proactive measures and
                        robust systems.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 my-3">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className="text-center">
                      <i class="bi bi-cloud-minus text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className="my-2 text-center fw-bold mt-3">Troubleshooting</h5>
                      <p className="fs-5">
                        Efficient troubleshooting for quicker solutions and
                        smoother operations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 my-3">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className="text-center">
                      <i class="bi bi-building-fill-check text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className="my-2 text-center fw-bold mt-3">Audit</h5>
                      <p className="fs-5">
                        We maintain unwavering adherence to quality standards
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 my-3">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className="text-center">
                      <i class="bi bi-cart text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className="my-2 text-center fw-bold mt-3">Sales</h5>
                      <p className="fs-5">
                        We rely on modern hardware components (e.g. Cisco)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 my-3">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className="text-center">
                      <i class="bi bi-tv text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className="my-2 text-center fw-bold mt-3">Monitoring</h5>
                      <p className="fs-5">
                        We provide 24/7 infrastructure monitoring for
                        uninterrupted performance
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 my-3">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className="text-center">
                      <i class="bi bi-person-raised-hand text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className="my-2 text-center fw-bold mt-3">Needed Support</h5>
                      <p className="fs-5">
                        Our floor and support services offer tailored assistance
                        whenever you require it
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 text-muted">
          <div className="row mt-lg-5">
            <h3 className="text-center my-lg-2 text-capitalize">
              Building a robust network, one step at a time
            </h3>
            {/* <p className='lead text-muted'>Efficient floor services tailored to your data center</p> */}
            <div className="row mt-5">
              <div className="col-lg-4 col-md-6 my-3">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body">
                    <div>
                      <h5 className="my-2 text-center fw-bold mt-3">Analysis</h5>
                      <p className="fs-5">
                        We conduct comprehensive analysis of your network
                        design, processes, applications, and systems to provide
                        personalized recommendations tailored to your specific
                        needs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 my-3">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body">
                    <div>
                      <h5 className="my-2 text-center fw-bold mt-3">
                        Conception & Planning
                      </h5>
                      <p className="fs-5">
                        We craft meticulously curated concepts based on thorough
                        network design assessments, tailoring service packages
                        to meet your unique corporate requirements.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 my-3">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body">
                    <div>
                      <h5 className="my-2 text-center fw-bold mt-3">
                        Improvemente
                      </h5>
                      <p className="fs-5">
                        We'll evaluate your current concept and collaboratively
                        refine it during a workshop to align with your ideas and
                        requirements.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 my-3">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body">
                    <div>
                      <h5 className="my-2 text-center fw-bold mt-3">
                        WiFi Enlightenment
                      </h5>
                      <p className="fs-5">
                        To review your WiFi concept, we virtually illuminate it
                        and offer implementation recommendations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 my-3">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body">
                    <div>
                      <h5 className="my-2 text-center fw-bold mt-3">
                        Hardware Update
                      </h5>
                      <p className="fs-5">
                        To ensure you have access to reliable and modern network
                        technology, we offer customizable hardware update models
                        such as leasing, loans, rentals, or immediate purchase
                        of cost-effective refurbished hardware.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 my-3">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body">
                    <div>
                      <h5 className="my-2 text-center fw-bold mt-3">
                        Operations & Monitoring
                      </h5>
                      <p className="fs-5">
                        We prioritize emergency preparedness and ensure high
                        reliability, so you can swiftly resume operations if the
                        worst-case scenario occurs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <GlobalPresence />
      <JoinCommunity />
      <InsightSlider/>
      <LetsConnect/>
    </React.Fragment>
  );
};

export default NetworkServices;
