import React from 'react'
import LetsConnect from '../../Components/LetsConnect/LetsConnect'
import ExtendedLifecycle from '../../Components/ExtendedLifecycle/ExtendedLifecycle'
import GlobalBrands from '../../Components/GlobalBrands/GlobalBrands'
import GlobalPresence from '../../Components/GlobalPresence/GlobalPresence'
import JoinCommunity from '../../Components/JoinCommunity/JoinCommunity'
import InsightSlider from '../../Components/InsightSlider/InsightSlider'

const ServerMaintenance = () => {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/server-maintainance-banner.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Proactive Service For Seamless Operations</h1>
              <p className="mt-5 text-warning" style={{fontSize:'30px'}}>
              Server Maintenanace
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section>
        <div className="container-fluid bg-light p-5 mt-5 text-muted">
          <div className="row">
            <div className="col-lg-4 mt-lg-5">
              <h3 className='text-center text-lg-start'>
              Continuous Proactive Maintenance Ensures Uninterrupted Operations</h3>
            </div>
            <div className="col-lg-4 mt-5 fs-5">
              <p>Leverage our expertise: Our services mitigate the risk of system failures, ensuring seamless business operations. According to our "Data Centre Maintenance 2019" study, two-thirds of surveyed companies endorse the advantages of external maintenance services.</p>
            </div>
            <div className="col-lg-4 mt-lg-5 fs-5">
              <p>Upon fault reporting, we swiftly rectify issues either remotely or on-site. Our monitoring tools or call home function enable direct reporting to our service team, ensuring rapid technician response even before you're aware of the problem.</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 text-muted">
          <div className="row mt-lg-5">
            <h3 className="text-center my-lg-2">Performance Contracts For Service Delivery</h3>
            <p className="text-center fs-5">Tailored SLAs to fit your needs. Flexible and personalized options available</p>
            <div className="row mt-5">
              <div className="col-lg-3 my-3 col-md-4">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body">
                    <div className='text-center'>
                      <i class="bi bi-currency-exchange text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className='text-center my-2 fw-bold'>Lower costs</h5>
                      <p className='fs-5 px-2'>No unplanned expenses due to faults</p>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-3 my-3 col-md-4">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body">
                    <div className='text-center'>
                      <i class="bi bi-clock-history text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className='text-center my-2 fw-bold'>Fast and effective</h5>
                      <p className='fs-5 px-2'>Our technician will be there in a hurry</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 my-3 col-md-4">
                <div className='card shadow-lg text-muted h-100 hover-metic'>
                  <div className="card-body">
                    <div className='text-center'>
                      <i class="bi bi-person-check text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className='text-center my-2 fw-bold'>24/7 availability</h5>
                      <p className='fs-5 px-2'>Fault reporting & troubleshooting around the clock</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 my-3 col-md-4">
                <div className='card shadow-lg text-muted h-100 hover-metic'>
                  <div className="card-body">
                    <div className='text-center'>
                      <i class="bi bi-infinity text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className='text-center my-2 fw-bold'>Extended lifecycle</h5>
                      <p className='fs-5 px-2'>Your hardware is always operational</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 my-3 col-md-4">
                <div className='card shadow-lg text-muted h-100 hover-metic'>
                  <div className="card-body">
                    <div className='text-center'>
                      <i class="bi bi-hand-thumbs-up text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className='text-center my-2 fw-bold'>Sustainability</h5>
                      <p className='fs-5 px-2'>Keep using your hardware beyond its indicated service life</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 my-3 col-md-4">
                <div className='card shadow-lg text-muted h-100 hover-metic'>
                  <div className="card-body">
                    <div className='text-center'>
                      <i class="bi bi-cpu text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className='text-center my-2 fw-bold'>Process optimisation</h5>
                      <p className='fs-5 px-2'>All processes run smoothly</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 my-3 col-md-4">
                <div className='card shadow-lg text-muted h-100 hover-metic'>
                  <div className="card-body">
                    <div className='text-center'>
                      <i class="bi bi-transparency text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className='text-center my-2 fw-bold'>Transparency</h5>
                      <p className='fs-5 px-2'>Services and costs are itemised and easy to calculate</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 my-3 col-md-4">
                <div className='card shadow-lg text-muted h-100 hover-metic'>
                  <div className="card-body">
                    <div className='text-center'>
                      <i class="bi bi-people-fill text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className='text-center my-2 fw-bold'>Free resources</h5>
                      <p className='fs-5 px-2'>Your employees have more time for your core business</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 bg-light p-5 text-muted">
          <div className="row text-center">
            <h3 className=''>We Provide Support For A Wide Range Of Brands, Models, And Systems</h3>
            <p className='fs-5'>We Offer Maintenance For A Variety Of Server Providers. Need Support For A Different Model? Contact Us For A Tailored Solution!</p>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 text-muted">
          <div className="row">
            <div className="col-lg-3 my-3 col-md-4">
              <div className="card shadow-lg h-100 text-muted">
                <div className="card-body">

                  <img src="images/hewlett-packard.png" className='card-img-top h-50' alt="" srcset="" />

                  <div>
                    <h5 className='text-center my-2 fw-bold'>Server</h5>
                    <p className='fs-5 px-2 mt-3'>ProLiant DL Series, ProLiant ML Series, ProLiant BL Series, Integrity, HP9000, Alpha, NonStop, BladeSystem, Synergy</p>
                  </div>
                </div>
              </div>

            </div>
            <div className="col-lg-3 my-3 col-md-4">
              <div className="card shadow-lg h-100 text-muted">
                <div className="card-body">

                  <img src="images/dellmec.png" className='card-img-top h-50' alt="" srcset="" />

                  <div>
                    <h5 className='text-center my-2 fw-bold'>Server</h5>
                    <p className='fs-5 px-2 mt-3'>PowerEdge R, PowerEdge T, PowerEdge MX, PowerEdge VRTX, PowerEdge FX</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 my-3 col-md-4">
              <div className="card shadow-lg h-100 text-muted">
                <div className="card-body">

                  <img src="images/ibm.webp" className='card-img-top h-50' alt="" srcset="" />

                  <div>
                    <h5 className='text-center my-2 fw-bold'>Server</h5>
                    <p className='fs-5 px-2 mt-3'>Blade Center, System p, System x, System z, System i, Power Systems, ThinkServer, PureFlex System, NeXtScale</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 my-3 col-md-4">
              <div className="card shadow-lg h-100 text-muted">
                <div className="card-body">

                  <img src="images/sun-oracle.png" className='card-img-top h-50' alt="" srcset="" />

                  <div>
                    <h5 className='text-center my-2 fw-bold'>Server</h5>
                    <p className='fs-5 px-2 mt-3'>SUN / Oracle Server X Series, SUN Fire Series, Blade Systems, SPARC T Series, SPARC M Series, Ultra Enterprise, Netra SPARC, Netra x86, Netra Blade</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 my-3 col-md-4">
              <div className="card shadow-lg h-100 text-muted">
                <div className="card-body">

                  <img src="images/cisco.png" className='card-img-top h-50' alt="" srcset="" />

                  <div>
                    <h5 className='text-center my-2 fw-bold'>Server</h5>
                    <p className='fs-5 px-2 mt-3'>UCS B-Series, UCS C-Series, MCS Series</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 my-3 col-md-4">
              <div className="card shadow-lg h-100 text-muted">
                <div className="card-body">

                  <img src="images/fujitsu.png" className='card-img-top h-50' alt="" srcset="" />

                  <div>
                    <h5 className='text-center my-2 fw-bold'>Server</h5>
                    <p className='fs-5 px-2 mt-3'>Primergy RX Series, Primergy TX Series, Primergy CX Series, Primergy BX Series, SPARC M Series, SPARC T Series, Primepower</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 my-3 col-md-4">
              <div className="card shadow-lg h-100 text-muted">
                <div className="card-body">

                  <img src="images/intel-1.png" className='card-img-top h-50' alt="" srcset="" />

                  <div>
                    <h5 className='text-center my-2 fw-bold'>Server</h5>
                    <p className='fs-5 px-2 mt-3'>M20MYP Family, S2600BP Family, S2600WF Family, R1000WF Family, R2000WF Family, H2000P Family, P4000G Family</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 my-3 col-md-4">
              <div className="card shadow-lg h-100 text-muted">
                <div className="card-body">
                  <img src="images/supermicr.png" className='card-img-top h-50' alt="" srcset="" />

                  <div>
                    <h5 className='text-center my-2 fw-bold'>Server</h5>
                    <p className='fs-5 px-2 mt-3'>SuperServer, SuperBlade, MicroBlade, UltraServer</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 my-3 col-md-4">
              <div className="card shadow-lg h-100 text-muted">
                <div className="card-body">

                  <img src="images/lenovo.png" className='card-img-top h-50' alt="" srcset="" />

                  <div>
                    <h5 className='text-center my-2 fw-bold'>Server</h5>
                    <p className='fs-5 px-2 mt-3'>ThinkServer, Flex System Blade Server, System x Server</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ExtendedLifecycle />
      <GlobalPresence/>
      <JoinCommunity/>
      <InsightSlider/>
      <LetsConnect />
    </React.Fragment>

  )
}

export default ServerMaintenance