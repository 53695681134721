import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { HOME } from '../../Services/ConstantUrls'
import { isMobile } from 'react-device-detect';

const HeaderItem = ({ key,item ,childHandler,parentIndex,childIndex,closeHeader,setIsMobMenuOpen}) => {
    const navigate=useNavigate()

    const onPreceedingChild=()=>{
        navigate(item?.childUrl||HOME)
        closeHeader()
        window.scrollTo(0, 0); 
        setIsMobMenuOpen(false)
    }
    const onSuccedingChild=(child)=>{
        navigate(child?.childUrl||'')
        closeHeader()
        window.scrollTo(0, 0); 
        setIsMobMenuOpen(false)
    }
    return (
        <li className='lead'>
            <a  onClick={onPreceedingChild} 
            // onMouseEnter={()=>childHandler(parentIndex,childIndex)}
            >{item.childTitle}</a>
            {item?.child?.length > 0 && item?.showChild && (
                <ul className='header-menu rounded ms-3'>
                    {item?.child?.map((child, index) => (
                        <li className='ps-0 lead'>
                        <a onClick={()=>onSuccedingChild(child)}>
                        {child.childTitle}
                        </a>
                        </li>
                    ))}
                </ul>
            )}
        </li>
    )
}

export default HeaderItem