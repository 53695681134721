import React, { useState, useEffect, useRef } from 'react';
import SliderBullets from '../SliderBullets/SliderBullets';
import { global_brand_slider_transform } from '../../Services/ConstantVariables';

const globalBrandData = [
  { title: 'google', image: 'google.webp' },
  { title: 'meta', image: 'meta.webp' },
  { title: 'amazon', image: 'amazon.webp' },
  { title: 'microsoft', image: 'microsoft.webp' },
  { title: 'wipro', image: 'wipro.webp' },
  { title: 'infosys', image: 'infosis.webp' },
  { title: 'Hewlett Packard', image: 'hewlett-packard.png' },
  { title: 'dellemc', image: 'dellmec.png' },
  { title: 'sun oracle', image: 'sun-oracle.png' },
  { title: 'ibm', image: 'ibm.png' },
  { title: 'netapp', image: 'netapp.png' },
  { title: 'synology', image: 'synology.png' },
  { title: 'quantum', image: 'quantum.png' },
  { title: 'overland', image: 'overland.png' },
  { title: 'hitachi', image: 'hitachi.png' },
  { title: 'qnap', image: 'qnap.png' },
  { title: 'lenovo', image: 'lenovo.png' },
  { title: 'fujitsu', image: 'fujitsu.png' }
];

const GlobalBrands = () => {
  const [activeBullet, setActiveBullet] = useState(0);
  const intervalRef = useRef(null);

  const startSlider = () => {
    if (intervalRef.current) return;
    intervalRef.current = setInterval(() => {
      setActiveBullet(prev => {
        const nextIndex = (prev + 1) % global_brand_slider_transform.length;
        moveSlider(nextIndex);
        return nextIndex;
      });
    }, 3000);
  };

  const stopSlider = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const moveSlider = (index) => {
    const element = document.getElementsByClassName('swiper-wrapper-global-brands')[0];
    element.style.transform = `translateX(${global_brand_slider_transform[index]}) translateY(0px) translateZ(0px)`;
    element.style.transition = 'transform 500ms ease';
  };

  useEffect(() => {
    startSlider();
    return () => stopSlider();
  }, []);

  const onBulletClick = (index) => {
    setActiveBullet(index);
    moveSlider(index);
  };

  return (
    <React.Fragment>
      <div className="swiper-container blog grid-view my-5 p-5">
        <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events case-studies-swiper pb-12 swiper-backface-hidden">
          <div className="global-brands-row swiper-wrapper swiper-wrapper-global-brands">
            {globalBrandData.map((item, index) => (
              <div key={index} className="col-4 col-lg-2 py-2 brand outer-logo-box m-0">
                <div
                  className='px-3 py-5 mb-5 px-md-0 px-lg-2 px-xl-3 px-xxl-4 customerLogos home-global-brands'
                  onMouseEnter={stopSlider}
                  onMouseLeave={startSlider}
                >
                  <img src={`images/${item.image}`} className='w-100 global-page-client-logo brand-logo' alt={item.title} />
                </div>
              </div>
            ))}
          </div>
          <div className="mt-5 swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
            <SliderBullets pagination={9} pageSize={2} onBulletClick={onBulletClick} activeBullet={activeBullet} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GlobalBrands;
