import React from 'react'

const DeliveryMissionCritical = () => {
  return (
    <section className="delivery-mission-container wrapper mt-5 p-5 text-muted">
      <div className="container-fluid pb-14 pb-md-16">
        <div className="row gx-lg-8 gx-xl-12 gy-15 align-items-center">
          <div className="col-lg-6">
            <div className="row d-flex me-4">
              <div className="col-6">
                <img src="images/deliveryMission.webp" alt="" srcset="" height={150} width={200}/>
                <img src="images/deliveryMissionThird.webp" alt="" srcset="" className='mt-4' height={200} width={150}/>

              </div>
              <div className="col-6">
              <img src="images/deliveryMissionSecond.webp" alt="" srcset="" className='mt-4' height={200} width={150}/>
                <img src="images/deliveryMissionFourth.webp" alt="" srcset="" className='mt-4' height={200} width={150}/>
              </div>
            </div>
          </div>
          <div className="col-lg-6 my-5 my-lg-3">
            <h3 className="">
            Accelerating Mission-Critical Service Delivery with Our Service Cloud Platform.
            </h3>
            <p className="mb-2 mb-lg-5 fs-5">
            Our Service Cloud platform, driven by advanced automation, robust capabilities, proactive incident management, chatbots, and machine learning, empowers our workforce to swiftly identify, collaborate on, and resolve issues using proven workflows.
            </p>
            <div className="row gy-3 fs-5 mb-3 mt-5">
              <div className="col-xl-6">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                <ul className="icon-list bullet-bg bullet-soft-blue mb-0 list-unstyled">
                  <li className='my-3 d-flex flex-row'>
                    <i class="bi bi-check2 text-warning fs-4 mx-2" />
                    <span>
                    Enable Real-Time Collaboration with B2B API, Mobile Apps, and Web Portals
                    </span>
                  </li>
                  <li className='my-lg-3 d-flex flex-row'>
                    <i class="bi bi-check2 text-warning fs-4 mx-2" />
                    <span>
                    Real-Time Omnichannel Communication: Mobile App, SMS, WhatsApp, Email Support
                    </span>
                  </li>
                </ul>
                </div>
                </div>
              </div>
              <div className="col-xl-6">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                <ul className="icon-list bullet-bg bullet-soft-blue mb-0 list-unstyled">
                  <li className='my-3 d-flex flex-row'>
                    <i class="bi bi-check2 text-warning fs-4 mx-2" />
                    <span>
                    Real-Time Visibility into Workforce Activity and 3PL Logistics
                    </span>
                  </li>
                  <li className='my-3 d-flex flex-row'>
                    <i class="bi bi-check2 text-warning fs-4 mx-2" />
                    <span>
                    Customer Workflows and Automated Processes Enhanced by Machine Learning
                    </span>
                  </li>
                </ul>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default DeliveryMissionCritical