import React from "react";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";

function RecruiterOnDemand() {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/recruiter-on-demand-banner.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Recruiter On Demand</h1>
              <p className="mt-5 text-warning text-capitalize" style={{fontSize:'30px'}}>
              Engage an embedded expert to bolster your recruitment team, offering the flexibility to scale up as needed and for as long as required
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section className="wrapper mt-5 p-5 text-muted bg-light">
        <div className="container">
          <div className="row gx-lg-8 gx-xl-12 gy-10 mb-14 mb-md-14 mt-md-14 mt-14 align-items-center">
            <div className="col-lg-6 position-relative">
              <div className="shape bg-soft-primary rounded-circle rellax w-20 h-20" />
              <img src='images/recruiter-on-demand-img.jpg' />
            </div>
            <div className="col-lg-6 px-lg-5 px-0">
              <h2 className="mb-3">Recruiter On Demand?</h2>
              <p className="fs-5">
                Utilize our Recruiter On Demand (RoD) service to onboard one or more temporary talent acquisition experts, complementing your recruitment team. These seasoned professionals bring years of proven experience and can be deployed at short notice. You have the flexibility to engage their services for as long as required.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container pt-1 pt-md-1 pb-7 pb-md-15 text-center">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h3 className="mb-10 px-xl-10">
                Scalable, Expert, Agile Talent Solutions.
              </h3>
            </div>
          </div>
          <div className="position-relative">
            <div
              className="shape rounded-circle bg-soft-blue rellax w-16 h-16"
              style={{
                bottom: "-0.5rem",
                right: "-2.2rem",
                zIndex: 0,
                transform: "translate3d(0px, 6px, 0px)",
              }}
            />
            <div
              className="shape bg-dot blue rellax w-16 h-17"
              style={{
                top: "-0.5rem",
                left: "-2.5rem",
                zIndex: 0,
                transform: "translate3d(0px, 2px, 0px)",
              }}
            />
            <div className="row gx-md-5 gy-5 d-flex mt-5">
              <div className="col-md-6 col-xl-3 d-flex">
                <div className="card shadow-lg text-muted hover-metic">
                  <div className="card-body">
                    <i class="bi bi-trophy text-warning fs-2 mx-2"></i>
                    <h5 className="fw-bold mt-2">Expertise on Demand</h5>
                    <p className="mb-2 fs-5 text-start px-2">
                      Global IT infrastructure experts with years of experience and extensive candidate networks worldwide, ensuring top talent acquisition for your organization.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3 d-flex">
                <div className="card shadow-lg text-muted hover-metic">
                  <div className="card-body">
                    <i class="bi bi-diagram-3-fill text-warning fs-2 mx-2"></i>
                    <h5 className="fw-bold mt-2">Flexibility</h5>
                    <p className="mb-2 fs-5 text-start px-2">
                      Effortlessly scale your recruitment team, up or down, without any downtime or disruptions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3 d-flex">
                <div className="card shadow-lg text-muted hover-metic">
                  <div className="card-body">
                    <i class="bi bi-shield-fill-check text-warning fs-2 mx-2"></i>
                    <h5 className="fw-bold mt-2">Guaranteed Performance</h5>
                    <p className="mb-2 fs-5 text-start px-2">
                      While freelancers offer flexibility, they come with inherent risks. Our vetted recruiters offer security; if they don't meet expectations, we ensure replacements.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3 d-flex">
                <div className="card shadow-lg text-muted hover-metic">
                  <div className="card-body">
                    <i class="bi bi-person-arms-up text-warning fs-2 mx-2"></i>
                    <h5 className="fw-bold mt-2">Peace of mind</h5>
                    <p className="mb-2 fs-5 text-start px-2">
                      Rest assured, candidates sourced by our RoDs belong solely to you. We neither store nor utilize them for any other purposes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container pt-5 pt-md-5 pb-13 pb-md-15">
          <div className="row text-center">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h3 className="mb-9 px-xl-11">How It Works</h3>
            </div>
          </div>
          <div className="row gx-lg-8 gx-xl-12 gy-8 mt-5">
            <div className="col-md-12 col-lg-4 mt-3 mt-lg-0 bg-light py-2 global-specialize border border-muted">
              <div className="d-flex flex-row py-2">
                <div>
                  <i class="bi bi-person-vcard-fill text-warning fs-4 mx-2"></i>
                </div>
                <div>
                  <p className="mb-0 fs-5">
                    We present vetted candidates for RoD roles with your team. You conduct interviews and make the final selection.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 mt-3 mt-lg-0 bg-light py-2 global-specialize border border-muted">
              <div className="d-flex flex-row py-2">
                <div>
                  <i class="bi bi-hand-index-thumb text-warning fs-4 mx-2"></i>
                </div>
                <div>
                  <p className="mb-0 fs-5">
                    Although our RoDs work remotely, we ensure comprehensive onboarding, acquainting them with your organization's values, culture, and objectives.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 mt-3 mt-lg-0 bg-light py-2 global-specialize border border-muted">
              <div className="d-flex flex-row py-2">
                <div>
                  <i class="bi bi-bullseye text-warning fs-4 mx-2"></i>
                </div>
                <div>
                  <p className="mb-0 fs-5">
                    You set the requirements and goals, and they deliver, backed by our performance guarantee!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LetsConnect />
    </React.Fragment>
  );
}

export default RecruiterOnDemand;
