import React from "react";
import JoinCommunity from "../../Components/JoinCommunity/JoinCommunity";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";
import GlobalPresence from "../../Components/GlobalPresence/GlobalPresence";
import InsightSlider from "../../Components/InsightSlider/InsightSlider";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";

const ProactiveItMonitoring = () => {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/proactive-it-monitoring.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold text-capitalize'>Proactive monitoring for seamless operations and minimal
                  disruptions</h1>
              <p className="mt-5 text-warning text-capitalize" style={{fontSize:'30px'}}>
              Streamlined and comprehensive, we offer independent monitoring
                  solutions to meet all your needs, providing peace of mind and
                  ensuring the smooth operation of your systems
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands/>
      <section>
        <div className="container-fluid bg-light p-5 mt-5 text-muted">
          <div className="row">
            <div className="col-lg-4 mt-lg-5">
              <h3 className="px-4 text-center text-lg-start text-capitalize">
                Our solution ensures transparent monitoring for enhanced
                visibility.
              </h3>
            </div>
            <div className="col-lg-4 mt-5 fs-5 px-5">
              <p>
                Smooth-running systems are crucial for profitability. Avoid
                disruptions and safeguard your balance sheets with our
                integrated IT monitoring. Our efficient solution ensures
                continuous system availability for uninterrupted operations.
              </p>
            </div>
            <div className="col-lg-4 mt-lg-5 fs-5 px-5">
              <p>
                As a multi-vendor service provider, we offer comprehensive 24/7
                monitoring of your entire system environment. Our central
                dashboards provide clear insights, allowing you to focus on
                strategic tasks. By combining monitoring with hardware
                maintenance and cross-manufacturer expertise, we enable
                automatic fault restoration, reducing process costs
                significantly.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h3 className="mb-3 text-center text-capitalize">
                Establishing robust foundations for streamlined processes.
              </h3>
              <h5 className="mb-9 text-center">
                Our solution provides continuous monitoring of your IT
                environment.
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4 my-3">
              <div className="card shadow-lg text-muted h-100 main-card">
              <div className="card-body">
              <div className="text-center">
                <i className="bi bi-clock-history text-warning fs-1" />
              </div>
              <h5 className="my-2 text-center fw-bold">Ensuring maximum uptime. </h5>
              <p className="mb-3 fs-5">
                Prevent costly and time-consuming system downtime.
              </p>
              </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 my-3">
            <div className="card shadow-lg text-muted h-100 main-card">
              <div className="card-body">
              <div className="text-center">
                <i className="bi bi-database-check text-warning fs-1" />
              </div>
              <h5 className="my-2 text-center fw-bold">Enhanced capacity.</h5>
              <p className="mb-3 fs-5">
                Maximize efficiency with optimized resources.
              </p>
            </div></div></div>
            <div className="col-md-6 col-lg-4 my-3">
            <div className="card shadow-lg text-muted h-100 main-card">
              <div className="card-body">
              <div className="text-center">
                <i className="bi bi-person-fill-gear text-warning fs-1" />
              </div>
              <h5 className="my-2 text-center fw-bold">Costs managed efficiently</h5>
              <p className="mb-3 fs-5">
                Optimal outcomes with manageable financial investment for
                top-tier achievements.
              </p>
            </div></div></div>
            <div className="col-md-6 col-lg-4 my-3">
            <div className="card shadow-lg text-muted h-100 main-card">
              <div className="card-body">
              <div className="text-center">
                <i className="bi bi-lightbulb text-warning fs-1" />
              </div>
              <h5 className="my-2 text-center fw-bold">Endless possibilities</h5>
              <p className="mb-3 fs-5">
                Our solution seamlessly integrates with diverse manufacturers
                and system environments.
              </p></div></div>
            </div>
            <div className="col-md-6 col-lg-4 my-3">
            <div className="card shadow-lg text-muted h-100 main-card">
              <div className="card-body">
              <div className="text-center">
                <i className="bi bi-hourglass-split text-warning fs-1" />
              </div>
              <h5 className="my-2 text-center fw-bold">Effortless integration and smooth implementation</h5>
              <p className="mb-3 fs-5">
                We deploy the system rapidly and efficiently.
              </p>
            </div></div></div>
            <div className="col-md-6 col-lg-4 my-3">
            <div className="card shadow-lg text-muted h-100 main-card">
              <div className="card-body">
              <div className="text-center">
                <i className="bi bi-flag-fill text-warning fs-1" />
              </div>
              <h5 className="my-2 text-center fw-bold">Flexible range of capabilities</h5>
              <p className="mb-3 fs-5">
                You can scale our solution for any number of services and
                locations.
              </p>
            </div></div></div>
          </div>
         
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 text-muted bg-light">
          <div className="row">
            <div className="col-lg-6 px-5">
              <img src="images/it-monitoring.jpeg" className="w-100" />
            </div>
            <div className="col-lg-6 px-lg-5 px-0 mt-lg-3 mt-0">
              <h3 className="text-center text-lg-start mt-5 mt-lg-0 text-capitalize">
                IT monitoring to optimize your IT environment.
              </h3>
              <p className="fs-5">
                Maintain control with our active and passive IT monitoring,
                ensuring uninterrupted system operation. Choose standalone or
                combined with hardware maintenance for comprehensive support.
                With a maintenance contract, error reports are analyzed and
                repairs carried out promptly, maximizing IT environment
                availability. Save time and money with continuous checks,
                integrated reporting, and clear insights.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 text-muted">
          <div className="row">
            <div className="col-lg-6 px-lg-5 px-0">
              <h3 className="text-center text-lg-start text-capitalize">
                Swift and efficient system implementation
              </h3>
              <p className="fs-5 mt-3 mt-lg-0">
                Eliminate costly implementation worries. Introducing our
                monitoring solution is effortless. We start by installing the
                monitoring appliance and configuring the basics. Align your
                systems with ease and instantly boost agility, productivity, and
                responsiveness.
              </p>
            </div>
            <div className="col-lg-6 px-5">
              <img src="images/quick-implementation.jpeg" />
            </div>
          </div>
        </div>
      </section>
      <GlobalPresence/>
      <JoinCommunity />
      <InsightSlider/>
      <LetsConnect/>
    </React.Fragment>
  );
};

export default ProactiveItMonitoring;
