import React from 'react'

const TalkToAnExpert = () => {
  return (
    <div className="modal fade" id="modal-signup" tabIndex={-1}>
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content text-dark text-start">
        <div className="modal-body">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
          <h2 className="mb-3 text-start">Talk to an Expert</h2>
          <p className="lead mb-6 text-start">
            Simply fill out the form and an DataCentrix™
            specialist will contact you within 1 business day.
          </p>
          <form className="contact-form needs-validation">
            <div className="messages" />
            <div className="row gx-4">
              <div className="col-md-6">
                <div className="form-floating mb-4">
                  <input
                    id="firstname"
                    type="text"
                    name="firstname"
                    className="form-control"
                    placeholder="First Name"
                    required=""
                  />
                  <label htmlFor="firstname">First Name *</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-4">
                  <input
                    id="lastname"
                    type="text"
                    name="lastname"
                    className="form-control"
                    placeholder="Last Name"
                    required=""
                  />
                  <label htmlFor="lastname">Last Name *</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-4">
                  <input
                    id="email"
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    required=""
                  />
                  <label htmlFor="email">Email *</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-4">
                  <input
                    id="phone"
                    type="text"
                    name="phone"
                    className="form-control"
                    placeholder="Phone Number"
                  />
                  <label htmlFor="phone">Phone Number</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-4">
                  <input
                    id="customer_account"
                    type="text"
                    name="customer_account"
                    className="form-control"
                    placeholder="Company Name"
                    required=""
                  />
                  <label htmlFor="customer_account">
                    Company Name *
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-4">
                  <input
                    id="title"
                    type="text"
                    name="title"
                    className="form-control"
                    placeholder="Job Title"
                  />
                  <label htmlFor="title">Job Title</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-select-wrapper mb-4">
                  <select
                    className="form-select"
                    id="industry"
                    name="industry"
                    required=""
                  >
                    <option disabled="" value="" selected="">
                      Select Industry *
                    </option>
                    <option value="Agriculture">Agriculture</option>
                    <option value="Apparel">Apparel</option>
                    <option value="Banking">Banking</option>
                    <option value="Biotechnology">
                      Biotechnology
                    </option>
                    <option value="Chemicals">Chemicals</option>
                    <option value="Communications">
                      Communications
                    </option>
                    <option value="Construction">
                      Construction
                    </option>
                    <option value="Consulting">Consulting</option>
                    <option value="Education">Education</option>
                    <option value="Electronics">Electronics</option>
                    <option value="Energy">Energy</option>
                    <option value="Engineering">Engineering</option>
                    <option value="Entertainment">
                      Entertainment
                    </option>
                    <option value="Environmental">
                      Environmental
                    </option>
                    <option value="Finance">Finance</option>
                    <option value="Food & Beverage">
                      Food &amp; Beverage
                    </option>
                    <option value="Government">Government</option>
                    <option value="Healthcare">Healthcare</option>
                    <option value="Hospitality">Hospitality</option>
                    <option value="Insurance">Insurance</option>
                    <option value="Machinery">Machinery</option>
                    <option value="Manufacturing">
                      Manufacturing
                    </option>
                    <option value="Media">Media</option>
                    <option value="Not For Profit">
                      Not For Profit
                    </option>
                    <option value="Other">Other</option>
                    <option value="Recreation">Recreation</option>
                    <option value="Retail">Retail</option>
                    <option value="Shipping">Shipping</option>
                    <option value="Technology">Technology</option>
                    <option value="Telecommunications">
                      Telecommunications
                    </option>
                    <option value="Transportation">
                      Transportation
                    </option>
                    <option value="Utilities">Utilities</option>
                    <option value="Promotional Marketing">
                      Promotional Marketing
                    </option>
                    <option value="Technology and Communications">
                      Technology and Communications
                    </option>
                    <option value="Software Development">
                      Software Development
                    </option>
                    <option value="Retail Merchandising Installation">
                      Retail Merchandising Installation
                    </option>
                    <option value="Transportation & Logistics">
                      Transportation &amp; Logistics
                    </option>
                  </select>
                  <div className="invalid-feedback">
                    Please select your industry.
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-select-wrapper mb-4">
                  <select
                    className="form-select"
                    id="country"
                    name="country"
                    required=""
                  >
                    <option disabled="" value="" selected="">
                      Select Country *
                    </option>
                    <option value="United States">
                      United States
                    </option>
                    <option value="United Kingdom">
                      United Kingdom
                    </option>
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">
                      American Samoa
                    </option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bosnia and Herzegovina">
                      Bosnia and Herzegovina
                    </option>
                    <option value="Botswana">Botswana</option>
                    <option value="Brazil">Brazil</option>
                    <option value="Brunei">Brunei</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">
                      Burkina Faso
                    </option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Cayman Islands">
                      Cayman Islands
                    </option>
                    <option value="Central African Republic">
                      Central African Republic
                    </option>
                    <option value="Chad">Chad</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Macau">Macau</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">
                      Czech Republic
                    </option>
                    <option value="Zaire">Zaire</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">
                      Dominican Republic
                    </option>
                    <option value="East Timor">East Timor</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">
                      Equatorial Guinea
                    </option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinea Bissau">
                      Guinea Bissau
                    </option>
                    <option value="Guyana">Guyana</option>
                    <option value="Guyana (Fr.)">
                      Guyana (Fr.)
                    </option>
                    <option value="Haiti">Haiti</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Ivory Coast">Ivory Coast</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyz Republic">
                      Kyrgyz Republic
                    </option>
                    <option value="Laos">Laos</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libya">Libya</option>
                    <option value="Liechtenstein">
                      Liechtenstein
                    </option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Moldova">Moldova</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montenegro">Montenegro</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Namibia">Namibia</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherland Antilles">
                      Netherland Antilles
                    </option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="New Caledonia Fr.">
                      New Caledonia Fr.
                    </option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Macedonia">Macedonia</option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">
                      Papua New Guinea
                    </option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Korea (South)">
                      Korea (South)
                    </option>
                    <option value="Congo">Congo</option>
                    <option value="Romania">Romania</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Saudi Arabia">
                      Saudi Arabia
                    </option>
                    <option value="Senegal">Senegal</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Sierra Leone">
                      Sierra Leone
                    </option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia (Slovak Republic)">
                      Slovakia (Slovak Republic)
                    </option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">
                      South Africa
                    </option>
                    <option value="South Sudan">South Sudan</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="St. Kitts Nevis Anguilla">
                      St. Kitts Nevis Anguilla
                    </option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Tadjikistan">Tadjikistan</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Togo">Togo</option>
                    <option value="Trinidad and Tobago">
                      Trinidad and Tobago
                    </option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">
                      Turkmenistan
                    </option>
                    <option value="Uganda">Uganda</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Emirates">
                      United Arab Emirates
                    </option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="US Minor Outlying Islands">
                      US Minor Outlying Islands
                    </option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vatican City State">
                      Vatican City State
                    </option>
                    <option value="Vietnam">Vietnam</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </select>
                  <div className="invalid-feedback">
                    Please select your country.
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="form-floating mb-4">
                  <textarea
                    id="field[14]"
                    type="text"
                    name="message"
                    className="form-control"
                    placeholder="Your Message"
                    defaultValue={""}
                  />
                  <label htmlFor="message">Message</label>
                </div>
              </div>
              <div className="col-12">
                <div className="form-check mb-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="invalidCheck"
                    required=""
                    defaultValue=""
                  />
                  <label
                    className="form-check-label"
                    htmlFor="invalidCheck"
                  >
                    I agree to{" "}
                    <a href="/privacy-policy" className="hover">
                      terms and policy
                    </a>
                    .
                  </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <div>
                    <div style={{ width: 304, height: 78 }}>
                      <div>
                        <iframe
                          title="reCAPTCHA"
                          width={304}
                          height={78}
                          role="presentation"
                          name="a-8vds9txozs71"
                          frameBorder={0}
                          scrolling="no"
                          sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                          src="https://www.google.com/recaptcha/api2/anchor?ar=1&k=6LcwIw8TAAAAACP1ysM08EhCgzd6q5JAOUR1a0Go&co=aHR0cHM6Ly9pcm9uc2VydmljZWdsb2JhbC5jb206NDQz&hl=en&type=image&v=V6_85qpc2Xf2sbe3xTnRte7m&theme=light&size=normal&badge=bottomright&cb=jkaz5prna32b"
                        />
                      </div>
                      <textarea
                        id="g-recaptcha-response-18"
                        name="g-recaptcha-response"
                        className="g-recaptcha-response"
                        style={{
                          width: 250,
                          height: 40,
                          border: "1px solid rgb(193, 193, 193)",
                          margin: "10px 25px",
                          padding: 0,
                          resize: "none",
                          display: "none"
                        }}
                        defaultValue={""}
                      />
                    </div>
                  </div>
                </div>
                <p className="text-danger" />
              </div>
              <div className="col-lg-6">
                <input
                  type="submit"
                  className="btn btn-primary rounded-pill btn-send mb-3"
                  defaultValue="Send message"
                />
                <p className="text-muted">
                  <strong>*</strong> These fields are required.
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  )
}

export default TalkToAnExpert