import React from 'react'
import TopBar from '../TopBar/TopBar'
import Header from '../Header/Header'

const MainTopBar = () => {
  return (
    <div className="position-fixed container-fluid p-0 topBar-index">
      <TopBar/>
      <Header />
      </div>
  )
}

export default MainTopBar