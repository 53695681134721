import React from 'react'
import InsightSlider from '../InsightSlider/InsightSlider'

const LatestInsights = () => {
  return (
    <section className='trending-insights-container wrapper py-5'>
      <div className='container pt-15 pb-0 pb-md-0'>
        <div className="row">
          <div className="col-lg-12">
            <h2 className='text-center text-lg-start text-muted mb-3 text-capitalize'>Here are the latest insights</h2>
            <p className='text-center text-lg-start text-muted fs-5 text-capitalize'>Explore our trending insights, news, and resources.</p>
          </div>
        </div>
        <div className='pt-2 pt-lg-5'>
          <InsightSlider />
        </div>
      </div>
    </section>
  )
}

export default LatestInsights