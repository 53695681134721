import React from "react";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";

function EmployerOfRecord() {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/employer-of-rec.webp)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Employer Of Record</h1>
              <p className="mt-5 text-warning text-capitalize" style={{fontSize:'30px'}}>
              We offer global hiring solutions, enabling recruitment in any country worldwide, regardless of your physical presence there
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section className="wrapper mt-5 p-5 text-muted bg-light">
        <div className="container py-10 py-md-12">
          <div className="row text-center">
            <div className="col-md-10 col-lg-9 col-xxl-8 mx-auto">
              <h2 className="mb-3">
                What We Do?
              </h2>
              <h3 className="ls-sm mb-9 px-xl-11 text-capitalize">
                Our service is tailored to your requirements
              </h3>
            </div>
          </div>
          <div className="row gx-lg-8 gx-xl-12 gy-8 mt-5">
            <div className="col-md-6 col-lg-4 my-2">
              <div className="d-flex flex-row align-items-center">
                <div>
                  <i class="bi bi-journals text-warning fs-4 mx-3"></i>
                </div>
                <div>
                  <p className="fs-5 ls-sm m-0">
                    Create and uphold employment contracts compliant with local regulations.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 my-2">
              <div className="d-flex flex-row align-items-center">
                <div>
                  <i class="bi bi-credit-card text-warning fs-4 mx-3"></i>
                </div>
                <div>
                  <p className="fs-5 ls-sm m-0">
                    Ensure timely payment for supported employees.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 my-2">
              <div className="d-flex flex-row align-items-center">
                <div>
                  <i class="bi bi-person-check text-warning fs-4 mx-3"></i>
                </div>
                <div>
                  <p className="fs-5 ls-sm m-0">
                    Guarantee adherence to termination procedures and offboarding compliance.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 my-2">
              <div className="d-flex flex-row align-items-center">
                <div>
                  <i class="bi bi-bank text-warning fs-4 mx-3"></i>
                </div>
                <div>
                  <p className="fs-5 ls-sm m-0">
                    Handle payroll processing and tax deductions accurately and efficiently.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 my-2">
              <div className="d-flex flex-row align-items-center">
                <div>
                  <i class="bi bi-card-checklist text-warning fs-4 mx-3"></i>
                </div>
                <div>
                  <p className="fs-5 ls-sm m-0">
                    Offer statutory benefits compliantly
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 my-2">
              <div className="d-flex flex-row align-items-center">
                <div>
                  <i class="bi bi-telephone text-warning fs-4 mx-3"></i>
                </div>
                <div>
                  <p className="fs-5 ls-sm m-0">
                    Ensure compliance with local labor laws
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container py-10 py-md-14">
          <div className="row gy-10 gy-sm-13 gx-md-8 gx-xl-12 align-items-center">
            <div className="col-lg-6 order-lg-2 mt-8 mt-md-12">
              <div className="row gx-md-5 gy-5">
                <img src='images/employer.jpg' />
              </div>
            </div>
            <div className="col-lg-6 mt-6 mt-md-10 mb-0 mb-md-10">
              <h3 className="ls-sm mb-5 text-center text-lg-start mt-5 mt-lg-0">Benefits Of EOR</h3>
              <div className="row gy-3 gx-xl-8">
                <div className="col-xl-12">
                  <ul className="icon-list bullet-primary mb-0 list-unstyled">
                    <li className="d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">
                        Hire top talent globally and support their local needs
                      </span>
                    </li>
                    <li className="mt-3 d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">Onboard talent quickly </span>
                    </li>
                    <li className="mt-3 d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">Save costs with global hiring</span>
                    </li>
                    <li className="mt-3 d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">Retain talent</span>
                    </li>
                    <li className="mt-3 d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">Have HR support</span>
                    </li>
                    <li className="mt-3 d-flex flex-row">
                      <span>
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                      </span>
                      <span className="fs-5">
                        Maintain compliance with evolving labor laws
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container">
          <div className="row gx-0">
            <div className="col-lg-5 mt-5">
              <img src='images/employer-bck.jpeg' />
            </div>
            <div className="col-lg-6 ms-auto">
              <div className="pt-13 pb-10 pb-lg-17 py-lg-16 ps-lg-12 pe-xxl-10">
                <h3 className="ls-sm mb-5 text-center text-lg-start mt-5 mt-lg-0">
                  EOR Services Are For You If You Want To
                </h3>
                <ul className="icon-list bullet-primary mb-0 list-unstyled">
                  <li className="d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span>
                      Dip your toe into new markets compliantly without setting up a legal entity.
                    </span>
                  </li>
                  <li className="mt-3 d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span>
                      Attract top talent globally with flexible hiring options.
                    </span>
                  </li>
                  <li className="mt-3 d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span>
                      Access top-tier talent worldwide without entity setup hassles.
                    </span>
                  </li>
                  <li className="mt-3 d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span>
                      Quickly expand internationally by hiring local talent without the complexities of setting up a legal entity.
                    </span>
                  </li>
                  <li className="mt-3 d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span>
                      Mitigate the risk of misclassifying contractors as employees by utilizing compliant hiring and classification practices.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LetsConnect />
    </React.Fragment>
  );
}

export default EmployerOfRecord;
