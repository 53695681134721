import React from "react";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";

function DiversityRecruiting() {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/diversity-recruiting.png)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Diversity &amp; Inclusion Recruiting</h1>
              <p className="mt-5 text-warning text-capitalize" style={{fontSize:'30px'}}>
              Allow our Diversity & Inclusion talent acquisition experts to collaborate with you on strategizing how to integrate Diversity & Inclusion as a cornerstone of your recruitment strategy
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section className="wrapper mt-5 p-5 bg-light text-muted">
        <div className="container">
          <div className="row gx-lg-8 gx-xl-12 gy-10 mb-13 mb-md-10 mt-md-10 mt-4 align-items-center">
            <div className="col-lg-6 position-relative">
              <div className="shape bg-soft-primary rounded-circle rellax w-20 h-20" />
              <img src='/images/diversity.jpeg' alt='' />
            </div>
            <div className="col-lg-6 px-lg-5 px-0">
              <h3 className="mb-3 mt-5 mt-lg-0 text-center text-lg-start">
                Diversity & Inclusion Recruitment Services
              </h3>
              <p className="fs-5">
                Diversity & Inclusion (D&I) recruiting involves intentionally seeking out and hiring individuals from diverse backgrounds, experiences, and perspectives. Research has shown that companies that prioritize D&I in their hiring practices tend to perform better and foster innovation.
              </p>
              <p className="fs-5">
                Diversity & Inclusion (D&I) recruiting entails hiring practices free from bias based on factors such as race, ethnicity, gender, gender identity, sexual orientation, age, social class, physical abilities, religious beliefs, political affiliations, and more. If your organization seeks improvement in this area, we can assist you. We comprehend the challenges you may encounter and can assist in strategizing and filling your key positions.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container">
          <div className="row gx-lg-8 gx-xl-12 gy-10 mb-13 mb-md-10 mt-md-10 mt-4 align-items-center">
            <div className="col-lg-6 position-relative order-lg-2">
              <div className="shape bg-soft-primary rounded-circle rellax w-20 h-20" />
              <img src='images/diverse-people-hand.webp' />
            </div>
            <div className="col-lg-6">
              <h3 className="mb-3 text-center text-lg-start mt-5 mt-lg-0">
                Benefits Of D&amp;I Recruitment:
              </h3>
              <h5 className="text-center text-lg-start">
                The company's inclusive approach to talent acquisition
                strategy generates a wide range of benefits:
              </h5>
              <ul className="icon-list bullet-bg bullet-soft-blue mb-0 list-unstyled mt-3">
                <li className="d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    Attracting the widest pool of candidates, better
                    utilization of talent,
                  </span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    Increased market share and competitive edge, enhanced
                    creativity and innovation based on a broader perspective
                    of the world.
                  </span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">Increased quality of team problem solving.</span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">Increased workforce productivity.</span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    Recognition for diversity resulting in elevated company
                    reputation.
                  </span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    Strong employer branding as an employer of choice.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-6 position-relative">
              <div className="shape bg-soft-primary rounded-circle rellax w-20 h-20" />
              <img src='images/diverse-people-desk.webp' />
            </div>
            <div className="col-lg-6 px-lg-5 px-0">
              <h2 className="mb-3 text-center text-lg-start mt-5 mt-lg-0 text-capitalize">How to get there</h2>
              <ul className="icon-list bullet-bg bullet-soft-primary mb-0 list-unstyled">
                <li className="d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span>
                    Companies that prioritize diversity and inclusion require leaders who can define the approach to their workforces, industries, and customers.
                  </span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span>
                    Companies committed to diversity and inclusion must engage in ongoing research, continually refine their talent recruitment strategies, and remain vigilant in ensuring that their organization-wide initiatives remain relevant in an evolving business landscape.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <LetsConnect />
    </React.Fragment>
  );
}

export default DiversityRecruiting;
