import React from 'react'
import TalkToAnExpert from '../../Components/TalkToAnExpert/TalkToAnExpert'
import GlobalBrands from '../../Components/GlobalBrands/GlobalBrands'
import GlobalPresence from '../../Components/GlobalPresence/GlobalPresence'
import JoinCommunity from '../../Components/JoinCommunity/JoinCommunity'
import ContactForm from '../../Components/ContactForm/ContactForm'
import LatestInsights from '../../Components/LatestInsights/LatestInsights'
import LetsConnect from '../../Components/LetsConnect/LetsConnect'
import ServiceSlider from '../../Components/ServiceSlider/ServiceSlider'

const CloudInfrastructure = () => {
    return (
        <React.Fragment>
            <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/cloud-infra-banner.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Cloud Evolution and Operational Solutions</h1>
              <p className="mt-5 text-warning" style={{fontSize:'30px'}}>
              DataCentrix™ Cloud Infrastructure Transformation And Operational Services Empower Organizations To Fulfill Their Digital Aspirations Across Public, Private, And Hybrid Cloud Environments. Our Comprehensive Solutions Offer A Secure Environment That Fosters Agility, Granting Organizations An Unparalleled Ability To Stay Ahead Of Rapidly Evolving Market Demands
              </p>
            </div>
          </div>
        </div>
      </section>
            <GlobalBrands />
            <section className="cloud-infra-leveragecloud-container wrapper bg-light mt-5 p-5 text-muted">
                <div className="container py-14 py-md-16">
                    <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                        <div className="col-lg-7 order-lg-2">
                            <div className="row gx-md-5 gy-5">
                                <div className="col-md-5 offset-md-1 align-self-end">
                                    <div className="card shadow-lg bg-soft-primary text-muted right-cross-card">
                                        <div className="card-body postion-relative">
                                        <div className='position-absolute top-0 end-0 translate-middle fs-1 text-warning'><i class="bi bi-bar-chart-fill"></i></div>
                                            <h5 className='fw-bold'>Build</h5>
                                            <p className="mb-0">
                                                Whether you're migrating, modernizing, or delving into data-driven technologies like data warehouses and machine learning, our expertise is here to assist you at any point in your cloud journey.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 align-self-end">
                                    <div className="card shadow-lg bg-soft-blue text-muted right-cross-card">
                                        <div className="card-body position-relative">
                                            <div className='position-absolute top-0 end-0 translate-middle fs-1 text-warning'><i class="bi bi-ui-checks"></i></div>
                                            <h5 className='fw-bold'>Optimize</h5>
                                            <p className="mb-0">
                                                Assessing your cloud environment allows us to identify gaps and restructure for optimal efficiency, performance, security, and governance. Our evaluation ensures that your infrastructure meets the highest standards, delivering the reliability and scalability needed to support your operations effectively.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="card shadow-lg bg-soft-blue text-muted right-cross-card">
                                        <div className="card-body position-relative">
                                            <div className='position-absolute top-0 end-0 translate-middle fs-1 text-warning'><i class="bi bi-person-workspace"></i></div>
                                            <h5 className='fw-bold'>Operate</h5>
                                            <p className="mb-0">
                                                Concentrate on advancing your core business objectives to enhance customer value, while we take charge of proactively monitoring and managing your infrastructure. With our vigilant oversight, you can remain focused on driving innovation and delivering exceptional experiences to your customers.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 align-self-start">
                                    <div className="card shadow-lg bg-soft-primary text-muted right-cross-card">
                                        <div className="card-body position-relative">
                                            <div className='position-absolute top-0 end-0 translate-middle fs-1 text-warning'><i class="bi bi-file-earmark-lock-fill"></i></div>
                                            <h5 className='fw-bold'>Govern</h5>
                                            <p className="mb-0">
                                                Secure control over your cloud infrastructure confidently and harness multi-cloud environments for seamless expansion without complexity. Our approach ensures adaptability and optimization to meet your evolving needs.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 mt-5 mt-lg-0">
                            <h3 className="mb-3">
                            Utilize The Cloud For Accelerated Growth
                            </h3>
                            <p className="mb-5 fs-5">
                                Unlock the potential of cloud computing for innovative solutions and organizational success.
                            </p>
                            <p className='fs-5'>
                            DataCentrix™ specializes in unlocking sustained value through the migration, operation, and scaling of business-critical applications in the cloud. Our team of experienced cloud experts provides organizations with a comprehensive suite of digital solutions, empowering them to enhance agility and security within their cloud environments. This enables businesses to rapidly adapt, creating seamless experiences while ensuring the safety of data across public, private, or hybrid clouds.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cloud-infra-bestpractices-container wrapper mt-5 p-5 text-muted">
                <div className="container py-14 py-md-16">
                    <div className="row gx-lg-8 gx-xl-12 gy-6 center">
                        <h3 className="mb-3">
                        Implementing Best Practices Ensures A Higher Return On Investment (ROI) And Greater Efficiency From Cloud Resources
                        </h3>
                        <p className="fs-5 mb-5">
                            At DataCentrix™, we serve as your trusted and dedicated partner, driving your mission forward. Our team offers the expertise and experience required to effectively build, launch, and maintain multi-cloud strategies tailored to your specific needs. Partnering with us streamlines modernization, reducing complexity, cost burdens, and operational challenges. This approach ensures an optimized outcome, consistently delivering reliable performance for our customers.
                        </p>
                    </div>
                    <div className="row gx-lg-8 gx-xl-12 gy-6">
                        <div className="col-md-6 col-lg-4 mt-3">
                            <div className="card shadow-lg text-muted h-100 hover-metic">
                                <div class="card-body">
                                    <div className="text-center mt-2 mt-lg-0">
                                        <i class="bi bi-diagram-3-fill text-warning fs-2 mx-2"></i>
                                    </div>
                                    <h5 class="mb-1 text-center my-2 fw-bold">Cloud Adoption and Migration</h5>
                                    <p class="mb-0 fs-5">
                                        Migrate legacy applications to the cloud for enhanced reliability, scalability, security, and performance efficiency, ensuring seamless modernization
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mt-3">
                            <div className="card shadow-lg text-muted h-100 hover-metic">
                                <div class="card-body">
                                    <div className="text-center mt-2 mt-lg-0">
                                        <i class="bi bi-app-indicator text-warning fs-2 mx-2"></i>
                                    </div>
                                    <h5 class="mb-1 text-center my-2 fw-bold">Application Modernization</h5>
                                    <p class="mb-0 fs-5">
                                        Boost efficiency and scalability in the cloud by embracing serverless or container platforms for cost-effective solutions
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mt-3">
                            <div className="card shadow-lg text-muted h-100 hover-metic">
                                <div class="card-body">
                                    <div className="text-center mt-2 mt-lg-0">
                                        <i class="bi bi-cloud text-warning fs-2 mx-2"></i>
                                    </div>
                                    <h5 class="mb-1 text-center my-2 fw-bold">Cloud DevOps</h5>
                                    <p class="mb-0 fs-5">
                                        Harness the potential of your data for informed decisions through analytics and machine learning, enabling smarter strategies and actions for your business
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mt-3">
                            <div className="card shadow-lg text-muted h-100 hover-metic">
                                <div class="card-body">
                                    <div className="text-center mt-2 mt-lg-0">
                                        <i class="bi bi-file-lock text-warning fs-2 mx-2"></i>
                                    </div>
                                    <h5 class="mb-1 text-center my-2 fw-bold">Ensuring Security and Compliance Measures</h5>
                                    <p class="mb-0 fs-5">
                                        Ensure continuous governance to deliver a secure and compliant cloud, meeting regulatory needs and addressing concerns for enhanced security and compliance
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mt-3">
                            <div className="card shadow-lg text-muted h-100 hover-metic">
                                <div class="card-body">
                                    <div className="text-center mt-2 mt-lg-0">
                                        <i class="bi bi-cash text-warning fs-2 mx-2"></i>
                                    </div>
                                    <h5 class="mb-1 text-center my-2 fw-bold">Cost Optimization</h5>
                                    <p class="mb-0 fs-5">
                                        Maximize ROI with Streamlined Cloud Operations, Optimizing Performance for Ultimate Efficiency through Enhanced Visibility of Cloud Services
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mt-3">
                            <div className="card shadow-lg text-muted h-100 hover-metic">
                                <div class="card-body">
                                    <div className="text-center mt-2 mt-lg-0">
                                        <i class="bi bi-cloud-download text-warning fs-2 mx-2"></i>
                                    </div>
                                    <h5 class="mb-1 text-center my-2 fw-bold">Cloud Advisory Services</h5>
                                    <p class="mb-0 fs-5">
                                        Increase business agility, reduce costs, and drive innovation with expert cloud and infrastructure advisory services tailored to your needs and goals
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cloud-infra-trustedbrands-container wrapper bg-light mt-5 p-5">
                <div className="container py-14 py-md-16">
                    <div className="row">
                        <div className="col-xl-10 mx-auto">
                            <div
                                className="card image-wrapper bg-full bg-image bg-overlay bg-overlay-400 text-white trusted-global-brands"
                                // style={{ backgroundImage: 'url("images/bg3.webp")' }}
                            >
                                <div className="card-body p-6 p-md-11 d-lg-flex flex-row align-items-lg-center justify-content-md-between text-center text-lg-start">
                                    <h5 className="mb-6 mb-lg-0 p-5 text-center">
                                    Trusted By Global Brands, We Tailor Services To Meet Your Business Objectives. Let's Explore Custom Solutions For Your Success Together
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cloud-infra-servicereason-container wrapper mt-5 p-5">
                <div className="container-fluid pt-9 pt-lg-11 pb-14 pb-lg-6">
                    <div className="row gx-lg-8 gx-xl-10">
                        <div className="col-lg-4 col-md-12 pt-5 text-start">
                            <figure className="rounded my-5 mb-md-0">
                                <img src="images/cld-infra.jpeg" height={400} width={265} alt="" srcset="" />
                            </figure>
                        </div>
                        <div className="col-lg-8 col-md-12 px-3 mt-5">
                            <h3 className="mb-2 text-center text-lg-start">Why DataCentrix™ ?</h3>
                            <p className="mb-8 fs-5">
                                Stay competitive and achieve business goals with DataCentrix™. Our digital engineering experts support enterprise modernization, ensuring you remain ahead in the industry.
                            </p>
                            <div className="row process-wrapper">
                                <div className="col-lg-4 col-md-6 my-3">
                                    <div className="card shadow-lg text-muted h-100 main-card">
                                        <div class="card-body">
                                            <div className="text-center mt-2 mt-lg-0">
                                                <i class="bi bi-diagram-3-fill text-warning fs-2 mx-2"></i>
                                            </div>
                                            <h5 class="mb-1 text-center my-2 fw-bold">Technical Expertise</h5>
                                            <p class="mb-0 fs-5">
                                                Proven expertise in delivering high-quality digital engineering and enterprise modernization solutions, backed by a track record of successful implementations
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 my-3">
                                    <div className="card shadow-lg text-muted h-100 main-card">
                                        <div class="card-body">
                                            <div className="text-center mt-2 mt-lg-0">
                                                <i class="bi bi-diagram-3-fill text-warning fs-2 mx-2"></i>
                                            </div>
                                            <h5 class="mb-1 text-center my-2 fw-bold">Industry Experience</h5>
                                            <p class="mb-0 fs-5">
                                                Extensive industry experience coupled with a profound understanding of business challenges, processes, and regulatory needs across diverse sectors
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 my-3">
                                    <div className="card shadow-lg text-muted h-100 main-card">
                                        <div class="card-body">
                                            <div className="text-center mt-2 mt-lg-0">
                                                <i class="bi bi-diagram-3-fill text-warning fs-2 mx-2"></i>
                                            </div>
                                            <h5 class="mb-1 text-center my-2 fw-bold">Project Delivery
                                                Methodology</h5>
                                            <p class="mb-0 fs-5">
                                                Our dedication to project delivery excellence drives innovation in tools and processes, ensuring successful outcomes and rapid time-to-value for our clients                                    </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 my-3">
                                    <div className="card shadow-lg text-muted h-100 main-card">
                                        <div class="card-body">
                                            <div className="text-center mt-2 mt-lg-0">
                                                <i class="bi bi-diagram-3-fill text-warning fs-2 mx-2"></i>
                                            </div>
                                            <h5 class="mb-1 text-center my-2 fw-bold">Ability to Scale</h5>
                                            <p class="mb-0 fs-5">
                                                Your ideal partner for the future, providing scalable solutions and adaptable engagement models to meet all your growth requirements
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 my-3">
                                    <div className="card shadow-lg text-muted h-100 main-card">
                                        <div class="card-body">
                                            <div className="text-center mt-2 mt-lg-0">
                                                <i class="bi bi-diagram-3-fill text-warning fs-2 mx-2"></i>
                                            </div>
                                            <h5 class="mb-1 text-center my-2 fw-bold">Communication and
                                                Collaboration</h5>
                                            <p class="mb-0 fs-5">
                                                Emphasizing communication and collaboration, we foster powerful engagement among teams throughout their project journey, ensuring success at every step
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 my-3">
                                    <div className="card shadow-lg text-muted h-100 main-card">
                                        <div class="card-body">
                                            <div className="text-center mt-2 mt-lg-0">
                                                <i class="bi bi-diagram-3-fill text-warning fs-2 mx-2"></i>
                                            </div>
                                            <h5 class="mb-1 text-center my-2 fw-bold">Innovation and Continuous
                                                Improvement
                                                Collaboration</h5>
                                            <p class="mb-0 fs-5">
                                                Committed to forward-thinking ideas and continuous improvement, we empower our customers to maintain a competitive edge in their industries
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <GlobalPresence />
            <ServiceSlider />
            <JoinCommunity />
            <LatestInsights />
            <LetsConnect />
        </React.Fragment>
    )
}

export default CloudInfrastructure