import React, { useState } from "react";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";
import ContactUsPopup from "../../Components/ContactUsPopup/ContactUsPopup";
import WhoAreWe from "../../Components/WhoAreWe/WhoAreWe";
import GlobalPresence from "../../Components/GlobalPresence/GlobalPresence";
import LatestInsights from "../../Components/LatestInsights/LatestInsights";
import Slider from "../../Components/Slider/Slider";
import {
  management_slider_data,
  slider_transform,
} from "../../Services/ConstantVariables";
import SliderBullets from "../../Components/SliderBullets/SliderBullets";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";

const CompanyOverview = () => {
  const [slider, setSlider] = useState([
    {
      title: "Our Management",
      isShow: true,
    },
    {
      title: "Partnership",
      isShow: false,
    },
    {
      title: "Working with us",
      isShow: false,
    },
  ]);
  const [activeBullet, setActiveBullet] = useState(0);
  const slideHandler = (index) => {
    let updateSlider = slider.map((element, i) => {
      if (index == i) {
        element.isShow = true;
      } else {
        element.isShow = false;
      }
      return element;
    });
    setSlider(updateSlider);
  };
  const onBulletClick = (index) => {
    setActiveBullet(index);
    console.log("slider_transform[index]===>",slider_transform[index]);
    const element = document.getElementsByClassName("company-overview-wrapper")[0];
    element.style.transform = `translateX(${slider_transform[index]}) translateY(0px) translateZ(0px)`;
    element.style.transitionDuration = "300ms";
  };
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/about-banner.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>About DataCentrix™</h1>
              <p className="mt-5 text-warning text-capitalize" style={{fontSize:'30px'}}>
              DataCentrix™ is a premier provider of integrated IT solutions and
                last-mile supply chain services for global Data Center, Edge
                Computing, Telecom, IoT Infrastructure, and related sectors
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <WhoAreWe />
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container py-8 py-md-15">
          <div className="row gx-md-5 gy-5">
            <div className="col-md-6 col-xl-6 d-flex">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-eye text-warning fs-2 mx-2"></i>
                  </div>
                  <h5 className="text-center my-2 fw-bold">Our Vision</h5>
                  <p className="mb-2 fs-5">
                    At DataCentrix™, we envision a future of seamlessly managed last-mile
                    technical and logistics services, empowering companies of
                    all sizes to expand globally. ISG removes the barrier of
                    high-cost support services infrastructure, enabling market
                    entry without compromising service levels
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-6 d-flex">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-rocket text-warning fs-2 mx-2"></i>
                  </div>
                  <h5 className="text-center my-2 fw-bold">Our Mission</h5>
                  <p className="mb-2 fs-5">
                    Our mission is to equip global IT companies with the tools
                    and human resources to enhance and extend their global
                    capability. We strive to create technology-driven platforms
                    that revolutionize global IT service delivery methods.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-6 d-flex">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-box2-heart text-warning fs-2 mx-2"></i>
                  </div>
                  <h5 className="text-center my-2 fw-bold">Our Values</h5>
                  <p className="mb-2 fs-5">
                    With 10 years of experience in the ITO industry, our DataCentrix™
                    team comprises veterans who have developed innovative
                    solutions for the world's leading IT brands. We continuously
                    refine tools and processes to ensure our customers' success
                    without compromising quality.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-6 d-flex">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-bullseye text-warning fs-2 mx-2"></i>
                  </div>
                  <h5 className="text-center my-2 fw-bold">Expanding Goals</h5>
                  <p className="mb-2 fs-5">
                  DataCentrix™ is dedicated to expanding its service portfolio for our
                    satisfied customers. Our team develops technology-driven
                    tools that revolutionize IT service consumption and
                    delivery. With flexible business models and innovative
                    service platforms, ISG is committed to customer success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 p-5 text-muted bg-light" id="teamSection">
        <div className="container">
          <div className="d-none d-lg-block">
            <ul className="nav nav-tabs nav-tabs-bg nav-tabs-shadow-lg d-flex justify-content-between nav-justified flex-lg-row flex-column text-muted">
              {slider?.map((element, index) => (
                <li className="nav-item" key={index}>
                  <a
                    className={`nav-link d-flex flex-row ${
                      element.isShow ? "active" : ""
                    } align-items-center`}
                    data-bs-toggle="tab"
                    href="#tab2-1"
                  >
                    <div>
                      <img src="images/" alt="" srcset="" />
                    </div>
                    <div>
                      <h5
                        className="mb-0 text-muted py-3 text-center"
                        onClick={() => slideHandler(index)}
                      >
                        {element.title}
                      </h5>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="tab-content mt-3 mt-md-3">
            {slider[0].isShow ? (
              <Slider>
                <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center text-muted">
                  <div className="col-lg-12">
                    <div className="swiper-container blog grid-view mb-10">
                      <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events team-swiper swiper-backface-hidden">
                        <div
                          className="swiper-wrapper company-overview-wrapper mb-5"
                          style={{
                            transform: "translate3d(-1970px, 0px, 0px)",
                            transitionDuration: "0ms",
                          }}
                        >
                          {management_slider_data?.map((element) => (
                            <div
                              className="swiper-slide mb-12"
                              style={{ width: 374, marginRight: 20 }}
                            >
                              <div
                                className="card h-100 text-muted"
                                style={{ textAlign: "left" }}
                              >
                                <div className="card-body">
                                  <figure className="w-15 mb-4">
                                    <img
                                      src={element.image}
                                      alt=""
                                      srcset=""
                                      height={200}
                                      width={200}
                                    />
                                  </figure>
                                  <h5 className="mb-1 text-center">
                                    {element.title}
                                  </h5>
                                  <div className="meta mb-2 fs-5 text-center">
                                    {element.content}
                                  </div>
                                  <div className="mb-0 text-center">
                                    <a
                                      href="https://www.linkedin.com/in/billybath/"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <i className="uil uil-linkedin text-warning fs-4 mx-2" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
                          <SliderBullets
                            pagination={6}
                            pageSize={2}
                            onBulletClick={onBulletClick}
                            activeBullet={activeBullet}
                          />
                        </div>
                      </div>
                      <div className="text-center justify-content-center d-flex pt-10">
                        <a
                          className="btn btn-outline-warning rounded-pill"
                          href="/about/management-team/"
                        >
                          Meet Our Leaders
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            ) : slider[1].isShow ? (
              <Slider>
                <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center text-muted">
                  <div className="col-lg-6 order-lg-2 d-none d-lg-block">
                    <figure className="rounded shadow-lg">
                      <img src="images/partnership.webp" alt="" srcset="" />
                    </figure>
                  </div>
                  <div className="col-lg-6">
                    <p className="fs-5">
                      We've cultivated strategic alliances with top technology
                      and service providers worldwide to deliver tailored
                      technology solutions for our customers.
                    </p>
                    <p className="fs-5">
                    DataCentrix™, in collaboration with our partners, crafts end-to-end
                      industry-leading solutions for our customers. Our
                      partnership model enables flexibility to meet your
                      specific needs, whether you're a global enterprise or
                      small business.
                    </p>
                    <p className="fs-5">
                      Our partner relationships drive the creation of
                      innovative, out-of-the-box solutions for end-users seeking
                      high performance, scalability, and reliability at
                      competitive costs.
                    </p>
                    <div className="text-center justify-content-start d-flex pt-2">
                      <a
                        className="btn btn-outline-warning rounded-pill"
                        href="/about/technology-partners/"
                      >
                        Strategic Partnership
                      </a>
                    </div>
                  </div>
                </div>
              </Slider>
            ) : slider[2].isShow ? (
              <Slider>
                <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center text-muted">
                  <div className="col-lg-12">
                    <h5 className="mb-3 mt-6">Why Join At DataCentrix™ ?</h5>
                    <p className="fs-5">
                      At DataCentrix™, we're a dedicated, success-oriented team, always
                      seeking key individuals who are dedicated, self-motivated,
                      and creative. Join us at the forefront of internet server
                      market development, where learning is constant. We
                      integrate hardware and operating system products with a
                      commitment to our customers' success.
                    </p>
                    <ul className="icon-list bullet-bg bullet-soft-blue mb-8 list-unstyled">
                      <li className="d-flex flex-row">
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                        <span className="fs-5">Let's collaborate and explore opportunities together</span>
                      </li>
                      <li className="d-flex flex-row">
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                        <span className="fs-5">Join a talented, success-oriented team of individuals</span>
                      </li>
                      <li className="d-flex flex-row">
                        <i className="uil uil-check text-warning fs-4 mx-2" />
                        <span className="fs-5">Access top-tier training, compensation, and benefits
                        packages</span>
                      </li>
                    </ul>
                    <h5 className="mb-3">Our leadership in the Industry:</h5>
                    <p className="fs-5">
                      We have been in this industry for more than 10 years with
                      successful track records and customer loyalty. Please come
                      and join an organization which has every reason to be
                      successful and help boost your career.
                    </p>
                  </div>
                </div>
              </Slider>
            ) : null}
          </div>
        </div>
      </section>
      {/* Company Locations Section Start*/}
      <section>
        <div className="container-fluid mt-5 p-5 text-muted bg-light">
          <div className="row mt-lg-5">
            <h3 className="text-center my-lg-2 text-muted">Our Presence</h3>
            <p className="text-center fs-5">
              {/* Stay up-to-date with our repair center, ensuring your IT systems and equipment remain current and reliable. */}
            </p>
            <div className="row mt-lg-5">
              <div className="col-lg-4 my-3 col-md-6">
                <div className="card shadow-lg text-muted h-100">
                  <div className="card-body">
                    <div className="text-center">
                      {/* <i class="bi bi-person-fill-gear text-warning fs-2 mx-2"></i> */}
                      {/* <img src='images/singapore-flag.jpg' /> */}
                      <img src="images/singapore-flag-img.webp" />
                    </div>
                    <div>
                      <h5 className="my-2 text-center">
                        <b>Singapore</b>
                      </h5>
                      <p className="fs-5">
                        Singapore Headquarter Address :<br />
                        68, Circular Road, # 02-01,
                        <br />
                        Singapore,
                        <br />
                        049422.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 my-3 col-md-6">
                <div className="card shadow-lg text-muted h-100">
                  <div className="card-body">
                    <div className="text-center">
                      {/* <i class="bi bi-pc-display text-warning fs-2 mx-2"></i> */}
                      {/* <img src='images/India-flag.jpg' /> */}
                      <img src="images/India-flag-img.png" />
                    </div>
                    <div>
                      <h5 className="my-2 text-center">
                        <b>India</b>
                      </h5>
                      <p className="fs-5">
                        Mumbai Address :<br />
                        Mashayakh Tower, G 1404, Chincholi Bunder,
                        <br />
                        Malad West, Mumbai, Maharashtra,
                        <br />
                        400064.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 my-3 col-md-6">
                <div className="card shadow-lg text-muted h-100">
                  <div className="card-body">
                    <div className="text-center">
                      {/* <i class="bi bi-pc-horizontal text-warning fs-2 mx-2"></i> */}
                      <img src="images/hongkong-flag.png" />
                    </div>
                    <div>
                      <h5 className="my-2 text-center">
                        <b>Hong Kong</b>
                      </h5>
                      <p className="fs-5">
                        Hong Kong Address :<br />
                        Unit 1603, 16Tth Floor, The L. Plaza 367 - 375,
                        <br />
                        Queen's Road Central Sheung Wan Hk, Hong Kong
                        <br />
                        99907.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Company Locations Section End*/}
      <GlobalPresence />
      <LatestInsights />
      <LetsConnect />
    </React.Fragment>
  );
};

export default CompanyOverview;
