import React, { useState } from "react";
import BookMeeting from "../../Components/BookMeeting/BookMeeting";
import JoinCommunity from "../../Components/JoinCommunity/JoinCommunity";
import GlobalPresence from "../../Components/GlobalPresence/GlobalPresence";
import { smartHands_accordion } from "../../Services/ConstantVariables";
import Accordian from "../../Components/Accordian/Accordian";
import ServiceSlider from "../../Components/ServiceSlider/ServiceSlider";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";
const SmartHandsDemands = () => {
  const [accordianData, setAccordianData] = useState(smartHands_accordion);

  // const _accordianHandler = (accordianIndex, data, updateFunction) => {
  //   let updatedAccordianData = data.map((element, i) => {
  //     if (accordianIndex == i) {
  //       element.isOpen = !element.isOpen;
  //     } else {
  //       element.isOpen = false;
  //     }
  //     return element;
  //   });
  //   updateFunction(updatedAccordianData);
  // };

  return (
    <React.Fragment>
      <section className="">
        <div
          className="container-fluid home-service-global-container"
          style={{
            background: `url(images/tech-assistance.jpg)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center bottom",
          }}
        >
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className="fw-bold text-capitalize">
                On-demand technical assistance
              </h1>
              <p
                className="mt-5 text-warning text-capitalize"
                style={{ fontSize: "30px" }}
              >
                Our comprehensive solution provides efficient, reliable 24/7 IT
                support globally. Our certified experts act as an extension to
                your IT teams, ensuring infrastructure uptime, regardless of
                location or time constraints
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section className="wrapper bg-light mt-5 p-5 text-muted">
        <div className="container py-14 py-md-17">
          <div className="row text-center">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h3 className="mb-3 text-capitalize">
                Use DataCentrix™ as your eyes, ears and hands
              </h3>
              <p className="fs-5 mb-10 px-xl-10">
                Our tailored consumption models help boost operational
                efficiency while increasing speed and flexibility
              </p>
            </div>
          </div>
          <div className="position-relative">
            <div
              className="shape rounded-circle bg-soft-primary rellax w-16 h-16"
              data-rellax-speed={1}
              style={{ bottom: "-0.5rem", right: "-2.5rem", zIndex: 0 }}
            />
            <div
              className="shape bg-dot primary rellax w-16 h-17"
              data-rellax-speed={1}
              style={{ top: "-0.5rem", left: "-2.5rem", zIndex: 0 }}
            />
            <div className="row gx-md-5 gy-5 mt-5">
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className="text-center">
                      <i className="uil uil-phone-volume text-warning fs-2 mx-2" />
                    </div>
                    <h5 className="text-center fw-bold">
                      24/7 Support and Availability
                    </h5>
                    <p className="mb-2 fs-5">
                      24/7 access to certified experts with SLAs to meet your
                      needs on demand.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className="text-center">
                      <i className="uil uil-shield-exclamation text-warning fs-2 mx-2" />
                    </div>
                    <h5 className="text-center fw-bold">
                      Flexible Pay-As-You-Go pricing model
                    </h5>
                    <p className="mb-2 fs-5">
                      Enjoy enterprise-quality IT service standards with the
                      freedom of pay-as-you-go pricing.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className="text-center">
                      <i className="uil uil-laptop-cloud text-warning fs-2 mx-2" />
                    </div>
                    <h5 className="text-center fw-bold">Global Coverage</h5>
                    <p className="mb-2 fs-5">
                      Our experienced workforce is available across diverse
                      locations, from urban hubs to remote areas.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className="text-center">
                      <i className="uil uil-chart-line text-warning fs-2 mx-2" />
                    </div>
                    <h5 className="text-center fw-bold">
                      Visibility and Control
                    </h5>
                    <p className="mb-2 fs-5">
                      Integrated platform fosters collaboration, offering
                      insights for speed and scale across numerous locations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container py-15">
          <div className="container">
            <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
              <div className="col-lg-5">
                <h3 className="mb-3 text-capitalize">
                  Empower your operations team with DataCentrix's Smart Hands
                  Services for maximum efficiency.
                </h3>
                <p className="fs-5 mb-6">
                DataCentrix™'s global technical workforce offers 24/7 remote assistance
                  for enhanced productivity. From rollout to troubleshooting,
                  expect top-tier professional services and expertise.
                </p>
                <figure>
                  <img
                    src="images/smart-hands-pic.jpeg"
                    height={400}
                    width={400}
                    alt=""
                    srcSet=""
                  />
                </figure>
              </div>
              <div className="col-lg-7">
                <ul className="icon-list bullet-bg bullet-soft-blue mb-0 list-unstyled">
                  <li className="d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span className="fs-5">
                      <strong>Global Rollout</strong> - Deploy IoT, edge
                      compute, wireless, and 5G equipment and software across
                      multiple locations with our global workforce.
                    </span>
                  </li>
                  <li className="mt-3 d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span className="fs-5">
                      <strong>Break Fix &amp; Maintenance</strong> - Ensure the
                      reliability of your IT infrastructure with scheduled
                      maintenance and break/fix services delivered by our
                      certified experts.
                    </span>
                  </li>
                  <li className="mt-3 d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span className="fs-5">
                      <strong>Troubleshooting</strong> - Certified experts
                      provide troubleshooting for equipment, software, and
                      network issues.
                    </span>
                  </li>
                  <li className="mt-3 d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span className="fs-5">
                      <strong>Equipment Installation Services</strong> -
                      Certified professionals provide server, storage, and
                      network equipment installation services.
                    </span>
                  </li>
                  <li className="mt-3 d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span className="fs-5">
                      <strong>Rack &amp; Stack Services</strong> - Configure,
                      install, connect, and test rack and stack equipment with
                      expertise from our certified professionals.
                    </span>
                  </li>
                  <li className="mt-3 d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span className="fs-5">
                      <strong>Remote Management</strong> - Our experienced
                      technicians offer power cycling, remote hands, visual
                      inspection, and remote monitoring services.
                    </span>
                  </li>
                  <li className="mt-3 d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span className="fs-5">
                      <strong>Cabling Services</strong> - Our certified experts
                      assemble, install, test, and certify cabling
                      infrastructure, including extending patch cables,
                      installing fiber optic cables, and more.
                    </span>
                  </li>
                  <li className="mt-3 d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span className="fs-5">
                      <strong>Asset Management</strong> - Our certified experts
                      provide inventory management, asset tagging, and asset
                      tracking services.
                    </span>
                  </li>
                  <li className="mt-3 d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span className="fs-5">
                      <strong>Logistics Support</strong> - Our certified experts
                      handle equipment relocation, packing, unpacking, returns,
                      repairs, and more with precision.
                    </span>
                  </li>
                  <li className="mt-3 d-flex flex-row">
                    <span>
                      <i className="uil uil-check text-warning fs-4 mx-2" />
                    </span>
                    <span className="fs-5">
                      <strong>On-Site Assessments/Surveys</strong> - Our
                      certified experts conduct site surveys, assessments, and
                      audits to evaluate and report on the current state of your
                      IT infrastructure.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted bg-light">
        <div className="container">
          <div className="row gy-10 gy-sm-13 gx-lg-3 align-items-center">
            <div className="col-lg-6 position-relative">
              <figure className="rounded">
                <img
                  src="images/workflow-img.png"
                  height={600}
                  width={650}
                  alt=""
                  srcset=""
                />
              </figure>
            </div>
            <div className="col-lg-6 col-xl-5 ps-lg-5 ps-0">
              <h3 className="mb-3 text-center text-lg-start mt-5 mt-lg-0">
                Zero Friction End-to-End Automation
              </h3>
              <p className="fs-5 mb-7">
                Our AI/ML-driven Service Cloud platform and software offer a
                seamless and efficient experience. Easily integrate with your
                existing ITSM software for a unified view of service requests
                and IT tickets.
              </p>
              <Accordian
                data={accordianData}
                // accordianHandler={_accordianHandler}
                // updateFunction={setAccordianData}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container py-5">
          <div className="row">
            <div className="col-xl-10 mx-auto">
              <div
                className="card image-wrapper bg-full bg-image bg-overlay bg-overlay-400 text-white"
                style={{ backgroundImage: 'url("images/bg3.webp")' }}
              >
                <div className="card-body p-6 p-md-11 d-lg-flex flex-row align-items-lg-center justify-content-md-between text-center text-lg-start">
                  <h5 className="mb-6 mb-lg-0 p-lg-5 text-center text-capitalize">
                    Revolutionize your operations with our Service Cloud
                    Platform, seamlessly integrating with existing systems.
                    Unlock new levels of performance today!
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container py-12 py-md-14">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h2 className="mb-3 text-center">The DataCentrix™ Difference</h2>
              <h5 className="px-xl-11 text-center">
                Reach peak performance and ensure reliability with right partner
                on your side
              </h5>
              <p className="mb-9 fs-5 mt-3">
                At DataCentrix™, we cater to the unique needs of enterprise customers
                with our SmartHands on-demand services. Our highly qualified
                technicians are available 24/7 worldwide, offering customized
                solutions to meet your specific requirements.
              </p>
            </div>
          </div>
          <div className="row gx-lg-8 gx-xl-12 gy-8 mt-5">
            <div className="col-md-6 col-lg-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="text-center">
                    <i className="bi bi-arrows-fullscreen text-warning fs-4 mx-2"></i>
                  </div>
                  <h5 className="mb-1 text-center fw-bold">Scalability</h5>
                  <p className="mb-0 fs-5">
                    Our global SmartHand network scales to meet the evolving
                    needs of enterprise customers. Capable of managing large,
                    complex IT environments and supporting multiple global
                    locations.
                  </p>{" "}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="text-center">
                    <i className="bi bi-shield-fill-plus text-warning fs-4 mx-2"></i>
                  </div>
                  <h5 className="mb-1 text-center fw-bold">Security</h5>
                  <p className="mb-0 fs-5">
                    We prioritize security, ensuring our SmartHand technicians
                    undergo rigorous training to safeguard customer data,
                    meeting compliance and regulatory standards.
                  </p>{" "}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-gear-fill text-warning fs-4 mx-2"></i>
                  </div>
                  <h5 className="mb-1 text-center fw-bold">Integration</h5>
                  <p className="mb-0 fs-5">
                    Easily integrate our platform with your existing IT
                    infrastructure, including IT service management (ITSM)
                    software and other systems, for seamless and efficient IT
                    operations.
                  </p>{" "}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-hand-index-thumb text-warning fs-4 mx-2"></i>
                  </div>
                  <h5 className="mb-1 text-center fw-bold">Customization</h5>
                  <p className="mb-0 fs-5">
                    Our solutions are customizable to meet your specific needs,
                    adapting to unique requirements with flexible engagement
                    models tailored to your budget and business objectives.
                  </p>{" "}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-person-badge text-warning fs-4 mx-2"></i>
                  </div>
                  <h5 className="mb-1 text-center fw-bold">Accountability</h5>
                  <p className="mb-0 fs-5">
                    We are committed to delivering top performance, offering
                    reliable SLAs with real-time reporting and analytics,
                    guaranteed response times, and swift issue resolution.
                  </p>{" "}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-cash-stack text-warning fs-4 mx-2"></i>
                  </div>
                  <h5 className="mb-1 text-center fw-bold">
                    Cost-effectiveness
                  </h5>
                  <p className="mb-0 fs-5">
                    We provide competitive service pricing with transparent and
                    predictable cost structures, along with various engagement
                    models, ensuring you receive the best possible value for
                    your investment.
                  </p>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <GlobalPresence />
      <JoinCommunity />
      <ServiceSlider />
      <LetsConnect />
    </React.Fragment>
  );
};

export default SmartHandsDemands;
