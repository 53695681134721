import React from 'react'
import LetsConnect from '../../Components/LetsConnect/LetsConnect'
import GlobalBrands from '../../Components/GlobalBrands/GlobalBrands'
import GlobalPresence from '../../Components/GlobalPresence/GlobalPresence'
import JoinCommunity from '../../Components/JoinCommunity/JoinCommunity'
import InsightSlider from '../../Components/InsightSlider/InsightSlider'

const DataCenterMaintenance = () => {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/data-center-maintainance.webp)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Proactive Maintenance Services Ensure Smooth Data Center Operations</h1>
              <p className="mt-5 text-warning" style={{fontSize:'30px'}}>
              Data Center Maintainance Solutions </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section>
        <div className="container-fluid bg-light mt-5 p-5 text-muted">
          <div className="row">
            <div className="col-lg-4 mt-lg-5">
              <h3 className='text-center text-lg-start'>
                Third-Party Maintenance: Your Comprehensive Independent IT Service Provider</h3>
            </div>
            <div className="col-lg-4 mt-5 fs-5">
              <p>Maintaining your data center is no small feat: ensuring optimal protection against failures while meeting the highest standards is essential. With rising costs and staffing requirements, optimizing data center processes becomes daunting. Our third-party maintenance (TPM) offers the advantages of an independent support model, catering to various manufacturers with top-quality standards at a fraction of the cost compared to manufacturer services. Say goodbye to multiple providers and maximize your hardware's potential, often exceeding manufacturer recommendations for service life.</p>
            </div>
            <div className="col-lg-4 mt-lg-5 fs-5">
              <p>As your dedicated service partner, we deliver tailored maintenance and repair solutions to meet your unique needs. With us as your single contact point, we swiftly resolve hardware issues in your data center, optimizing efficiency and cost-effectiveness. Our comprehensive services encompass the entirety of your IT lifecycle, and we can also manage your IT systems within the data center as needed. This empowers you to focus on your core business objectives and strategic initiatives, such as digital transformation, enhancing customer experience, and adopting cutting-edge technologies like cloud computing, AI, and IoT.</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid  p-5 mt-5 text-muted">
          <div className="row">
            <div className="col-lg-6">
              <h3 className='text-center text-lg-start'>Our Service Is Meticulously Tailored To Meet Your Exact Specifications And Needs</h3>
            </div>
            <div className="col-lg-6 mt-3 text-lg-start">
              <p className='fs-5'>Collaboratively, we determine the optimal level of support for your company. You have the flexibility to select the services that best suit your business needs, ensuring comprehensive support without unnecessary complexity. Plus, you can adjust your support levels as needed.</p>
            </div>
          </div>
          <div className="row mt-lg-5">
            <div className="col-lg-4 col-md-6 my-2">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="d-flex flex-row mt-3">
                    <i class="bi bi-clock-history text-warning fs-4 mx-2"></i>
                    <span className='fs-5'>24/7, 365 days a year service desk support</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 my-2">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="d-flex flex-row mt-3">
                    <i class="bi bi-hand-index fs-4 mx-2 text-warning"></i>
                    <span className='fs-5'>Choose between 5x13 or 7x24 service hours</span>
                  </div>
                </div></div></div>
            <div className="col-lg-4 col-md-6 my-2">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="d-flex flex-row mt-3">
                    <i class="bi bi-telephone-inbound fs-4 mx-2 text-warning"></i>
                    <span className='fs-5'>Select from telephone response times of either 15 or 60 minutes.</span>
                  </div></div></div></div>
            <div className="col-lg-4 col-md-6 my-2">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="d-flex flex-row mt-3">
                    <i class="bi bi-person-gear fs-4 mx-2 text-warning"></i>
                    <span className='fs-5'>On-site technician options: 2 hours, 4 hours, or Next Business Day.</span>
                  </div></div></div></div>
            <div className="col-lg-4 col-md-6 my-2">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="d-flex flex-row mt-3">
                    <i class="bi bi-arrows-fullscreen fs-4 mx-2 text-warning"></i>
                    <span className='fs-5'>Repair times range from 6 to 48 hours.</span>
                  </div></div></div></div>
            <div className="col-lg-4 col-md-6 my-2">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="d-flex flex-row mt-3">
                    <i class="bi bi-suitcase fs-4 mx-2 text-warning"></i>
                    <span className='fs-5'>Automated case and remote support for servers, storage systems, libraries, and networks.</span>
                  </div></div></div></div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 bg-light text-muted">
          <div className="row">
            <h3 className="text-center">Essential It Maintainance</h3>
            <p className="text-center fs-5">Ensure IT Continuity: Versatile Hardware Services for Enhanced Security</p>
            <div className="row">
              <div className="col-lg-3 my-3 col-md-4">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className='text-center maintain-icon'>
                      <i class="bi bi-clock text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className='my-2 text-center fw-bold'>24/7 Avaibility</h5>
                      <p className='fs-5 mt-2'>We work around the clock, 365 days a year</p>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-3 my-3 col-md-4">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className='text-center maintain-icon'>
                      <i class="bi bi-currency-exchange text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className='my-2 text-center fw-bold'>Save time and money</h5>
                      <p className='fs-5 mt-2'>We optimise your processes to save time and money</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 my-3 col-md-4">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className='text-center maintain-icon'>
                      <i class="bi bi-currency-exchange text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className='my-2 text-center fw-bold'>Fast on-site support</h5>
                      <p className='fs-5 mt-2'>Our technicians are quick to deal with any emergencies</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 my-3 col-md-4">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className='text-center maintain-icon'>
                      <i class="bi bi-file-text text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className='my-2 text-center fw-bold'>Tailored contracts</h5>
                      <p className='fs-5 mt-2'>We offer contractual terms of under a year, flexible notice periods and tailored service packages</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 my-3 col-md-4">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className='text-center maintain-icon'>
                      <i class="bi bi-gear-wide-connected text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className='my-2 text-center fw-bold'>Spare parts</h5>
                      <p className='fs-5 mt-2'>Over 850,000 original spare parts and replacement systems are instantly available.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 my-3 col-md-4">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className='text-center maintain-icon'>
                      <i class="bi bi-ticket-perforated text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className='my-2 text-center fw-bold'>Professional ticketing system</h5>
                      <p className='fs-5 mt-2'>Our ticketing system is divided into clearly defined escalation levels</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 my-3 col-md-4">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className='text-center maintain-icon'>
                      <i class="bi bi-kanban text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className='my-2 text-center fw-bold'>Project and service management</h5>
                      <p className='fs-5 mt-2'>We consult and guide you through the entire process.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 my-3 col-md-4">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className='text-center maintain-icon'>
                      <i class="bi bi-person-rolodex text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className='my-2 text-center fw-bold'>Central point of contact</h5>
                      <p className='fs-5 mt-2'>Your dedicated system specialist is on hand for any questions.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid p-5 mt-5 text-muted">
          <div className="row">
            <div className="col-lg-4">
              <img src="images/it-hardware.jpeg" alt="" srcset="" />
            </div>
            <div className="col-lg-8 px-lg-5 px-0">
              <h3 className="text-center text-lg-start mt-5 mt-lg-0">Dynamic Hardware Maintenance Across Various Manufacturers</h3>
              <p className="fs-5 text-center text-lg-start">Comprehensive Maintenance Across Major Hardware Brands</p>
              <div className="row mt-lg-4">
                <div className="col-lg-6 mt-3 mt-lg-0">
                  <div className="card shadow-lg h-100 text-muted p-2  hover-metic">
                  <div className="card-body">
                  <h5 className='fw-bold text-center'>Hardware Support Across Manufacturers, Models, and Systems</h5>
                  <p className='fs-5'>Our specialized services ensure uninterrupted operations for your critical systems. With tailored troubleshooting and rapid on-site response according to SLAs, we're your reliable single point of contact for comprehensive support.</p>
                </div>
                </div>
                </div>
                <div className="col-lg-6 mt-3 mt-lg-0">
                <div className="card shadow-lg h-100 text-muted p-2  hover-metic">
                  <div className="card-body">
                  <h5 className='fw-bold text-center'>Prolong hardware lifespan with our efficient maintenance and repair services.</h5>
                  <p className='fs-5'>Prolong the lifespan of your hardware by accessing comprehensive support services beyond the manufacturer's indicated end-of-service life (EOSL) period through our independent TPM provision.</p>
                </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 bg-light text-muted">
          <div className="row">
            <div className="col-lg-6 px-lg-5 px-0">
              <h3 className='text-center text-lg-start'>Strengthen Data Center Resilience With Practical, Customized Solutions For Continuity</h3>
              <p className='fs-5'>Discover how our solutions enhance the resilience of your data center, ensuring uninterrupted operations and cost-effective performance. Explore our customer success stories for real-world insights and results.</p>
            </div>
            <div className="col-lg-6 mt-3 mt-lg-0">
              <img src='images/resilience-data-centre.jpeg' />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 text-muted">
          <div className="row">
            <div className="col-lg-6">
              <img src='images/support-pkg.jpg' />
            </div>
            <div className="col-lg-6 px-lg-5 px-0">
              <h3 className='text-center text-lg-start mt-5 mt-lg-0'>Support packages</h3>
              <p className='fs-5'>Our flexible maintenance contracts extend beyond the standard warranty, offering tailored support for specific manufacturers. Get peace of mind and optimize your IT systems with our variable support packages.</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 text-muted">
          <div className="row my-lg-5">
            <div className="col-lg-6 px-lg-5 px-0">
              <h3 className='text-center text-lg-start'>Ensure Longevity And Performance With Extended Hardware Warranty Solutions</h3>
              <p className='fs-5'>Enhance the longevity of your hardware with extended warranty options, ensuring sustained reliability and performance beyond standard manufacturer coverage. Customize services to your unique needs for comprehensive hardware protection and peace of mind.</p>
            </div>
            <div className="col-lg-6 mt-3 mt-lg-0">
              <img src='images/exyended-warranty.jpg' />
            </div>
          </div>
        </div>
      </section>
      <GlobalPresence/>
      <JoinCommunity/>
      <InsightSlider/>
      <LetsConnect />
    </React.Fragment>

  )
}

export default DataCenterMaintenance