import React, { useEffect } from "react";
import Swiper from "swiper";
import 'swiper/css';
import 'swiper/css/pagination';

function ReviewSlider(props) {
    useEffect(() => {
        const swiper = new Swiper('.js-testimonials-slider', {
            grabCursor: true,
            spaceBetween: 30,
            pagination: {
                el: ".js-testimonials-pagination",
                clickable: true
            },
            breakpoints: {
                767: {
                    slidesPerView: 2
                }
            }
        });
       
    }, []); 

    return (
        <div className="testimonials-content">
            <div className="swiper testimonials-slider js-testimonials-slider">
                <div className="swiper-wrapper">
                    {props.reviewData.map((ele, id) => (
                        <div key={id} className="swiper-slide testimonials-item">
                            <div className="info">
                                <div>
                                    <img src="" alt="flag_logo" />
                                    <div className="text-box">
                                        <h3 className="name">{ele.name}</h3>
                                        <span className="job">{`${ele.designation} - ${ele.country}`}</span>
                                    </div>
                                </div>
                                <p>{ele.review}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="swiper-pagination js-testimonials-pagination"></div>
        </div>
    );
}

export default ReviewSlider;
