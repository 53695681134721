import React, { useState } from 'react'
import TalkToAnExpert from '../../Components/TalkToAnExpert/TalkToAnExpert'
import GlobalBrands from '../../Components/GlobalBrands/GlobalBrands'
import DeliveryMissionCritical from '../../Components/DeliveryMissionCritical/DeliveryMissionCritical'
import GlobalPresence from '../../Components/GlobalPresence/GlobalPresence'
import JoinCommunity from '../../Components/JoinCommunity/JoinCommunity'
import LatestInsights from '../../Components/LatestInsights/LatestInsights'
import LetsConnect from '../../Components/LetsConnect/LetsConnect'
import ServiceSlider from '../../Components/ServiceSlider/ServiceSlider'
import Slider from '../../Components/Slider/Slider'

const NetworkOperations = () => {

    const [slider, setSlider] = useState([
        {
            title: "Dedicated On-prem NOC",
            description: 'On-site Dedicated Resources for Customer NOC Operations Supported by On-Demand Resource Pool',
            isShow: true,
        },
        {
            title: "Shared NOC",
            description: 'Experienced network engineers and technicians delivering remote monitoring, management, and support services',
            isShow: false,
        },
        {
            title: "Hybrid NOC",
            description: 'On-site Dedicated Resources for Customer NOC Operations Supported by On-Demand Resource Pool',
            isShow: false,
        },
    ]);
    const slideHandler = (index) => {
        let updateSlider = slider.map((element, i) => {
            if (index == i) {
                element.isShow = true;
            } else {
                element.isShow = false;
            }
            return element;
        });
        setSlider(updateSlider);
    };
    return (
        <React.Fragment>
            <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/network-opr-banner.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Managed Network Services</h1>
              <p className="mt-5 text-warning" style={{fontSize:'30px'}}>
              DataCentrix™ Unleashes The Capabilities Of Your Networking Ecosystem, Enhancing IT And Business Performance Through Dynamic Scalability. Our Managed Network Services Optimize Agility, Stability, And Responsiveness, Providing A Competitive Edge In Today's Dynamic Landscape
              </p>
            </div>
          </div>
        </div>
      </section>
            <GlobalBrands />
            <section className="networkoperations-endtoend-container wrapper bg-light mt-5 p-5 text-muted">
                <div className="container py-14 py-md-16">
                    <h3 className="text-center mb-3">
                        End-to-end Network Operations
                    </h3>
                    <h5 className="mb-5 text-center">
                    Streamline Network Monitoring And Management Complexities With Our Robust Global Managed Services
                    </h5>
                    <div className="row gx-md-8 gy-8">
                        <div className="col-md-6 col-lg-4 my-3">
                            <div className="card shadow-lg text-muted h-100 main-card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <i className="uil uil-file-search-alt fs-2 mx-2 text-warning" />
                                    </div>
                                    <h5 className='text-center my-2 fw-bold'>Event Monitoring Services</h5>
                                    <p className="mb-3 fs-5 mt-1">
                                        Detect, assess, and direct notifications regarding alarms, conditions, and alerts, aiding in preemptive problem identification before operational impacts occur.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 my-3">
                            <div className="card shadow-lg text-muted h-100 main-card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <i className="uil uil-servers fs-2 mx-2 text-warning" />
                                    </div>
                                    <h5 className='text-center my-2 fw-bold'>Fault Detection and Management</h5>
                                    <p className="mb-3 fs-5 mt-1">
                                        Teamed with Event Management, promptly resolve faults by harnessing our dedicated and on-demand workforce synergy.
                                    </p>
                                </div></div>
                        </div>
                        <div className="col-md-6 col-lg-4 my-3">
                            <div className="card shadow-lg text-muted h-100 main-card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <i className="uil uil-server-network fs-2 mx-2 text-warning" />
                                    </div>
                                    <h5 className='text-center my-2 fw-bold'>Network Operations Management</h5>
                                    <p className="mb-3 fs-5 mt-1">
                                        NetOps offers a comprehensive managed service with provisioning workflow managed by our experts. Our NOC specialists provide Moves, Adds, and Changes (MAC) services for your network.
                                    </p>
                                </div></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="networkoperations-operationcenter-container wrapper mt-5 p-5 text-muted">
                <div className="container py-14 py-md-16">
                    <div className="row">
                        <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 mx-auto text-center">
                            <h3 className="mb-5 px-xl-10 px-xxl-15">
                            DataCentrix™'s Network Operations Center (NOC) Services
                            </h3>
                        </div>
                    </div>
                    <ul className="nav nav-tabs nav-tabs-bg d-flex justify-content-between nav-justified flex-lg-row flex-column text-muted mb-5" >
                        {slider?.map((element, index) =>
                            <li className="nav-item">
                                <a
                                    className={`nav-link d-flex flex-row ${element.isShow ? "active" : ""
                                        } align-items-center`}
                                    data-bs-toggle="tab"
                                    href="#tab2-1"
                                    onClick={() => slideHandler(index)}
                                >
                                    <div>
                                        <i class="bi bi-card-heading fs-4 mx-2 text-warning"></i>
                                    </div>
                                    <div>
                                        <h4 className='text-muted'>{element.title}</h4>
                                        <p className='text-muted fs-5'>
                                            {element.description}
                                        </p>
                                    </div>
                                </a>
                            </li>
                        )}
                    </ul>
                    <div className="tab-content mt-6 mt-lg-8">
                        {slider[0].isShow ?
                            (
                                <Slider>
                                    <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center text-muted">
                                        <div className="col-lg-6">
                                            <figure className="rounded">
                                                <img src="images/noc-img.jpeg" alt="" srcset="" />
                                            </figure>
                                        </div>
                                        <div className="col-lg-6">
                                            <h3 className="mb-3 text-center text-lg-start">
                                                On-Site Network Operations Center (NOC)
                                            </h3>
                                            <p className='fs-5'>
                                                The On-Premises Managed Network Operations Center (NOC) comprises a dedicated team within the organization's IT or infrastructure group. This team offers remote monitoring, management, and support services for the network. Staffed by experienced network engineers and technicians, the NOC employs various tools and techniques to monitor and manage the organization's network.
                                            </p>
                                            <ul className="icon-list bullet-bg bullet-soft-blue list-unstyled">
                                                <li className='d-flex flex-row'>
                                                    <i className="uil uil-check text-warning fs-4 mx-2" />
                                                    <span className='fs-5'>Empowers complete control over network operations and security</span>
                                                </li>
                                                <li className='d-flex flex-row'>
                                                    <i className="uil uil-check text-warning fs-4 mx-2" />
                                                    <span className='fs-5'>Enables direct network access for prompt issue resolution</span>
                                                </li>
                                                <li className='d-flex flex-row'>
                                                    <i className="uil uil-check text-warning fs-4 mx-2" />
                                                    <span className='fs-5'>Lower costs, enhance efficiency, and ensure swift, effective resolution of network issues</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Slider>
                            ) :
                            slider[1].isShow ?
                                (
                                    <Slider>
                                        <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center text-muted">
                                            <div className="col-lg-6 order-lg-2">
                                                <figure className="rounded">
                                                    <img src="images/shared-noc.jpeg" alt="" srcset="" />
                                                </figure>
                                            </div>
                                            <div className="col-lg-6">
                                                <h3 className="mb-3 text-center text-lg-start">Shared Network Operations Center (NOC)</h3>
                                                <p className='fs-5'>
                                                    In a shared NOC, the clients' networks are monitored and managed
                                                    using a centralized platform, enabling the NOC team to monitor and
                                                    manage multiple clients' networks from a single location. This can
                                                    reduce costs, improve efficiency, and resolve network issues
                                                    quickly and effectively.
                                                </p>
                                                <ul className="icon-list bullet-bg bullet-soft-blue">
                                                    <li className='d-flex flex-row'>
                                                        <i className="uil uil-check text-warning fs-4 mx-2" />
                                                        <span className='fs-5'>The benefits of centralized management</span>
                                                    </li>
                                                    <li className='d-flex flex-row'>
                                                        <i className="uil uil-check text-warning fs-4 mx-2" />
                                                        <span className='fs-5'>Allowing the NOC team to resolve issues quickly and efficiently</span>
                                                    </li>
                                                    <li className='d-flex flex-row'>
                                                        <i className="uil uil-check text-warning fs-4 mx-2" />
                                                        <span className='fs-5'>Reducing downtime and minimizing network disruptions</span>
                                                    </li>
                                                </ul>
                                                <p className='fs-5'>
                                                    Overall, a shared NOC is an excellent option for organizations
                                                    that want to outsource their network operations to a trusted
                                                    partner, ensuring that their network is running smoothly and
                                                    securely 24/7.
                                                </p>
                                            </div>
                                        </div>
                                    </Slider>
                                )

                                :
                                slider[2].isShow ?
                                    (
                                        <Slider>
                                            <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center text-muted">
                                                <div className="col-lg-6">
                                                    <div className="row gx-md-5 gy-5">
                                                        <figure className="rounded">
                                                            <img src="images/hybrid-noc.jpg" alt="" srcset="" />
                                                        </figure>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <h3 className="mb-3 text-center text-lg-start">Hybrid Network Operations Center (NOC)</h3>
                                                    <p className='fs-5'>
                                                        Hybrid NOC provides organizations with the best of both worlds –
                                                        the control and security of an on-premises NOC and the
                                                        cost-effectiveness and expertise of a remote NOC using a
                                                        combination of on-premises and remote resources. In a hybrid NOC,
                                                        some network monitoring and management functions are performed
                                                        on-site, while a remote NOC team performs others.
                                                    </p>
                                                    <ul className="icon-list bullet-bg bullet-soft-blue">
                                                        <li className='d-flex flex-row'>
                                                            <i className="uil uil-check text-warning fs-4 mx-2" />
                                                            <span className='fs-5'>Critical network and security functions are performed by on-site
                                                            resources</span>
                                                        </li>
                                                        <li className='d-flex flex-row'>
                                                            <i className="uil uil-check text-warning fs-4 mx-2" />
                                                            <span className='fs-5'>Routine monitoring, performance management, and reporting is
                                                            performed by remote NOC</span>
                                                        </li>
                                                        <li className='d-flex flex-row'>
                                                            <i className="uil uil-check text-warning fs-4 mx-2" />
                                                            <span className='fs-5'>Provides a balance between control, security,
                                                            cost-effectiveness, and expertise</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Slider>
                                    )
                                    : null
                        }
                    </div>
                </div>
            </section>
            <section className="networkoperations-managednoc-container wrapper bg-light mt-5 p-5 text-muted">
                <div className="container py-15">
                    <div className="container pb-10 pb-lg-15">
                        <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                            <div className="col-lg-5">
                                <h3 className="mb-3 text-center text-lg-start">
                                Managed NOC Services Driving Efficiencies While Gaining Insights
                                </h3>
                                <p className="mb-5 fs-5 mt-3 mt-lg-0">
                                DataCentrix™'s dedicated/managed NOC service is the ideal solution for enterprise network and data center requirements. Our 24/7 tech support, operational monitoring, and commitment to WANs, clouds, and data center solutions deliver reliable results with zero downtime. With a detailed organizational hierarchy ensuring prompt responses in any situation, you can trust that we've got your back!
                                </p>
                            </div>
                            <div className="col-lg-7">
                                <ul className="icon-list bullet-bg bullet-soft-blue mb-0 list-unstyled">
                                    <li className="d-flex flex-row">
                                        <span>
                                            <i className="uil uil-check fs-4 mx-2 text-warning" />
                                        </span>
                                        <span className='fs-5'>
                                            <strong>Monitoring</strong> - Employing a myriad of tools and techniques, we ensure continuous 24/7 monitoring of the enterprise network, swiftly detecting and responding to issues in real-time.
                                        </span>
                                    </li>
                                    <li className="mt-3 d-flex flex-row">
                                        <span>
                                            <i className="uil uil-check fs-4 mx-2 text-warning" />
                                        </span>
                                        <span className='fs-5'>
                                            <strong>Alerting and Notification</strong> - Deliver real-time alerts and notifications to the enterprise in case of network issues, facilitating prompt resolution.
                                        </span>
                                    </li>
                                    <li className="mt-3 d-flex flex-row">
                                        <span>
                                            <i className="uil uil-check fs-4 mx-2 text-warning" />
                                        </span>
                                        <span className='fs-5'>
                                            <strong>Event Management</strong> - Utilize AI/ML-driven event management tools to track and handle network incidents, guaranteeing timely resolution.
                                        </span>
                                    </li>
                                    <li className="mt-3 d-flex flex-row">
                                        <span>
                                            <i className="uil uil-check fs-4 mx-2 text-warning" />
                                        </span>
                                        <span className='fs-5'>
                                            <strong>Performance Management</strong> - Utilize integrated performance management tools to monitor network performance, identifying and resolving bottlenecks and issues.
                                        </span>
                                    </li>
                                    <li className="mt-3 d-flex flex-row">
                                        <span>
                                            <i className="uil uil-check fs-4 mx-2 text-warning" />
                                        </span>
                                        <span className='fs-5'>
                                            <strong>Security Monitoring</strong> - Monitor network security, promptly detecting and responding to security threats and incidents in real-time.
                                        </span>
                                    </li>
                                    <li className="mt-3 d-flex flex-row">
                                        <span>
                                            <i className="uil uil-check fs-4 mx-2 text-warning" />
                                        </span>
                                        <span className='fs-5'>
                                            <strong>Configuration Management</strong> - Leverage smart configuration management tools to oversee network device configurations, ensuring they remain up-to-date and secure.
                                        </span>
                                    </li>
                                    <li className="mt-3 d-flex flex-row">
                                        <span>
                                            <i className="uil uil-check fs-4 mx-2 text-warning" />
                                        </span>
                                        <span className='fs-5'>
                                            <strong>Capacity Planning</strong> - Employ capacity planning tools to predict network resource needs, guaranteeing that the network is appropriately scaled to meet enterprise requirements.
                                        </span>
                                    </li>
                                    <li className="mt-3 d-flex flex-row">
                                        <span>
                                            <i className="uil uil-check fs-4 mx-2 text-warning" />
                                        </span>
                                        <span className='fs-5'>
                                            <strong>Reporting and Analytics</strong> - Utilize reporting and analytics tools to furnish the enterprise with insights into network performance and usage, facilitating informed decision-making.
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <DeliveryMissionCritical />
            <GlobalPresence />
            <ServiceSlider />
            <JoinCommunity />
            <LatestInsights />
            <LetsConnect />
        </React.Fragment>
    )
}

export default NetworkOperations