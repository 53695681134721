import React from "react";
import JoinCommunity from "../../Components/JoinCommunity/JoinCommunity";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";
import GlobalPresence from "../../Components/GlobalPresence/GlobalPresence";
import InsightSlider from "../../Components/InsightSlider/InsightSlider";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";

const FinancingSolutions = () => {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/financial-solutions.webp)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold text-capitalize'> Flexible investment solutions for smart finance management</h1>
              <p className="mt-5 text-warning" style={{fontSize:'30px'}}>
              Finance Solutions
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section>
        <div className="container-fluid mt-5 p-5 text-muted bg-light">
          <div className="row">
            <div className="col-lg-4 mt-lg-5">
              <h3 className="px-lg-4 text-capitalize">
                Tailored and adaptable solutions for every business
              </h3>
            </div>
            <div className="col-lg-4 mt-5 fs-5">
              <p>
                In today's business landscape, high-performance IT is paramount
                for success, evolving rapidly to meet changing demands and
                seasonal peaks. However, this presents both technical and
                financial challenges, as requirements may not always align with
                available resources.
              </p>
            </div>
            <div className="col-lg-4 mt-lg-5 fs-5">
              <p>
                We're here to assist you in optimizing your IT operations both
                technically and financially. With our smart financing options,
                we ensure your IT remains top-notch while providing financial
                flexibility. Leveraging almost 30 years of financing expertise
                at Evernex, we work with capable finance partners to tailor
                solutions to your needs.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid text-muted mt-5 p-5">
          <h3 className="text-center my-2 text-capitalize">
            What types of IT assets and systems are eligible for financing?
          </h3>
          <p className="text-center fs-5">
            We provide comprehensive solutions for flexible financing of
            hardware, software, and services. Whether you require individual
            office equipment like multifunction printers on short notice or seek
            to minimize the financial burden of operating systems or application
            software through leasing, we have you covered.
          </p>
        </div>
      </section>
      <section className="wrapper text-muted mt-5 p-5 bg-light">
        <div className="container-fluid">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-6 position-relative order-lg-2">
              <div className="shape bg-soft-primary rounded-circle rellax w-20 h-20" />
              <img src="images/finance-agreement.png" />
            </div>
            <div className="col-lg-6">
              <h3 className="mb-3 text-center text-lg-start text-capitalize">
                Components of an IT finance agreement may include
              </h3>
              <ul className="icon-list bullet-bg bullet-soft-blue mb-0 list-unstyled">
                <li className="d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    IT hardware: Servers, storage and networking components,
                    PCs, laptops, printers, monitors, and other peripherals.
                  </span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    Software: Application software, operating systems (e.g.
                    i/OS), virtualisation (VMware)
                  </span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">Services: Installation, implementation, training.</span>
                </li>
                <li className="d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    Extended services: OEM maintenance agreements, Third-Party
                    Maintenance, hosting.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid text-muted mt-5 p-5">
          <div className="row">
            <div className="col-lg-6 mt-5">
              <img src="images/benefits.jpeg" />
            </div>
            <div className="col-lg-6 px-lg-5 mt-5">
              <h3 className="text-center text-lg-start">Benefits</h3>
              <ul className="list-unstyled mt-3 mt-lg-0">
                <li className="d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    Flexible financing models with fair terms and conditions.
                  </span>
                </li>
                <li className="d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    Comprehensive solutions comprising hardware, software, and
                    services from a single provider.
                  </span>
                </li>
                <li className="d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    Consistently updating your IT technology.
                  </span>
                </li>
                <li className="d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    Budget management throughout the agreement duration.
                  </span>
                </li>
                <li className="d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    Safeguarding your credit lines with consistent monthly
                    payments.
                  </span>
                </li>
                <li className="d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    Quick decision-making ability.
                  </span>
                </li>
                <li className="d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    Immediate utilization of the new equipment.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid text-muted mt-5 p-5">
          <div className="row">
            <div className="col-lg-4 col-md-6 my-3">
              <div className="card shadow-lg text-muted h-100">
                <div className="card-body">
                  <h5 className="text-center my-2 fw-bold">Leasing</h5>
                  <p className="fs-5 px-3 mt-3">
                    Leasing models can be ideal when you require new technical equipment
                    beyond your budget planning. This approach doesn't necessitate the
                    full upfront investment, instead offering convenient financing
                    through manageable installments. This allows you to benefit from the
                    new equipment while spreading the financial burden. Through leasing,
                    we provide the necessary hardware, with monthly installment payments
                    directed to a leasing bank or company. Fees are determined by the
                    equipment's value, lease term, and lessee's creditworthiness. At the
                    lease term's end, the equipment typically reverts to the leasing
                    company.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 my-3">
              <div className="card shadow-lg text-muted h-100">
                <div className="card-body">
                  <h5 className="text-center my-2 fw-bold">Credit/Loan</h5>
                  <p className="fs-5 px-3 mt-3">
                    Utilizing credit or securing a loan empowers the contractual partner
                    to promptly acquire products with provided funds, deferring
                    repayment to a later date. This approach offers flexibility in
                    financial management, enabling timely acquisition of necessary
                    products without immediate financial strain. By leveraging credit or
                    loans, the partner can efficiently address business needs and
                    capitalize on opportunities while spreading the financial commitment
                    over time. This strategy fosters agility and responsiveness,
                    ensuring the partner remains competitive in dynamic markets by
                    swiftly adapting to evolving requirements and seizing strategic
                    opportunities.
                  </p>
                </div></div></div>
            <div className="col-lg-4 col-md-6 my-3">
              <div className="card shadow-lg text-muted h-100">
                <div className="card-body">
                  <h5 className="text-center my-2 fw-bold">Rent</h5>
                  <p className="fs-5 px-3 mt-3">
                    In the rental model, the renter receives products and/or services in
                    exchange for a monthly rental payment, with no fixed term planned
                    and flexible termination options. At the end of the rental period, a
                    new agreement can be established, allowing for hardware replacement
                    with state-of-the-art equipment. The rental model does not involve
                    any transfer of ownership.
                  </p>
                </div>
              </div></div></div>
        </div>
      </section>
      <GlobalPresence/>
      <JoinCommunity />
      <InsightSlider/>
      <LetsConnect/>
    </React.Fragment>
  );
};

export default FinancingSolutions;
