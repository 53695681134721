import React, { useEffect, useState } from 'react'

const SliderBullets = ({onBulletClick ,pagination,pageSize,activeBullet}) => {
    let array=new Array(pagination).fill(false)
    const [bulletsArray,setBulletsArray]=useState(array)
    console.log("bulletsArray",bulletsArray);

  return (
    <React.Fragment>
        {
            bulletsArray.map((element,index)=>(
             <span className={`swiper-pagination-bullet ${activeBullet==index?'swiper-pagination-bullet-active':''}`} onClick={()=>onBulletClick(index)}/>
            ))
        }
    
    </React.Fragment>
  )
}

export default SliderBullets