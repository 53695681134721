import React from 'react'
import GlobalPresence from '../../Components/GlobalPresence/GlobalPresence'
import JoinCommunity from '../../Components/JoinCommunity/JoinCommunity'
import LetsConnect from '../../Components/LetsConnect/LetsConnect'
import GlobalBrands from '../../Components/GlobalBrands/GlobalBrands'
import InsightSlider from '../../Components/InsightSlider/InsightSlider'

const Manufacturers = () => {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/it-enironment.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Leverage Our Deep Industry Knowledge For Optimizing Your It Infrastructure And Achieving Peak Performance In Your Digital Environment</h1>
              <p className="mt-5 text-warning" style={{fontSize:'30px'}}>
              Support For All Major Brands
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands/>
      <section>
        <div className="container-fluid text-muted mt-5 p-5 bg-light">
          <div className="row">
            <div className="col-lg-6">
              <h3 className='text-center text-lg-start'>Your Streamlined Path To Efficient IT</h3>
            </div>
            <div className="col-lg-6 mt-3 mt-lg-0">
              <p className='fs-5'>As a top-tier independent IT service provider and the European leader in third-party maintenance, we assure expert support. With diverse hardware expertise, our technicians deliver comprehensive and unlimited assistance to meet evolving needs</p>
            </div>
          </div>
        </div>
      </section>
      <GlobalPresence />
      <JoinCommunity />
      <InsightSlider/>
      <LetsConnect />
    </React.Fragment>
  )
}

export default Manufacturers