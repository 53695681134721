import React from 'react'
import GlobalPresence from '../../Components/GlobalPresence/GlobalPresence'
import JoinCommunity from '../../Components/JoinCommunity/JoinCommunity'
import LetsConnect from '../../Components/LetsConnect/LetsConnect'
import GlobalBrands from '../../Components/GlobalBrands/GlobalBrands'
import InsightSlider from '../../Components/InsightSlider/InsightSlider'

const EndOfServiceLife = () => {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/our-services.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Our Service: Ensuring Your Investment Security</h1>
              <p className="mt-5 text-warning" style={{fontSize:'30px'}}>
              End Of Service Life
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands/>
      <section>
        <div className="container-fluid p-5 mt-5 text-muted bg-light">
          <div className="row">
            <div className="col-lg-4 mt-lg-5">
              <h3 className='px-4 text-center text-lg-start text-capitalize'>
              Our sustainable service enables you to maximize the full potential of your hardware.</h3>
            </div>
            <div className="col-lg-4 mt-5 fs-5">
              <p>Is your hardware nearing its End of Life (EOL) or End of Service Life (EOSL)? Don't rush into purchasing expensive new systems. Even if your manufacturer discontinues support or sets an "expiry date," your old equipment still has plenty of life left. We ensure your hardware infrastructure operates perfectly beyond the EOL or EOSL date.</p>
            </div>
            <div className="col-lg-4 mt-lg-5 fs-5">
              <p>More than half of companies rely on temporary service contracts with manufacturers for their hardware. As an independent service provider for all major brands, we maintain your hardware and provide quick and convenient spare parts services. Our cost-effective solutions allow you to extend your hardware's lifecycle without being restricted to a specific manufacturer.</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid text-muted mt-5 p-5">
          <div className="row my-3 py-5">
            <h3 className="text-center text-capitalize">The difference between end of life (EOL) and end of service life (EOSL)</h3>
          </div>
          <div className="row p-5 bg-secondary text-white">
            <div className="col-lg-6 px-5">
              <h5 className='text-center text-lg-start'>End of Life</h5>
              <p className='mt-2 fs-5'>Certainly, if you have a system approaching its End of Life (EOL) and are seeking support beyond the manufacturer's discontinuation, we're here to help. As a third-party maintenance provider, we specialize in extending the service life of hardware, offering sustainable and cost-effective alternatives to investing in new products. Contact us to explore how we can assist you in maximizing the lifespan of your equipment while minimizing expenses.</p>
            </div>
            <div className="col-lg-6 px-5 mt-5 mt-lg-0">
              <h5 className='text-center text-lg-start'>End of Service Life</h5>
              <p className='mt-2 fs-5'>When the original manufacturer declares the "end of service life" (EOSL), technical support and maintenance either cease altogether or become significantly more expensive. If you wish to continue utilizing your hardware beyond this point, opting for third-party maintenance (TPM) is a viable solution. According to Gartner, over 70% of Fortune 500 companies leverage third-party maintenance as a support option in their IT environments. This approach allows you to maintain your hardware effectively and affordably, even after the manufacturer's support ends.</p>
            </div>
          </div>
        </div>
      </section>
      <GlobalPresence/>
      <JoinCommunity/>
      <InsightSlider/>
      <LetsConnect/>
    </React.Fragment>
  )
}

export default EndOfServiceLife