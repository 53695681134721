import React from 'react'

const Electrotechnology = () => {
  return (
    <div
  id="wrapper"
  className="hide-title-section no-mobile-animation"
  style={{ marginBottom: "559.667px" }}
>
  <div id="primary" className="content-area">
    <main id="main" className="site-main" role="main">
      <section id="single-page" className="section repair-services" style={{}}>
        <div className="container">
          <div className="row single-page-content">
            <section className="wpb-content-wrapper">
              <div className="vc_row wpb_row vc_row-fluid">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <div className="vc_column-inner">
                    <div className="wpb_wrapper">
                      {/* START TG Header Reperatur EN REVOLUTION SLIDER 6.6.4 */}
                      <rs-fullwidth-wrap
                        id="rev_slider_243_1_forcefullwidth"
                        style={{ marginTop: 0, marginBottom: 0 }}
                      >
                        <rs-module-wrap
                          id="rev_slider_243_1_wrapper"
                          data-source="gallery"
                          style={{
                            visibility: "visible",
                            background: "transparent",
                            padding: 0,
                            margin: "0px auto",
                            height: 600,
                            display: "block",
                            width: 1263,
                            position: "absolute",
                            overflow: "visible",
                            transform: "translate(0px, 0px)",
                            top: 0,
                            left: "-27px"
                          }}
                        >
                          <rs-module
                            id="rev_slider_243_1"
                            style={{ height: 600, width: 1263, maxHeight: 600 }}
                            data-version="6.6.4"
                            data-idcheck="true"
                            className="revslider-initialised rev_redraw_on_blurfocus"
                            data-slideactive="rs-273"
                          >
                            <rs-loader
                              className="spinner0 "
                              style={{ display: "none" }}
                            >
                              <div className="dot1" />
                              <div className="dot2" />
                              <div className="bounce1" />
                              <div className="bounce2" />
                              <div className="bounce3" />
                            </rs-loader>
                            <rs-progress style={{ visibility: "hidden" }}>
                              <rs-progress-bar
                                style={{
                                  background: "rgba(255, 255, 255, 0.5)"
                                }}
                              />
                            </rs-progress>
                            <rs-slides
                              style={{
                                overflow: "hidden",
                                position: "absolute",
                                visibility: "visible",
                                maxHeight: "none",
                                height: "100%",
                                width: "100%"
                              }}
                            >
                              <rs-slide
                                style={{
                                  position: "absolute",
                                  display: "block",
                                  overflow: "hidden",
                                  height: "100%",
                                  width: "100%",
                                  zIndex: 20,
                                  opacity: 1,
                                  visibility: "inherit"
                                }}
                                data-key="rs-273"
                                data-title="Slide"
                                data-anim="adpr:false;"
                                data-in="o:0;"
                                data-out="a:false;"
                                data-originalindex={1}
                                data-origindex={0}
                                data-description=""
                                data-sba=""
                                data-scroll-based="false"
                                data-owidth={1920}
                                data-oheight={800}
                                data-rspausetimeronce={0}
                                data-isactiveslide="true"
                              >
                                <rs-sbg-px>
                                  <rs-sbg-wrap
                                    data-key="rs-273"
                                    data-owidth={1920}
                                    data-oheight={800}
                                  >
                                    {/*Runtime Modification - Img tag is Still Available for SEO Goals in Source - <img fetchpriority="high" decoding="async" src="//www.technogroup.com/wp-content/plugins/revslider/public/assets/assets/dummy.png" alt="technogroup_reperaturservice" title="TG_header-reperatur (3)" width="1920" height="800" class="rev-slidebg tp-rs-img rs-lazyload" data-lazyload="//www.technogroup.com/wp-content/uploads/2023/02/TG_header-reperatur-3.png" data-bg="p:center top;" data-no-retina="">*/}
                                    <rs-sbg
                                      data-lazyload="//www.technogroup.com/wp-content/uploads/2023/02/TG_header-reperatur-3.png"
                                      className=""
                                      data-bgcolor="transparent"
                                      style={{ width: "100%", height: "100%" }}
                                      data-src-rs-ref="//www.technogroup.com/wp-content/uploads/2023/02/TG_header-reperatur-3.png"
                                    >
                                      <canvas
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          backgroundColor: "transparent",
                                          opacity: 1,
                                          filter: "none"
                                        }}
                                        width={1263}
                                        height={600}
                                      />
                                    </rs-sbg>
                                  </rs-sbg-wrap>
                                </rs-sbg-px>
                                {/*
                                 */}
                                <rs-layer-wrap
                                  className="rs-parallax-wrap"
                                  style={{
                                    position: "absolute",
                                    display: "block",
                                    pointerEvents: "auto",
                                    left: "11.5px",
                                    top: 463,
                                    zIndex: 8,
                                    visibility: "visible",
                                    perspective: 601
                                  }}
                                >
                                  <h1
                                    id="slider-243-slide-273-layer-0"
                                    className="rs-layer"
                                    data-type="text"
                                    data-color="#f4bc01"
                                    data-rsp_ch="on"
                                    data-xy="x:c;xo:-340px;y:c;yo:199px;"
                                    data-text="w:normal;s:64;l:72;ls:0px;fw:700;"
                                    data-frame_999="o:0;st:w;"
                                    style={{
                                      zIndex: 8,
                                      fontFamily: '"Roboto Slab"',
                                      height: "auto",
                                      width: "auto",
                                      color: "rgb(244, 188, 1)",
                                      textDecoration: "none",
                                      whiteSpace: "nowrap",
                                      minHeight: 0,
                                      minWidth: 0,
                                      maxHeight: "none",
                                      maxWidth: "none",
                                      textAlign: "left",
                                      lineHeight: 72,
                                      letterSpacing: 0,
                                      fontWeight: 700,
                                      fontSize: 64,
                                      transformOrigin: "50% 50%",
                                      opacity: 1,
                                      transform: "translate(0px, 0px)",
                                      visibility: "visible"
                                    }}
                                    data-idcheck="true"
                                    data-stylerecorder="true"
                                    data-initialised="true"
                                  >
                                    REPAIR SERVICES
                                  </h1>
                                </rs-layer-wrap>
                                {/*

							*/}
                                <rs-layer-wrap
                                  className="rs-parallax-wrap"
                                  style={{
                                    position: "absolute",
                                    display: "block",
                                    pointerEvents: "auto",
                                    left: "12.5px",
                                    top: 334,
                                    zIndex: 9,
                                    visibility: "visible",
                                    perspective: 601
                                  }}
                                >
                                  <h2
                                    id="slider-243-slide-273-layer-2"
                                    className="rs-layer"
                                    data-type="text"
                                    data-color="#ffffff"
                                    data-rsp_ch="on"
                                    data-xy="x:c;xo:-281px;y:c;yo:106px;"
                                    data-text="w:normal;s:42;l:52;fw:700;"
                                    data-dim="w:676px;"
                                    data-margin="b:40;"
                                    data-frame_999="o:0;st:w;"
                                    style={{
                                      zIndex: 9,
                                      fontFamily: '"Roboto Slab"',
                                      height: "auto",
                                      color: "rgb(255, 255, 255)",
                                      textDecoration: "none",
                                      whiteSpace: "normal",
                                      width: 676,
                                      minHeight: 0,
                                      minWidth: 0,
                                      maxHeight: "none",
                                      maxWidth: "none",
                                      textAlign: "left",
                                      lineHeight: 52,
                                      letterSpacing: "-1px",
                                      fontWeight: 700,
                                      fontSize: 42,
                                      marginBottom: 40,
                                      transformOrigin: "50% 50%",
                                      opacity: 1,
                                      transform: "translate(0px, 0px)",
                                      visibility: "visible"
                                    }}
                                    data-idcheck="true"
                                    data-stylerecorder="true"
                                    data-initialised="true"
                                  >
                                    Maintenance and repair of your
                                    <br />
                                    IT equipment and electronics
                                  </h2>
                                </rs-layer-wrap>
                                {/*
                                 */}{" "}
                              </rs-slide>
                            </rs-slides>
                          </rs-module>
                        </rs-module-wrap>
                        <rs-fw-forcer style={{ height: 600 }} />
                      </rs-fullwidth-wrap>
                      {/* END REVOLUTION SLIDER */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-vc-full-width="true"
                data-vc-full-width-init="true"
                data-vc-kd-parallax="1.5"
                className="vc_row wpb_row vc_row-fluid vc_custom_1587041701292 vc_row-has-fill vc_row-has-padding-top vc_row-has-padding-bottom vc_general kd_vc_parallax kd_vc_parallax-content-moving"
                style={{
                  position: "relative",
                  left: "-11.6667px",
                  boxSizing: "border-box",
                  width: 1263,
                  maxWidth: 1263,
                  paddingLeft: "11.6667px",
                  paddingRight: "11.3333px"
                }}
              >
                <div
                  className="parallax-overlay"
                  data-vc-kd-parallax="1.5"
                  style={{
                    height: "619.5px",
                    transform: "translate3d(0px, 629.111px, 0px)"
                  }}
                />
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <div className="vc_column-inner">
                    <div className="wpb_wrapper">
                      <div className="vc_row wpb_row vc_inner vc_row-fluid">
                        <div className="wpb_column vc_column_container vc_col-sm-4 vc_col-lg-4 vc_col-md-12">
                          <div className="vc_column-inner vc_custom_1539607714286">
                            <div className="wpb_wrapper">
                              <header
                                className="kd-section-title col-lg-12 text-left kd-animated fadeIn kd-animate"
                                data-animation-delay={200}
                              >
                                <h2 className="separator_off">
                                  Maintenance and repair work under laboratory
                                  conditions
                                </h2>
                              </header>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-4 vc_col-lg-4 vc_col-md-6">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <p
                                style={{ textAlign: "left" }}
                                className="vc_custom_heading wpb_animate_when_almost_visible wpb_fadeIn fadeIn wpb_start_animation animated"
                              >
                                Our repair centre is where highly qualified
                                technicians inspect and repair IT equipment and
                                electronics made by all major brands. We can
                                repair and refurbish the hardware in your IT
                                system environment and check the technical
                                functionality of all your IT components.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-4 vc_col-lg-4 vc_col-md-6">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <p
                                style={{ textAlign: "left" }}
                                className="vc_custom_heading wpb_animate_when_almost_visible wpb_fadeIn fadeIn wpb_start_animation animated"
                              >
                                In addition to our repair centre, our focus is
                                on the healthcare sector. We offer tailored
                                support and services, and we can even work
                                confidently in critical infrastructures to meet
                                the highest requirements and quality standards.
                                Our employees are trained and authorised to
                                carry out repairs and safety checks according to
                                each manufacturer’s specifications.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="vc_row-full-width vc_clearfix" />
              <div
                data-vc-full-width="true"
                data-vc-full-width-init="true"
                data-vc-stretch-content="true"
                className="vc_row wpb_row vc_row-fluid vc_custom_1587064010370 vc_row-has-padding-top vc_row-no-padding"
                style={{
                  position: "relative",
                  left: "-11.6667px",
                  boxSizing: "border-box",
                  width: 1263,
                  maxWidth: 1263
                }}
              >
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <div className="vc_column-inner">
                    <div className="wpb_wrapper">
                      <div
                        className="vc_tta-container"
                        data-vc-action="collapse"
                      >
                        <div className="vc_general vc_tta vc_tta-tabs vc_tta-color-grey vc_tta-style-classic vc_tta-shape-square vc_tta-spacing-1 vc_tta-tabs-position-top vc_tta-controls-align-center">
                          <div className="vc_tta-tabs-container">
                            <ul className="vc_tta-tabs-list">
                              <li
                                className="vc_tta-tab vc_active"
                                data-vc-tab=""
                              >
                                <a
                                  href="#1604418357579-1f906210-5419"
                                  data-vc-tabs=""
                                  data-vc-container=".vc_tta"
                                  className="__mPS2id"
                                >
                                  <i className="vc_tta-icon vc_li vc_li-search" />
                                  <span className="vc_tta-title-text">
                                    IT repair services
                                  </span>
                                </a>
                              </li>
                              <li className="vc_tta-tab" data-vc-tab="">
                                <a
                                  href="#1515088440909-fd412287-d84ee0d9-e113"
                                  data-vc-tabs=""
                                  data-vc-container=".vc_tta"
                                  className="__mPS2id"
                                >
                                  <i className="vc_tta-icon vc_li vc_li-display" />
                                  <span className="vc_tta-title-text">
                                    Medical technology
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="vc_tta-panels-container">
                            <div className="vc_tta-panels">
                              <div
                                className="vc_tta-panel vc_active"
                                id="1604418357579-1f906210-5419"
                                data-vc-content=".vc_tta-panel-body"
                              >
                                <div className="vc_tta-panel-heading">
                                  <p className="vc_tta-panel-title">
                                    <a
                                      href="#1604418357579-1f906210-5419"
                                      data-vc-accordion=""
                                      data-vc-container=".vc_tta-container"
                                      className="__mPS2id"
                                    >
                                      <i className="vc_tta-icon vc_li vc_li-search" />
                                      <span className="vc_tta-title-text">
                                        IT repair services
                                      </span>
                                    </a>
                                  </p>
                                </div>
                                <div className="vc_tta-panel-body">
                                  <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1536151167985 vc_row-has-padding-top vc_row-has-padding-bottom">
                                    <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-7 vc_hidden-md vc_col-has-fill">
                                      <div className="vc_column-inner vc_custom_1606298317142">
                                        <div className="wpb_wrapper">
                                          <div
                                            className="vc_empty_space"
                                            style={{ height: 170 }}
                                          >
                                            <span className="vc_empty_space_inner" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-5">
                                      <div className="vc_column-inner vc_custom_1587063586709">
                                        <div className="wpb_wrapper">
                                          <header className="kd-section-title col-lg-12 text-left    ">
                                            <h2 className="separator_off">
                                              IT repair services
                                            </h2>
                                            <h6 className="subtitle ">
                                              We repair, check and guarantee.
                                            </h6>
                                          </header>
                                          <div className="wpb_text_column wpb_content_element ">
                                            <div className="wpb_wrapper">
                                              <ul>
                                                <li style={{ fontWeight: 400 }}>
                                                  <span
                                                    style={{ fontWeight: 400 }}
                                                  >
                                                    Inspection and repair of IT
                                                    equipment and electronics
                                                    for all major brands
                                                  </span>
                                                </li>
                                                <li style={{ fontWeight: 400 }}>
                                                  <span
                                                    style={{ fontWeight: 400 }}
                                                  >
                                                    Function tests in
                                                    customer-specific system
                                                    environment
                                                  </span>
                                                </li>
                                                <li style={{ fontWeight: 400 }}>
                                                  <span
                                                    style={{ fontWeight: 400 }}
                                                  >
                                                    Warranty for repairs
                                                    <br />
                                                  </span>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <div
                                            className="vc_empty_space"
                                            style={{ height: 20 }}
                                          >
                                            <span className="vc_empty_space_inner" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="vc_tta-panel"
                                id="1515088440909-fd412287-d84ee0d9-e113"
                                data-vc-content=".vc_tta-panel-body"
                              >
                                <div className="vc_tta-panel-heading">
                                  <p className="vc_tta-panel-title">
                                    <a
                                      href="#1515088440909-fd412287-d84ee0d9-e113"
                                      data-vc-accordion=""
                                      data-vc-container=".vc_tta-container"
                                      className="__mPS2id"
                                    >
                                      <i className="vc_tta-icon vc_li vc_li-display" />
                                      <span className="vc_tta-title-text">
                                        Medical technology
                                      </span>
                                    </a>
                                  </p>
                                </div>
                                <div className="vc_tta-panel-body">
                                  <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1605279027350 vc_row-has-fill">
                                    <div className="wpb_column vc_column_container vc_col-sm-5 vc_col-lg-5 vc_col-md-12">
                                      <div className="vc_column-inner vc_custom_1587063640229">
                                        <div className="wpb_wrapper">
                                          <header className="kd-section-title col-lg-12 text-left    ">
                                            <h2 className="separator_off">
                                              Medical technology
                                            </h2>
                                            <h6 className="subtitle ">
                                              Make the most of our consulting
                                              and solution expertise in the
                                              clinical field.
                                            </h6>
                                          </header>
                                          <div className="wpb_text_column wpb_content_element ">
                                            <div className="wpb_wrapper">
                                              <ul>
                                                <li style={{ fontWeight: 400 }}>
                                                  <span
                                                    style={{ fontWeight: 400 }}
                                                  >
                                                    Customised service plans for
                                                    critical infrastructure
                                                  </span>
                                                </li>
                                                <li style={{ fontWeight: 400 }}>
                                                  <span
                                                    style={{ fontWeight: 400 }}
                                                  >
                                                    Tailored maintenance
                                                    contracts
                                                  </span>
                                                </li>
                                                <li style={{ fontWeight: 400 }}>
                                                  <span
                                                    style={{ fontWeight: 400 }}
                                                  >
                                                    System support for all major
                                                    brands
                                                  </span>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <div
                                            className="vc_empty_space"
                                            style={{ height: 30 }}
                                          >
                                            <span className="vc_empty_space_inner" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="wpb_column vc_column_container vc_col-sm-7 vc_col-lg-7 vc_col-md-12 vc_hidden-md vc_col-has-fill">
                                      <div className="vc_column-inner vc_custom_1605279075577">
                                        <div className="wpb_wrapper">
                                          <div
                                            className="vc_empty_space"
                                            style={{ height: 170 }}
                                          >
                                            <span className="vc_empty_space_inner" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="vc_row-full-width vc_clearfix" />
              <div
                data-vc-full-width="true"
                data-vc-full-width-init="true"
                className="vc_row wpb_row vc_row-fluid vc_custom_1604571192371 vc_row-has-fill"
                style={{
                  position: "relative",
                  left: "-11.6667px",
                  boxSizing: "border-box",
                  width: 1263,
                  maxWidth: 1263,
                  paddingLeft: "11.6667px",
                  paddingRight: "11.3333px"
                }}
              >
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <div className="vc_column-inner">
                    <div className="wpb_wrapper">
                      <div className="vc_empty_space" style={{ height: 62 }}>
                        <span className="vc_empty_space_inner" />
                      </div>
                      <header className="kd-section-title col-lg-12 text-center    ">
                        <h2 className="separator_off">
                          Maintenance and repairs of the highest quality
                        </h2>
                        <h6 className="subtitle ">
                          We will keep your IT systems and equipment up to date
                          at our repair centre.
                        </h6>
                      </header>
                      <div className="vc_row wpb_row vc_inner vc_row-fluid">
                        <div className="wpb_column vc_column_container vc_col-sm-3">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div
                                className="cb-container cb-cont-left kd-animated fadeInUp kd-animate"
                                style={{ backgroundColor: "#fff" }}
                              >
                                <div className="cb-img-area">
                                  <i
                                    className="iconsmind-Engineering iconsmind-People"
                                    style={{}}
                                  />{" "}
                                </div>
                                <div className="cb-text-area">
                                  <h3 className="cb-heading ">
                                    Highly qualified technicians
                                  </h3>
                                  <p>
                                    Highly trained, certified and authorised
                                    employees work at our repair centre.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-3">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div
                                className="cb-container cb-cont-left kd-animated fadeInUp kd-animate"
                                style={{ backgroundColor: "#fff" }}
                                data-animation-delay={200}
                              >
                                <div className="cb-img-area">
                                  <i
                                    className="iconsmind-Big-Data iconsmind-Database"
                                    style={{}}
                                  />{" "}
                                </div>
                                <div className="cb-text-area">
                                  <h3 className="cb-heading ">
                                    Custom system environment
                                  </h3>
                                  <p>
                                    We test the functionality of IT components
                                    in customer-specific environments.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-3">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div
                                className="cb-container cb-cont-left kd-animated fadeInUp kd-animate"
                                style={{ backgroundColor: "#fff" }}
                                data-animation-delay={400}
                              >
                                <div className="cb-img-area">
                                  <i
                                    className="iconsmind-Data-Settings iconsmind-Database"
                                    style={{}}
                                  />{" "}
                                </div>
                                <div className="cb-text-area">
                                  <h3 className="cb-heading ">
                                    Replacement of wearing parts
                                  </h3>
                                  <p>
                                    We replace defective parts with high-quality
                                    components.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-3">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div
                                className="cb-container cb-cont-left kd-animated fadeInUp kd-animate"
                                style={{ backgroundColor: "#fff" }}
                                data-animation-delay={600}
                              >
                                <div className="cb-img-area">
                                  <i
                                    className="iconsmind-Clock-Forward iconsmind-Time"
                                    style={{}}
                                  />{" "}
                                </div>
                                <div className="cb-text-area">
                                  <h3 className="cb-heading ">Quick repairs</h3>
                                  <p>
                                    We repair IT equipment in an average of 5-10
                                    days, and critical system components can be
                                    repaired even faster if required.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="vc_row wpb_row vc_inner vc_row-fluid">
                        <div className="wpb_column vc_column_container vc_col-sm-4">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div
                                className="cb-container cb-cont-left kd-animated fadeInUp kd-animate"
                                style={{ backgroundColor: "#fff" }}
                              >
                                <div className="cb-img-area">
                                  <i
                                    className="iconsmind-Doctor iconsmind-People"
                                    style={{}}
                                  />{" "}
                                </div>
                                <div className="cb-text-area">
                                  <h3 className="cb-heading ">
                                    Medical repair service
                                  </h3>
                                  <p>
                                    We provide a global repair service for
                                    diagnostic and analytical devices according
                                    to each manufacturer’s specifications.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-4">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div
                                className="cb-container cb-cont-left kd-animated fadeInUp kd-animate"
                                style={{ backgroundColor: "#fff" }}
                                data-animation-delay={200}
                              >
                                <div className="cb-img-area">
                                  <i
                                    className="iconsmind-Geo3-Star iconsmind-Maps-Locations"
                                    style={{}}
                                  />{" "}
                                </div>
                                <div className="cb-text-area">
                                  <h3 className="cb-heading ">
                                    Comprehensive service
                                  </h3>
                                  <p>
                                    We offer maintenance and repairs,
                                    <br />
                                    quality control and assurance, auditing,
                                    documentation, logistics and shipping of
                                    equipment.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-4">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div
                                className="cb-container cb-cont-left kd-animated fadeInUp kd-animate"
                                style={{ backgroundColor: "#fff" }}
                                data-animation-delay={400}
                              >
                                <div className="cb-img-area">
                                  <i
                                    className="iconsmind-Security-Check iconsmind-Security"
                                    style={{}}
                                  />{" "}
                                </div>
                                <div className="cb-text-area">
                                  <h3 className="cb-heading ">
                                    Secure environment
                                  </h3>
                                  <p>
                                    Our repair centre is secured against
                                    unauthorised access and features an
                                    ESD-protected area that prevents
                                    contamination.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="vc_row-full-width vc_clearfix" />
              <div
                id="elektrotechnik"
                data-vc-full-width="true"
                data-vc-full-width-init="true"
                className="vc_row wpb_row vc_row-fluid vc_custom_1607605945969 vc_row-has-padding-top vc_row-has-padding-bottom vc_row-o-content-middle vc_row-flex _mPS2id-t"
                style={{
                  position: "relative",
                  left: "-11.6667px",
                  boxSizing: "border-box",
                  width: 1263,
                  maxWidth: 1263,
                  paddingLeft: "11.6667px",
                  paddingRight: "11.3333px"
                }}
              >
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <div className="vc_column-inner">
                    <div className="wpb_wrapper">
                      <div className="vc_row wpb_row vc_inner vc_row-fluid">
                        <div className="wpb_column vc_column_container vc_col-sm-4">
                          <div className="vc_column-inner vc_custom_1541586958015">
                            <div className="wpb_wrapper">
                              <header
                                className="kd-section-title col-lg-12 text-left kd-animated fadeIn kd-animate"
                                data-animation-delay={200}
                              >
                                <h2 className="separator_off">
                                  Inspection and repair of IT equipment and
                                  electronics
                                </h2>
                              </header>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-4">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <p
                                style={{ textAlign: "left" }}
                                className="vc_custom_heading"
                              >
                                One of the most important parts of Technogroup
                                is our repair centre, where highly qualified
                                technicians inspect and repair IT equipment from
                                all major brands under laboratory conditions.
                              </p>
                              <p>
                                We test monitors, power supplies, tape
                                libraries, tape drives, UPS systems and many
                                other electronic units and ensure the full
                                functionality of all components within a
                                customer-specific system environment.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-4">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <p
                                style={{ textAlign: "left" }}
                                className="vc_custom_heading"
                              >
                                Whenever possible and with given reasonability
                                from an economic point of view, we replace
                                defective parts with high-quality components and
                                exchange any wearing parts. For additional
                                security, you benefit from our full repair
                                guarantee within the scope of our terms and
                                conditions. Each repair takes an average of 5-10
                                days (between the arrival of defective parts at
                                the repair centre and their return). However, we
                                can also test and repair critical system
                                components with a very short turnaround time
                                upon request.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="vc_row wpb_row vc_inner vc_row-fluid content-boxes-no-content vc_custom_1607510040375 vc_row-has-padding-top vc_row-has-padding-bottom">
                        <div className="wpb_column vc_column_container vc_col-sm-4">
                          <div className="vc_column-inner vc_custom_1604571711737">
                            <div className="wpb_wrapper">
                              <div
                                className="cb-container cb-cont-left   kd-animated fadeInUp"
                                style={{ backgroundColor: "#fff" }}
                              >
                                <div className="cb-img-area">
                                  <i
                                    className="iconsmind-Wrench iconsmind-User-Interface"
                                    style={{}}
                                  />{" "}
                                </div>
                                <div className="cb-text-area">
                                  <h3 className="cb-heading ">
                                    Repair and maintenance of IT components
                                  </h3>
                                  <p />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-4">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div
                                className="cb-container cb-cont-left   kd-animated fadeInUp"
                                style={{ backgroundColor: "#fff" }}
                                data-animation-delay={200}
                              >
                                <div className="cb-img-area">
                                  <i
                                    className="iconsmind-Geo2-Close iconsmind-Maps-Locations"
                                    style={{}}
                                  />{" "}
                                </div>
                                <div className="cb-text-area">
                                  <h3 className="cb-heading ">
                                    On-site repair service
                                  </h3>
                                  <p />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-4">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div
                                className="cb-container cb-cont-left   kd-animated fadeInUp"
                                style={{ backgroundColor: "#fff" }}
                                data-animation-delay={400}
                              >
                                <div className="cb-img-area">
                                  <i
                                    className="iconsmind-Support iconsmind-User-Interface"
                                    style={{}}
                                  />{" "}
                                </div>
                                <div className="cb-text-area">
                                  <h3 className="cb-heading ">
                                    ISO fault hotline with Technogroup SPOC
                                    principal
                                  </h3>
                                  <p />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="vc_row wpb_row vc_inner vc_row-fluid content-boxes-no-content vc_custom_1607510043535 vc_row-has-padding-top vc_row-has-padding-bottom">
                        <div className="wpb_column vc_column_container vc_col-sm-4">
                          <div className="vc_column-inner vc_custom_1604571711737">
                            <div className="wpb_wrapper">
                              <div
                                className="cb-container cb-cont-left   kd-animated fadeInUp"
                                style={{ backgroundColor: "#fff" }}
                              >
                                <div className="cb-img-area">
                                  <i
                                    className="iconsmind-Arrow-Circle iconsmind-Arrow-View"
                                    style={{}}
                                  />{" "}
                                </div>
                                <div className="cb-text-area">
                                  <h3 className="cb-heading ">
                                    Software update and upgrade service
                                  </h3>
                                  <p />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-4">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div
                                className="cb-container cb-cont-left   kd-animated fadeInUp"
                                style={{ backgroundColor: "#fff" }}
                                data-animation-delay={200}
                              >
                                <div className="cb-img-area">
                                  <i
                                    className="iconsmind-Big-Data iconsmind-Database"
                                    style={{}}
                                  />{" "}
                                </div>
                                <div className="cb-text-area">
                                  <h3 className="cb-heading ">
                                    Hardware maintenance
                                  </h3>
                                  <p />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-4">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div
                                className="cb-container cb-cont-left   kd-animated fadeInUp"
                                style={{ backgroundColor: "#fff" }}
                                data-animation-delay={400}
                              >
                                <div className="cb-img-area">
                                  <i
                                    className="iconsmind-VPN iconsmind-Communications-Network"
                                    style={{}}
                                  />{" "}
                                </div>
                                <div className="cb-text-area">
                                  <h3 className="cb-heading ">
                                    Screen maintenance and incident services
                                  </h3>
                                  <p />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="vc_row-full-width vc_clearfix" />
              <div
                id="healthcare"
                data-vc-full-width="true"
                data-vc-full-width-init="true"
                className="vc_row wpb_row vc_row-fluid vc_custom_1607605955336 vc_row-has-fill _mPS2id-t"
                style={{
                  position: "relative",
                  left: "-11.6667px",
                  boxSizing: "border-box",
                  width: 1263,
                  maxWidth: 1263,
                  paddingLeft: "11.6667px",
                  paddingRight: "11.3333px"
                }}
              >
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <div className="vc_column-inner">
                    <div className="wpb_wrapper">
                      <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1511348417220 vc_row-has-padding-top vc_row-has-padding-bottom">
                        <div className="wpb_column vc_column_container vc_col-sm-4">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <header
                                className="kd-section-title col-lg-12 text-left  kd-animated fadeIn   vc_custom_1605545968378"
                                data-animation-delay={200}
                              >
                                <h2 className="separator_off">
                                  Our specialisation in the healthcare sector
                                </h2>
                              </header>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-4">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <p
                                style={{ textAlign: "left" }}
                                className="vc_custom_heading"
                              >
                                Following the rapid development of IT in the
                                healthcare sector, medical technology has become
                                a high-tech field with a promising future. The
                                professional use, care and maintenance of modern
                                medical equipment requires highly trained
                                specialists. Medical devices have to meet high
                                safety and reliability requirements, because
                                they have a direct impact on a patient’s
                                well-being.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-4">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <p
                                style={{ textAlign: "left" }}
                                className="vc_custom_heading"
                              >
                                Technogroup has built a reputation as an IT
                                service provider that meets the highest
                                standards, but not many people know that we have
                                expanded our portfolio over the past few years
                                and have been providing repair services around
                                Europe for leading manufacturers of diagnostic
                                and analytical equipment with biosensor
                                technology. Our specialists plan and create
                                tailored servicing plans to meet specific
                                clinical requirements and we have even developed
                                additional spatial conditions for the medical
                                technology sector.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1605277633460 vc_row-has-padding-top vc_row-has-padding-bottom vc_row-o-content-middle vc_row-flex">
                        <div className="wpb_column vc_column_container vc_col-sm-6">
                          <div className="vc_column-inner vc_custom_1604571711737">
                            <div className="wpb_wrapper">
                              <div
                                className="key-icon-box icon-default icon-top cont-left ib-hover-1"
                                style={{ backgroundColor: "transparent" }}
                              >
                                <div className="ib-wrapper">
                                  <i
                                    className="iconsmind-Checked-User iconsmind-People"
                                    style={{}}
                                  />{" "}
                                  <h5 className="service-heading">
                                    Top consulting and solution expertise
                                  </h5>
                                  <p>
                                    As a service provider for all major brands,
                                    we have proven our expertise during numerous
                                    projects in the healthcare sector.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-6">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div
                                className="key-icon-box icon-default icon-top cont-left ib-hover-1"
                                style={{ backgroundColor: "transparent" }}
                              >
                                <div className="ib-wrapper">
                                  <i
                                    className="iconsmind-Checked-User iconsmind-People"
                                    style={{}}
                                  />{" "}
                                  <h5 className="service-heading">
                                    Support for all major manufacturers
                                  </h5>
                                  <p>
                                    Our service is specifically designed for
                                    your company’s critical systems. In the
                                    event of a fault, we provide support for
                                    many well-known manufacturers and operating
                                    systems.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1605277638628 vc_row-has-padding-top vc_row-has-padding-bottom vc_row-o-content-middle vc_row-flex">
                        <div className="wpb_column vc_column_container vc_col-sm-6">
                          <div className="vc_column-inner vc_custom_1604571711737">
                            <div className="wpb_wrapper">
                              <div
                                className="key-icon-box icon-default icon-top cont-left ib-hover-1"
                                style={{ backgroundColor: "transparent" }}
                              >
                                <div className="ib-wrapper">
                                  <i
                                    className="iconsmind-Checked-User iconsmind-People"
                                    style={{}}
                                  />{" "}
                                  <h5 className="service-heading">
                                    Repair centre
                                  </h5>
                                  <p>
                                    We carry out maintenance, repairs and
                                    technical function tests for diagnostic and
                                    analytical equipment at our repair centre.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-6">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div
                                className="key-icon-box icon-default icon-top cont-left ib-hover-1"
                                style={{ backgroundColor: "transparent" }}
                              >
                                <div className="ib-wrapper">
                                  <i
                                    className="iconsmind-Checked-User iconsmind-People"
                                    style={{}}
                                  />{" "}
                                  <h5 className="service-heading">
                                    Certified technicians
                                  </h5>
                                  <p>
                                    Our employees are authorised to carry out
                                    repairs and safety checks at our facilities
                                    according to each manufacturer’s
                                    specifications.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="vc_row-full-width vc_clearfix" />
              <div
                data-vc-full-width="true"
                data-vc-full-width-init="true"
                data-vc-kd-parallax="1.5"
                className="vc_row wpb_row vc_row-fluid vc_custom_1604579362992 vc_row-has-fill vc_row-has-padding-top vc_row-has-padding-bottom vc_general kd_vc_parallax kd_vc_parallax-content-moving"
                style={{
                  position: "relative",
                  left: "-11.6667px",
                  boxSizing: "border-box",
                  width: 1263,
                  maxWidth: 1263,
                  paddingLeft: "11.6667px",
                  paddingRight: "11.3333px"
                }}
              >
                <div
                  className="parallax-overlay"
                  data-vc-kd-parallax="1.5"
                  style={{
                    height: "1485.5px",
                    transform: "translate3d(0px, -777px, 0px)"
                  }}
                />
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <div className="vc_column-inner">
                    <div className="wpb_wrapper">
                      <header
                        className="kd-section-title col-lg-12 text-left  kd-animated fadeIn  "
                        data-animation-delay={200}
                      >
                        <h2 className="separator_off">
                          Our extensive experience
                        </h2>
                        <h6 className="subtitle ">
                          Ever since our repair centre was opened in 1990, we
                          have continued to expand our entire IT service
                          portfolio.
                        </h6>
                      </header>
                      <div className="kd-process-steps process_four_elem process-checkbox-template ">
                        <ul>
                          <li className="pss-item">
                            <div className="pss-container  ">
                              <div className="pss-step-number">
                                <span>1990</span>
                              </div>
                              <div className="pss-text-area">
                                <p>
                                  We extend our technical repair service to
                                  screens and printers.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="pss-item">
                            <div className="pss-container  ">
                              <div className="pss-step-number">
                                <span>1993</span>
                              </div>
                              <div className="pss-text-area">
                                <p>
                                  We start to repair tape drives and printheads.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="pss-item">
                            <div className="pss-container  ">
                              <div className="pss-step-number">
                                <span>1998</span>
                              </div>
                              <div className="pss-text-area">
                                <p>
                                  We move to our new company headquarters and
                                  introduce an in-house repair service.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="pss-item">
                            <div className="pss-container  ">
                              <div className="pss-step-number">
                                <span>2005</span>
                              </div>
                              <div className="pss-text-area">
                                <p>
                                  We receive our first order in the field of
                                  medical technology: monitor and power supply
                                  repairs for analytical devices
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="vc_empty_space" style={{ height: 32 }}>
                        <span className="vc_empty_space_inner" />
                      </div>
                      <div className="kd-process-steps process_four_elem process-checkbox-template ">
                        <ul>
                          <li className="pss-item">
                            <div className="pss-container  ">
                              <div className="pss-step-number">
                                <span>2010</span>
                              </div>
                              <div className="pss-text-area">
                                <p>
                                  We receive a European service and maintenance
                                  contract for special mobile diagnostic
                                  equipment from a US manufacturer.
                                  <br />
                                  We upgrade our facilities to meet special
                                  decontamination requirements.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="pss-item">
                            <div className="pss-container  ">
                              <div className="pss-step-number">
                                <span>2012</span>
                              </div>
                              <div className="pss-text-area">
                                <p>
                                  We expand our service portfolio by
                                  refurbishing laboratory apparatus.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="pss-item">
                            <div className="pss-container  ">
                              <div className="pss-step-number">
                                <span>2014</span>
                              </div>
                              <div className="pss-text-area">
                                <p>
                                  We upgrade our facilities by setting up a
                                  fully ESD-protected area for the repair and
                                  maintenance of medical devices.
                                  <br />
                                  Since 2014, we have been continuously
                                  developing a service and maintenance contract
                                  to enable a US mobile diagnostics brand to
                                  branch out into the EMEA region.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="pss-item">
                            <div className="pss-container  ">
                              <div className="pss-step-number">
                                <span>2018</span>
                              </div>
                              <div className="pss-text-area">
                                <p>
                                  We expand the service portfolio of a US mobile
                                  diagnostics brand to include the handling of
                                  rental equipment.
                                  <br />
                                  The service is now being provided in over 50
                                  countries around the world.
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="vc_row-full-width vc_clearfix" />
            </section>{" "}
          </div>
        </div>
      </section>
    </main>
    {/* #main */}
  </div>
  {/* #primary */}
</div>

  )
}

export default Electrotechnology