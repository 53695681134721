import React, { useEffect, useRef, useState } from "react";
import { headerData } from "../../Services/ConstantVariables";
import HeaderItem from "./HeaderItem";
import { useNavigate } from "react-router-dom";
import { HOME } from "../../Services/ConstantUrls";

const Header = () => {
  const [HeaderData, setHeaderData] = useState(headerData);
  const [isMobMenuOpen, setIsMobMenuOpen] = useState(false);
  const ref = useRef();
  const navigate = useNavigate();

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setHeaderData((prevData) =>
          prevData.map((item) => ({
            ...item,
            showChild: false,
            child: item.child.map((childItem) => ({
              ...childItem,
              showChild: false,
            })),
          }))
        );
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const closeHeader = () => {
    setHeaderData((prevData) =>
      prevData.map((item) => ({
        ...item,
        showChild: false,
        child: item.child.map((childItem) => ({
          ...childItem,
          showChild: false,
        })),
      }))
    );
  };

  const parentHandler = (parentIndex) => {
    // if (window.innerWidth > 768) {
      let data = HeaderData;
      data[parentIndex].showChild = true;
      let updatedData = data.map((item, index) => {
        if (index == parentIndex) {
          item.showChild = true;
          return item;
        } else {
          item.child = item.child.map((ele) => ({ ...ele, showChild: false }));
          item.showChild = false;
          return item;
        }
      });
      setHeaderData(updatedData);
    // }
  };

  const childHandler = (parentIndex, childIndex) => {
    // if (window.innerWidth > 768) {
      let data = HeaderData;
      let updatedData = data.map((item, index) => {
        if (index == parentIndex) {
          item.child = item.child.map((innerEle, childi) => {
            if (childi == childIndex) {
              innerEle.showChild = true;
            } else {
              innerEle.showChild = false;
            }
            return innerEle;
          });
          return item;
        } else {
          return item;
        }
      });
      setHeaderData(updatedData);
    // }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const menuIconClick = () => {
    setIsMobMenuOpen(!isMobMenuOpen)
    closeHeader()
  }

  return (
    <header className={`lead header header_white`} ref={ref}>
      <div className="container-fluid d-flex justify-content-between align-items-center">
        <div className="position-relative ms-2">
        <span className="trade-mark-styling">TM</span>
          <img
            src="images/logoHome.png"
            height={60}
            width={100}
            alt=""
            srcset=""
            onClick={() => {navigate(HOME); window.scrollTo(0, 0);}}
          className="logo-image-style"
          />
          {/* <span className="position-absolute top-0 end-0">TM</span> */}
          <p className="slogan-styling" onClick={() => {navigate(HOME); window.scrollTo(0, 0);}}>Service Simplified Delivered</p>
        </div>
        <div className="mobile_menu_icon d-lg-none d-block text-muted">
          <span
            className="bi bi-list"
            onClick={menuIconClick}
          ></span>
        </div>
        <nav className={`nav d-block ${isMobMenuOpen ? "active" : ""}`}>
          <div className="mobile_menu_header d-flex justify-content-between align-items-center px-2 py-4 d-lg-none border-bottom">
            <div className="mobile_header_logo">
            {/* <span className="trade-mark-styling-mob">TM</span> */}
              <img
                src="images/logoHome.png"
                height={60}
                width={100}
                alt=""
                srcset=""
                onClick={() => navigate(HOME)}
                className="logo-image-style-mob"
              />
              <p className="slogan-styling-mob">Service Simplified Delivered</p>
            </div>
            {/* <div> */}
            <button
              type="button"
              className="close_btn close bg-transparent text-white border-0"
              aria-label="Close"
              onClick={() => setIsMobMenuOpen(false)}
            >
              &times;
            </button>
            {/* </div> */}
          </div>
          <ul className="rounded d-flex px-lg-0 px-2 pt-3">
            {HeaderData.map((parentItem, index) => (
              <li key={index} className="py-lg-0 py-2">
                <span className="d-flex flex-row">
                  <a
                    onClick={() => {
                      setIsMobMenuOpen(false);
                      navigate(parentItem?.parentUrl || HOME);
                       closeHeader()
                    }}
                    // onMouseEnter={() => parentHandler(index)}
                  >
                    {parentItem.parentTitle}

                  </a>
                  {/* ${window.innerWidth > 768?'text-white':'text-white'}`} */}
                  {
                    parentItem.child.length > 0 ? <i class={`bi bi-caret-down-fill ${window.innerWidth > 768?'text-muted':'text-white'}`} onClick={() => parentHandler(index)} /> : ""
                  }
                </span>
                {parentItem?.child?.length > 0 && parentItem?.showChild && (
                  <ul
                    className={`rounded border fs-6 mt-lg-3 ${parentItem.parentTitle == "Company"
                        ? "me-5 company-dropdown"
                        : ""
                      }`}
                  >
                    {parentItem.child.map((childItem, childIndex) => (
                      <span className="d-flex flex-row">
                        <HeaderItem
                          key={childIndex}
                          item={childItem}
                          childHandler={childHandler}
                          parentIndex={index}
                          childIndex={childIndex}
                          setIsMobMenuOpen={setIsMobMenuOpen}
                          closeHeader={closeHeader}
                        />
                        {
                          childItem.child.length > 0 ? <i class={`bi bi-caret-down-fill mt-2 ${window.innerWidth > 768?'text-muted':'text-light'}`} onClick={() => childHandler(index, childIndex)} /> : ""
                        }
                      </span>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
