import React from "react";
import LatestInsights from "../../Components/LatestInsights/LatestInsights";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";

function GlobalProjectManagement() {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/global-project-banner.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold text-capitalize'>Activate Command Center for Project Assignments and Workforce
                Allocation</h1>
              <p className="mt-5 text-warning text-capitalize" style={{fontSize:'30px'}}>
              Address global challenges by providing your team with products
                that offer comprehensive visibility, seamless collaboration,
                and predictable service delivery capabilities
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section className="wrapper bg-light mt-5 p-5 text-muted">
        <div className="container py-10 py-md-12">
          <div className="row">
            <div className="col-md-6 col-lg-9 col-xxl-8 mx-auto">
              <h3 className="ls-sm mb-2 px-xl-11 text-center">
                Project Management Based Services
              </h3>
              <h5 className="text-center">
                Allow our local team to support you comprehensively from start
                to finish
              </h5>
              <p className="fs-5">
                The continuous emergence of new platforms and products across
                all IT sectors significantly impacts the resource mix that IT
                organizations must maintain to maximize the potential of these
                technologies.
              </p>
              <p className="fs-5">
              DataCentrix™ offers Professional Services featuring a multi-disciplinary
                team of IT consultants and experts. Together, we implement
                proven strategies to deliver tailored solutions for your
                organization's needs.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <img src="images/project-management-services.jpg" />
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container pt-9 ">
          <div className="row gx-lg-8 gx-xl-10 align-items-start">
            <div className="col-lg-6">
              <img src="images/pmo.webp" />
            </div>
            <div className="col-lg-6">
              <h3 className="mb-5 text-center text-lg-start mt-5 mt-lg-0">
                Project Events Management (PMO)
              </h3>
              <div className="row gy-6 gx-xxl-8 process-wrapper">
                <div className="col-md-12 d-flex">
                  <p className="mb-1 fs-5">
                    Centralize project management in one system for enhanced
                    performance measurement across your organization. Elevate
                    project outcomes with portfolio administration, systematized
                    workflows, and automated processes for consistent and
                    efficient results.
                  </p>
                </div>
                <div className="col-md-12 d-flex">
                  <p className="mb-1 fs-5">
                    Enhance team collaboration by enabling multiple project
                    teams to achieve high efficiency through collaboration and
                    document sharing. Facilitate communication and status
                    updates with an integrated message board.
                  </p>
                </div>
                <div className="col-md-12 d-flex">
                  <p className="mb-1 fs-5">
                    Optimize your team's organization with intelligent resource
                    management. Gain a portfolio-wide visualization of available
                    resources and balance capacity against demand effectively.
                  </p>
                </div>
                <div className="col-md-12 d-flex">
                  <p className="mb-1 fs-5">
                    Harness our state-of-the-art project templates to
                    efficiently initiate and execute hundreds of projects with
                    consistency and transparency, ensuring on-time delivery.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted bg-light">
        <div className="container pt-9">
          <div className="row gx-lg-8 gx-xl-10 align-items-start">
            <div className="col-lg-6 order-lg-2 mt-5 pt-5">
              <img src="images/pasm.webp" />
            </div>
            <div className="col-lg-6">
              <h3 className="mb-5 text-center text-lg-start mt-5 mt-lg-0">
                Professional Services Automation Management (PSAM)
              </h3>
              <div className="row gy-6 gx-xxl-8 process-wrapper">
                <div className="col-md-12 d-flex">
                  <p className="my-2 fs-5">
                    Leverage professional services automation (PSA) software to
                    streamline project execution and enhance collaboration,
                    enabling the delivery of new profitable services. Automate
                    project, resource, and billing processes for more
                    consistent, efficient, and profitable outcomes.
                  </p>
                </div>
                <div className="col-md-12 d-flex">
                  <p className="my-2 fs-5">
                    Optimize workforce projects for optimal assignment and
                    profitability by embracing cost-effective capacity planning
                    and consumption approaches. Enhance prediction, scheduling,
                    and management of your team across geographies by
                    programming individuals based on availability, skills, and
                    proficiency.
                  </p>
                  <p />
                </div>
                <div className="col-md-12 d-flex">
                  <p className="my-2 fs-5">
                    Transform project repeatability by centralizing front and
                    back-office tasks within your organization. This approach
                    enables you to prioritize discovering customer value,
                    enhancing project quality, and maximizing profits.
                  </p>
                </div>
                <div className="col-md-12 d-flex">
                  <p className="my-2 fs-5">
                    Achieve positive customer outcomes by capturing customer
                    feedback throughout every service engagement. Use this
                    feedback to address any issues and enhance customer loyalty.
                  </p>
                </div>
                <div className="col-md-12 d-flex">
                  <p className="my-2 fs-5">
                    Expanding project collaboration within and beyond the
                    organization drives innovation and success. Utilize internal
                    platforms, cross-functional teams, and knowledge sharing
                    initiatives. Engage clients transparently and explore
                    external partnerships for growth and innovation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container pt-9 ">
          <div className="row gx-lg-8 gx-xl-10 align-items-start">
            <div className="col-lg-6 mt-5 pt-5">
              <img src="images/program-protfolio-management.jpg" />
            </div>
            <div className="col-lg-6">
              <h3 className="mb-5 text-center text-lg-start mt-5 mt-lg-0">
                Program Portfolio For Service Delivery Management (PGMO)
              </h3>
              <div className="row gy-6 gx-xxl-8 process-wrapper">
                <div className="col-md-12 d-flex">
                  <p className="my-2 fs-5">
                    Accelerate your enterprise vision with our proprietary
                    Program Portfolio Management (PPM) software. Seamlessly
                    align strategy with planning, execution, and results,
                    prioritizing work aligned to corporate goals for maximum
                    value delivery and strategic alignment.
                  </p>
                </div>
                <div className="col-md-12 d-flex">
                  <p className="my-2 fs-5">
                    Our PPM software empowers you to define, track, and validate
                    project performance in line with projected outcomes.
                    Establish multi-disciplinary teams for optimized delivery
                    and value. Streamline processes for efficient work
                    management, reducing time to market and boosting revenue.
                  </p>
                </div>
                <div className="col-md-12 d-flex">
                  <p className="my-2 fs-5">
                    Gain enhanced visibility by consolidating data from various
                    sources to visualize real-time performance metrics. Track
                    resources, financials, and overall project progress
                    seamlessly, empowering informed decision-making and
                    optimizing outcomes.
                  </p>
                </div>
                <div className="col-md-12 d-flex">
                  <p className="my-2 fs-5">
                    Achieve financial transparency by forecasting, planning, and
                    tracking all project-related costs. With our system, you can
                    realize actual dollar savings through accurate budgeting and
                    cost management.
                  </p>
                </div>
                <div className="col-md-12 d-flex">
                  <p className="my-2 fs-5">
                    Enable seamless collaboration by integrating teams with
                    diverse processes into a centralized platform for efficient
                    performance and communication.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper  mt-5 p-5 text-muted bg-light">
        <div className="container pt-9 ">
          <div className="row gx-lg-8 gx-xl-10 align-items-start">
            <div className="col-lg-6 order-lg-2 mt-5 pt-5">
              <img src="images/talent-pool.webp" />
            </div>
            <div className="col-lg-6">
              <h3 className="mb-5 text-center text-lg-start mt-5 mt-lg-0">Talent Pool Management</h3>
              <div className="row gy-6 gx-xxl-8 process-wrapper">
                <div className="col-md-12 d-flex">
                  <p className="mb-2 fs-5">
                    Leverage our in-house Professional Services Automation (PSA)
                    tool to allocate billable and non-billable resources based
                    on their skills and availability, ensuring precise delivery
                    aligned with customer needs. Schedule with confidence,
                    optimizing resource allocation according to capacity and
                    demand.
                  </p>
                </div>
                <div className="col-md-12 d-flex">
                  <p className="mb-2 fs-5">
                    Maximize your services team's potential with our PSA,
                    ensuring projects are delivered on time and within budget.
                    Easily match specific technical skills, languages, and
                    expertise levels across your global workforce. Organize
                    projects based on client demands and budgets, whether
                    hourly, daily, weekly, or monthly. Assess upcoming project
                    pipelines to ensure adequate resources at every level. Share
                    KPIs with finance, project managers, and customer success
                    for informed decisions on profitability, staffing, and skill
                    development.
                  </p>
                </div>
                <div className="col-md-12 d-flex">
                  <p className="mb-2 fs-5">
                    Effectively manage team workload to prevent exhaustion and
                    maintain high engagement levels for optimal performance.
                    Implement strategies to vary tasks, provide opportunities
                    for skill development, and foster a supportive work
                    environment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container pt-9 ">
          <div className="row gx-lg-8 gx-xl-10 align-items-start">
            <div className="col-lg-6 mt-5 pt-5">
              <img src="images/api-integration.webp" />
            </div>
            <div className="col-lg-6">
              <h3 className="mb-5 text-center text-lg-start mt-5 mt-lg-5">
                Project Management API Integration Services
              </h3>
              <div className="row gy-6 gx-xxl-8 process-wrapper">
                <div className="col-md-12 d-flex">
                  <p className="my-2 fs-5">
                    Our PSA tool seamlessly integrates with leading enterprise
                    software packages, maximizing productivity and extracting
                    maximum value from existing resources. With automated
                    workflows and comprehensive visibility into projects,
                    resources, and financials, you can accelerate
                    decision-making and execution, moving swiftly with
                    confidence.
                  </p>
                </div>
                <div className="col-md-12 d-flex">
                  <p className="my-2 fs-5">
                    Our integration platform facilitates seamless connections
                    across all enterprise systems, ensuring up-to-date employee
                    skills inventory and availability. Detailed field mapping
                    maintains consistent and organized data accessible to all.
                    With fully flexible system configuration, you have complete
                    control to adapt workflows and automation rules as needed,
                    ensuring efficiency and agility.
                  </p>
                </div>
                <div className="col-md-12 d-flex">
                  <p className="my-2 fs-5">
                    Simplify administration with our drag-and-drop empowered
                    visual editor, enabling users to effortlessly create and
                    update field mapping to precisely align with your business
                    needs.
                  </p>
                </div>
                <div className="col-md-12 d-flex">
                  <p className="my-2 fs-5">
                    Achieve data consistency with our system, enhancing accuracy
                    and delivering precise reports through one-way or two-way
                    information flow based on cross-departmental processes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted bg-light">
        <div className="container">
          <h3 className="mb-6 text-center">Our Goals</h3>
          <div className="row mt-5">
            <div className="col-lg-4 col-md-6">
              <div className="card shadow-lg mb-5 text-muted p-3 h-100 main-card">
                <div className="card-body">
                  <div className="text-center">
                    <i className="uil uil-lightbulb-alt text-warning fs-2 mx-2" />
                  </div>
                  <p className="mb-1 fs-5 mt-3">
                    Leveraging New Technologies for Organizational Innovation
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-2">
              <div className="card shadow-lg mb-5 text-muted p-3 h-100 main-card">
                <div className="card-body">
                  <div className="text-center">
                    <i className="uil uil-clock-five text-warning fs-2 mx-2" />
                  </div>
                  <p className="mb-1 fs-5 mt-3">
                    Streamlining Deployments to Critical Environments for
                    Faster Implementation
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-2">
              <div className="card shadow-lg mb-5 text-muted p-3 h-100 main-card">
                <div className="card-body">
                  <div className="text-center">
                    <i className="uil uil-house-user text-warning fs-2 mx-2" />
                  </div>
                  <p className="mb-1 fs-5 mt-3">
                    Freeing IT Teams from Project Management Burdens
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-2">
              <div className="card shadow-lg mb-5 text-muted p-3 h-100 main-card">
                <div className="card-body">
                  <div className="text-center">
                    <i className="uil uil-code-branch text-warning fs-2 mx-2" />
                  </div>
                  <p className="mb-1 fs-5 mt-3">
                    Maximizing Solutions through Internal Strengths and
                    External Partnerships
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-2">
              <div className="card shadow-lg mb-5 text-muted p-3 h-100 main-card">
                <div className="card-body ">
                  <div className="text-center">
                    <i className="uil uil-servers text-warning fs-2 mx-2" />
                  </div>
                  <p className="mb-1 fs-5 mt-3">
                    Expanding IT Technology Roadmap for Ongoing Innovation
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-md-8 col-lg-6 position-relative order-lg-2">
              <div className="shape rounded-circle rellax w-20 h-20" />
              <img src="images/key-differentiators.webp" />
            </div>
            <div className="col-lg-6">
              <h3 className="mb-5 text-center text-lg-start mt-5 mt-lg-0">DataCentrix™ Key Differentiators</h3>
              <div className="d-flex flex-row mb-6">
                <div>
                  <span className="icon btn rounded-pill btn-warning pe-none me-5">
                    <span className="number fs-18">1</span>
                  </span>
                </div>
                <div>
                  <p className="mb-1 fs-5">
                    Comprehensive End-to-End Consulting Services for Full-Scale
                    Solutions
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row mb-6">
                <div>
                  <span className="icon btn rounded-pill btn-warning pe-none me-5">
                    <span className="number fs-18">2</span>
                  </span>
                </div>
                <div>
                  <p className="mb-1 fs-5">
                    Multidisciplinary IT Consulting Teams: Expertise for
                    Comprehensive Solutions"
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row mb-6">
                <div>
                  <span className="icon btn rounded-pill btn-warning pe-none me-5">
                    <span className="number fs-18">3</span>
                  </span>
                </div>
                <div>
                  <p className="mb-1 fs-5">
                    Global Expertise, Local Talent: Worldwide Solutions,
                    Personalized Service
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row mb-6">
                <div>
                  <span className="icon btn rounded-pill btn-warning pe-none me-5">
                    <span className="number fs-18">4</span>
                  </span>
                </div>
                <div>
                  <p className="mb-1 fs-5">
                    Tailored Solutions: Customized to Your Unique Needs
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 bg-light text-muted">
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center">
              <h3 className="mb-5 text-capitalize">
                Specialized Expertise Tailored to Your Needs
              </h3>
            </div>
          </div>
          <div className="row gx-md-8 gy-8 text-center d-flex">
            <div className="col-md-6 col-lg-3 my-3 my-lg-0 global-specialize">
              <div className="icon btn btn-circle btn-lg pe-none mb-2">
                {" "}
                <i className="uil uil-code-branch text-warning fs-2" />{" "}
              </div>
              <h5 className="mb-1">Network Services</h5>
            </div>
            <div className="col-md-6 col-lg-3 my-3 my-lg-0 global-specialize">
              <div className="icon btn btn-circle btn-lg  pe-none mb-2">
                {" "}
                <i className="uil uil-monitor text-warning fs-2" />{" "}
              </div>
              <h5 className="mb-1">Mobility Services</h5>
            </div>
            <div className="col-md-6 col-lg-3 my-3 my-lg-0 global-specialize">
              <div className="icon btn btn-circle btn-lg pe-none mb-2">
                {" "}
                <i className="uil uil-desktop text-warning fs-2" />{" "}
              </div>
              <h5 className="mb-1">Workplace Services</h5>
            </div>
            <div className="col-md-6 col-lg-3 my-3 my-lg-0 global-specialize">
              <div className="icon btn btn-circle btn-lg pe-none mb-2">
                {" "}
                <i className="uil uil-database text-warning fs-2" />{" "}
              </div>
              <h5 className="mb-1">Big Data Consulting Services</h5>
            </div>
            <div className="col-md-6 col-lg-3 my-3 my-lg-0 global-specialize">
              <div className="icon btn btn-circle btn-lg pe-none mb-2">
                {" "}
                <i className="uil uil-chat-bubble-user text-warning fs-2" />{" "}
              </div>
              <h5 className="mb-1">Platform Consulting Services</h5>
            </div>
            <div className="col-md-6 col-lg-3 my-3 my-lg-0 global-specialize">
              <div className="icon btn btn-circle btn-lg pe-none mb-2">
                {" "}
                <i className="uil uil-data-sharing text-warning fs-2" />{" "}
              </div>
              <h5 className="mb-1">
                Data Center Facilities Consulting Services
              </h5>
            </div>
            <div className="col-md-6 col-lg-3 my-3 my-lg-0 global-specialize">
              <div className="icon btn btn-circle btn-lg pe-none mb-2">
                {" "}
                <i className="uil uil-cloud-computing text-warning fs-2" />{" "}
              </div>
              <h5 className="mb-1">Cloud Services</h5>
            </div>
            <div className="col-md-6 col-lg-3 my-3 my-lg-0 global-specialize">
              <div className="icon btn btn-circle btn-lg pe-none mb-2">
                {" "}
                <i className="uil uil-cloud-database-tree text-warning fs-2" />{" "}
              </div>
              <h5 className="mb-1">Storage Services</h5>
            </div>
          </div>
        </div>
      </section>
      <LatestInsights />
      <LetsConnect />
    </React.Fragment>
  );
}

export default GlobalProjectManagement;
