import React, { useState } from 'react'
import GlobalPresence from '../../Components/GlobalPresence/GlobalPresence'
import JoinCommunity from '../../Components/JoinCommunity/JoinCommunity'
import LetsConnect from '../../Components/LetsConnect/LetsConnect'
import Accordian from '../../Components/Accordian/Accordian'
import { support_accordian } from '../../Services/ConstantVariables'
import GlobalBrands from '../../Components/GlobalBrands/GlobalBrands'
import InsightSlider from '../../Components/InsightSlider/InsightSlider'

const SupportPacks = () => {
  const [accordianData, setAccordianData] = useState(support_accordian);

  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/tech-support-banner-img.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold text-capitalize'>Customized support services ensuring high availability from day one</h1>
              <p className="mt-5 text-warning" style={{fontSize:'30px'}}>
              Support Packs
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section>
        <div className="container-fluid mt-5 p-5 text-muted bg-light">
          <div className="row">
            <div className="col-lg-4 mt-lg-5">
              <h3 className='px-4 text-center text-lg-start text-capitalize'>
                Enhanced flexibility and security for your IT systems, right from the start.</h3>
            </div>
            <div className="col-lg-4 mt-5 fs-5">
              <p>Data centers serve as vital hubs for operations in many companies. In the event of an impending system failure, quick decisions are crucial. What are the critical steps to take? Who can provide immediate support at the data center? We offer flexible support packages for hardware emergencies, ensuring you reap the benefits right from day one.</p>
            </div>
            <div className="col-lg-4 mt-lg-5 fs-5">
              <p>Our support packages encompass comprehensive diagnosis and resolution of hardware faults, including repair or replacement. They comprise pre-defined services with SLAs applicable across your business. We provide full protection for your IT systems, extending beyond the standard manufacturer's warranty to offer enhanced flexibility and security.</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 text-muted">
          <div className="row">
            <div className="col-lg-6">
              <h3 className='px-3 text-center text-lg-start text-capitalize'>How can you book our support packages?</h3>
              <p className='fs-5 mt-3 px-3 text-center text-lg-start'>Our support packages are facilitated through our partners in the form of support keys. End users can acquire a license for our services by purchasing a support key. These keys can be activated via our customer portal, TIM (Technogroup Information Manager). Once activated, the selected services, identified by article numbers, become immediately accessible.</p>
            </div>
            <div className="col-lg-6 mt-5 mt-lg-0">
              <div className="row px-3">
                <div className="col-lg-12">
                  <div className="card shadow-lg text-muted h-100 main-card">
                    <div className="card-body">
                      <h5 className="fs-5 fw-bold">Activation</h5>
                      <p className="fs-5 mt-2">Once activated in our customer portal TIM (Technogroup Information Manager), the key unlocks the selected services based on article numbers, making them immediately accessible.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mt-3">
                  <div className="card shadow-lg text-muted h-100 main-card">
                    <div className="card-body">
                      <h5 className="fs-5 fw-bold">Please note</h5>
                      <p className="fs-5 mt-2">Support Packs can only be booked for new and functional systems, including their components. However, Synology offers Post Warranty Packs for one and two years. Cross-system utilization is not feasible. In such cases, we provide our services under third-party maintenance arrangements.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 text-muted">
          <div className="row">
            <div className="col-lg-6 p-5">
              <img src='images/support-image.jpg' className='h-100' />
            </div>
            <div className="col-lg-6 mt-lg-5 mt-0">
              <h3 className='text-center text-lg-start mb-5 text-capitalize'>Our support packages: as individual as you</h3>
              <Accordian
                  data={accordianData}
                />
            </div>
          </div>
        </div>
      </section>
      <GlobalPresence />
      <JoinCommunity />
      <InsightSlider/>
      <LetsConnect />
    </React.Fragment>

  )
}

export default SupportPacks