import React from 'react'

const MeetOurLeaders = () => {
    return (
        <React.Fragment>
            {/* <section
                className="wrapper home-service-global-container text-white"
                // style={{ backgroundImage: 'url("images/leadership-banner.webp")' }}
            >
                <div className="container py-5 mt-5">
                    <div className="row gx-0 gy-12 align-items-center">
                        <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-6 content text-center text-lg-startcol-md-10 offset-md-1 offset-lg-0 col-lg-6 content text-center text-lg-start">
                            <h1 className="">Meet Our Leaders</h1>
                        </div>
                        <div className="col-lg-5 offset-lg-1 mb-n18 d-none d-sm-block">
                            <div className="position-relative">
                                <figure className="rounded shadow-lg">
                                    <img src="images/leadership-banner.webp" alt="" srcset="" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/leaders-bannner.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Meet Our Leaders</h1>
              {/* <p className="mt-5 text-warning" style={{fontSize:'30px'}}>
              DXC empowers organizations to achieve tangible business results across customer engagement, workforce enablement, and operations optimization
              </p> */}
            </div>
          </div>
        </div>
      </section>
            <section className="wrapper mt-5 p-5 text-muted">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 pt-6 d-flex my-3">
                            <div className="card shadow-lg text-muted h-100 w-100">
                                <div className="card-body text-center align-items-center d-flex flex-column align-items-center d-flex flex-column">
                                    <figure className="w-16 mb-4 my-5">
                                        <img src="images/user-image.png" className='rounded-pill' height={150} width={150} alt="" srcset="" />
                                    </figure>
                                    <h5 className="mb-1">Sameer Bhatia</h5>
                                    <div className="meta mb-2 text-muted fw-bold fs-5">Chief Executive Officer</div>
                                    <nav className="nav social my-3 d-flex justify-content-center">
                                        <a
                                            target="_blank"
                                            aria-label="Linkedin"
                                            // href="https://www.linkedin.com/in/billybath/"
                                        >
                                            <i class="bi bi-linkedin meet-icon text-warning fs-4 mx-2"></i>
                                        </a>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 pt-6 d-flex my-3">
                            <div className="card shadow-lg text-muted h-100 w-100">
                                <div className="card-body text-center align-items-center d-flex flex-column">
                                    <figure className="w-16 mb-4 my-5">
                                        <img src="images/user-image.png" className='rounded-pill' height={150} width={150} alt="" srcset="" />
                                    </figure>
                                    <h5 className="mb-1">Fazal Nadaf</h5>
                                    <div className="meta mb-2 text-muted fw-bold fs-5">Executive Director</div>
                                    <nav className="nav social my-3 d-flex justify-content-center">
                                        <a
                                            target="_blank"
                                            aria-label="Linkedin"
                                            href="https://www.linkedin.com/in/fazalnadaf/"
                                        >
                                            <i class="bi bi-linkedin meet-icon text-warning fs-4 mx-2"></i>
                                        </a>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 pt-6 d-flex my-3">
                            <div className="card shadow-lg text-muted h-100 w-100">
                                <div className="card-body text-center align-items-center d-flex flex-column">
                                    <figure className="w-16 mb-4 my-5">
                                        <img src="images/user-image.png" className='rounded-pill' height={150} width={150} alt="" srcset="" />
                                    </figure>
                                    <h5 className="mb-1">Abdul Rahman</h5>
                                    <div className="meta mb-2 text-muted fw-bold fs-5">Business Operation</div>
                                    <nav className="nav social my-3 d-flex justify-content-center">
                                        <a
                                            target="_blank"
                                            aria-label="Linkedin"
                                            href="https://www.linkedin.com/in/abdul-rahman-05b59b18a/"
                                        >
                                            <i class="bi bi-linkedin meet-icon text-warning fs-4 mx-2"></i>
                                        </a>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 pt-6 d-flex my-3">
                            <div className="card shadow-lg text-muted h-100 w-100">
                                <div className="card-body text-center align-items-center d-flex flex-column">
                                    <figure className="w-16 mb-4 my-5">
                                        <img src="images/user-image.png" className='rounded-pill' height={150} width={150} alt="" srcset="" />
                                    </figure>
                                    <h5 className="mb-1">Devender Singh B</h5>
                                    <div className="meta mb-2 text-muted fw-bold fs-5">Tech Business</div>
                                    <nav className="nav social my-3 d-flex justify-content-center">
                                        <a
                                            target="_blank"
                                            aria-label="Linkedin"
                                            // href="https://www.linkedin.com/in/harpreetdhillon/"
                                        >
                                            <i class="bi bi-linkedin meet-icon text-warning fs-4 mx-2"></i>
                                        </a>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 pt-6 d-flex my-3">
                            <div className="card shadow-lg text-muted h-100 w-100">
                                <div className="card-body text-center align-items-center d-flex flex-column">
                                    <figure className="w-16 mb-4 my-5">
                                        <img src="images/user-image.png" className='rounded-pill' height={150} width={150} alt="" srcset="" />
                                    </figure>
                                    <h5 className="mb-1">Devender Singh Chauhan</h5>
                                    <div className="meta mb-2 text-muted fw-bold fs-5">Network NOC Head</div>
                                    <nav className="nav social my-3 d-flex justify-content-center">
                                        <a
                                            target="_blank"
                                            aria-label="Linkedin"
                                            // href="https://www.linkedin.com/in/omeshsharma/"
                                        >
                                            <i class="bi bi-linkedin meet-icon text-warning fs-4 mx-2"></i>
                                        </a>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 pt-6 d-flex my-3">
                            <div className="card shadow-lg text-muted h-100 w-100">
                                <div className="card-body text-center align-items-center d-flex flex-column">
                                    <figure className="w-16 mb-4 my-5">
                                        <img src="images/user-image.png" className='rounded-pill' height={150} width={150} alt="" srcset="" />
                                    </figure>
                                    <h5 className="mb-1">R. Nadeem</h5>
                                    <div className="meta mb-2 text-muted fw-bold fs-5">Tech Business</div>
                                    <nav className="nav social my-3 d-flex justify-content-center">
                                        <a
                                            target="_blank"
                                            aria-label="Linkedin"
                                            href="https://www.linkedin.com/in/abdul-rahman-05b59b18a/"
                                        >
                                            <i class="bi bi-linkedin meet-icon text-warning fs-4 mx-2"></i>
                                        </a>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 pt-6 d-flex my-3">
                            <div className="card shadow-lg text-muted h-100 w-100">
                                <div className="card-body text-center align-items-center d-flex flex-column">
                                    <figure className="w-16 mb-4 my-5">
                                        <img src="images/user-image.png" className='rounded-pill' height={150} width={150} alt="" srcset="" />
                                    </figure>
                                    <h5 className="mb-1">Arbaz Khan</h5>
                                    <div className="meta mb-2 text-muted fw-bold fs-5">Tech Business</div>
                                    <nav className="nav social my-3 d-flex justify-content-center">
                                        <a
                                            target="_blank"
                                            aria-label="Linkedin"
                                            href="https://www.linkedin.com/in/arbazkhan4595"
                                        >
                                            <i class="bi bi-linkedin meet-icon text-warning fs-4 mx-2"></i>
                                        </a>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default MeetOurLeaders