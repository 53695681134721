import React from "react";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";
import GlobalPresence from "../../Components/GlobalPresence/GlobalPresence";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";

function AboutUs() {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/aboutus-banner.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold text-capitalize'> We're not just a service provider; we're your strategic
                  partner</h1>
              <p className="mt-5 text-warning" style={{fontSize:'30px'}}>
              This Is DataCentrix™
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section>
        <div className="container-fluid mt-5 p-5 text-muted">
          <div className="row mt-2 mt-lg-0">
            <div className="col-lg-4 mt-lg-5">
              <h3 className="px-4 text-capitalize">
                Hard data and statistics
              </h3>
            </div>
            <div className="col-lg-4 mt-lg-5 fs-5">
              <p>
                With over 30 years of expertise, we specialize in data center
                hardware and services. As the leading provider of third-party
                maintenance (TPM) in the D/A/CH region, and part of Evernex,
                we're also the European TPM market leader and a global hardware
                maintenance provider.
              </p>
            </div>
            <div className="col-lg-4 mt-lg-5 fs-5 text-muted">
              <p>
                Despite our growth, we remain true to our origins as a
                medium-sized company, staying close to our customers,
                comprehending their challenges and needs, and maintaining our
                flexibility, simplicity, and effectiveness in our approach.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid bg-light mt-5 p-5 text-muted">
          <div className="row text-center">
            <div className="col-lg-2 my-lg-3 col-md-4">
              <div className="card-body">
                <div className="my-2">
                  <i class="bi bi-globe text-warning fs-2 mx-2"></i>
                </div>
                <div>
                  <h3 className="fs-5 fw-bold">
                    Operating in 80+ countries
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-2 my-lg-3 col-md-4">
              <div className="card-body">
                <div className="my-2">
                  <i class="bi bi-tools text-warning fs-2 mx-2"></i>
                </div>
                <div>
                  <p className="fs-5 fw-bold">
                    850,000+ spare parts
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 my-lg-3 col-md-4">
              <div className="card-body">
                <div className="my-2">
                  <i class="bi bi-building-fill text-warning fs-2 mx-2"></i>
                </div>
                <div>
                  <p className="fs-5 fw-bold">
                    350+ logistics hubs around the world
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 my-lg-3 col-md-4">
              <div className="card-body">
                <div className="my-2">
                  <i class="bi bi-person text-warning fs-2 mx-2"></i>
                </div>
                <div>
                  <p className="fs-5 fw-bold">5,000+ customers</p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 my-lg-3 col-md-4">
              <div className="card-body">
                <div className="my-2">
                  <i class="bi bi-person-arms-up text-warning fs-2 mx-2"></i>
                </div>
                <div>
                  <p className="fs-5 fw-bold">500+ employees</p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 my-lg-3 col-md-4">
              <div className="card-body">
                <div className="my-2">
                  <i class="bi bi-person-walking text-warning fs-2 mx-2"></i>
                </div>
                <div>
                  <p className="fs-5 fw-bold">
                    Approx. 200+ technicians
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid text-muted mt-5 p-5">
          <h3 className="text-center my-2">Services Offered</h3>
          <p className="text-center fs-5 px-5">
            We go beyond monitoring individual hardware components; we oversee
            your entire IT environment. As a multi-vendor service provider, we
            specialize in all major brands, ensuring comprehensive maintenance
            and support for your entire IT landscape, regardless of devices,
            systems, or manufacturers.
          </p>
          <div className="row mt-lg-5 text-center">
            <div className="col-lg-3 my-3 col-md-4">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="my-2 text-center">
                    <i class="bi bi-database text-warning fs-2 mx-2"></i>
                  </div>
                  <div>
                    <h3 className="fs-5 text-muted">
                      Data center maintenance
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 my-3 col-md-4">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="my-2 text-center">
                    <i class="bi bi-cpu-fill text-warning fs-2 mx-2"></i>
                  </div>
                  <div>
                    <h3 className="fs-5 text-muted">
                      IT Hardware & Spare as a Service
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 my-3 col-md-4">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="my-2 text-center">
                    <i class="bi bi-search text-warning fs-2 mx-2"></i>
                  </div>
                  <div>
                    <h3 className="fs-5 text-muted">
                      Value Added Services
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 my-3 col-md-4">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="my-2 text-center">
                    <i class="bi bi-tools text-warning fs-2 mx-2"></i>
                  </div>
                  <div>
                    <h3 className="fs-5 text-muted">Repair Service</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid bg-light mt-5 p-5 text-muted">
          <div className="row mt-5">
            <h3 className="text-center my-2 text-muted text-capitalize">
              We ensure your IT stays operational, allowing you to keep the
              world running.
            </h3>
            <div className="row mt-5">
              <div className="col-lg-6 my-3 col-md-12">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className="my-lg-2 text-center">
                      <i class="bi bi-trophy text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className="text-center my-2 fw-bold">Mission</h5>
                      <p className="fs-5">
                        We deliver top-tier IT services to ensure seamless,
                        cost-effective, and secure operations for your data
                        center and business around the clock.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 my-3 col-md-12">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className="my-2 text-center">
                      <i class="bi bi-eye-fill text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className="text-center my-2 fw-bold">Vision</h5>
                      <p className="fs-5">
                        We specialize in providing independent data center
                        services, meticulously designed to not only optimize
                        cost-efficiency but also champion environmental
                        sustainability.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 text-muted">
          <div className="row">
            <div className="col-lg-6 px-5">
              <img src="/images/iso-management.png" />
            </div>
            <div className="col-lg-6 px-lg-5">
              <h3 className="text-center text-lg-start mt-2 mt-lg-0">Certified Quality</h3>
              <p className="fs-5">
                At Technogroup, we don't just make promises of quality; we
                deliver on them. As one of the pioneering triple-certified
                companies in the TPM market, we set the standard for excellence.
              </p>
              <ul className="list-unstyled">
                <li className="d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">ISO 9001 for Quality Management</span>
                </li>
                <li className="d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">ISO 14001 for Eco-Management</span>
                </li>
                <li className="d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">ISO 27001 for Information Security</span>
                </li>
              </ul>
              <p className="fs-5">
                In addition to being triple-certified in the TPM market, we've
                consistently earned the CrefoZert certificate for many years,
                affirming our outstanding credit rating and commitment to
                financial integrity.
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalPresence />
      <LetsConnect />
    </React.Fragment>
  );
}

export default AboutUs;
