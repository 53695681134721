import React from 'react'

const Slider = (props) => {
    return (
        <React.Fragment>
             <div className={`tab-pane fade show active`} id="tab2-1">
                <div className="accordion accordion-wrapper" id="teamAccordian">
                    <div className="card plain accordion-border-color mb-2 rounded-0 accordion-item border-bottom">
                        <div className="card-header" id="headingOne">
                            <div
                                id="collapseTwo"
                                className={`accordion-collapse show collapse mt-6`}
                                aria-labelledby="headingTwo"
                                data-bs-parent="#teamAccordian"
                            >
                                <div className="accordion-body">
                                    {props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </React.Fragment>
    )
}

export default Slider