import React from 'react'
import TalkToAnExpert from '../../Components/TalkToAnExpert/TalkToAnExpert'
import GlobalBrands from '../../Components/GlobalBrands/GlobalBrands'
import OpportunityEdgeContainer from '../../Components/OpportunityEdgeContainer/OpportunityEdgeContainer'
import GlobalPresence from '../../Components/GlobalPresence/GlobalPresence'
import JoinCommunity from '../../Components/JoinCommunity/JoinCommunity'
import ContactForm from '../../Components/ContactForm/ContactForm'
import LatestInsights from '../../Components/LatestInsights/LatestInsights'
import LetsConnect from '../../Components/LetsConnect/LetsConnect'
import ServiceSlider from '../../Components/ServiceSlider/ServiceSlider'

const EdgeComputingInfrastructure = () => {
    return (
        <React.Fragment>
            <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/edge-computing-bnr.png)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Edge Computing & Infrastructure Solutions for Next-Gen Connectivity</h1>
              <p className="mt-5 text-warning" style={{fontSize:'30px'}}>
              DataCentrix™ Leads In Enabling Major Industries To Achieve Real-time Processing With Edge Infrastructure, Spanning Computing, Storage, And Data Processing. With A Global Workforce Proficient In Building, Maintaining, And Scaling Edge Infrastructure, Including Private 5G, IoT Devices, And Hyperscale Cloud Services
              </p>
            </div>
          </div>
        </div>
      </section>
            <GlobalBrands />
            <section className="edgecomputing-unlockpotential-container wrapper bg-light mt-5 p-5 text-muted">
                <div className="container pt-10">
                    <div className="row gx-md-8 gx-xl-12 gy-10 mb-12 mb-md-14 align-items-center">
                        <div className="col-lg-6 order-lg-2">
                            <div className="card shadow-lg me-lg-6 text-muted my-3 right-cross-card">
                                <div className="card-body p-6">
                                    <div className="d-flex flex-row">
                                        <div>
                                            <h5 className="mb-1 fw-bold">Provision</h5>
                                            <p className="mb-0 fs-5">
                                            DataCentrix™ simplifies and democratizes edge computing through its global presence, expertise, and strategic partnerships with hardware and software solution providers.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card shadow-lg ms-lg-13 mt-6 text-muted  my-3 ms-lg-5 ms-0 right-cross-card">
                                <div className="card-body p-6">
                                    <div className="d-flex flex-row">
                                        <div>
                                            <h5 className="mb-1 fw-bold">Manage</h5>
                                            <p className="mb-0 fs-5">
                                            Recognizing the criticality of edge infrastructure, we offer 24/7 monitoring and rapid response. With a 4-hour SLA, streamlined processes, automation, and visibility tools, we ensure your requirements are met.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card shadow-lg mx-lg-6 mt-6 text-muted  my-3 right-cross-card">
                                <div className="card-body p-6">
                                    <div className="d-flex flex-row">
                                        <div>
                                            <h5 className="mb-1 fw-bold">Scale</h5>
                                            <p className="mb-0 fs-5">
                                            As your use case for edge computing grows in demand, Service
                                                Global's technical and logistics team can work closely with
                                                you to assess, expand, or increase the capacity of
                                                infrastructure without interruption.As your demand for edge computing expands, DataCentrix's technical and logistics team collaborates closely with you to assess, expand, or scale infrastructure seamlessly, ensuring uninterrupted operations.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h3 className="mb-3 text-center text-lg-start mt-3 mt-lg-0">
                                Unlock Potential
                            </h3>
                            <h5 className="mb-2 text-center text-lg-start">
                                Expanding the possibilities with edge computing
                            </h5>
                            <p className='fs-5'>
                            Edge computing represents a transformative shift in the IT landscape, revolutionizing infrastructure deployment and maintenance. Foundational to edge computing, infrastructure facilitates data and application processing at network peripheries. These decentralized edge locations demand adept personnel for upkeep. Infrastructure design must cater to each location's unique demands and workload requirements while enduring challenging environmental conditions.
                            </p>
                            <p className="mb-6 fs-5">
                            Countless organizations face the dilemma of over-provisioning infrastructure or grappling with the complexities and costs of edge infrastructure deployment and maintenance. DataCentrix™ comprehends these challenges intimately and possesses the expertise to construct and sustain a global edge infrastructure. Leveraging our global workforce and robust project management capabilities, we aid organizations in achieving their edge infrastructure objectives.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="edgecomputing-edgedevelopment-container wrapper mt-5 p-5 text-muted">
                    <div className="container-fluid">
                        <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                            <div className="col-lg-6">
                                <figure>
                                    <img src="images/edge-computing-infra.png" height={400} width={400} alt="" />
                                </figure>
                            </div>
                            <div className="col-lg-6 px-lg-5 px-0">
                                <h3 className="mb-3 text-center text-lg-start mt-3 mt-lg-0">Edge Deployment Use Cases</h3>
                                <p className="mb-6 fs-5">
                                DataCentrix's edge computing solutions streamline edge deployment use cases, cutting down on edge infrastructure operational and maintenance (O&M) Opex expenditure.
                                </p>
                                <div className="row gy-3">
                                    <div className="col-lg-6 col-md-12 my-3">
                                    <div className="card shadow-lg h-100 text-muted hover-metic">
                                        <div className="card-body">
                                        <ul className="icon-list bullet-bg bullet-soft-blue mb-0 list-unstyled">
                                            <li className='d-flex flex-row'>
                                                <span>
                                                    <i className="uil uil-check text-warning fs-4 mx-2" />
                                                </span>
                                                <span className='fs-5'>
                                                Program Management & Coverage with 4-hour and Next Business Day (NBD) SLA
                                                </span>
                                            </li>
                                            <li className="mt-3 d-flex flex-row">
                                                <span>
                                                    <i className="uil uil-check text-warning fs-4 mx-2" />
                                                </span>
                                                <span className='fs-5'>
                                                Skilled Global Workforce: Over 6000 Engineers from L1 to L5 Available for Last-Mile Services
                                                </span>
                                            </li>
                                        </ul>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 my-3">
                                    <div className="card shadow-lg h-100 text-muted hover-metic">
                                        <div className="card-body">
                                        <ul className="icon-list bullet-bg bullet-soft-blue mb-0 list-unstyled">
                                            <li className='d-flex flex-row'>
                                                <span>
                                                    <i className="uil uil-check text-warning fs-4 mx-2" />
                                                </span>
                                                <span className='fs-5'>
                                                Global Logistics & Export Compliance: Over 160 Supported Operations for Forward and Reverse Logistics
                                                </span>
                                            </li>
                                            <li className="mt-3 d-flex flex-row">
                                                <span>
                                                    <i className="uil uil-check text-warning fs-4 mx-2" />
                                                </span>
                                                <span className='fs-5'>
                                                Process Management & Analytics Empowered by ML-Assisted Automation and Portals
                                                </span>
                                            </li>
                                        </ul>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
            <section className="edgecomputing-solutions-container text-muted mt-5 p-5">
                <div className="container-fluid py-12 py-md-14">
                    <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                        <div className="col-lg-6 order-lg-2">
                            <div className="row gx-md-5 gy-5 position-relative">
                                <div className="col-5">
                                    <img src="images/challenge1.webp" className='my-2' height={200} width={200} alt="" srcset="" />
                                    <img src="images/challenge2.webp" className='my-2' height={200} width={200} alt="" srcset="" />
                                </div>
                                <div className="col-7">
                                    <img src="images/challenge3.webp" className='my-2' height={200} width={200} alt="" srcset="" />
                                    <img src="images/challenge4.webp" className='my-2' height={200} width={200} alt="" srcset="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 px-lg-5 px-0 mt-3 mt-lg-0">
                            <h3 className="mb-3 text-center text-lg-start">
                            We Bring Solutions To Solve Your Edge Infrastructure Challenges
                            </h3>
                            <p className="fs-5 mb-5">
                            The exponential growth in edge solutions has created a significant skill gap, with the workforce unprepared to manage edge infrastructure complexities. DataCentrix™ acknowledges these challenges and proactively trains its workforce in edge infrastructure technologies to meet global customer demands.
                            </p>
                            <div className="row gy-3">
                                <div className="col-lg-6 col-md-12 my-3">
                                <div className="card shadow-lg h-100 text-muted hover-metic">
                                        <div className="card-body">
                                    <ul className="icon-list bullet-bg bullet-soft-blue mb-0 list-unstyled">
                                        <li className='d-flex flex-row'>
                                            <span>
                                                <i className="uil uil-check text-warning fs-4 mx-2" />
                                            </span>
                                            <span className='fs-5'>5G - Private/Public Network Solutions</span>
                                        </li>
                                        <li className="mt-3 d-flex flex-row">
                                            <span>
                                                <i className="uil uil-check text-warning fs-4 mx-2" />
                                            </span>
                                            <span className='fs-5'>
                                                IOT - Devices, Gateways, Sensors, and Edge Computing
                                            </span>
                                        </li>
                                    </ul>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 my-3">
                                <div className="card shadow-lg h-100 text-muted hover-metic">
                                        <div className="card-body">
                                    <ul className="icon-list bullet-bg bullet-soft-blue mb-0 list-unstyled">
                                        <li className='d-flex flex-row'>
                                            <span>
                                                <i className="uil uil-check text-warning fs-4 mx-2" />
                                            </span>
                                            <span className='fs-5'>
                                                Orchestration &amp; Automation - Kubernetes, Docker, Ansible,
                                                Terraform and more
                                            </span>
                                        </li>
                                        <li className="mt-3 d-flex flex-row">
                                            <span>
                                                <i className="uil uil-check text-warning fs-4 mx-2" />
                                            </span>
                                            <span className='fs-5'>
                                                Monitoring, Security &amp; Analytics - AI/ML, Data Analytics,
                                                and more
                                            </span>
                                        </li>
                                    </ul>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <OpportunityEdgeContainer />
            <GlobalPresence />
            <ServiceSlider/>
            <JoinCommunity />
            <LatestInsights/>
            <LetsConnect/>
        </React.Fragment>
    )
}

export default EdgeComputingInfrastructure