import React from 'react'
import GlobalPresence from '../../Components/GlobalPresence/GlobalPresence'
import JoinCommunity from '../../Components/JoinCommunity/JoinCommunity'
import LetsConnect from '../../Components/LetsConnect/LetsConnect'
import GlobalBrands from '../../Components/GlobalBrands/GlobalBrands'
import InsightSlider from '../../Components/InsightSlider/InsightSlider'

const ITHardware = () => {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/data-center-hardware-banner.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold text-capitalize'>Rent, buy, recycle: cost-effective and intelligent options</h1>
              <p className="mt-5 text-white fs-1 " style={{fontSize:'30px'}}>
              Data Center Hardware
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section>
        <div className="container-fluid p-5 mt-5 text-muted bg-light text-muted">
          <div className="row">
            <div className="col-lg-4 mt-lg-5">
              <h3 className='px-4 text-capitalize'>
                Flexible sourcing: beyond mere new purchases</h3>
            </div>
            <div className="col-lg-4 mt-3 fs-5">
              <p>Today, companies demand three key factors in IT equipment procurement: swift availability, cutting-edge technology, and economic viability. Variable sourcing models prove advantageous in meeting these needs. It doesn't always necessitate purchasing new equipment. Leasing, rentals, and buying refurbished hardware offer flexible options to maintain performance, address seasonal demands, and swiftly adapt to evolving requirements. With our tailored solutions encompassing hardware procurement and suitable financing, we aid in optimizing your IT operations technically and economically. Our IT experts provide flexible options: complete systems, upgrades, or individual components, whether refurbished or new, tailored to your specific needs.</p>
            </div>
            <div className="col-lg-4 mt-lg-3 fs-5">
              <p>If you have short-term procurement needs and seek to minimize financial strain, we're here to guide you through an all-encompassing solution. This includes flexible financing for hardware, software, and services. Moreover, our worldwide Spare Parts as a Service (SPaaS) ensures timely delivery of the right hardware to your location, wherever it may be.</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 text-muted">
          <div className="row py-3">
            <h3 className="text-center text-capitalize">Here are your advantages at a glance</h3>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 my-3">
              <div className="card my-3 shadow-lg text-muted h-100 main-card">
                <div className="text-center mt-2">
                  <i class="bi bi-basket text-warning fs-2 mx-2"></i>
                </div>
                <h5 className="card-title text-center fw-bold mt-4 my-1">Sales</h5>
                <div className="card-body fs-5">
                  We offer refurbished or new hardware in various forms: complete systems, upgrades, or individual spare parts, tailored to your specific requirements.</div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 my-3">
              <div className="card my-3 shadow-lg text-muted h-100 main-card">
                <div className="text-center mt-2">
                  <i class="bi bi-backpack4 text-warning fs-2 mx-2"></i>
                </div>
                <h5 className="card-title text-center fw-bold mt-4 my-1">Rentals</h5>
                <div className="card-body fs-5">
                  For temporary support during order peaks, you can easily rent systems for short periods.
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 my-3">
              <div className="card my-3 shadow-lg text-muted h-100 main-card">
                <div className="text-center mt-2">
                  <i class="bi bi-receipt text-warning fs-2 mx-2"></i>
                </div>
                <h5 className="card-title text-center fw-bold mt-4 my-1">Repurchase & Recycling</h5>
                <div className="card-body fs-5">Whether you're in need of a more efficient system or looking to free up space by disposing of unused hardware, we've got you covered. We purchase and collect your used, still-working IT hardware. You'll receive a portion of your original investment in return based on the remaining value of the hardware.</div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 my-3">
              <div className="card my-3 shadow-lg text-muted h-100 main-card">
                <div className="card-body fs-5 mt-3">
                  <ul className='list'>
                    <li className="list-item d-flex flex-row"><span className=''><i className='uil uil-check text-warning fs-4'></i></span>Refurbished hardware serves as a sustainable alternative for both individual components and complete systems</li>
                    <li className="list-item d-flex flex-row"><span className=''><i className='uil uil-check text-warning fs-4'></i></span>Our IT specialists' extensive expertise ensures first-class quality in all our offerings</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 my-3">
              <div className="card my-3 shadow-lg text-muted h-100 main-card">
                <div className="card-body fs-5  mt-3">
                  <ul className='list lsit-unstyled'>
                    <li className="list-item d-flex flex-row"><span className=''><i className='uil uil-check text-warning fs-4'></i></span>Ensure operational continuity while safeguarding your budget</li>
                    <li className="list-item d-flex flex-row"><span className=''><i className='uil uil-check text-warning fs-4'></i></span>Reliability without the need for significant financial investments</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 my-3">
              <div className="card my-3 shadow-lg text-muted h-100 main-card">
                <div className="card-body fs-5  mt-3">
                  <ul className='list'>
                    <li className="list-item d-flex flex-row"><span className=''><i className='uil uil-check text-warning fs-4'></i></span>Reap a partial return on your initial hardware investment</li>
                    <li className="list-item d-flex flex-row"><span className=''><i className='uil uil-check text-warning fs-4'></i></span>Dispose of unused hardware and reclaim space in your data center</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 text-muted bg-light">
          <div className="row">
            <div className="col-lg-6">
              <h3 className='text-lg-start mt-5 mt-lg-0 text-capitalize'>Hardware sales: Assured quality at equitable terms.</h3>
            </div>
            <div className="col-lg-6 mt-3 mt-lg-0 px-lg-5 px-0">
              <p className='fs-5'>We provide refurbished spare parts or new products tailored to your specific requirements, available as complete systems, upgrades, or individual components. Additionally, we offer maintenance services for servers, storage, and network components, as well as entire systems, along with software installation. Our sustainable spare parts and systems from refurbished hardware offer a quick, environmentally-friendly, and cost-effective alternative to current manufacturer products. Professionally refurbished used hardware ensures high quality and full functionality at significantly reduced costs compared to new products.</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid text-muted mt-5 p-5">
          <h3 className="my-2 text-center text-capitalize">Your hardware purchase benefits</h3>

          <div className="row mt-3">
            <div className="col-lg-3 col-md-6 my-3">
              <div className="card shadow-lg h-100 text-muted hover-metic">
                <div className="card-body">
                  <div className="text-center mt-2">
                    <i class="bi bi-cash text-warning fs-2 mx-2"></i>
                  </div>
                  <p className='fs-5 mt-3 text-center'>Low costs</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 my-3">
              <div className="card shadow-lg h-100 text-muted hover-metic">
                <div className="card-body">
                  <div className="text-center mt-2">
                    <i class="bi bi-patch-check-fill text-warning fs-2 mx-2"></i>
                  </div>
                  <p className='fs-5 mt-3 text-center'>First-class quality</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 my-3">
              <div className="card shadow-lg h-100 text-muted hover-metic">
                <div className="card-body">
                  <div className="text-center mt-2">
                    <i class="bi bi-clock-history text-warning fs-2 mx-2"></i>
                  </div>
                  <p className='fs-5 mt-3 text-center'>Quick availability</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 my-3">
              <div className="card shadow-lg h-100 text-muted hover-metic">
                <div className="card-body">
                  <div className="text-center mt-2">
                    <i class="bi bi-newspaper text-warning fs-2 mx-2"></i>
                  </div>
                  <p className='fs-5 mt-3 text-center'>New goods and licences e.g. from the CISCO and Synology portfolios</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 my-3">
              <div className="card shadow-lg h-100 text-muted hover-metic">
                <div className="card-body">
                  <div className="text-center mt-2">
                    <i class="bi bi-screwdriver text-warning fs-2 mx-2"></i>
                  </div>
                  <p className='fs-5 mt-3 text-center'>Easily adaptable to your corporate structure</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 my-3">
              <div className="card shadow-lg h-100 text-muted hover-metic">
                <div className="card-body">
                  <div className="text-center mt-2">
                    <i class="bi bi-shield-shaded text-warning fs-2 mx-2"></i>
                  </div>
                  <p className='fs-5 mt-3 text-center'>Comprehensive protection for refurbished hardware purchases, including a 30-day guarantee and optional additional hardware maintenance coverage.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 my-3">
              <div className="card shadow-lg h-100 text-muted hover-metic">
                <div className="card-body">
                  <div className="text-center mt-2">
                    <i class="bi bi-tree text-warning fs-2 mx-2"></i>
                  </div>
                  <p className='fs-5 mt-3 text-center'>An eco-friendly and sustainable alternative to new products.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 my-3">
              <div className="card shadow-lg h-100 text-muted hover-metic">
                <div className="card-body">
                  <div className="text-center mt-2">
                    <i class="bi bi-lightbulb text-warning fs-2 mx-2"></i>
                  </div>
                  <p className='fs-5 mt-3 text-center'>Our technicians possess comprehensive expertise and are specially trained and certified.</p>
                </div>
              </div>
            </div>
          </div>


        </div>
      </section>
      <section>
        <div className="container-fluid my-5 p-5 text-muted bg-light">
          <div className="row">
            <div className="col-lg-6">
              <h3 className='text-center text-lg-start mt-5 mt-lg-0 text-capitalize'>Hardware rental: Short-term options with the choice to purchase afterward.</h3>
            </div>
            <div className="col-lg-6 px-lg-5 px-0 mt-3 mt-lg-0">
              <p className='fs-5'>Another established concept in the electronics sector is hardware loaning or renting. Renting hardware is particularly sensible for shorter usage periods or to accommodate additional capacity during peak times. This approach can help reduce costs, as purchasing hardware often entails significant expenses. We handle installation and promptly address any malfunctions, ensuring quick, reliable resolution without complications.</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid my-5 p-5 text-muted">
          <h3 className='text-center text-capitalize'>Advantages of hardware leasing</h3>
          <div className="row my-3">
            <div className="col-lg-3 col-md-6 my-3">
              <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
                  <div className="text-center mt-2">
                    <i class="bi bi-cart-plus text-warning fs-2 mx-2"></i>
                  </div>
                  <p className='fs-5 mt-3'>Potential purchase option available at the end of the rental period.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 my-3">
              <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
                  <div className="text-center mt-2">
                    <i class="bi bi-currency-exchange text-warning fs-2 mx-2"></i>
                  </div>
                  <p className='fs-5 mt-3'>Achievable monthly financial commitments and cost savings through tailored offers and financing options.</p>
                </div></div></div>
            <div className="col-lg-3 col-md-6 my-3">
              <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
                  <div className="text-center mt-2">
                    <i class="bi bi-buildings-fill text-warning fs-2 mx-2"></i>
                  </div>
                  <p className='fs-5 mt-3'>Short-term rental options ranging from 1 to 12 months to accommodate seasonal or order peaks, as well as unexpected performance shortages.</p>
                </div></div></div>
            <div className="col-lg-3 col-md-6 my-3">
              <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
                  <div className="text-center mt-2">
                    <i class="bi bi-arrow-repeat text-warning fs-2 mx-2"></i>
                  </div>
                  <p className='fs-5 mt-3'>Effortlessly change systems with flexibility and short notice.</p></div></div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid p-5 my-5 bg-light text-muted">
          <div className="row">
            <div className="col-lg-4 mt-lg-5">
              <h3 className='px-4 text-center text-lg-start mt-5 mt-lg-0 text-capitalize'>
                Hardware buyback and retrieval service</h3>
            </div>
            <div className="col-lg-4 mt-lg-5 fs-5 mt-3 mt-lg-0">
              <p>There are various reasons for selling hardware. It may be obsolete and no longer meets the company's needs, technical advancements require a change, or the hardware is surplus and occupying valuable space in the data center.</p>
            </div>
            <div className="col-lg-4 mt-lg-5 fs-5">
              <p>With our brokerage service, you can securely resell or recycle your hardware in an environmentally-friendly manner, without any hassle. You'll receive payment for the remaining value of the hardware without having to handle the process yourself. Data protection is our top priority, ensuring your data is irreversibly and securely erased from the hardware.</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid my-5 p-5 text-muted">
          <h3 className='text-center text-capitalize'>Your hardware resale benefits</h3>
          <div className="row my-3">
            <div className="col-lg-4 col-md-6 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="text-center mt-2">
                    <i class="bi bi-motherboard-fill text-warning fs-2 mx-2"></i>
                  </div>
                  <p className='fs-5 mt-3'>Sell outdated hardware swiftly and hassle-free.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="text-center mt-2">
                    <i class="bi bi-bezier text-warning fs-2 mx-2"></i>
                  </div>
                  <p className='fs-5 mt-3'>Reinvest the residual value of your hardware into new hardware or upgrades.</p>
                </div></div></div>
            <div className="col-lg-4 col-md-6 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="text-center mt-2">
                    <i class="bi bi-signpost-split-fill text-warning fs-2 mx-2"></i>
                  </div>
                  <p className='fs-5 mt-3'>Embrace ecological and social responsibility by reusing equipment, safeguarding the climate and budgets.</p>
                </div></div></div>
            <div className="col-lg-4 col-md-6 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="text-center mt-2">
                    <i class="bi bi-wallet text-warning fs-2 mx-2"></i>
                  </div>
                  <p className='fs-5 mt-3'>Offset a portion of your initial hardware investment by investing in upgrades or new infrastructure.</p>
                </div></div></div>
            <div className="col-lg-4 col-md-6 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="text-center mt-2">
                    <i class="bi bi-gear-wide-connected text-warning fs-2 mx-2"></i>
                  </div>
                  <p className='fs-5 mt-3'>Our full-service package includes the collection of your hardware.</p>
                </div></div></div>
            <div className="col-lg-4 col-md-6 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="text-center mt-2">
                    <i class="bi bi-radioactive text-warning fs-2 mx-2"></i>
                  </div>
                  <p className='fs-5 mt-3'>Decrease your CO2 footprint, conserve energy, and recycle raw materials.</p>
                </div></div></div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid my-5 p-5 text-muted">
          <div className="row">
            <div className="col-lg-6">
              <h3 className='text-center text-lg-start mt-5 mt-lg-0 text-capitalize'>Ensuring the correct spare parts are available at the right place and time.</h3>
            </div>
            <div className="col-lg-6 px-lg-5 px-0 mt-3 mt-lg-0">
              <p className='fs-5'>With our international spare parts management system, SPare as a Service™, we guarantee you have the necessary spare parts globally, precisely when and where you need them, ensuring uninterrupted operation of your data centers around the clock. If required, we swiftly deliver the spare part you need from one of our 330 stocking locations worldwide, ensuring your data centers operate seamlessly 24/7.</p>
            </div>
          </div>
        </div>
      </section>
      <GlobalPresence />
      <JoinCommunity />
      <InsightSlider />
      <LetsConnect />
    </React.Fragment>
  )
}

export default ITHardware