import React from "react";
import JoinCommunity from "../../Components/JoinCommunity/JoinCommunity";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";
import GlobalPresence from "../../Components/GlobalPresence/GlobalPresence";
import InsightSlider from "../../Components/InsightSlider/InsightSlider";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";

const DataCenterIMAC = () => {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/data-center-bannner.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold text-capitalize'> Individual services for the lifecycle of your software</h1>
              <p className="mt-5 text-warning text-capitalize" style={{fontSize:'30px'}}>
              IT plays a pivotal role in modern businesses, driving
                  innovation, productivity, and efficiency. From network
                  infrastructure to software solutions, IT empowers
                  organizations to adapt, compete, and thrive in today's dynamic
                  and competitive landscape
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands/>
      <section>
        <div className="container-fluid mt-5 p-5 text-muted bg-light">
          <div className="row">
            <div className="col-lg-4 mt-5 mt-lg-0">
              <h3 className="px-4 text-center text-lg-start text-capitalize">
                Efficient hardware lifecycle management maximizes asset value
                and reliability
              </h3>
            </div>
            <div className="col-lg-4 fs-5">
              <p>
                In a dynamic business environment, staying agile means adapting
                swiftly to evolving technology and customer needs. This demands
                a flexible IT landscape, where upgrades, conversions, and
                decommissions are seamlessly managed to ensure optimal
                performance and efficiency.
              </p>
            </div>
            <div className="col-lg-4 fs-5">
              <p>
                Managing a data center's infrastructure throughout its lifecycle
                is complex but rewarding. Mastery yields agile, uninterrupted
                operations crucial for daily business continuity.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 text-muted">
          <div className="row">
            <div className="col-lg-6 px-5">
              <img src='/images/hardware-assistance.jpeg' className="w-100" />
            </div>
            <div className="col-lg-6 px-5 mt-3">
              <h3 className="text-center text-lg-start mt-5 mt-lg-0 text-capitalize">
                We'll provide comprehensive and tailored assistance
              </h3>
              <p className="fs-5">
                The lifecycle actions for hardware components fall under
                IMAC/R/D (Install, Move, Add, Change, Remove, and Dispose).
                These tasks demand quick responses and implementation from IT
                teams, often requiring extensive resources.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 text-muted bg-light">
          <div className="row my-lg-5">
            <div className="col-lg-6 px-5">
              <h3 className='text-capitalize'>Third-party Maintainance Provider</h3>
              <p className='fs-5'>Utilizing a third-party maintenance (TPM) provider can streamline efforts and reduce costs in your IT department. Our experts offer tailored service chains and adaptable maintenance solutions, minimizing faults and ensuring high data center availability</p>
            </div>
            <div className="col-lg-6 px-5">
              <img src='images/third-party-maintainance.jpeg' />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid text-muted mt-5 p-5">
          <div className="row mt-lg-5">
            <h3 className="text-center my-lg-2 text-capitalize">Continuous services encompass Install, Move, Add, and Change tasks</h3>
            <div className="row mt-lg-5 mt-3">
              <div className="col-lg-3 col-md-6 my-3">
                <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
                  <div className='text-center'>
                    <i class="bi bi-bag text-warning fs-2 mx-2"></i>
                  </div>
                  <div>
                    <h5 className='my-2 text-center fw-bold'>Install</h5>
                    <p className='fs-5'>We'll procure the required hardware and handle its setup, initial installation, and integration into your infrastructure according to your specific needs</p>
                  </div>
                </div>
                </div>

              </div>
              <div className="col-lg-3 col-md-6 my-3">
              <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
                  <div className='text-center'>
                    <i class="bi bi-truck text-warning fs-2 mx-2"></i>
                  </div>
                  <div>
                    <h5 className='my-2 text-center fw-bold'>Move</h5>
                    <p className='fs-5'>We manage relocation services, including project management and implementation. From infrastructure preparation to dismantling and safe transport, we ensure GDPR-compliant relocation. On-site, we integrate and activate hardware efficiently.</p>
                  </div>
                </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 my-3">
              <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
                  <div className='text-center'>
                    <i class="bi bi-plus-circle text-warning fs-2 mx-2"></i>
                  </div>
                  <div>
                    <h5 className='my-2 text-center fw-bold'>Add</h5>
                    <p className='fs-5'>We'll upgrade hardware and install new OS systems as needed. Additionally, we offer support for contract and license adaptations to accommodate changing technical or strategic conditions.</p>
                  </div>
                </div>
              </div>
              </div>
              <div className="col-lg-3 col-md-6 my-3">
              <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
                  <div className='text-center'>
                    <i class="bi bi-arrow-clockwise text-warning fs-2 mx-2"></i>
                  </div>
                  <div>
                    <h5 className='my-2 text-center fw-bold'>Change</h5>
                    <p className='fs-5'>We can also exchange hardware and software and convert it to meet the necessary requirements – including the appropriate configuration adjustments.</p>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 text-muted bg-light">
          <div className="row mt-lg-5">
            <h3 className="text-center my-lg-2 text-capitalize">At the end of the lifecycle, we handle removal and disposal.</h3>
            <div className="row mt-lg-5 mt-3">
              <div className="col-lg-6 col-md-12 my-3">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className='text-center'>
                    <i class="bi bi-folder-minus text-warning fs-2 mx-2"></i>
                  </div>
                  <div>
                    <h5 className='text-center my-2 fw-bold'>Remove</h5>
                    <p className='fs-5'>We provide comprehensive support for recycling or disposing of your used IT hardware, including data migration and uninstallation.</p>
                  </div>
                </div>
                </div>

              </div>
              <div className="col-lg-6 col-md-12 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className='text-center'>
                    <i class="bi bi-trash2 text-warning fs-2 mx-2"></i>
                  </div>
                  <div>
                    <h5 className='text-center my-2 fw-bold'>Disposal</h5>
                    <p className='fs-5'>What happens to decommissioned hardware? We handle proper removal and disposal, either through environmentally friendly recycling or quality-controlled refurbishment, according to your preference.</p>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      <section>
        <div className="container-fluid mt-5 p-5 text-muted">
          <div className="row">
            <h3 className="text-center text-capitalize">Ensuring data security beyond hardware life cycles.</h3>
            <p className='fs-5 text-center mt-2'>We collaborate with certified specialists for secure data disposal, adhering to GDPR and BSI requirements. Our end-to-end, transparent procedures ensure documented and audit-compliant erasure, safeguarding your data beyond equipment life cycles.</p>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 bg-light text-muted">
        <div className="container">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-6 position-relative order-lg-2">
              <div className="shape bg-soft-primary rounded-circle rellax " />
              <img src="images/recycle.jpeg"  className="w-20 h-40"/>
            </div>
            <div className="col-lg-6 px-lg-5 px-0">
              <h3 className="mb-3 text-center text-lg-start mt-5 mt-lg-0 text-capitalize">Sustainability through recycling and refurbishment</h3>
              <p className="fs-5">
                Disused IT hardware holds value beyond its primary use. Components and materials can be recycled or reused, contributing to the circular economy and environmental protection.
              </p>
              <ul className="icon-list bullet-bg bullet-soft-blue mb-0 list-unstyled">
                <li className="d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">Reducing energy consumption and CO2 emissions by minimizing the need for raw material mining, production, and transportation through recycling and reuse.</span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    By avoiding waste incineration or landfill, you prevent contaminants from entering the air and water, contributing to environmental preservation.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 my-3">
              <div className="card shadow-lg text-muted h-100">
              <div className="card-body">
              <h3 className="my-2 text-center">Refurbished Hardware</h3>
              <p className="fs-5">
                Servers, storage solutions, and other equipment sold to us for reuse undergo rigorous functionality checks, cleaning, and repairs as needed. This refurbished, quality-controlled hardware can then be reused as complete systems or individual components, extending its lifespan.
              </p>
              <p className="fs-5">Purchasing refurbished hardware offers data centers an immediately available, environmentally friendly, and up to 50% cheaper alternative compared to new equipment of similar quality.</p>
            </div>
            </div>
            </div>
            <div className="col-lg-6 my-3">
            <div className="card shadow-lg text-muted h-100">
              <div className="card-body">
              <h3 className="my-2 text-center">Recycling</h3>
              <p className="fs-5">
                If equipment or components cannot be reused, valuable materials can still be recovered through proper recycling procedures, reintroducing them into the economic cycle."
              </p>
              <p className="fs-5">
                As part of Evernex, we provide comprehensive service compliant with the WEEE Standard (EU Waste Electrical and Electronic Equipment Directive), partnering with certified disposal experts for proper recycling of raw materials and environmentally-friendly disposal of contaminants.
              </p>
              </div></div>
            </div>
          </div>
        </div>
      </section>
      <GlobalPresence/>
      <JoinCommunity />
      <InsightSlider/>
      <LetsConnect/>
    </React.Fragment>
  );
};

export default DataCenterIMAC;
