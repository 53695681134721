import React, { useState } from "react";
import { contingentStaffing_accordian } from "../../Services/ConstantVariables";
import Accordian from "../../Components/Accordian/Accordian";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";
function ContingentStaffing() {
  const [accordianData, setAccordianData] = useState(
    contingentStaffing_accordian
  );


  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/contingent-staffing.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Contingent Workforce Services</h1>
              <p className="mt-5 text-warning text-capitalize" style={{fontSize:'30px'}}>
              Whether you have dynamic staffing needs, require specialized skills, or need resources for a limited duration, a contingent workforce can provide the flexibility and expertise you require
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section className="wrapper mt-5 p-5 text-muted bg-light">
        <div className="container">
          <div className="row gx-lg-8 gx-xl-12 gy-10 mb-14 mb-md-14 mt-md-15 mt-15 align-items-center">
            <div className="col-lg-6 position-relative">
              <div className="shape bg-soft-primary rounded-circle rellax w-20 h-20" />
              <img src="images/contingent-workforce.jpg" />
            </div>
            <div className="col-lg-6 px-lg-5 px-0">
              <h3 className="mb-3 text-center text-lg-start mt-5 mt-lg-0">Budget-Friendly Staffing</h3>
              <p className="fs-5">
                Contingent Workforce entails recruiting temporary workers to supplement a company's permanent staff. These workers are typically hired for specific projects or seasons, with contracts ranging from weeks to months.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-6 px-lg-5 px-0">
              <h3 className="mb-5 text-center text-lg-start mt-5 mt-lg-0">
                Benefits Of Contingent Workforce
              </h3>
              <div className="row gx-xl-10 gy-6 mt-5">
                <div className="col-md-12 col-lg-12 col-xl-12">
                  <div className="d-flex flex-row">
                    <div>
                      <i class="bi bi-cash-coin text-warning fs-4 mx-3"></i>
                    </div>
                    <div>
                      <h5 className="mb-1 fw-bold">Cost-Efficient Solution</h5>
                      <p className="mb-0 fs-5">
                        Full-time employees receive comprehensive benefits like health insurance, retirement plans, and paid time off, adding to their overall compensation. In contrast, contingent workers, lacking such benefits, offer cost savings to companies while providing flexibility in workforce management.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-12 mt-3">
                  <div className="d-flex flex-row">
                    <div>
                      <i class="bi bi-arrows-fullscreen text-warning fs-4 mx-3"></i>
                    </div>
                    <div>
                      <h5 className="mb-1 fw-bold">Flexibility</h5>
                      <p className="mb-0 fs-5">
                        Contingent workers offer flexibility as their numbers can be easily adjusted to meet business needs. Typically hired for projects, their contracts end with project completion, without company liability. In some cases, companies may opt for "contract-to-hire" agreements, facilitating transition to permanent employment.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 position-relative mt-3 mt-lg-0">
              <div className="shape bg-soft-primary rounded-circle rellax w-20 h-20" />
              <img src="images/work-force.webp" />
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-6 position-relative lg-order-2">
              <div className="shape bg-soft-primary rounded-circle rellax w-20 h-20" />
              <img src="images/workforce-img.jpg" />
            </div>
            <div className="col-lg-6 px-lg-5 px-0">
              <h3 className="mb-3 text-center text-lg-start mt-5 mt-lg-0">Is Contingent Workforce For You?</h3>
              <p className="fs-5 mb-6 text-center text-lg-start">It is a 3 step process</p>
              <Accordian
                data={accordianData}
                // accordianHandler={_accordianHandler}
                // updateFunction={setAccordianData}
              />
            </div>
          </div>
        </div>
      </section>
      <LetsConnect />
    </React.Fragment>
  );
}

export default ContingentStaffing;
