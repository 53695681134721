import React from 'react'
import LongerServiceLife from '../../Components/LongerServiceLife/LongerServiceLife'
import GlobalPresence from '../../Components/GlobalPresence/GlobalPresence'
import JoinCommunity from '../../Components/JoinCommunity/JoinCommunity'
import LetsConnect from '../../Components/LetsConnect/LetsConnect'
import GlobalBrands from '../../Components/GlobalBrands/GlobalBrands'
import InsightSlider from '../../Components/InsightSlider/InsightSlider'

const NetworkMaintenance = () => {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/networking-services-banner.png)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold text-capitalize'>Robust networks offering enhanced mobility and reliability</h1>
              <p className="mt-5 text-warning" style={{fontSize:'30px'}}>
              Networking Services
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands/>
      <section>
        <div className="container-fluid text-muted mt-5 p-5 bg-light">
          <div className="row">
            <div className="col-lg-6 px-lg-4 px-0 mt-lg-3 mt-0">
              <h3 className='text-center text-lg-start text-capitalize'>
              Adaptable hardware maintenance ensuring enduring network security</h3>
            </div>
            <div className="col-lg-6 px-lg-4 px-0 mt-3 mt-lg-0">
              <p className="fs-5 my-lg-2">We ensure reliable, highly secure access to your data across LAN, WAN, or SAN, recognizing their critical role in delivering your applications, processes, and services</p>
              <p className="fs-5">Stay agile in managing your network configurations to adapt to evolving needs. Our professional hardware maintenance services cover everything from minor switches to powerful directors. We support all conventional network products from major brands</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid text-muted mt-5 p-5">
          <div className="row text-center">
            <h3 className="my-lg-2 text-capitalize">Service-level agreements</h3>
            <p className='fs-5'>Customized SLAs for every storage need.</p>
          </div>
          <div className="row mt-5">
            <div className="col-lg-3 col-md-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body fs-5">
                <h5 className="text-center py-2 h5 mt-2 fw-bold">24/7 support</h5>
                  <p>24/7 maintenance and troubleshooting support</p>
                  </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body fs-5">
                <h5 className="text-center py-2 h5 mt-2 fw-bold">Great flexibility</h5>
                  <p>Customized services to suit your individual requirements</p>
                  </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body fs-5">
                <h5 className="text-center py-2 h5 mt-2 fw-bold">Complete maintenance services</h5>
                  <p>We monitor your entire network environment closely</p>
                  </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body fs-5">
                <h5 className="text-center py-2 h5 mt-2 fw-bold">Speedy repairs</h5>
                  <p>Our technicians promptly arrive on site</p>
                  </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body fs-5">
                <h5 className="text-center py-2 h5 mt-2 fw-bold">Clear savings</h5>
                  <p>Save up to 70% compared to OEM support costs</p>
                  </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body fs-5">
                <h5 className="text-center py-2 h5 mt-2 fw-bold">Extended lifespan</h5>
                  <p>Even at the end of its service life, old equipment remains accessible</p>
                  </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body fs-5">
                <h5 className="text-center py-2 h5 mt-2 fw-bold">Cost structure</h5>
                <p>You can easily track your monthly expenses with transparent calculations</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body fs-5">
                <h5 className="text-center py-2 h5 mt-2 fw-bold">Spare parts</h5>
                 <p>We offer 24/7, 365-day replacement for faulty components</p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LongerServiceLife />
      <GlobalPresence />
      <JoinCommunity />
      <InsightSlider/>
      <LetsConnect />
    </React.Fragment>

  )
}

export default NetworkMaintenance