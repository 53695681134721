import React from "react";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";

function RpoServices() {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/rpo-b-img.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Recruitment Process Outsourcing (RPO)</h1>
              <p className="mt-5 text-warning text-capitalize" style={{fontSize:'30px'}}>
              Enhance or substitute your internal recruitment team to attain greater agility and expertise while ensuring swift turnaround times
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section className="wrapper mt-5 p-5 text-muted bg-light">
        <div className="container">
          <div className="row gy-10 gy-sm-13 gx-lg-3 align-items-center mb-10 mb-lg-15 mt-0 mt-lg-8">
            <div className="col-lg-5 position-relative">
              <div
                className="shape bg-line blue rounded-circle rellax w-18 h-18"
                style={{ top: "-2.2rem", left: "-2.4rem" }}
              />
              <div
                className="shape rounded bg-soft-primary rellax d-md-block"
                style={{
                  bottom: "-1.8rem",
                  right: "-1.5rem",
                  width: "85%",
                  height: "90%",
                }}
              />
              <img src='images/rpo-understanding.webp' />
            </div>
            <div className="col-lg-6 col-xl-6 offset-lg-1 px-lg-5 px-0">
              <h2 className="mb-3 text-center text-lg-start mt-5 mt-lg-0">RPO With Understanding</h2>
              <p className="fs-5 mb-6">
                In the face of economic fluctuations and seasonal growth shifts, determining the optimal size of your talent acquisition team becomes challenging. Equally crucial is selecting the right technology stack for recruitment. With over a decade of experience, DataCentrix™ comprehends your needs for bolstering your recruitment team, ensuring they effectively sustain ongoing operations and accommodate expansion demands.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-6 px-lg-5 px-0">
              <h2 className="mb-3 text-center text-lg-start">DataCentrix™ RPO Advantages</h2>
              <p className="fs-5">
                As an RPO provider, we oversee your entire or partial recruitment process. Collaborating closely, we grasp your hiring requirements and craft and implement recruitment strategies to entice top IT Infrastructure talent. Our approach enhances recruitment efficiency through our leading Applicant Tracking System and access to a pertinent candidate pool.
              </p>
            </div>
            <div className="col-lg-6 position-relative mt-5 mt-lg-0">
              <div className="shape bg-soft-primary rounded-circle rellax w-20 h-20" />
              <img src='images/rpo-advantages-img.webp' />
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted bg-light">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-10 col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center">
              <h3 className="mb-10 text-center">Benefits of RPO</h3>
            </div>
          </div>
          <div className="row gx-md-8 gy-8 d-flex mt-5">
            <div className="col-lg-4 col-md-6 my-2">
              <div className="card shadow-lg text-muted main-card">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-piggy-bank-fill text-warning fs-2 mx-2"></i>
                  </div>
                  <h5 className="fw-bold mt-2 text-center text-capitalize">Cost savings</h5>
                  <p className="mb-3 fs-5 mt-3">
                    RPO proves advantageous when your recruitment needs fluctuate. Additionally, our industry expertise and established systems drive efficiency, yielding further cost savings for your organization.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 my-2">
              <div className="card shadow-lg text-muted main-card">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-arrow-down-right text-warning fs-2 mx-2"></i>
                  </div>
                  <h5 className="fw-bold mt-2 text-center text-capitalize">Reduced time to fill</h5>
                  <p className="mb-3 fs-5 mt-3">
                    Benefit from our decade-long industry presence, granting access to an extensive candidate pool. We excel in swiftly filling your open positions, ensuring operational continuity and rapid progress.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 my-2">
              <div className="card shadow-lg text-muted main-card">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-person text-warning fs-2 mx-2"></i>
                  </div>
                  <h5 className="fw-bold mt-2 text-center text-capitalize">Better experience for new hires and hiring managers</h5>
                  <p className="mb-3 fs-5 mt-3">
                    Improve the overall hiring experience for both candidates and hiring managers through our comprehensive RPO program, ensuring a seamless and efficient recruitment process.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 my-2">
              <div className="card shadow-lg text-muted main-card">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-repeat text-warning fs-2 mx-2"></i>
                  </div>
                  <h5 className="fw-bold mt-2 text-center text-capitalize">Compliance with changing regulation</h5>
                  <p className="mb-3 fs-5 mt-3">
                    Stay compliant effortlessly with our RPO program, led by experts who stay updated on changing regulations and labor laws, ensuring your recruitment process meets all necessary legal requirements.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 my-2">
              <div className="card shadow-lg text-muted main-card">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-suitcase-lg text-warning fs-2 mx-2"></i>
                  </div>
                  <h5 className="fw-bold mt-2 text-center text-capitalize">Reporting and benchmarking</h5>
                  <p className="mb-3 fs-5 mt-3">
                    Leverage our RPO program to benchmark your recruitment processes and spending against industry standards, gaining a competitive edge over your competitors with data-driven insights and optimizations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container">
          <div className="row gx-lg-0 gy-10 align-items-center">
            <div className="col-lg-6">
              <div className="row g-6 text-center">
                <div className="col-md-6 ">
                  <div className="row">
                    <div className="col-lg-12">
                      <img src='images/se1.webp' alt='se1' />
                    </div>
                    <div className="col-lg-12">
                      <div className="card mb-md-6 mt-lg-6 shadow-lg text-muted">
                        <div className="card-body">
                          <div className="icon btn btn-circle btn-lg btn-warning pe-none mb-3">
                            <i class="bi bi-display"></i>
                          </div>
                          <h5 className="fw-bold">Project RPO</h5>
                          <p className="mb-2 fs-5">
                            This model offers flexible access to our services, catering specifically to sudden or short-term hiring needs or project-based requirements, ensuring you receive support precisely when and where you need it.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-lg-12 order-md-2">
                      <img src='images/rpo-img1.webp' />
                    </div>
                    <div className="col-lg-12">
                      <div className="card mb-md-6 mt-lg-6 shadow-lg text-muted">
                        <div className="card-body">
                          <div className="icon btn btn-circle btn-lg btn-warning pe-none mb-3">
                            <i class="bi bi-android2"></i>
                          </div>
                          <h5 className="fw-bold">Full RPO</h5>
                          <p className="mb-2 fs-5">
                            In this comprehensive model, you entrust all your IT Infrastructure recruitment requirements to us, ensuring ongoing support to fill any vacant positions related to IT Infrastructure as they arise.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1">
              <h2 className="mb-3 text-center text-lg-start mt-5 mt-lg-0">We Provide Two RPO Models</h2>
              <p className="fs-5">
                If you're unsure which option suits you best, our RPO experts are available for a detailed discussion. They'll assess your needs and constraints, providing a recommendation. This consultation is free of charge and obligation.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center">
              <h3 className="mb-10">RPO Cost</h3>
            </div>
          </div>
          <div className="row gx-lg-8 gx-xl-12 gy-6 px-xl-5 mt-5">
            <div className="col-lg-4 my-2 my-lg-0">
              <div className="d-flex flex-row">
                <div>
                  <div className="icon btn btn-circle pe-none btn-warning me-4">
                    <i class="bi bi-currency-dollar"></i>
                  </div>
                </div>
                <div>
                  <h5 className="fw-bold">Cost-Per-Hire</h5>
                  <p className="mb-2 fs-5">
                    Under our RPO program, you pay a fixed fee for each successful hire made.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 my-2 my-lg-0">
              <div className="d-flex flex-row">
                <div>
                  <div className="icon btn btn-circle pe-none btn-warning me-4">
                    <i class="bi bi-person-exclamation"></i>
                  </div>
                </div>
                <div>
                  <h5 className="fw-bold">Cost-Per-Resource</h5>
                  <p className="mb-2 fs-5">
                    With our RPO program, you pay a monthly fee for each candidate hired for a specified period, rather than an upfront fixed cost.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 my-2 my-lg-0">
              <div className="d-flex flex-row">
                <div>
                  <div className="icon btn btn-circle pe-none btn-warning me-4">
                    <i class="bi bi-card-checklist"></i>
                  </div>
                </div>
                <div>
                  <h5 className="fw-bold">Hybrid</h5>
                  <p className="mb-2 fs-5">
                    In this hybrid structure, you pay both a fixed fee for each candidate hired and additional fees for ancillary services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LetsConnect />
    </React.Fragment>
  );
}

export default RpoServices;
