export const HOME = '/';
export const DATA_CENTER_INFRASTRUCTURE = 'data-center-infrastructure';
export const ON_PREMISE_INFRASTRUCTURE = 'on-premise-infrastructure';
export const CLOUD_INFRASTRUCTURE = 'cloud-infrastructure';
export const SMART_HANDS_SERVICE = 'smart-hands-service';
export const TALENT_MARKETPLACE = 'talent-marketplace';
export const DIGITAL_INFRASTRUCTURE = 'digital-infrastructure';
export const DIGITAL_WORKPLACE = 'digital-workplace';
export const EDGE_COMPUTING_INFRASTRUCTURE='edge-computing-infrastructure';
export const CONNECTIVITY_SERVICES='connectivity-service';
export const NETWORK_OPERATIONS='network-operations';
export const APPLICATION_DEVELOPMENT='application-development';
export const ENTERPRISE_INTEGRATION_SERVICES='enterprise-integration-services';
export const SALESFORCE='salesforce';
export const TECHNOLOGY_PARTNERS='technology-partners';
export const CONTACT_US='contact-us';
export const COMPANY_OVERVIEW='company-overview';
export const MODERN_DEVICE_MANAGEMENT='modern-device-management';
export const WORK_AS_SERVICE='work-as-service';
export const INTELLIGENT_SERVICE_DESK='intelligent-service-desk';
export const WORK_MANAGEMENT='work-management';
export const RECRUITER_ON_DEMAND='recruiter-on-demand';
export const DIVERSITY_RECRUITING='diversity-recruiting';
export const MSP_STAFFING='msp-staffing';
export const CONTINGENT_STAFFING='contingent-staffing';
export const RPO_SERVICES='rpo-services';
export const EMPLOYER_OF_RECORD='employer-of-record';
export const MANAGEMENT_TEAM='management-team';
export const DIGITAL_TRANSFORMATION='digital-transformation';
export const GLOBAL_WORKFORCE='global-workforce';
export const GLOBAL_PROJECT_MANAGEMENT='global-project-management';



export const DATA_CENTER_MAINTENANCE='data-center-maintenance';
export const VALUE_ADDED_SERVICES='value-added-services';
export const IT_HARDWARE='it-hardware';
export const REPAIR_SERVICES='repair-services';
export const ABOUT_US='about-us';
export const MANUFACTURERS='manufacturers';
export const SERVER_MAINTENANCE='server-maintenance';
export const STORAGE_MAINTENENCE='storage-maintenance';
export const LIBRARY_MAINTENANCE='library-maintenance';
export const NETWORK_MAINTENANCE='network-maintenance';
export const END_OF_SERVICE_LIFE='end-of-service-life';
export const WARRANTY_CHECK='warranty-check';
export const WARRANTY_EXTENSIONS='warranty-extensions';
export const SUPPORT_PACKS='support-packs';
export const PROACTIVE_IT_MONITORING='proactive-it-monitoring';
export const DATA_CENTER_IMAC='data-center-imac';
export const FLOOR_AND_SUPPORT_SERVICES='floor-and-support-services';
export const DATA_CENTER_BACKUP_SERVICE='data-center-backup-service';
export const NETWORK_SERVICES='network-services';
export const FINANCING_SOLUTIONS='financing-solutions';
export const HARDWARE_SALES='hardware-sales';
export const RENTING_HARDWARE='renting-hardware';
export const HARDWARE_REPURCHASE='hardware-repurchase';
export const SPARE_AS_SERVICE='spare-as-service';
export const ELECTROTECHNOLOGY='electrotechnology';
export const MEDICAL_TECHNOLOGY='medical-technology';
export const FACTS_AND_FIGURES='facts-and-figures';
export const MISSION_AND_VISSION='mission-and-vision';
export const LOCATIONS='locations';
export const CERTIFIED_QUALITY='certified-quality';
export const ABOUT_MANAGEMENT_TEAM='about-management-team';
