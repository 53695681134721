import React from "react";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";

function DigitalWorkplace() {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/digital-work-bannner.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold text-capitalize'>Empower Your Workforce with Digital Workplace Solutions</h1>
              <p className="mt-5 text-warning text-capitalize" style={{fontSize:'30px'}}>
              digital solutions
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper p-5 text-muted" style={{marginTop:'130px'}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <h2 className="mb-5 text-center text-lg-start">Our Strategy</h2>
              <p className="fs-5">
                The post-pandemic landscape has spurred unparalleled recalibration of Digital Workplace Services. Enterprises, catalyzed by the pandemic, are redefining workplace models with employee-friendly options. This overhaul encompasses tools, processes, security, and governance mechanisms.
              </p>
              <p className="mb-6 fs-5">
                Our Digital Workplace strategy centers on experience, security, and support. Initially prompted by the pandemic, these pillars have now become essential for sustaining business in remote or work-from-home environments.
              </p>
            </div>
            <div className="col-lg-6">
              <div className="card shadow-lg me-lg-6 mt-6 border-0 mb-3 text-muted right-cross-card">
                <div className="card-body p-6">
                  <div className="d-flex flex-row">
                    <div>
                      <h5 className="mb-1 fw-bold">User Experience</h5>
                      <p className="mb-0 fs-5 mt-2">
                        In a competitive job market and remote work environment, technology designed with user experience in mind becomes a key differentiator.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card shadow-lg ms-lg-13 mt-6 border-0 mb-3 ms-lg-4 text-muted right-cross-card">
                <div className="card-body p-6">
                  <div className="d-flex flex-row">
                    <div>
                      <h5 className="mb-1 fw-bold">IT Security</h5>
                      <p className="mb-0 fs-5 mt-2">
                        An updated centralized security policy is essential to address the needs of a distributed work environment.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card shadow-lg mx-lg-6 border-0 mb-3 text-muted right-cross-card">
                <div className="card-body p-6">
                  <div className="d-flex flex-row">
                    <div>
                      <h5 className="mb-1 fw-bold">Seamless Support</h5>
                      <p className="mb-0 fs-5 mt-2">
                        Enterprise and employee IT devices, such as laptops and servers, necessitate uninterrupted support regardless of location.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-12">
              <h2 className="text-capitalize mb-3">
                What We Do?
              </h2>
              <h3 className="fs-5">
                Our strategy underpins three core services
              </h3>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-6 col-lg-4 mt-2">
              <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
              <div className="text-center">
                {" "}
                <i className="uil uil-phone-volume text-warning fs-1 mx-2" />{" "}
              </div>
              <h5 className="my-2 text-center fw-bold">Cutting-Edge Device Management</h5>
              <p className="mb-3 mt-2 fs-5">
                We provide comprehensive device management, from procurement to asset disposition, with top-tier data wipe certification. Our managed device catalog includes those utilized in data centers, by end users, office premises, lab facilities, and more.
              </p>
              </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mt-2">
            <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
              <div className="text-center">
                {" "}
                <i className="uil uil-shield-exclamation text-warning fs-1 mx-2" />{" "}
              </div>
              <h5 className="my-2 text-center fw-bold">Workplace-as-a-Service (WaaS)</h5>
              <p className="mb-3 mt-2 fs-5">
                Our Workplace-as-a-Service (WaaS) solution combines cutting-edge technology with best-in-class support services. This includes deskside support, remote monitoring and management of devices for issue resolution, last mile operations through field services, and eliminating communication barriers.
              </p>
              </div></div>
            </div>
            <div className="col-md-6 col-lg-4 mt-2">
            <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
              <div className="text-center">
                {" "}
                <i className="uil uil-laptop-cloud text-warning fs-1 mx-2" />{" "}
              </div>
              <h5 className="my-2 text-center fw-bold">Smart Service Desk Solution</h5>
              <p className="mb-3 mt-2 fs-5">
                Our responsive and agile 24/7 customer service helpdesk handles ticket case management and dispatches technical and logistics services globally. On-Demand Smart Hands technicians are stationed in local regions to execute last-mile tasks efficiently.
              </p>
              </div></div>
            </div>
          </div>
        </div>
      </section>
      <LetsConnect />
    </React.Fragment>
  );
}

export default DigitalWorkplace;
