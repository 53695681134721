import React, { useState } from 'react'
import ContactForm from '../../Components/ContactForm/ContactForm'
import Footer from '../../Components/Footer/Footer'
import ReviewSlider from '../../Components/ReviewsSlider/ReviewSlider';
import GlobalPresence from '../../Components/GlobalPresence/GlobalPresence'
import GlobalBrands from '../../Components/GlobalBrands/GlobalBrands'
import JoinCommunity from '../../Components/JoinCommunity/JoinCommunity';
import LatestInsights from '../../Components/LatestInsights/LatestInsights';
import LetsConnect from '../../Components/LetsConnect/LetsConnect';
import Accordian from '../../Components/Accordian/Accordian';
import { home_accordian } from '../../Services/ConstantVariables';
import WhoAreWe from '../../Components/WhoAreWe/WhoAreWe';
import ServiceSlider from '../../Components/ServiceSlider/ServiceSlider';
import { SERVER_MAINTENANCE, STORAGE_MAINTENENCE, DATA_CENTER_MAINTENANCE, DATA_CENTER_IMAC, SMART_HANDS_SERVICE, NETWORK_MAINTENANCE, IT_HARDWARE, GLOBAL_PROJECT_MANAGEMENT, TALENT_MARKETPLACE, CLOUD_INFRASTRUCTURE } from '../../Services/ConstantUrls';
import { useNavigate } from 'react-router-dom';

function Home() {
  const [accordianData, setAccordianData] = useState(home_accordian);
  const navigate = useNavigate();
  
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/homepage-banner.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Count On Us As Your Trusted Partner For Driving Digital Transformation, Enabling Global Expansion, Achieving Breakthrough Results, and Accelerating Innovation</h1>
              <p className="mt-5 text-warning" style={{fontSize:'30px'}}>
              DataCentrix™ Empowers Organizations To Achieve Tangible Business Results Across Customer Engagement, Workforce Enablement, and Operations Optimization
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section className="home-project-management-container wrapper mb-18">
        <div className="container">
          <div className="row mb-5">
            <div className="col-xl-7 col-md-12 d-flex imgFullHeight rounded mb-lg-0 mb-4">
              <img src="images/project-management.webp" alt="" srcset="" />
            </div>
            <div className="col-xl-5 col-md-12 d-flex">
              <div className="card">
                <div className="card-body">
                  <div className="post-header">
                    <h2 className="post-title mb-3">
                      <a className="text-decoration-none text-muted" href="/global-project-management/">
                        Project Management Cloud Solution
                      </a>
                    </h2>
                  </div>
                  <div className="fs-5 text-muted">
                    <p>
                    Our next-generation online project management platform
                      offers customized workflows, diverse work views for
                      functional teams, real-time collaboration, and robust
                      reporting. With features like project templates, milestone
                      planning, digital SOPs, and billing, teams can work from
                      anywhere and track results efficiently.
                    </p>
                    <a class="read_more hover link-blue text-decoration-none text-muted" href="#" onClick={() => {navigate(GLOBAL_PROJECT_MANAGEMENT)}}>
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-5 mb-5">
            <div className="col-xl-7 col-md-12 order-xl-2 d-flex imgFullHeight rounded mb-lg-0 mb-4">
              <img src="images/cloud-talent.jpg" alt="" srcset="" />
            </div>
            <div class="col-xl-5 col-md-12 d-flex">
              <div class="card">
                <div class="card-body">
                  <div class="post-header">
                    <h2 class="post-title mb-3">
                      <a className='text-decoration-none text-muted' href="#">Cloud Talent Management Solution</a>
                    </h2>
                  </div>
                  <div class="fs-5 text-muted">
                    <p>
                    Introducing Our Intelligent Online Workforce Management &
                      Global Marketplace Platform: Access 300,000+ Skilled
                      Resources, Anytime, Anywhere! Explore expertise in Cloud,
                      Digital Transformation, AI/Automation, and more. Always
                      on, real-time collaboration, quality assured.
                    </p>
                    <a class="read_more hover link-blue text-decoration-none text-muted" href="#" onClick={() => {navigate(TALENT_MARKETPLACE)}}>
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-5 mb-5">
            <div className="col-xl-7 col-md-12 d-flex imgFullHeight rounded mb-lg-0 mb-4">
              <img src="images/cloud-management.png" alt="" srcset="" />
            </div>

            <div class="col-xl-5 col-md-12 d-flex">
              <div class="card">
                <div class="card-body">
                  <div class="post-header">
                    <h2 class="post-title mb-3">
                      <a className='text-decoration-none text-muted' href="#">Cloud Work Management Solution</a>
                    </h2>
                  </div>
                  <div class="fs-5 text-muted">
                    <p>
                    Our innovative work management platform seamlessly
                      connects customers, workers, and CX teams worldwide for
                      real-time collaboration, enabling last-mile support
                      anywhere, anytime. With an event-driven operational
                      framework, we empower clients seeking start-to-finish
                      solutions. Supported by our on-demand network of 6000+
                      technical engineers and 600+ warehouses across 160+
                      countries, we deliver comprehensive services efficiently
                      and effectively, ensuring satisfaction and success for all
                      stakeholders involved.
                    </p>
                    <a class="read_more hover link-blue text-decoration-none text-muted" href="#" onClick={() => {navigate(CLOUD_INFRASTRUCTURE)}}>
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <h3 className="text-center fw-bold">Explore Our Hardware Support Services</h3>
        <div className="row mt-5">
          <div className="col-lg-3 col-md-6 my-3" onClick={() => {navigate(SERVER_MAINTENANCE)}}>
            <div className="card text-white fw-bold h-80 position-relative explore-card" >
              <img src="images/server-maintenance-image.jpeg" alt="" srcset="" className='card-img h-100'/>
              <div className='position-absolute top-50 start-50 translate-middle'>
              <h3 className="px-3 fw-bold">Server Maintenance</h3>
              <div className='mt-3'>
              <button className="btn btn-warning btn-lg-lg btn-md-sm text-white fw-bold">Learn more <span><i class="bi bi-arrow-right-short"></i></span></button>
              </div>
              </div>
              <div className="card-shadow"></div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 my-3" onClick={() => {navigate(DATA_CENTER_MAINTENANCE)}}>
          <div className="card text-white fw-bold h-80 position-relative explore-card">
              <img src="images/data-center-services-image.png" alt="" srcset="" className='card-img  h-100'/>
              <div className='position-absolute top-50 start-50 translate-middle'>
              <h3 className="px-3 fw-bold">Data Center Services</h3>
              <div className='mt-3'>
              <button className="btn btn-warning btn-lg-lg btn-md-sm text-white fw-bold">Learn more <span><i class="bi bi-arrow-right-short"></i></span></button>
            </div>
            </div>
            <div className="card-shadow"></div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 my-3" onClick={() => {navigate(STORAGE_MAINTENENCE)}}>
          <div className="card text-white fw-bold h-80 position-relative explore-card">
              <img src="images/storage-maintenance-image.png" alt="" srcset="" className='card-img  h-100'/>
              <div className='position-absolute top-50 start-50 translate-middle'>
              <h3 className="px-3  fw-bold">Storage Maintenance</h3>
              <div className='mt-3'>
              <button className="btn btn-warning btn-lg-lg btn-md-sm text-white fw-bold">Learn more <span><i class="bi bi-arrow-right-short"></i></span></button>
            </div>
            </div>
            <div className="card-shadow"></div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 my-3" onClick={() => {navigate(DATA_CENTER_IMAC)}}>
          <div className="card text-white fw-bold h-80 position-relative explore-card">
              <img src="images/itad-service-image.png" alt="" srcset="" className='card-img h-100'/>
              <div className='position-absolute top-50 start-50 translate-middle'>
              <h3 className="px-3 fw-bold">ITAD Services</h3>
              <div className='mt-3'>
              <button className="btn btn-warning btn-lg-lg btn-md-sm text-white fw-bold">Learn more <span><i class="bi bi-arrow-right-short"></i></span></button>
            </div>
            </div>
            <div className="card-shadow"></div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 my-3" onClick={() => {navigate(SMART_HANDS_SERVICE)}}>
          <div className="card text-white fw-bold h-80 position-relative explore-card">
              <img src="images/smart-hands-image.png" alt="" srcset="" className='card-img h-100'/>
              <div className='position-absolute top-50 start-50 translate-middle'>
              <h3 className="px-3 fw-bold">Smart Hands</h3>
              <div className='mt-3'>
              <button className="btn btn-warning btn-lg-lg btn-md-sm text-white fw-bold">Learn more <span><i class="bi bi-arrow-right-short"></i></span></button>
            </div>
            </div>
            <div className="card-shadow"></div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 my-3" onClick={() => {navigate(NETWORK_MAINTENANCE)}}>
          <div className="card text-white fw-bold h-80 position-relative explore-card">
              <img src="images/network-maintenance-image.png" alt="" srcset="" className='card-img h-100'/>
              <div className='position-absolute top-50 start-50 translate-middle'>
              <h3 className="px-3 fw-bold">Network Maintenance</h3>
              <div className='mt-3'>
              <button className="btn btn-warning btn-lg-lg btn-md-sm text-white fw-bold">Learn more <span><i class="bi bi-arrow-right-short"></i></span></button>
            </div>
            </div>
            <div className="card-shadow"></div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 my-3" onClick={() => {navigate(DATA_CENTER_MAINTENANCE)}}>
          <div className="card text-white fw-bold h-80 position-relative explore-card">
              <img src="images/third-party-image.png" alt="" srcset="" className='card-img h-100'/>
              <div className='position-absolute top-50 start-50 translate-middle'>
              <h3 className="px-3 fw-bold">Third Party Maintenance</h3>
              <div className='mt-3'>
              <button className="btn btn-warning btn-lg-lg btn-md-sm text-white fw-bold">Learn more <span><i class="bi bi-arrow-right-short"></i></span></button>
            </div>
            </div>
            <div className="card-shadow"></div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 my-3" onClick={() => {navigate(IT_HARDWARE)}}>
          <div className="card text-white fw-bold h-80 position-relative explore-card">
              <img src="images/hardware-sales-image.png" alt="" srcset="" className='card-img h-100'/>
              <div className='position-absolute top-50 start-50 translate-middle'>
              <h3 className="px-3 fw-bold">Hardware Sales</h3>
              <div className='mt-3'>
              <button className="btn btn-warning btn-lg-lg btn-md-sm text-white fw-bold">Learn more <span><i class="bi bi-arrow-right-short"></i></span></button>
            </div>
            </div>
            <div className="card-shadow"></div>
            </div>
          </div>
        </div>
      </section>
      <WhoAreWe />
      <section className="home_what_we_do_container wrapper pt-5 angled lower-start what-we-do-bg-color">
        <div className="container py-5">
          <div className="pt-10">
            <div className="row text-center">
              <div className="col-lg-12 col-xl-10 mx-auto">
                <h2 className="fs-16 text-uppercase text-muted mb-3 what-we-do-font">
                  What We Do?
                </h2>
                <h3 className="sub_title mb-5 px-xxl-10 what-er-do-inner-text text-muted">
                We Approach Every Challenge With Unwavering Confidence and Determination, Ready To Conquer Even The Most Formidable Obstacles
                </h3>
              </div>
            </div>

            <div className="row  pb-10">
              <div className="col-md-6 mb-5 col-lg-4">
                <div className="work-card-box card h-100">
                  <div className="rounded m-2 text-center overflow-hidden overflow-hidden">
                    <img src="images/edge-computing.webp" alt="" srcset="" />
                  </div>
                  <div class="card-body p-4 pt-2 work-inner">
                    <div class="post-header">
                      <h2 class="post-title h3 mt-0 mb-3 text-muted">Edge Computing</h2>
                    </div>
                    <div class="fs-5 text-muted">
                      <p class="mb-0">
                        Our last-mile infrastructure operations, emerging
                        technologies skills, process automation, and
                        comprehensive partner ecosystem accelerate innovation
                        and empower progress at scale.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-5 col-lg-4 d-flex hover-scale">
                <div className="work-card-box card h-100">
                  <div className="rounded m-2 text-center overflow-hidden">
                    <img src="images/digital-workplace.webp" alt="" srcset="" />
                  </div>
                  <div class="card-body p-4 pt-2 work-inner">
                    <div class="post-header">
                      <h2 class="post-title h3 mt-0 mb-3 text-muted">Digital Workplace</h2>
                    </div>
                    <div class="fs-5 text-muted">
                      <p class="mb-0">
                      Embracing and integrating new technologies into our
                        services has revolutionized our business operations.
                        Through continuous innovation, we guarantee an
                        unparalleled experience.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-5 col-lg-4 d-flex hover-scale">
                <div className="work-card-box card h-100">
                  <div className="rounded m-2 text-center overflow-hidden">
                    <img
                      src="images/digital-transformation.webp"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="card-body p-4 pt-2 work-inner">
                    <div class="post-header">
                      <h2 class="post-title h3 mt-0 mb-3 text-muted">
                        Digital Transformation
                      </h2>
                    </div>
                    <div class="fs-5 text-muted">
                      <p class="mb-0">
                      We embark on a transformative journey with our partners,
                        guiding them to reshape their business, technology, and
                        operational frameworks for success in the digital age,
                        navigating and mitigating risks.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-5 col-lg-4 d-flex hover-scale">
                <div className="work-card-box card h-100">
                  <div className="rounded m-2 text-center overflow-hidden">
                    <img
                      src="images/work-management (1).webp"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="card-body p-4 pt-2 work-inner">
                    <div class="post-header">
                      <h2 class="post-title h3 mt-0 mb-3 text-muted">Work Management</h2>
                    </div>
                    <div class="fs-5 text-muted">
                      <p class="mb-0">
                      The Service Desk platform seamlessly aligns projected
                        work demand with the optimal talent supply at every
                        stage of the business lifecycle. This ensures
                        sustainable growth & boosts productivity.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-5 col-lg-4 d-flex hover-scale">
                <div className="work-card-box card h-100">
                  <div className="rounded m-2 text-center overflow-hidden">
                    <img
                      src="images/project-management (1).webp"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="card-body p-4 pt-2 work-inner">
                    <div class="post-header">
                      <h2 class="post-title h3 mt-0 mb-3 text-muted">
                        Project Management
                      </h2>
                    </div>
                    <div class="fs-5 text-muted">
                      <p class="mb-0">
                      Transform your PMO for large projects with our
                        innovative platform and hybrid expertise delivery,
                        blending onsite and remote support seamlessly.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-lg-5 col-lg-4 d-flex hover-scale">
                <div className="work-card-box card h-100">
                  <div className="rounded m-2 text-center overflow-hidden">
                    <img
                      src="images/strategic-resourcing.webp"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="card-body p-4 pt-2 work-inner">
                    <div class="post-header">
                      <h2 class="post-title h3 mt-0 mb-3 text-muted">
                        Strategic Resourcing
                      </h2>
                    </div>
                    <div class="fs-5 text-muted">
                      <p class="mb-0">
                      We accelerate contingent talent acquisition, ensuring
                        end-to-end management, compliance, and cost savings,
                        aligning closely with partners to meet their resourcing
                        needs efficiently.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='home-reasons-container wrapper bg-light'>
        <div className='container py-5'>
          <div>
            <div className='row gy-10 gy-sm-13 gx-lg-3 align-items-center'>
              <div className='col-lg-6 position-relative order-lg-2 offset-lg-1 d-none d-md-block'>
                <div className='row gx-md-5 gy-5'>
                  <img src='images/why-choose-us.jpg' />
                </div>
              </div>
              <div className="col-lg-5">
                <h2 className="fs-16 text-uppercase mb-3 why-choose-us-heading text-muted">
                  WHY CHOOSE US?
                </h2>
                <h3 class="display-4 mb-5 why-choose-us-inner-heading text-muted">
                A Few Reasons Why Our Valued Customers Choose Us.
                </h3>
                <Accordian
                  data={accordianData}
                  // accordianHandler={_accordianHandler}
                  // updateFunction={setAccordianData}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <GlobalPresence />
      <ServiceSlider/>
      <JoinCommunity />
      <LatestInsights />
      <LetsConnect />
    </React.Fragment>
  );
}

export default Home;
