import React, { useState } from "react";
import { mspStaffing_accordian } from "../../Services/ConstantVariables";
import Accordian from "../../Components/Accordian/Accordian";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";
function MspStaffing() {
  const [accordianData, setAccordianData] = useState(mspStaffing_accordian);


  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{ background: `url(images/msp-staffing-banner-image.webp)`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center bottom' }}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Msp Staffing</h1>
              <p className="mt-5 text-warning text-capitalize" style={{ fontSize: '30px' }}>
                Discover how you can streamline the management of all your
                contingent staffing providers through a single service provider
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section className="wrapper mt-5 p-5 text-muted bg-light">
        <div className="container">
          <div className="row gx-lg-8 gx-xl-12 gy-10 mb-14 mb-md-14 mt-md-10 mt-3 align-items-center">
            <div className="col-lg-6 position-relative">
              <div className="shape bg-soft-primary rounded-circle rellax w-20 h-20" />
              <img src="images/msp-staff.jpeg" />
            </div>
            <div className="col-lg-6 px-lg-5 px-0">
              <h2 className="mb-3 mt-5 mt-lg-0 text-center text-lg-start">Msp Staffing</h2>
              <p className="fs-5">
                Efficient supply chain management of the contingent workforce is
                crucial for the success of contingent hiring programs. Staffing
                vendors with varying capabilities, independent contractors,
                freelancers - each demands a tailored approach, yet all must be
                managed through a unified interface. Our Managed Staffing Services
                are designed to assist with this challenge.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-6 position-relative order-lg-2">
              <div className="shape bg-soft-primary rounded-circle rellax w-20 h-20" />
              <img src="images/flexible-approach-img.jpg" />
            </div>
            <div className="col-lg-6 px-lg-5 px-0">
              <h2 className="mb-3 mt-5 mt-lg-0 text-center text-lg-start">Flexible Approach</h2>
              <p className="fs-5">
                Our managed staffing services encompass the entire contingent
                worker lifecycle, from requisition and sourcing to invoicing and
                payment. We handle independent contractors, freelancers,
                staffing vendors, and permanent employees based on your desired
                scope. Our Vendor Management System (VMS) provides visibility
                into the status of each requirement and candidate. You can opt
                to include staffing vendors of your choice or use our pool, or a
                combination of both.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted bg-light">
        <div className="container">
          <div className="row gy-10 gy-sm-13 gx-lg-8 align-items-center">
            <div className="col-lg-7">
              <div className="row gx-md-5 gy-5">
                <div className="col-md-4 offset-md-1 align-self-end">
                  <img src="images/g1.webp" />
                </div>
                <div className="col-md-6 align-self-end d-none d-md-block">
                  <img src="images/g2.webp" />
                </div>
                <div className="col-md-6 offset-md-1 d-none d-md-block">
                  <img src="images/g3.webp" />
                </div>
                <div className="col-md-4 align-self-start d-none d-md-block">
                  <img src="images/g4.webp" />
                </div>
              </div>
            </div>
            <div className="col-lg-5 mb-7 mb-md-0">
              <h3 className="mb-7 mt-5 mt-lg-0 text-center text-lg-start text-capitalize">
                A few advantages of staffing services
              </h3>
              <Accordian
                data={accordianData}
              // accordianHandler={_accordianHandler}
              // updateFunction={setAccordianData}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="mb-9 mt-5 mt-lg-0 text-center text-capitalize">
                Is (MSP) Staffing Services the right choice for your
                organization?
              </h3>
            </div>
          </div>
          <div className="row gx-md-8 gy-8 mt-5">
            <div className="col-md-6 col-lg-4 my-2">
              <div className="card shadow-lh h-100 text-muted px-1">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-clipboard2-data-fill text-warning fs-2 mx-2"></i>
                  </div>
                  <h5 className="text-capitalize fw-bold text-center mt-1">Enough volume of contingent spend</h5>
                  <p className="mb-3 fs-5">
                    If you have a significant volume of contingent spend in IT
                    infrastructure that is challenging to manage in-house, this
                    program is likely well-suited for you.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 my-2">
              <div className="card shadow-lh h-100 text-muted px-1">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-card-checklist text-warning fs-2 mx-2"></i>
                  </div>
                  <h5 className="text-capitalize fw-bold text-center mt-1">Compliance risks</h5>
                  <p className="mb-3 fs-5">
                    If you observe variability in contingent workforce management
                    across your organization and are concerned about legal
                    compliance, this program can certainly provide assistance.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 my-2">
              <div className="card shadow-lh h-100 text-muted px-1">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-bookmark-star text-warning fs-2 mx-2"></i>
                  </div>
                  <h5 className="text-capitalize fw-bold text-center mt-1">Seeking standardization</h5>
                  <p className="mb-3 fs-5">
                    If you're ready to elevate your contingent workforce program by
                    standardizing and consolidating spend across your organization,
                    then this option is ideal for you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LetsConnect />
    </React.Fragment>
  );
}

export default MspStaffing;
