import React from "react";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";

function IntelligentServiceDesk() {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/intelligent-service-banner.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Intelligent Service Desk</h1>
              <h2 className='fw-bold'> Helpdesk and On-Demand IT Smart Hands</h2>
              <p className="mt-5 text-warning text-capitalize" style={{fontSize:'30px'}}>
              A responsive and agile 24/7 customer service helpdesk efficiently manages ticket case management and dispatches both technical and logistics services globally. On-Demand Smart Hands technicians, available in local regions, perform last-mile tasks independently or in collaboration with Level 2 support techs
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands/>
      <section className="wrapper mt-5 p-5 text-muted bg-light">
        <div className="container">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-6 position-relative">
              <div className="shape bg-soft-primary rounded-circle rellax w-20 h-20" />
              <img src='images/help-desk-img.png' />
            </div>
            <div className="col-lg-6 px-lg-5 px-0">
              <h2 className="mb-3 text-center text-lg-start mt-5 mt-lg-0">24/7 Help Desk</h2>
              <p className="fs-5">
              DataCentrix™'s help desk team comprises passionate IT experts, including certified technicians, system architects, network engineers, and experienced customer service representatives. They are adept at handling various issues at different levels, ensuring efficient support for our clients.
              </p>
              <p className="fs-5">
              We offer a comprehensive array of support services, including troubleshooting software and hardware issues, diagnostics, application support and installation guidance, network optimization, data backup and recovery services, and more.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-6 position-relative order-lg-2">
              <div className="shape bg-soft-primary rounded-circle rellax w-20 h-20" />
              <img src='images/it-assistance.webp' />
            </div>
            <div className="col-lg-6 px-lg-5 px-0">
              <h2 className="mb-3 text-center text-lg-start mt-5 mt-lg-0">IT Assistance On-Demand</h2>
              <p className="fs-5">
              With a network of over 1000+ skilled engineers, DataCentrix™ Smart Hands services allow your organization to access and pay for resources as needed. Say goodbye to paying for stagnant technical resources in one region while scrambling for additional resources in another. Depending on your organization’s requirements, ISG can bridge critical gaps in your current operations or extend your existing operations to new regions, facilitating market expansion.
              </p>
            </div>
          </div>
        </div>
      </section>
      <LetsConnect />
    </React.Fragment>
  );
}

export default IntelligentServiceDesk;
