import React, { useState } from "react";
import GlobalPresence from "../../Components/GlobalPresence/GlobalPresence";
import JoinCommunity from "../../Components/JoinCommunity/JoinCommunity";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";

const RepairServices = () => {
  const [slider, setSlider] = useState([
    {
      title: "IT repair services",
      isShow: true,
    },
    {
      title: "Medical technology",
      isShow: false,
    },
  ]);
  const slideHandler = (index) => {
    let updateSlider = slider.map((element, i) => {
      if (index == i) {
        element.isShow = true;
      } else {
        element.isShow = false;
      }
      return element;
    });
    setSlider(updateSlider);
  };
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/it-repair-services.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold text-capitalize'>Maintenance and repair services for your IT equipment and electronics</h1>
              <p className="mt-5 text-warning" style={{fontSize:'30px'}}>
              Repair Services
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands/>
      <section>
        <div className="container-fluid p-5 mt-5 bg-light text-muted">
          <div className="row">
            <div className="col-lg-4 mt-lg-5">
              <h3 className="px-4 text-capitalize">
              Maintenance and repair conducted in controlled laboratory environments.
              </h3>
            </div>
            <div className="col-lg-4 mt-5 fs-5">
              <p className="fs-5">
              At our repair center, highly skilled technicians meticulously inspect and repair IT equipment and electronics from all major brands. We specialize in repairing and refurbishing hardware within your IT system environment, ensuring the technical functionality of all your IT components.
              </p>
            </div>
            <div className="col-lg-4 mt-lg-5 fs-5">
              <p className="fs-5">
              In addition to our repair center, we specialize in serving the healthcare sector. We provide customized support and services, including work in critical infrastructures to meet the highest requirements and quality standards. Our trained employees are authorized to conduct repairs and safety checks according to each manufacturer's specifications.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid text-muted mt-5 p-5">
          <div className="row my-3">
            <h4
              className="col-lg-6 text-center text-capitalize"
              onClick={() => slideHandler(0)}
            >
              IT repair services
            </h4>
            <h4
              className="col-lg-6 text-center text-capitalize"
              onClick={() => slideHandler(1)}
            >
              Medical technology
            </h4>
          </div>
          <div className="tab-content mt-3">
            {slider[0].isShow ? (
              <>
                <div className="row">
                  <div className="col-lg-6">
                    <img src="images/it-repair-service-img.webp" />
                  </div>
                  <div className="col-lg-6 px-lg-5 px-0">
                    <h3 className="text-center text-lg-start mt-5 mt-lg-0 text-capitalize">IT repair services</h3>
                    <p className="fs-5">We repair, inspect, and guarantee our services.</p>
                    <p className="my-2 fs-5">
                    We conduct inspections and repairs on IT equipment and electronics for all major brands, including functional tests within customer-specific system environments. Our repairs come with a warranty guarantee.
                    </p>
                  </div>
                </div>
              </>
            ) : slider[1].isShow ? (
              <>
                <div className="row">
                  <div className="col-lg-6 px-lg-5 px-0">
                    <h3 className="text-center text-lg-start mb-5 mb-lg-0 text-capitalize">Medical technology</h3>
                    <p className="fs-5">
                    Leverage our consulting and solution expertise in the clinical field to optimize your operations.
                    </p>
                    <p className="my-2 fs-5">
                    Tailored service plans for critical infrastructure, including customized maintenance contracts and system support for all major brands.
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <img src='images/medical-technology.webp' />
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 text-muted bg-light">
          <div className="row mt-lg-5">
            <h3 className="text-center my-lg-2 text-muted text-capitalize">
            Experience unparalleled quality with our expert maintenance and repair services, trusted by industry professionals.
            </h3>
            <p className="text-center fs-5 text-capitalize">
            Stay up-to-date with our repair center, ensuring your IT systems and equipment remain current and reliable.
            </p>
            <div className="row mt-lg-5">
              <div className="col-lg-4 my-3 col-md-6">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className="text-center main-card-icon">
                      <i class="bi bi-person-fill-gear text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className="my-2 text-center fw-bold mt-3">
                      Highly skilled technicians
                      </h5>
                      <p className="fs-5 repair-detail">
                      Our repair center features skilled, certified, and authorized experts committed to serving you.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 my-3 col-md-6">
                <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
                  <div className="text-center main-card-icon">
                    <i class="bi bi-pc-display text-warning fs-2 mx-2"></i>
                  </div>
                  <div>
                    <h5 className="my-2 text-center fw-bold mt-3">
                    Tailored system environment
                    </h5>
                    <p className="fs-5">
                    We validate the functionality of IT components in customized environments.
                    </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 my-3 col-md-6">
                <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
                  <div className="text-center main-card-icon">
                    <i class="bi bi-pc-horizontal text-warning fs-2 mx-2"></i>
                  </div>
                  <div>
                    <h5 className="my-2 text-center fw-bold mt-3">
                    Replacement of damaged components
                    </h5>
                    <p className="fs-5">
                    We replace faulty parts with high-quality components.
                    </p>
                  </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 my-3 col-md-6">
                <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
                  <div className="text-center main-card-icon">
                    <i class="bi bi-hourglass-bottom text-warning fs-2 mx-2"></i>
                  </div>
                  <div>
                    <h5 className="my-2 text-center fw-bold mt-3">Rapid repairs</h5>
                    <p className="fs-5">
                    On average, we complete IT equipment repairs within 5-10 days, with critical components prioritized for even quicker turnaround.
                    </p>
                  </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 my-3 col-md-6">
                <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
                  <div className="text-center main-card-icon">
                    <i class="bi bi-shield-plus text-warning fs-2 mx-2"></i>
                  </div>
                  <div>
                    <h5 className="my-2 text-center fw-bold mt-3">
                    Medical equipment repair service
                    </h5>
                    <p className="fs-5">
                    We provide a global repair service for diagnostic and analytical devices, adhering strictly to manufacturers' specifications.
                    </p>
                  </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 my-3 col-md-6">
                <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
                  <div className="text-center main-card-icon">
                    <i class="bi bi-bookmark-star text-warning fs-2 mx-2"></i>
                  </div>
                  <div>
                    <h5 className="my-2 text-center fw-bold mt-3">
                    Comprehensive servicing
                    </h5>
                    <p className="fs-5">
                    Comprehensive services including quality control, auditing, documentation, logistics, and equipment shipping.
                    </p>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 text-muted">
          <div className="row">
            <div className="col-lg-4 mt-lg-5">
              <h3 className="px-4 text-capitalize">
              Inspection and repair services for IT equipment and electronics.
              </h3>
            </div>
            <div className="col-lg-4 mt-5 fs-5">
              <p className="fs-5">
              At the core of Technogroup is our repair center, manned by highly skilled technicians who meticulously inspect and repair IT equipment from top brands in controlled laboratory environments.
              </p>
              <p className="fs-5">
              We perform comprehensive testing on monitors, power supplies, tape libraries, tape drives, UPS systems, and various other electronic units to ensure their full functionality within each customer's unique system environment.
              </p>
            </div>
            <div className="col-lg-4 mt-lg-5 fs-5">
              <p className="fs-5">
              We prioritize the replacement of defective parts with high-quality components whenever possible, taking into account economic viability. Additionally, we exchange any worn parts to enhance reliability. With our comprehensive repair guarantee within our terms and conditions, you receive added assurance. Our typical repair timeframe averages between 5-10 days, from the arrival of faulty parts to their return. However, we also offer expedited testing and repair services for critical system components upon request.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 text-muted bg-light">
          <div className="row">
            <div className="col-lg-4 mt-lg-5">
              <h3 className="px-4 text-capitalize">
              Our expertise in the healthcare sector is unparalleled.
              </h3>
            </div>
            <div className="col-lg-4 mt-5 fs-5">
              <p className="fs-5">
              As IT rapidly advances in the healthcare sector, medical technology has become a cutting-edge field with vast potential. The professional utilization, maintenance, and care of modern medical equipment demand highly trained specialists. Given their direct impact on patient well-being, medical devices must adhere to stringent safety and reliability standards.
              </p>
            </div>
            <div className="col-lg-4 mt-lg-5 fs-5">
              <p className="fs-5">
              While Technogroup has long been recognized for meeting top-tier IT service standards, our recent expansion may come as a surprise to many. Over the past few years, we've extended our portfolio to include repair services across Europe for leading manufacturers of diagnostic and analytical equipment with biosensor technology. Our specialists meticulously craft customized servicing plans tailored to meet precise clinical needs. Furthermore, we've developed specialized spatial solutions tailored specifically for the medical technology sector.
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalPresence />
      <JoinCommunity />
      <LetsConnect />
    </React.Fragment>
  );
};

export default RepairServices;
