import React from 'react'
import ContactForm from '../ContactForm/ContactForm'

const LetsConnect = () => {
    return (
        <section
            className="wrapper bg-light angled lower-start upper-end py-5"
            style={{
                // backgroundImage: 'url("images/form-bg.webp")',
                // backgroundSize: "cover"
            }}
        >
            <div className="container-fluid pt-14 pb-10 pb-md-10">
                <div className="row gy-10">
                    <div className="col-lg-4 offset-lg-1  text-center text-lg-start">
                        <h1 className="my-3 text-muted pt-md-10 pt-0 fw-bold">
                            Let's Connect
                        </h1>
                        <p className="text-muted fs-5 my-3">
                            Our global experts are ready to help your business. Get in touch with
                            one of our Experts today.
                        </p>
                        <h1 className="my-3 text-muted fw-bold fs-5">
                            We look forward to hearing from you!
                        </h1>
                    </div>
                    <div className="col-lg-6">
                        <div className="formWhite mb-n10 me-0  py-10  pe-lg-0 customAngleForm wrapper bg-white angled upper-end">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-12">
                                    <form className="contact-form needs-validation">
                                        <div className="messages" />
                                        <div className="row gx-4 mt-5">
                                            <div className="col-12 col-lg-6 px-4">
                                                <div className="form-floating mb-4">
                                                    <input
                                                        id="firstname"
                                                        type="text"
                                                        name="firstname"
                                                        className="form-control"
                                                        placeholder="First Name"
                                                        required=""
                                                    />
                                                    <label htmlFor="firstname">First Name *</label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 px-4">
                                                <div className="form-floating mb-4">
                                                    <input
                                                        id="lastname"
                                                        type="text"
                                                        name="lastname"
                                                        className="form-control"
                                                        placeholder="Last Name"
                                                        required=""
                                                    />
                                                    <label htmlFor="lastname">Last Name *</label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 px-4">
                                                <div className="form-floating my-4">
                                                    <input
                                                        id="email"
                                                        type="email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Email"
                                                        required=""
                                                    />
                                                    <label htmlFor="email">Email *</label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 px-4">
                                                <div className="form-floating my-4">
                                                    <input
                                                        id="customer_account"
                                                        type="text"
                                                        name="customer_account"
                                                        className="form-control"
                                                        placeholder="Company Name"
                                                        required=""
                                                    />
                                                    <label htmlFor="customer_account">Company Name *</label>
                                                </div>
                                            </div>
                                            <div className="col-12 px-4">
                                                <div className="form-floating my-4">
                                                    <textarea
                                                        id="field[14]"
                                                        type="text"
                                                        name="message"
                                                        className="form-control blueCustomBg rounded border-bottom-0"
                                                        placeholder="Your Message"
                                                        style={{ height: 125 }}
                                                        defaultValue={""}
                                                    />
                                                    <label htmlFor="message">Message</label>
                                                </div>
                                            </div>
                                            <div className="col-12 text-center">
                                                <div className="form-check my-4">
                                                   
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="invalidCheck"
                                                    >
                                                    <input
                                                        className="form-check-input text-dark"
                                                        type="checkbox"
                                                        id="invalidCheck"
                                                        required=""
                                                        defaultValue=""
                                                    />I agree to{" "}
                                                        <a href="/privacy-policy" className="hover">
                                                            terms and policy
                                                        </a>
                                                        .
                                                    </label>
                                                    <div className="invalid-feedback">
                                                        You must agree before submitting.
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-12 col-lg-6">
                                                <div>
                                                    <div>
                                                        <div >
                                                            <div>
                                                                <iframe
                                                                    title="reCAPTCHA"
                                                                    style={{maxWidth:'150px'}}
                                                                    role="presentation"
                                                                    name="a-pk9clxmq2p8s"
                                                                    frameBorder={0}
                                                                    scrolling="no"
                                                                    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                                                                    src="https://www.google.com/recaptcha/api2/anchor?ar=2&k=6LcwIw8TAAAAACP1ysM08EhCgzd6q5JAOUR1a0Go&co=aHR0cHM6Ly9pcm9uc2VydmljZWdsb2JhbC5jb206NDQz&hl=en&type=image&v=V6_85qpc2Xf2sbe3xTnRte7m&theme=light&size=normal&badge=bottomright&cb=cdorilwjd6rx"
                                                                />
                                                            </div>
                                                            <textarea
                                                                id="g-recaptcha-response-2"
                                                                name="g-recaptcha-response"
                                                                className="g-recaptcha-response"
                                                                style={{
                                                                    width: 250,
                                                                    height: 40,
                                                                    border: "1px solid rgb(193, 193, 193)",
                                                                    margin: "10px 25px",
                                                                    padding: 0,
                                                                    resize: "none",
                                                                    display: "none"
                                                                }}
                                                                defaultValue={""}
                                                            />
                                                        </div>
                                                        <iframe style={{ display: "none" }} />
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="col-12 text-center">
                                                <input
                                                    type="submit"
                                                    className="btn btn-outline-warning rounded-pill btn-send mb-3 btn-lg py-2 px-5 fw-bold"
                                                    defaultValue="Send message"
                                                />

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default LetsConnect