import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "./Pages/HomePage/Home";
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css"
import OnPremiseInfrastructure from "./Pages/OnPremiseInfrastructure/OnPremiseInfrastructure";
import CloudInfrastructure from "./Pages/CloudInfrastructure/CloudInfrastructure";
import DataCenterInfrastructure from "./Pages/DataCenterInfrastructure.js/DataCenterInfrastructure";
import SmartHandsDemands from "./Pages/SmartHandsDemands/SmartHandsDemands";
import TalentMarketplace from "./Pages/TalentMarketplace/TalentMarketplace";
import { Route, Routes } from "react-router-dom";
import { ABOUT_MANAGEMENT_TEAM, ABOUT_US, APPLICATION_DEVELOPMENT, CERTIFIED_QUALITY, CLOUD_INFRASTRUCTURE, COMPANY_OVERVIEW, CONNECTIVITY_SERVICES, CONTACT_US, CONTINGENT_STAFFING, DATA_CENTER_BACKUP_SERVICE, DATA_CENTER_IMAC, DATA_CENTER_INFRASTRUCTURE, DATA_CENTER_MAINTENANCE, DIGITAL_INFRASTRUCTURE, DIGITAL_TRANSFORMATION, DIGITAL_WORKPLACE, DIVERSITY_RECRUITING, EDGE_COMPUTING_INFRASTRUCTURE, ELECTROTECHNOLOGY, EMPLOYER_OF_RECORD, END_OF_SERVICE_LIFE, ENTERPRISE_INTEGRATION_SERVICES, FACTS_AND_FIGURES, FINANCING_SOLUTIONS, FLOOR_AND_SUPPORT_SERVICES, GLOBAL_PROJECT_MANAGEMENT, GLOBAL_WORKFORCE, HARDWARE_REPURCHASE, HARDWARE_SALES, HOME, INTELLIGENT_SERVICE_DESK, IT_HARDWARE, LIBRARY_MAINTENANCE, LOCATIONS, MANAGEMENT_TEAM, MANUFACTURERS, MEDICAL_TECHNOLOGY, MISSION_AND_VISSION, MODERN_DEVICE_MANAGEMENT, MSP_STAFFING, NETWORK_MAINTENANCE, NETWORK_OPERATIONS, NETWORK_SERVICES, ON_PREMISE_INFRASTRUCTURE, PROACTIVE_IT_MONITORING, RECRUITER_ON_DEMAND, RENTING_HARDWARE, REPAIR_SERVICES, RPO_SERVICES, SALESFORCE, SERVER_MAINTENANCE, SMART_HANDS_SERVICE, SPARE_AS_SERVICE, STORAGE_MAINTENENCE, SUPPORT_PACKS, TALENT_MARKETPLACE, TECHNOLOGY_PARTNERS, VALUE_ADDED_SERVICES, WARRANTY_CHECK, WARRANTY_EXTENSIONS, WORK_AS_SERVICE, WORK_MANAGEMENT } from "./Services/ConstantUrls";
import ModernDeviceManagement from "./Components/ModernDeviceManagement/ModernDeviceManagement";
import EdgeComputingInfrastructure from "./Pages/EdgeComputingInfrastructure/EdgeComputingInfrastructure";
import ConnectivityServices from "./Pages/ConnectivityServices/ConnectivityServices";
import NetworkOperations from "./Pages/NetworkOperations/NetworkOperations";
import ApplicationDevelopment from "./Pages/ApplicationDevelopment/ApplicationDevelopment";
import EnterpriseIntegrationServices from "./Pages/EnterpriseIntegrationServices/EnterpriseIntegrationServices";
import Salesforce from "./Pages/Salesforce/Salesforce";
import WorkAsService from "./Pages/WorkAsService/WorkAsService";
import IntelligentServiceDesk from "./Pages/IntelligentServiceDesk/IntelligentServiceDesk";
import WorkManagement from "./Pages/WorkManagement/WorkManagement";
import RecruiterOnDemand from "./Pages/RecruiterOnDemand/RecruiterOnDemand";
import DiversityRecruiting from "./Pages/DiversityRecruiting/DiversityRecruiting";
import MspStaffing from "./Pages/MspStaffing/MspStaffing";
import ContingentStaffing from "./Pages/ContingentStaffing/ContingentStaffing";
import RpoServices from "./Pages/RpoServices/RpoServices";
import EmployerOfRecord from "./Pages/EmployerOfRecord/EmployerOfRecord";
import MeetOurLeaders from "./Pages/MeetOurLeaders/MeetOurLeaders";
import TechnologyPartners from "./Pages/TechnologyPartners/TechnologyPartners";
import ContactUs from "./Pages/ContactUs/ContactUs";
import CompanyOverview from "./Pages/CompanyOverview/CompanyOverview";
import DataInfrastructure from "./Pages/DigitalInfrastructure/DigitalInfrastructure";
import DigitalWorkplace from "./Pages/DigitalWorkplace/DigitalWorkplace";
import DigitalTransformation from "./Pages/DigitalTransformation/DigitalTransformation";
import GlobalWorkforce from "./Pages/GlobalWorkforce/GlobalWorkforce";
import GlobalProjectManagement from "./Pages/GlobalProjectManagement/GlobalProjectManagement";
import DataCenterMaintenance from "./Pages/DataCenterMaintenance/DataCenterMaintenance";
import Manufacturers from "./Pages/Manufacturers/Manufacturers";
import ServerMaintenance from "./Pages/ServerMaintenance/ServerMaintenance";
import StorageMaintenance from "./Pages/StorageMaintenance/StorageMaintenance";
import LibraryMaintenance from "./Pages/LibraryMaintenance/LibraryMaintenance";
import NetworkMaintenance from "./Pages/NetworkMaintenance/NetworkMaintenance";
import EndOfServiceLife from "./Pages/EndOfServiceLife/EndOfServiceLife";
import WarrantyCheck from "./Pages/WarrantyCheck/WarrantyCheck";
import WarrantyExtensions from "./Pages/WarrantyExtensions/WarrantyExtensions";
import SupportPacks from "./Pages/SupportPacks/SupportPacks";
import ProactiveItMonitoring from "./Pages/ProactiveItMonitoring/ProactiveItMonitoring";
import DataCenterIMAC from "./Pages/DataCenterIMAC/DataCenterIMAC";
import FloorAndSupportServices from "./Pages/FloorAndSupportServices/FloorAndSupportServices";
import DataCenterBackupService from "./Pages/DataCenterBackupService/DataCenterBackupService";
import NetworkServices from "./Pages/NetworkServices/NetworkServices";
import FinancingSolutions from "./Pages/FinancingSolutions/FinancingSolutions";
import HardwareSales from "./Pages/HardwareSales/HardwareSales";
import RentingHardware from "./Pages/RentingHardware/RentingHardware";
import HardwareRepurchase from "./Pages/HardwareRepurchase/HardwareRepurchase";
import SpareAsService from "./Pages/SpareAsService/SpareAsService";
import Electrotechnology from "./Pages/Electrotechnology/Electrotechnology";
import MedicalTechnology from "./Pages/MedicalTechnology/MedicalTechnology";
import FactsAndFigure from "./Pages/FactsAndFigure/FactsAndFigure";
import MissionAndVission from "./Pages/MissionAndVission/MissionAndVission";
import Locations from "./Pages/Locations/Locations";
import CertifiedQuality from "./Pages/CertifiedQuality/CertifiedQuality";
import ManagementTeam from "./Pages/ManagementTeam/ManagementTeam";
import ValueAddedServices from "./Pages/ValueAddedServices/ValueAddedServices";
import AboutUs from "./Pages/AboutUs/AboutUs";
import ITHardware from "./Pages/ITHardware/ITHardware";
import RepairServices from "./Pages/RepairServices/RepairServices";
import TopBar from "./Components/TopBar/TopBar";
import MainTopBar from "./Components/MainTopBar/MainTopBar";

function App() {
  
  return (
    <div>
      <MainTopBar/>
       <Routes>
        <Route path={HOME} element={<Home />}></Route>
        <Route path={DATA_CENTER_INFRASTRUCTURE} element={<DataCenterInfrastructure />}></Route>
        <Route path={ON_PREMISE_INFRASTRUCTURE} element={<OnPremiseInfrastructure />}></Route>
        <Route path={CLOUD_INFRASTRUCTURE} element={<CloudInfrastructure />}></Route>
        <Route path={SMART_HANDS_SERVICE} element={<SmartHandsDemands />}></Route>
        <Route path={TALENT_MARKETPLACE} element={<TalentMarketplace />}></Route>
        <Route path={EDGE_COMPUTING_INFRASTRUCTURE} element={<EdgeComputingInfrastructure />}></Route>
        <Route path={CONNECTIVITY_SERVICES} element={<ConnectivityServices />}></Route>
        <Route path={NETWORK_OPERATIONS} element={<NetworkOperations />}></Route>
        <Route path={APPLICATION_DEVELOPMENT} element={<ApplicationDevelopment />}></Route>
        <Route path={ENTERPRISE_INTEGRATION_SERVICES} element={<EnterpriseIntegrationServices />}></Route>
        <Route path={SALESFORCE} element={<Salesforce />}></Route>
        <Route path={MANAGEMENT_TEAM} element={<MeetOurLeaders />}></Route>
        <Route path={TECHNOLOGY_PARTNERS} element={<TechnologyPartners />}></Route>
        <Route path={CONTACT_US} element={<ContactUs />}></Route>
        <Route path={COMPANY_OVERVIEW} element={<CompanyOverview />}></Route>
        <Route path={MODERN_DEVICE_MANAGEMENT} element={<ModernDeviceManagement />}></Route>
        <Route path={WORK_AS_SERVICE} element={<WorkAsService />}></Route>
        <Route path={INTELLIGENT_SERVICE_DESK} element={<IntelligentServiceDesk />}></Route>
        <Route path={WORK_MANAGEMENT} element={<WorkManagement />}></Route>
        <Route path={RECRUITER_ON_DEMAND} element={<RecruiterOnDemand />}></Route>
        <Route path={DIVERSITY_RECRUITING} element={<DiversityRecruiting />}></Route>
        <Route path={MSP_STAFFING} element={<MspStaffing />}></Route>
        <Route path={CONTINGENT_STAFFING} element={<ContingentStaffing />}></Route>
        <Route path={RPO_SERVICES} element={<RpoServices />}></Route>
        <Route path={EMPLOYER_OF_RECORD} element={<EmployerOfRecord />}></Route>
        <Route path={DATA_CENTER_MAINTENANCE} element={<DataCenterMaintenance />}></Route>
        <Route path={MANUFACTURERS} element={<Manufacturers />}></Route>
        <Route path={SERVER_MAINTENANCE} element={<ServerMaintenance />}></Route>
        <Route path={STORAGE_MAINTENENCE} element={<StorageMaintenance />}></Route>
        <Route path={LIBRARY_MAINTENANCE} element={<LibraryMaintenance />}></Route>
        <Route path={NETWORK_MAINTENANCE} element={<NetworkMaintenance />}></Route>
        <Route path={END_OF_SERVICE_LIFE} element={<EndOfServiceLife />}></Route>
        <Route path={WARRANTY_CHECK} element={<WarrantyCheck />}></Route>
        <Route path={WARRANTY_EXTENSIONS} element={<WarrantyExtensions />}></Route>
        <Route path={SUPPORT_PACKS} element={<SupportPacks />}></Route>
        <Route path={PROACTIVE_IT_MONITORING} element={<ProactiveItMonitoring />}></Route>
        <Route path={DATA_CENTER_IMAC} element={<DataCenterIMAC />}></Route>
        <Route path={FLOOR_AND_SUPPORT_SERVICES} element={<FloorAndSupportServices />}></Route>
        <Route path={DATA_CENTER_BACKUP_SERVICE} element={<DataCenterBackupService />}></Route>
        <Route path={NETWORK_SERVICES} element={<NetworkServices />}></Route>
        <Route path={FINANCING_SOLUTIONS} element={<FinancingSolutions />}></Route>
        <Route path={HARDWARE_SALES} element={<HardwareSales />}></Route>
        <Route path={RENTING_HARDWARE} element={<RentingHardware />}></Route>
        <Route path={HARDWARE_REPURCHASE} element={<HardwareRepurchase />}></Route>
        <Route path={SPARE_AS_SERVICE} element={<SpareAsService />}></Route>
        <Route path={ELECTROTECHNOLOGY} element={<Electrotechnology />}></Route>
        <Route path={MEDICAL_TECHNOLOGY} element={<MedicalTechnology />}></Route>
        <Route path={FACTS_AND_FIGURES} element={<FactsAndFigure />}></Route>
        <Route path={MISSION_AND_VISSION} element={<MissionAndVission />}></Route>
        <Route path={LOCATIONS} element={<Locations />}></Route>
        <Route path={CERTIFIED_QUALITY} element={<CertifiedQuality />}></Route>
        <Route path={ABOUT_MANAGEMENT_TEAM} element={<ManagementTeam />}></Route>
        <Route path={ABOUT_US} element={<AboutUs />}></Route>
        <Route path={VALUE_ADDED_SERVICES} element={<ValueAddedServices/>}></Route>
        <Route path={IT_HARDWARE} element={<ITHardware />}></Route>
        <Route path={REPAIR_SERVICES} element={<RepairServices />}></Route>
        <Route path={DIGITAL_TRANSFORMATION} element={<DigitalTransformation />}></Route>
        <Route path={GLOBAL_WORKFORCE} element={<GlobalWorkforce />}></Route>
        <Route path={GLOBAL_PROJECT_MANAGEMENT} element={<GlobalProjectManagement />}></Route>
        <Route path={DIGITAL_WORKPLACE} element={<DigitalWorkplace />}></Route>
        <Route path={DIGITAL_INFRASTRUCTURE} element={<DataInfrastructure />}></Route>
      </Routes>
      <div className="fixed-bottom right-100 p-3 whatsapp-styling" style={{zIndex:'6', left:'initial'}}>
        <a href="https://wa.me/+6591409304?text=Hello How can I help you ?" target="_blank">
          <img src="images/whatsapp-logo.webp" title='WhatsApp'/>
        </a>  
      </div>
      <Footer/>
    </div>
  );
}

export default App;
