import React from 'react'
import LetsConnect from '../../Components/LetsConnect/LetsConnect'
import GlobalPresence from '../../Components/GlobalPresence/GlobalPresence'
import JoinCommunity from '../../Components/JoinCommunity/JoinCommunity'
import LongerServiceLife from '../../Components/LongerServiceLife/LongerServiceLife'
import GlobalBrands from '../../Components/GlobalBrands/GlobalBrands'
import InsightSlider from '../../Components/InsightSlider/InsightSlider'

const LibraryMaintenance = () => {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/library-maintainance.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold text-capitalize'> We safeguard your backups, ensuring the security of your business data</h1>
              <p className="mt-5 text-warning" style={{fontSize:'30px'}}>
              Library Maintainance
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section>
        <div className="container-fluid text-muted mt-5 p-5 bg-light">
          <div className="row">
            <div className="col-lg-6 px-lg- px-0">
              <h3 className='text-center text-lg-start text-capitalize'>
                Reliable library maintenance ensures data security and availability for your business operations</h3>
            </div>
            <div className="col-lg-6 px-lg-5 px-0 mt-3 mt-lg-0">
              <p className="fs-5 my-lg-2">Minimize downtime, maximize competitiveness. Tape libraries ensure vital data protection and long-term archiving, guaranteeing permanent security for your company's critical information.</p>
              <p className="fs-5">Our hardware maintenance contracts offer access to certified technical support and a vast spare parts inventory 24/7. We're your go-to for independent repair services and replacements for libraries and systems reaching end of life or service. With our backup solutions in tape drives and libraries, you can always respond swiftly.</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid text-muted mt-5 p-5">
          <div className="row text-center">
            <h3 className="my-lg-2 text-capitalize">Contracts outlining agreed-upon service standards</h3>
            <p className='fs-5 text-capitalize'>Customized service level agreements for diverse storage needs</p>
          </div>
          <div className="row mt-5">
            <div className="col-lg-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body text-center fs-5 mt-2"> 
                <h5 className="py-2 h5 fw-bold text-center">Global network of partners</h5>
                <p>Global coverage, 24/7, 365 days</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body text-center fs-5 mt-2"> 
                <h5 className="py-2 h5 fw-bold text-center">Field service technicians</h5>
                <p>Rapid tape library on-site repair</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body text-center fs-5 mt-2"> 
                <h5 className="py-2 h5 fw-bold text-center">Large inventory of spare parts</h5>
                <p>We've got the hardware you require</p></div>
              </div>
            </div>
            <div className="col-lg-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body text-center fs-5 mt-2">
                <h5 className="py-2 h5 fw-bold text-center">Data security</h5>
                <p>Protect your vital business data with our full-service maintenance solutions</p></div>
              </div>
            </div>
            <div className="col-lg-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body text-center fs-5 mt-2">
                <h5 className="py-2 h5 fw-bold text-center">Field service technicians</h5>
                 <p>Quick on-site repair of your tape libraries</p>
                  </div>
              </div>
            </div>
            <div className="col-lg-4 my-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body text-center fs-5 mt-2">
                <h5 className="py-2 h5 fw-bold text-center">Large inventory of spare parts</h5>
                  <p>We have the hardware you need</p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LongerServiceLife />
      <GlobalPresence />
      <JoinCommunity />
      <InsightSlider/>
      <LetsConnect />
    </React.Fragment>
  )
}

export default LibraryMaintenance