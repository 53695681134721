import React from 'react'

const TopBar = () => {
    return (
        <section>
            <div className="container-fluid py-2 text-muted fs-5 border bg-white">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="social-medias-links">
                        <ul className="list list-unstyled d-flex flex-row flex-wrap">
                            <li className="list-item mx-2"><i class="bi bi-linkedin linked-in-color" onClick={()=>window.open('https://www.linkedin.com/company/data-centrix/', '_blank')} title="linkedin"></i></li>
                            <li className="list-item mx-2"><i class="bi bi-instagram insta-color" title="Instagram"></i></li>
                            <li className="list-item mx-2"><i class="bi bi-facebook facebook-color" title="Facebook"></i></li>
                            <li className="list-item mx-2"><i class="bi bi-youtube youtube-color" title="youTube"></i></li>
                        </ul>
                    </div>
                    <div className="contactInfo d-flex flex-row flex-wrap">

                        <span className='mx-2'><button className="btn btn-outline-secondary disabled">Open call ticket</button></span>
                        <span className='mx-2'><i class="bi bi-telephone-fill" ></i><span className='d-none d-lg-inline'> +91 9136 123455 (Toll Free)</span></span>
                        <span className='mx-2'><i class="bi bi-envelope text-danger"></i> <span className='d-none d-lg-inline text-primary'>smc@datacentrix.com</span></span>
                    </div>
                    {/* <div className="language-selector d-none d-lg-block">
                    <button className="btn btn-outline-secondary d-none">Select Language</button>
                    </div> */}

                </div>
            </div>
        </section>
    )
}

export default TopBar