import React, { useState, useEffect } from 'react'

const globalPresenceData = {
    title: "ACHIEVEMENTS",
    subTitle: 'Our Worldwide Footprint',
    lead: "We have a global presence spanning across every continent.",
    list: [
        {
            counter: 10,
            title: "Year Of Experience",
            icon: 'bi bi-trophy'
        },
        {
            counter: 80,
            title: "Countries",
            icon: 'bi bi-globe2'
        },
        {
            counter: 200,
            title: "Tier 1/2/3 Cities",
            icon: 'bi bi-buildings'
        },
        {
            counter: 600,
            title: "Skilled Engineers",
            icon: 'bi bi-person-check'
        },
        {
            counter: 100,
            title: "Stocking Locations",
            icon: 'bi bi-crosshair'
        },
    ]
}

const GlobalPresence = () => {
    const [yearOfExperience, setYearOfExperience] = useState(0);
    const [countries, setCountries] = useState(0);
    const [tierCities, setTierCities] = useState(0);
    const [skilledEngineers, setSkilledEngineers] = useState(0);
    const [stockingLocations, setStockingLocations] = useState(0);

    useEffect(() => {
        const counters = [
            { setter: setYearOfExperience, start: 0, end: 25, duration: 3000 },
            { setter: setCountries, start: 0, end: 80, duration: 3500 },
            { setter: setTierCities, start: 0, end: 900, duration: 3500 },
            { setter: setSkilledEngineers, start: 5000, end: 6000, duration: 3000 }, // Start from halfway
            { setter: setStockingLocations, start: 0, end: 600, duration: 3500 }
        ];

        counters.forEach(({ setter, start, end, duration }) => {
            let current = start;
            let range = end - start;
            let increment = end > start ? 1 : -1;
            let step = Math.abs(Math.floor(duration / range));

            let timer = setInterval(() => {
                current += increment;
                setter(current);
                if (current === end) {
                    clearInterval(timer);
                }
            }, step);
            return () => clearInterval(timer);
        });
    }, []);
    return (
        <section style={{ backgroundImage: "url('images/global-presence-bnr.webp')" }} className="global-presence-container wrapper bg-light global-presence-bg-image">
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div class="col-xxl-11 col-xl-12 mx-auto pt-md-12 pb-xxl-18 pb-xl-12 pt-6 pb-6" style={{
                            backgroundImage: 'url("images/global-presence-bnr.webp")',
                            // global-presence-bnr.webp
                            // global-presence-banner.jpg
                            backgroundSize: "cover",
                            backgroundPosition: 'center bottom',
                            backgroundRepeat: 'no-repeat'
                        }}>
                            <div className='container'>
                                <div className='row pt-4'>
                                    <div className='col-lg-10 col-xl-9 col-xxl-7 mx-auto text-center p-0'>
                                        <h5 className='title text-uppercase mt-5 mb-4 fw-bold text-white'>{globalPresenceData.title}</h5>
                                        <h3 className='sub_title mb-3 fw-bold text-white'>{globalPresenceData.subTitle}</h3>
                                        <p className='lead fs-lg mb-3 text-white fs-4'>{globalPresenceData.lead}</p>
                                    </div>
                                </div>
                                <div className="row mb-10">
                                    {
                                        globalPresenceData.list.map(element => (
                                            <div className="col-12 col-lg-6 col-sm-6 my-4 text-muted">
                                                <div className='bg-light shadow rounded position-relative py-4 px-4 text-left'>
                                                    <i class={`${element.icon} position-absolute top-0 end-0 translate-middle global-icon rounded-pill fs-1 px-3 py-1`} />
                                                    <h3 className='counter global-num-weights'>{element.counter}<span>+</span></h3>
                                                    <p className='global-num-weights fs-5'>{element.title}</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default GlobalPresence