import React from 'react'

const OpportunityEdgeContainer = () => {
    return (
        <React.Fragment>
            <section className="wrapper bg-light mt-5 p-5 text-muted">
                <div className="container py-14 py-md-16">
                    <div className="row">
                        <div className="col-lg-10 col-xl-9 col-xxl-8 mx-auto">
                            <h3 className="my-5 mb-md-6 px-lg-10 text-center">
                            The Potential Of Edge Computing Is Vast, Enabling Novel Approaches Like Private 5G Networks And IoT Platforms
                            </h3>
                        </div>
                    </div>
                    <div className="pricing-wrapper position-relative mt-n22 mt-md-n24">
                        <div
                            className="shape bg-dot primary rellax w-16 h-18"
                            style={{ top: "2rem", right: "-2.4rem" }}
                        />
                        <div className="row gy-6 mt-3 mt-md-5">
                            <div className="col-md-4 my-3 my-lg-0">
                                <div className="card shadow-lg text-center text-muted h-100">
                                    <div className="card-header py-0 px-0 mb-5">
                                        <img src="images/industrial.webp" height={200} width={360} alt="" srcset="" />
                                    </div>
                                    <div className="card-body py-0">
                                        <h5 className="card-title fw-bold mb-4">Industrial</h5>
                                        <div className='d-flex justify-content-center text-muted fs-5'>
                                            <ul className="icon-list bullet-bg bullet-soft-blue mt-7 mb-8 text-start list-unstyled">
                                                <li className='my-2'>Edge Compute Server</li>
                                                <li className='my-2'>Industrial Gateway</li>
                                                <li className='my-2'>Industrial PC</li>
                                                <li className='my-2'>Factory Automation</li>
                                                <li className='my-2'>Process Control</li>
                                                <li className='my-2'>HMI/AR Device</li>
                                                <li className='my-2'>Mobile Robots</li>
                                                <li className='my-2'>Industrial Drone</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 my-3 my-lg-0">
                                <div className="card shadow-lg text-center text-muted h-100">
                                    <div className="card-header py-0 px-0 mb-5">
                                        <img src="images/smart-city.webp" height={200} width={360} alt="" srcset="" />
                                    </div>
                                    <div className="card-body py-0">
                                        <h5 className="card-title fw-bold">Smart City</h5>
                                        <div className='d-flex justify-content-center text-muted fs-5'>
                                            <ul className="icon-list bullet-bg bullet-soft-blue mt-7 mb-8 text-start list-unstyled">
                                                <li className='my-2'>Fixed Wireless Gateway</li>
                                                <li className='my-2'>Transportation Gateway</li>
                                                <li className='my-2'>Video Analytics Server</li>
                                                <li className='my-2'>Public Kiosk/Signage</li>
                                                <li className='my-2'>Public Safety Gateway</li>
                                                <li className='my-2'>Edge vision/AI Solutions</li>
                                                <li className='my-2'>Roadside Units</li>
                                                <li className='my-2'>&nbsp;</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 my-3 my-lg-0">
                                <div className="card shadow-lg text-center text-muted h-100">
                                    <div className="card-header py-0 px-0 mb-5">
                                        <img src="images/retail.webp" height={200} width={360} alt="" srcset="" />
                                    </div>
                                    <div className="card-body py-0">
                                        <h5 className="card-title fw-bold">Retail</h5>
                                        <div className='d-flex justify-content-center text-muted fs-5'>
                                            <ul className="icon-list bullet-bg bullet-soft-blue mt-7 mb-8 text-start list-unstyled">
                                                <li className='my-2'>SD-WAN Enterprise/Edge Server</li>
                                                <li className='my-2'>On-premise Kiosk, Signage, Survillance</li>
                                                <li className='my-2'>Service Robot</li>
                                                <li className='my-2'>Smart Education Solutions</li>
                                                <li className='my-2'>AR/VR</li>
                                                <li className='my-2'>&nbsp;</li>
                                                <li className='my-2'>&nbsp;</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row gy-6 mt-3 mt-md-5">
                            <div className="col-md-4 offset-md-2 my-3 my-lg-0">
                                <div className="card shadow-lg text-center text-muted h-100">
                                    <div className="card-header py-0 px-0 mb-5">
                                        <img src="images/health-care.webp" height={200} width={360} alt="" srcset="" />
                                    </div>
                                    <div className="card-body py-0">
                                        <h5 className="card-title fw-bold">Healthcare</h5>
                                        <div className='d-flex justify-content-center text-muted fs-5'>
                                            <ul className="icon-list bullet-bg bullet-soft-blue mt-7 mb-8 text-start list-unstyled">
                                                <li className='my-2'>Medical Edge Compute</li>
                                                <li className='my-2'>Real Time Surgery</li>
                                                <li className='my-2'>Digital Pathalogy</li>
                                                <li className='my-2'>Health Care Robot</li>
                                                <li className='my-2'>Remote Healthcare</li>
                                                <li className='my-2'>&nbsp;</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 my-3 my-lg-0">
                                <div className="card shadow-lg text-center text-muted h-100">
                                    <div className="card-header py-0 px-0 mb-5">
                                        <img src="images/public-defence.webp" height={200} width={360} alt="" srcset="" />
                                    </div>
                                    <div className="card-body py-0">
                                        <h5 className="card-title fw-bold">Public/Defense</h5>
                                        <div className='d-flex justify-content-center text-muted fs-5'>
                                            <ul className="icon-list bullet-bg bullet-soft-blue mt-7 mb-8 text-start list-unstyled">
                                                <li className='my-2'>Urban Air Mobility</li>
                                                <li className='my-2'>Air Traffic Management</li>
                                                <li className='my-2'>Surveillance Drones</li>
                                                <li className='my-2'>Military Applications</li>
                                                <li className='my-2'>&nbsp;</li>
                                                <li className='my-2'>&nbsp;</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default OpportunityEdgeContainer