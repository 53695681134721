import React from 'react'

function Footer() {
  return (
    <footer className='footer-style text-white lead py-5'>
      <div className='container pt-10 pt-md-15 pb-13 pb-md-15'>
        <div className="row gy-6 gy-lg-0">
          <div className="col-12 col-md-6 col-lg-3 text-center text-lg-start">
            <div className='widget'>
              <div className='footer-logo-image text-center text-lg-start'>
                <img src="images/logoHome.png" className='img-fluid' alt="" />
              </div>
              <p className='my-4 fs-5'>© 2024 DataCentrix™, Inc. <br className='d-none d-lg-block' />
                All rights reserved.</p>
                <div>
                <i class="bi bi-facebook mx-3 fs-3"/>
                <i class="bi bi-twitter mx-3 fs-3"/>
                <i class="bi bi-linkedin mx-3 fs-3"/>
                </div>
              <div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3 px-2 px-lg-0 text-center text-lg-start">
            <div className="widget">
              <h4 className="widget-title my-3">Get in Touch</h4>
              <address className="pe-xl-15 pe-xxl-17 fs-5">
                 DataCentrix™, Inc.
                <br className='mt-1'/>
                68, Circular Road,
                <br className='mt-1'/>
                # 02-01, Singapore, 049422
              </address>
              <a href="mailto:info@serviceglobal.com text-white lead" className='link-light underline-none link-underline-opacity-0'>smc@datacentrix.com</a>
              <br /> <a href="tel:+14089438000" className='link-light underline-none link-underline-opacity-0 mt-1'>+91 9136 123455</a>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-3 mt-3 px-2 mt-lg-0 text-center text-lg-start">
            <div className="widget">
              <h4 className="widget-title my-3">Quick Links</h4>
              <ul className="mb-0 list-unstyled">
                <li className='my-2'>
                  <a href="#" className='link-light underline-none link-underline-opacity-0 fs-5'>About Us</a>
                </li>
                <li className='my-2'>
                  <a href="#" className='link-light underline-none link-underline-opacity-0 fs-5'>Blog</a>
                </li>
                <li className='my-2'>
                  <a href="#" className='link-light underline-none link-underline-opacity-0 fs-5'>
                    Inclusion and Diversity
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-3 mt-3 px-2 mt-lg-0 text-center text-lg-start">
            <div className="widget">
              <h4 className="widget-title text-white my-3">&nbsp;</h4>
              <ul className="list-unstyled mb-0">
                <li className='my-2'>
                  <a href="#" className='link-light underline-none link-underline-opacity-0 fs-5'>Our Partners</a>
                </li>
                <li className='my-2'>
                  <a href="#" className='link-light underline-none link-underline-opacity-0 fs-5'>Privacy Policy</a>
                </li>
                <li className='my-2'>
                  <a href="#" className='link-light underline-none link-underline-opacity-0 fs-5'>Cookies Policy</a>
                </li>
                <li className='my-2'>
                  <a href="#" className='link-light underline-none link-underline-opacity-0 fs-5'>Legal Disclaimer</a>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </footer>
  )
}

export default Footer