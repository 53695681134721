import React from "react";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";
import LatestInsights from "../../Components/LatestInsights/LatestInsights";
import JoinCommunity from "../../Components/JoinCommunity/JoinCommunity";
import ServiceSlider from "../../Components/ServiceSlider/ServiceSlider";
import GlobalPresence from "../../Components/GlobalPresence/GlobalPresence";

function DigitalTransformation() {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/digital-trans-banner-img.png)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold text-capitalize'>Services for Digital Transformation</h1>
              <p className="mt-5 text-warning text-capitalize" style={{fontSize:'30px'}}>
              Our digital transformation services aim to identify
                opportunities for improvement and growth, craft a robust digital
                strategy, and implement changes to optimize operations and
                elevate customer experience. From streamlining processes to
                innovating digital products and refining marketing strategies,
                we're here to drive your success
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section className="wrapper bg-light mt-5 p-5 text-muted">
        <div className="container py-14 py-md-16">
          <div className="row">
            <div className="col">
              <h3 className="mb-3 text-center text-capitalize">
                Your Trusted Partner for Digital Transformation Strategies
              </h3>
              <p className="mb-3 fs-5 text-center text-lg-start">
                Digital Transformation is a comprehensive journey, integrating
                cutting-edge technology across all business facets.
              </p>
            </div>
          </div>
          <div className="row gx-md-8 gy-8">
            <div className="col-md-6 col-lg-4 mt-3">
              <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
                  <div className="text-center">
                    <i className="uil uil-file-search-alt text-warning fs-2 mx-2" />
                  </div>
                  <h5 className="text-center fw-bold mt-2 text-capitalize">Transformation of Business Processes</h5>
                  <p className="mb-3 fs-5 mt-2">
                    Incorporate digital technologies to streamline and optimize
                    business processes, including supply chain management, human
                    resources, finance, and customer service.
                  </p>
                </div></div>
            </div>
            <div className="col-md-6 col-lg-4 mt-3">
              <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
                  <div className="text-center">
                    <i className="uil uil-servers text-warning fs-2 mx-2" />
                  </div>
                  <h5 className="text-center mt-2 fw-bold">Revolutionizing Customer Experience</h5>
                  <p className="mb-3 fs-5 mt-2">
                    Leverage digital technologies to enhance customer experience,
                    incorporating personalized messaging, improved information
                    accessibility, and streamlined transaction processes for greater
                    efficiency and convenience.
                  </p>
                </div></div>
            </div>
            <div className="col-md-6 col-lg-4 mt-3">
              <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
                  <div className="text-center">
                    <i className="uil uil-server-network text-warning fs-2 mx-2" />
                  </div>
                  <h5 className="text-center mt-2 fw-bold">Transforming Products and Services</h5>
                  <p className="mb-3 fs-5 mt-2">
                    Enhance product and service offerings through advanced analytics
                    for improved product design and integration of digital tools to
                    elevate service delivery.
                  </p>
                </div></div>
            </div>
            <div className="col-md-6 col-lg-4 mt-3">
              <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
                  <div className="text-center">
                    <i className="uil uil-file-search-alt text-warning fs-2 mx-2" />
                  </div>
                  <h5 className="text-center mt-2 fw-bold text-capitalize">Transformation at the Organizational Level</h5>
                  <p className="mb-3 fs-5 mt-2">
                    Emphasis on integrating digital technologies to enhance
                    organizational processes, including collaboration,
                    communication, and knowledge management.
                  </p>
                </div></div>
            </div>
            <div className="col-md-6 col-lg-4 mt-3">
              <div className="card shadow-lg text-muted h-100 main-card">
                <div className="card-body">
                  <div className="text-center">
                    <i className="uil uil-servers text-warning fs-2 mx-2" />
                  </div>
                  <h5 className="text-center mt-2 fw-bold">Transforming Organizational Culture</h5>
                  <p className="mb-3 fs-5 mt-2">
                    Drive cultural change within the organization, leveraging
                    digital tools to promote transparency, innovation, and
                    continuous learning.
                  </p>
                </div></div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container py-14 py-md-16">
          <div className="row gx-lg-8 gx-xl-12 gy-6 center">
            <h2 className="mb-3 text-capitalize">
              Boost Your Digital Transformation Journey with DataCentrix's
              Expertise
            </h2>
            <p className="fs-5 mb-3">
              Embark on your digital transformation journey with DataCentrix™
              as your trusted and dedicated partner. Our diverse global talent
              is equipped with the latest skills and knowledge to ensure your
              business thrives in today's ever-evolving digital landscape. Let
              us help you fully leverage technology to set you up for success,
              both now and in the future.
            </p>
          </div>
          <div className="row gx-lg-8 gx-xl-12 gy-6">
            <div className="col-md-6 col-lg-4 mt-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-robot text-warning fs-2 mx-2"></i>
                  </div>
                  <h5 className="text-center fw-bold mt-2">Artificial Intelligence (AI)</h5>
                  <p className="mb-2 fs-5">
                    Incorporate AI technologies like machine learning, natural
                    language processing, and computer vision to automate tasks,
                    enhance decision-making, and gain insights into customer
                    behavior and business operations.
                  </p>
                </div></div>
            </div>
            <div className="col-md-6 col-lg-4 mt-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-app-indicator text-warning fs-2 mx-2"></i>
                  </div>
                  <h5 className="text-center fw-bold mt-2">Application Development &amp; Modernization</h5>
                  <p className="mb-2 fs-5">
                    Prolong the lifespan of your legacy applications while
                    concurrently reducing operational expenses and enhancing
                    features, performance, security, and scalability - all with
                    future maintenance in focus.
                  </p>
                </div></div>
            </div>
            <div className="col-md-6 col-lg-4 mt-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-router-fill text-warning fs-2 mx-2"></i>
                  </div>
                  <h5 className="text-center fw-bold mt-2">Internet of Things (IoT) Solutions</h5>
                  <p className="mb-2 fs-5">
                    Utilize IoT devices, sensors, and other physical objects to
                    establish a data network, optimizing business processes,
                    enhancing efficiency, and introducing new products and services.
                  </p>
                </div></div>
            </div>
            <div className="col-md-6 col-lg-4 mt-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-shield-lock-fill text-warning fs-2 mx-2"></i>
                  </div>
                  <h5 className="text-center fw-bold mt-2">Blockchain</h5>
                  <p className="mb-2 fs-5">
                    Implement distributed ledger technology for secure and
                    transparent record-keeping, developing applications across
                    various industries to enhance transaction efficiency and
                    security.
                  </p>
                </div></div>
            </div>
            <div className="col-md-6 col-lg-4 mt-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-badge-ar text-warning fs-2 mx-2"></i>
                  </div>
                  <h5 className="text-center fw-bold mt-2">Augmented Reality (AR) &amp; Virtual Reality (VR)</h5>
                  <p className="mb-2 fs-5">
                    Incorporate AR and VR technologies to craft immersive customer
                    experiences, refine employee training, and innovate product
                    development.
                  </p></div></div>
            </div>
            <div className="col-md-6 col-lg-4 mt-3">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                  <div className="text-center">
                    <i class="bi bi-cloud-plus-fill text-warning fs-2 mx-2"></i>
                  </div>
                  <h5 className="text-center fw-bold mt-2">Cloud Computing</h5>
                  <p className="mb-2 fs-5">
                    Empower your business with Cloud computing for enhanced
                    flexibility and scalability, securely storing and accessing data
                    and applications over the internet, unlocking improved agility
                    and efficiency.
                  </p>
                </div></div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper bg-light mt-5 p-5 text-muted">
        <div className="container py-10">
          <div className="row gx-md-8 gx-xl-12 gy-10 pt-5 mb-10 mb-md-12 align-items-center">
            <div className="col-lg-6 order-lg-2">
              <div className="card shadow-lg me-lg-6 text-muted my-3">
                <div className="card-body p-3">
                  <div className="d-flex flex-row">
                    <div>
                      <h5 className="mb-1 fw-bold">Project-Centric Solutions</h5>
                      <p className="mb-0 fs-5">
                        We define the project scope, develop a proposal, and
                        execute the project within the agreed-upon timeline and
                        budget.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card shadow-lg mx-lg-6 mt-6 text-muted my-3">
                <div className="card-body p-3">
                  <div className="d-flex flex-row">
                    <div>
                      <h5 className="mb-1 fw-bold">Managed Service Solutions</h5>
                      <p className="mb-0 fs-5">
                      DataCentrix™ oversees applications, software,
                        hardware, and cloud services.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card shadow-lg ms-lg-13 mt-6  text-muted my-3">
                <div className="card-body p-3">
                  <div className="d-flex flex-row">
                    <div>
                      <h5 className="mb-1 fw-bold">Consultancy Model</h5>
                      <p className="mb-0 fs-5">
                        We collaborate with customers to pinpoint areas for
                        improvement in their digital operations, offering
                        recommendations on implementation strategies
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card shadow-lg mx-lg-6 mt-6  text-muted my-3">
                <div className="card-body p-3">
                  <div className="d-flex flex-row">
                    <div>
                      <h5 className="mb-1 fw-bold">Co-creation Model</h5>
                      <p className="mb-0 fs-5">
                        We partner with customers to understand their business
                        needs, design tailored solutions, and oversee the
                        testing and implementation process.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card shadow-lg me-lg-6 mt-6  text-muted my-3">
                <div className="card-body p-3">
                  <div className="d-flex flex-row">
                    <div>
                      <h5 className="mb-1 fw-bold">Hybrid Model</h5>
                      <p className="mb-0 fs-5">
                        We partner with customers to understand their business
                        needs, design tailored solutions, and oversee the
                        testing and implementation process.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <h2 className="mb-3 text-center text-lg-start mt-5 mt-lg-0">
                Customer Centric Approach
              </h2>
              <h5 className="mb-5 text-center text-lg-start">
                We align with your business objectives and digital
                transformation requirements.
              </h5>
              <p className="fs-5">
              DataCentrix's engagement in digital transformation services
                adopts a fitting operating model to address clients' needs.
                Options range from tailored project solutions to ongoing support
                and maintenance services, or strategic partnerships. Regardless
                of the strategy chosen, these models ensure an optimized
                approach for successful transformations in today's dynamic
                technology landscape.
              </p>
              <img src="images/customer-centric.jpg" />
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper bg-navy angled upper-start text-muted mt-5 p-5">
        <div className="container py-14 py-md-16">
          <div className="row mb-8">
            <div className="col-lg-8 mx-auto">
              <h3 className="mb-0 text-center text-capitalize">
                Unlock the potential of digital transformation for your
                business: explore our comprehensive range of services and begin
                your journey today!
              </h3>
            </div>
          </div>
        </div>
      </section>
      <GlobalPresence />
      <ServiceSlider />
      <JoinCommunity />
      <LatestInsights />
      <LetsConnect />
    </React.Fragment>
  );
}

export default DigitalTransformation;
