import React from 'react'

const JoinCommunity = () => {
  return (


    <section className="join-community-container bg-light customAngle wrapper angled lower-start upper-end" 
    style={{
      // backgroundImage: 'url("images/join-community.webp")',
      // backgroundSize: "cover",
    }}>
      <div className='container py-md-5 py-5'>
        <div className="row align-items-center">
          <div className="col-lg-6 text-center text-lg-start">
            <h3 className='text-muted mb-3 mt-10 mt-sm-0 fw-bold'>Join Our Community</h3>
            <h3 className='mb-3 text-muted pe-xxl-18'>We're trusted by 200+ clients worldwide.</h3>
            <p className='text-muted mb-6 fs-5'>Join them and elevate your business with our services.</p>
            <div>
              <button className='btn btn-outline-warning rounded-pill mb-3 text-nowrap mb-lg-0 mb-7 btn-lg fw-bold'>Join Us Today</button>
            </div>
          </div>
          <div className="col-lg-6">
            <figure className='rounded d-sm-block'>
              <img src="images/join-us.jpg" alt="" className='join-image' />
            </figure>
          </div>
        </div>
      </div>
    </section>
  )
}

export default JoinCommunity