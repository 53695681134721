import React from "react";
import TalkToAnExpert from "../../Components/TalkToAnExpert/TalkToAnExpert";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";
import DeliveryMissionCritical from "../../Components/DeliveryMissionCritical/DeliveryMissionCritical";
import GlobalPresence from "../../Components/GlobalPresence/GlobalPresence";
import JoinCommunity from "../../Components/JoinCommunity/JoinCommunity";
import LatestInsights from "../../Components/LatestInsights/LatestInsights";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";
import ServiceSlider from "../../Components/ServiceSlider/ServiceSlider";

const ConnectivityServices = () => {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/connectivity-bnr.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Connectivity Solutions</h1>
              <p className="mt-5 text-warning" style={{fontSize:'30px'}}>
              DataCentrix™ comprehends the intricate dynamics of state-of-the-art communication systems and their capacity to meet the rigorous demands of mission-critical users. Our array of services spans the entire network connectivity lifecycle, from requirement analysis to design, system integration, deployment, and maintenance support, all executed with certified quality processes
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section className="connectivityservices-helping-container wrapper bg-light mt-5 p-5 text-muted">
        <div className="container py-14 py-md-16 text-center">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <h3 className="mb-10">
              Assisting Organizations In Leveraging Advanced Connectivity Components To Construct Configurable Networks
              </h3>
            </div>
          </div>
          <div className="position-relative">
            <div
              className="shape rounded-circle bg-soft-blue rellax w-16 h-16"
              style={{ bottom: "-0.5rem", right: "-2.2rem", zIndex: 0 }}
            />
            <div
              className="shape bg-dot blue rellax w-16 h-17"
              style={{ top: "-0.5rem", left: "-2.5rem", zIndex: 0 }}
            />
            <div className="row gx-md-5 gy-5 text-center mt-3">
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body px-5">
                    <div className="text-center">
                    <i class="bi bi-ethernet text-warning fs-2 mx-2"></i>
                    </div>
                    <h4>Carrier Ethernet</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body px-5">
                  <div className="text-center">
                    <i class="bi bi-gear-wide-connected text-warning fs-2 mx-2"></i>
                    </div>
                    <h4>Data Link</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body px-5">
                  <div className="text-center">
                    <i class="bi bi-wifi text-warning fs-2 mx-2"></i>
                    </div>
                    <h4>Private 5G</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body px-5">
                  <div className="text-center">
                    <i class="bi bi-database text-warning fs-2 mx-2"></i>
                    </div>
                    <h4>Data Center Solutions</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body px-5">
                  <div className="text-center">
                    <i class="bi bi-router text-warning fs-2 mx-2"></i>
                    </div>
                    <h4>Dedicated Internet Access</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body px-5">
                  <div className="text-center">
                    <i class="bi bi-pci-card-network text-warning fs-2 mx-2"></i>
                    </div>
                    <h4>Global MPLS VPN</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body px-5">
                  <div className="text-center">
                    <i class="bi bi-modem-fill text-warning fs-2 mx-2"></i>
                    </div>
                    <h4>National Broadband</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body px-5">
                  <div className="text-center">
                    <i class="bi bi-node-plus text-warning fs-2 mx-2"></i>
                    </div>
                    <h4>Wave Service</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="connectivityservices-assessment-container wrapper mt-5 p-5 text-muted">
        <div className="container py-14 py-md-16">
          <div className="row gy-10 gy-sm-13 gx-lg-3 align-items-center">
            <div className="col-lg-6 position-relative">
              <div
                className="shape rounded bg-soft-primary rellax d-md-block"
                data-rellax-speed={0}
                style={{
                  bottom: "-1.8rem",
                  right: "-1.5rem",
                  width: "85%",
                  height: "90%",
                }}
              />
              <figure className="rounded">
                <img src="images/planning-img.jpg" alt="" srcset="" />
              </figure>
            </div>
            <div className="col-lg-5 col-xl-4 offset-lg-1">
              <h3 className="mb-3 text-center text-lg-start mt-3 mt-lg-0">
                Strategic Planning and Assessment
              </h3>
              <p className="fs-5 mb-6">
                Empower your network's performance and scalability with Service
                Global's Assessment and Planning services. Our seasoned
                professionals meticulously evaluate your existing connectivity
                infrastructure, ensuring optimal performance and strategic
                capacity planning for future growth.
              </p>
              <ul className="icon-list bullet-bg bullet-soft-blue list-unstyled">
                <li className="d-flex flex-row">
                  <i className="uil-arrow-right text-warning fs-4 mx-2" />
                  <span className="fs-5">Network assessment and strategy</span>
                </li>
                <li className="d-flex flex-row">
                  <i className="uil-arrow-right text-warning fs-4 mx-2" />
                  <span className="fs-5">Wireless &amp; 5G conceptual architecture</span>
                </li>
                <li className="d-flex flex-row">
                  <i className="uil-arrow-right text-warning fs-4 mx-2" />
                  <span className="fs-5">Site survey &amp; infrastructure management</span>
                </li>
                <li className="d-flex flex-row">
                  <i className="uil-arrow-right text-warning fs-4 mx-2" />
                  <span className="fs-5">Transport planning &amp; optimization</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="connectivityservices-global-container wrapper mt-5 p-5 bg-light text-muted">
        <div className="container py-14 py-md-16">
          <div className="row gx-md-8 gy-10 align-items-center">
            <div className="col-lg-6 offset-lg-1 order-lg-2 position-relative">
              <figure className="rounded">
                <img src="images/global-deployment-img.jpg" alt="" srcset="" />
              </figure>
              <div
                className="card shadow-lg position-absolute d-none d-md-block"
                style={{ top: "15%", left: "-7%" }}
              >
                {/* <div className="card-body py-4 px-5">
                                    <div className="d-flex flex-row align-items-center">
                                        <div>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                version="1.1"
                                                width={45}
                                                height={45}
                                                x={0}
                                                y={0}
                                                viewBox="0 0 32 32"
                                                className="me-3"
                                            >
                                                <g>
                                                    <g data-name="Layer 2">
                                                        <circle
                                                            cx={16}
                                                            cy={25}
                                                            r={3}
                                                            fill="#c5d7f6"
                                                            data-original="#c5d7f6"
                                                        />
                                                        <path
                                                            fill="#3f78e0"
                                                            d="M22.58 17.479a1 1 0 1 1-1.32 1.5 7.974 7.974 0 0 0-10.52 0 1 1 0 1 1-1.32-1.5 9.972 9.972 0 0 1 13.16 0zM16 9a15.9 15.9 0 0 0-10.531 3.97 1 1 0 1 0 1.322 1.5 13.955 13.955 0 0 1 18.418 0 1 1 0 1 0 1.322-1.5A15.9 15.9 0 0 0 16 9zm13.621-1.264A21.717 21.717 0 0 0 16 3 21.717 21.717 0 0 0 2.379 7.736 1 1 0 0 0 3.621 9.3a19.954 19.954 0 0 1 24.758 0 1 1 0 0 0 1.242-1.568z"
                                                            data-original="#3f78e0"
                                                        />
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <div>
                                            <h3 className="fs-25 counter mb-0 text-nowrap">Private 5G</h3>
                                            <p className="fs-16 lh-sm mb-0 text-nowrap">Support</p>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
            <div className="col-lg-5">
              <h2 className="mb-4 me-lg-n5 mt-3 mt-lg-0 text-center text-lg-start">
                Installation Services and Global Deployment
              </h2>
              <p className="fs-5 mb-6">
                Simplify your deployment and installation processes while
                ensuring enterprise-grade security and device management. Our
                experienced global team, backed by regional project managers,
                adeptly navigates logistics challenges for seamless rollout,
                configuration, and installation.
              </p>
              <ul className="icon-list bullet-bg bullet-soft-blue list-unstyled">
                <li className="d-flex flex-row">
                  <i className="uil-arrow-right text-warning fs-4 mx-2" />
                  <span className="fs-5">Network Deployment Services</span>
                </li>
                <li className="d-flex flex-row">
                  <i className="uil-arrow-right text-warning fs-4 mx-2" />
                  <span className="fs-5">Installation, Configuration, and Provisioning Services</span>
                </li>
                <li className="d-flex flex-row">
                  <i className="uil-arrow-right text-warning fs-4 mx-2" />
                  <span className="fs-5">Device Provisioning Services</span>
                </li>
                <li className="d-flex flex-row">
                  <i className="uil-arrow-right text-warning fs-4 mx-2" />
                  <span className="fs-5">Implementation and Provisioning of Edge Infrastructure</span>
                </li>
                <li className="d-flex flex-row">
                  <i className="uil-arrow-right text-warning fs-4 mx-2" />
                  <span className="fs-5">Edge Cloud Installation and Commissioning Services</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="connectivityservices-managedservices-container wrapper bg-light text-muted p-5">
        <div className="container py-14 py-md-16">
          <div className="row gx-lg-8 gx-xl-12 gy-10 mb-14 mb-md-18 align-items-center">
            <div className="col-lg-6 position-relative">
              <div
                className="shape bg-line blue rounded-circle rellax w-17 h-17"
                data-rellax-speed={1}
                style={{ top: "-2rem", right: "-0.6rem" }}
              />
              <div
                className="shape bg-soft-blue rounded-circle rellax w-17 h-17"
                data-rellax-speed={1}
                style={{ bottom: "-2rem", left: "-0.4rem" }}
              />
              <figure className="rounded mb-0">
                <img
                  src="images/monitoring.webp"
                  height={400}
                  width={500}
                  alt=""
                  srcset=""
                />
              </figure>
            </div>
            <div className="col-lg-6 px-lg-5 px-0">
              <h2 className="mb-4 me-lg-n5 text-center text-lg-start mt-3 mt-lg-0">Managed Support</h2>
              <p className="fs-5 mb-6">
              DataCentrix™'s Managed Services provide a comprehensive range of global
                support solutions ensuring exceptional service levels and
                reliable remote management. Our integrated systems, tools,
                dashboards, and analytics offer valuable insights for efficient
                troubleshooting, threshold control, and anomaly detection,
                facilitating informed decisions when required.
              </p>
              <ul className="icon-list bullet-bg bullet-soft-blue list-unstyled">
                <li className="d-flex flex-row">
                  <i className="uil-arrow-right text-warning fs-4 mx-2" />
                  <span className="fs-5">Continuous monitoring and operations</span>
                </li>
                <li className="d-flex flex-row">
                  <i className="uil-arrow-right text-warning fs-4 mx-2" />
                  <span className="fs-5">Corrective field maintenance</span>
                </li>
                <li className="d-flex flex-row">
                  <i className="uil-arrow-right text-warning fs-4 mx-2" />
                  <span className="fs-5">Preventive field maintenance</span>
                </li>
                <li className="d-flex flex-row">
                  <i className="uil-arrow-right text-warning fs-4 mx-2" />
                  <span className="fs-5">Quality of service management</span>
                </li>
                <li className="d-flex flex-row">
                  <i className="uil-arrow-right text-warning fs-4 mx-2" />
                  <span className="fs-5">Edge analytics and reporting</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <DeliveryMissionCritical />
      <section className="connectivityservices-connectserviceglobal-container wrapper mt-5 p-5 text-muted bg-light">
        <div className="container py-14 py-md-16">
          <div className="row mb-8">
            <div className="col-lg-8 mx-auto text-center">
              <h3 className="mb-3">
                Connect with a DataCentrix™ specialist to explore how our
                solutions can drive success and growth for your business.
              </h3>
              <div>
                <a
                  href="#"
                  className="btn btn-outline-warning rounded-pill mb-0 mt-10 text-nowrap"
                  data-bs-toggle="modal"
                  data-bs-target="#modal-signup"
                >
                  Talk to an Expert
                </a>
                {/* <TalkToAnExpert/> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <GlobalPresence />
      <ServiceSlider />
      <JoinCommunity />
      <LatestInsights />
      <LetsConnect />
    </React.Fragment>
  );
};

export default ConnectivityServices;
