import React from 'react'

const FactsAndFigure = () => {
  return (
    <React.Fragment>
      
    </React.Fragment>
  )
}

export default FactsAndFigure