import React, { useState } from "react";

const Accordion = ({ data }) => {
  const [accordionData, setAccordionData] = useState(data.map(item => ({ ...item, isOpen: false })));

  const toggleAccordion = (index) => {
    setAccordionData(prevData => {
      const newData = [...prevData];
      newData[index].isOpen = !newData[index].isOpen;
      return newData;
    });
  };

  return (
    <div className="accordion" id="accordionExample">
      {accordionData.map((ele, accordianIndex) => (
        <div className="accordion-item" key={accordianIndex}>
          <h2 className="accordion-header" id={`heading-${accordianIndex}`}>
            <button
              className={`fs-5 text-muted accordion-button ${ele.isOpen ? '' : 'collapsed'}`}
              type="button"
              onClick={() => toggleAccordion(accordianIndex)}
            >
              {ele.title}
            </button>
          </h2>
          <div
            className={`fs-5 text-muted accordion-collapse collapse ${ele.isOpen ? 'show' : ''}`}
            aria-labelledby={`heading-${accordianIndex}`}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              {ele.description}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
