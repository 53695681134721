import React from 'react'
import LetsConnect from '../../Components/LetsConnect/LetsConnect'
import GlobalBrands from '../../Components/GlobalBrands/GlobalBrands'
import GlobalPresence from '../../Components/GlobalPresence/GlobalPresence'
import JoinCommunity from '../../Components/JoinCommunity/JoinCommunity'
import InsightSlider from '../../Components/InsightSlider/InsightSlider'

const TechnologyPartners = () => {
    return (
        <React.Fragment>
            <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/partners-banner-img.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Our Technology Partners</h1>
              {/* <p className="mt-5 text-warning" style={{fontSize:'30px'}}>
              DXC empowers organizations to achieve tangible business results across customer engagement, workforce enablement, and operations optimization
              </p> */}
            </div>
          </div>
        </div>
      </section>
            <GlobalBrands />
            <section className="mt-5 p-5 text-muted bg-light">
                <div className="container">
                    <div className="row gx-lg-8 gx-xl-12 gy-10">
                        <div className="col-lg-6 position-relative">
                            <figure className="rounded">
                                <img src="images/tech-deals.jpeg" height={450} width={450} alt="" srcset="" />
                            </figure>
                        </div>
                        <div className="col-lg-6 px-lg-5 px-0">
                            <h3 className="text-center text-lg-start mt-5 mt-lg-0 mb-3">
                                Nurturing Alliances
                            </h3>
                            <h5 className="mb-3">
                                Delivering tailored technology solutions for our customers is our priority
                            </h5>
                            <p className='fs-5'>
                                We've cultivated strategic alliances with top-tier technology and service providers to deliver tailored technology solutions for our customers.
                            </p>
                            <p className='fs-5'>
                                In collaboration with our partners, DataCentrix™ crafts end-to-end industry-leading solutions for our customers. Our partnership model enables flexibility to meet your specific needs, whether you're a global enterprise or small business.
                            </p>
                            <p className='fs-5'>
                                Our partner relationships drive the creation of innovative, out-of-the-box solutions for end-users seeking high performance, scalability, and reliability at competitive costs.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mt-5 p-5 text-muted">
                <div className="container">
                    <h3 className="mt-7 mb-5 text-center">Strategic OEM Platform Partnerships</h3>
                    <div className="row d-flex mt-5">
                        <div className="col-lg-4 col-md-6 my-2">
                            <div className="card shadow-lg text-muted h-100">
                                <div className="card-body">
                                    <figure>
                                        <img src="images/Intel-technology-partner.webp" alt="" srcset="" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 my-2">
                            <div className="card shadow-lg text-muted h-100">
                                <div className="card-body">
                                    <figure>
                                        <img src="images/amd-partner.webp" alt="" srcset="" />
                                    </figure>
                                </div></div>
                        </div>
                        <div className="col-lg-4 col-md-6 my-2">
                            <div className="card shadow-lg text-muted h-100">
                                <div className="card-body">
                                    <figure>
                                        <img src="images/dell-oem-partner.webp" alt="" srcset="" />
                                    </figure>
                                </div></div>
                        </div>
                        <div className="col-lg-4 col-md-6 my-2">
                            <div className="card shadow-lg text-muted h-100">
                                <div className="card-body">
                                    <figure>
                                        <img src="images/dell-partner-direct.webp" alt="" srcset="" />
                                    </figure></div></div>
                        </div>
                        <div className="col-lg-4 col-md-6 my-2">
                            <div className="card shadow-lg text-muted h-100">
                                <div className="card-body">
                                    <figure>
                                        <img src="images/supermicro-partner.webp" alt="" srcset="" />
                                    </figure></div></div>
                        </div>
                        <div className="col-lg-4 col-md-6 my-2">
                            <div className="card shadow-lg text-muted h-100">
                                <div className="card-body">
                                    <figure>
                                        <img src="images/seagate-logo.webp" alt="" srcset="" />
                                    </figure></div></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mt-5 p-5 text-muted bg-light">
                <div className="container">
                    <div className="row">
                        <h3 className="mt-7 mb-5">
                        Key Certifications & Technology Expertise
                        </h3>
                        <div className="col-lg-4 col-md-6 my-2">
                            <div className="card shadow-lg text-muted h-100">
                                <div className="card-body">
                                    <figure>
                                        <img src="images/vmware-partner.webp" alt="" srcset="" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 my-2">
                            <div className="card shadow-lg text-muted h-100">
                                <div className="card-body">
                                    <figure>
                                        <img src="images/hortonworks-partner.webp" alt="" srcset="" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 my-2">
                            <div className="card shadow-lg text-muted h-100">
                                <div className="card-body">
                                    <figure>
                                        <img src="images/red-hat-partner.webp" alt="" srcset="" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 my-2">
                            <div className="card shadow-lg text-muted h-100">
                                <div className="card-body">
                                    <figure>
                                        <img src="images/cisco-partner.webp" alt="" srcset="" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 my-2">
                            <div className="card shadow-lg text-muted h-100">
                                <div className="card-body">
                                    <figure>
                                        <img src="images/microsoft-certified-partner.webp" alt="" srcset="" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 my-2">
                            <div className="card shadow-lg text-muted h-100">
                                <div className="card-body">
                                    <figure>
                                        <img src="images/quality-partner.webp" alt="" srcset="" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <GlobalPresence />
            <JoinCommunity />
            <InsightSlider />
            <LetsConnect />
        </React.Fragment>
    )
}

export default TechnologyPartners