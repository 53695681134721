import React from 'react'

const ContactUs = () => {
    return (
        <React.Fragment>
            <div className="p-5 text-muted">
                <div className="row gx-md-8 gx-xl-12 gy-10 contact-us-form">
                    <div className="col-lg-6 pt-10 pt-md-10 pb-10 bg-soft-primary justify-content-end d-flex mt-5">
                        <div className="px-4" style={{ maxWidth: 600 }}>
                            <img src="images/" alt="" srcset="" />
                            <h3 className='text-center text-lg-start fw-bold'>Contact Us</h3>
                            <p className='fs-5 my-3 my-lg-0'>
                                Our global experts are prepared to assist your business. Contact one of our experts today.
                            </p>
                            <p className='fs-5  mt-3 fw-bold'>We look forward to hearing from you!</p>
                            <div className="ps-4 ps-md-10 ps-md-11 ps-lg-14 pt-7 pb-10">
                                <div className="d-flex flex-row  mt-5">
                                    <div>
                                        <div className="icon text-primary fs-28 me-4 mt-n1">
                                            {" "}
                                            <i className="uil uil-location-pin-alt text-warning fs-4 mx-2" />{" "}
                                        </div>
                                    </div>
                                    <div className="align-self-start justify-content-start">
                                        <h5 className="mb-1 fw-bold">DataCentrix™</h5>
                                        <address>
                                        DataCentrix™
                                        68, Circular Road, # 02-01, 
                                            <br />
                                            Singapore, 049422
                                        </address>
                                    </div>
                                </div>
                                <div className="d-flex flex-row  mt-3">
                                    <div>
                                        <div className="icon text-primary fs-28 me-4 mt-n1">
                                            {" "}
                                            <i className="uil uil-phone-volume text-warning fs-4 mx-2" />{" "}
                                        </div>
                                    </div>
                                    <div>
                                        <h5 className="mb-1 fw-bold">Phone</h5>
                                        <p>+91 9136 123455</p>
                                    </div>
                                </div>
                                {/* Fax Section */}
                                {/* <div className="d-flex flex-row">
                                    <div>
                                        <div className="icon text-primary fs-28 me-4 mt-n1">
                                            {" "}
                                            <i className="uil uil-print text-warning fs-4 mx-2" />{" "}
                                        </div>
                                    </div>
                                    <div>
                                        <h5 className="mb-1">Fax</h5>
                                        <p>+(1) 408-943-8101</p>
                                    </div>
                                </div> */}
                                {/* Fax Section */}
                                <div className="d-flex flex-row  mt-3">
                                    <div>
                                        <div className="icon text-primary fs-28 me-4 mt-n1">
                                            {" "}
                                            <i className="uil uil-envelope text-warning fs-4 mx-2" />{" "}
                                        </div>
                                    </div>
                                    <div>
                                        <h5 className="mb-1 fw-bold">For support email us to :</h5>
                                        <p className="mb-0">
                                            <a href="mailto:info@serviceglobal.com" className="link-body">
                                            smc@datacentrix.com
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 pt-0 pt-md-10 pb-10 pb-md-10 mt-5">
                        <section className="section-frame overflow-hidden">
                            <div className="wrapper">
                                <div className="container pt-8 pb-10 pb-md-10">
                                    <div className="row">
                                        <div className="col-xl-11 mx-auto">
                                            <div className="row gy-10 gx-lg-8 gx-xl-12">
                                                <div className="col-lg-12">
                                                    <form className="contact-form needs-validation">
                                                        <div className="messages" />
                                                        <div className="row gx-4">
                                                            <div className="col-md-12">
                                                                <div className="form-floating mb-4">
                                                                    <input
                                                                        id="firstname"
                                                                        type="text"
                                                                        name="firstname"
                                                                        className="form-control"
                                                                        placeholder="First Name"
                                                                        required=""
                                                                    />
                                                                    <label htmlFor="firstname">First Name *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-floating mb-4">
                                                                    <input
                                                                        id="lastname"
                                                                        type="text"
                                                                        name="lastname"
                                                                        className="form-control"
                                                                        placeholder="Last Name"
                                                                        required=""
                                                                    />
                                                                    <label htmlFor="lastname">Last Name *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-floating mb-4">
                                                                    <input
                                                                        id="email"
                                                                        type="email"
                                                                        name="email"
                                                                        className="form-control"
                                                                        placeholder="Email"
                                                                        required=""
                                                                    />
                                                                    <label htmlFor="email">Email *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-floating mb-4">
                                                                    <input
                                                                        id="customer_account"
                                                                        type="text"
                                                                        name="customer_account"
                                                                        className="form-control"
                                                                        placeholder="Company Name"
                                                                        required=""
                                                                    />
                                                                    <label htmlFor="customer_account">
                                                                        Company Name *
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="form-floating mb-4">
                                                                    <textarea
                                                                        id="field[14]"
                                                                        type="text"
                                                                        name="message"
                                                                        className="form-control"
                                                                        placeholder="Your Message"
                                                                        defaultValue={""}
                                                                    />
                                                                    <label htmlFor="message">Message</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="form-check mb-4">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id="invalidCheck"
                                                                        required=""
                                                                        defaultValue=""
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="invalidCheck"
                                                                    >
                                                                        I agree to{" "}
                                                                        <a href="/privacy-policy" className="hover">
                                                                            terms and policy
                                                                        </a>
                                                                        .
                                                                    </label>
                                                                    <div className="invalid-feedback">
                                                                        You must agree before submitting.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-12 col-xxl-6">
                                                                <div>
                                                                    <div>
                                                                        <div style={{ width: 304, height: 78 }}>
                                                                            <div>
                                                                                <iframe
                                                                                    title="reCAPTCHA"
                                                                                    width={304}
                                                                                    height={78}
                                                                                    role="presentation"
                                                                                    name="a-ayi954rja8my"
                                                                                    frameBorder={0}
                                                                                    scrolling="no"
                                                                                    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                                                                                    src="https://www.google.com/recaptcha/api2/anchor?ar=1&k=6LcwIw8TAAAAACP1ysM08EhCgzd6q5JAOUR1a0Go&co=aHR0cHM6Ly9pcm9uc2VydmljZWdsb2JhbC5jb206NDQz&hl=en&type=image&v=V6_85qpc2Xf2sbe3xTnRte7m&theme=light&size=normal&badge=bottomright&cb=kq8q4ur2jbrq"
                                                                                />
                                                                            </div>
                                                                            <textarea
                                                                                id="g-recaptcha-response-8"
                                                                                name="g-recaptcha-response"
                                                                                className="g-recaptcha-response"
                                                                                style={{
                                                                                    width: 250,
                                                                                    height: 40,
                                                                                    border: "1px solid rgb(193, 193, 193)",
                                                                                    margin: "10px 25px",
                                                                                    padding: 0,
                                                                                    resize: "none",
                                                                                    display: "none"
                                                                                }}
                                                                                defaultValue={""}
                                                                            />
                                                                        </div>
                                                                        <iframe style={{ display: "none" }} />
                                                                    </div>
                                                                </div>
                                                                <p className="text-danger" />
                                                            </div>
                                                            <div className="col-xl-12 col-xxl-6 ps-xxl-8">
                                                                <input
                                                                    type="submit"
                                                                    className="btn btn-outline-warning btn-large rounded-pill btn-send mb-3"
                                                                    defaultValue="Send message"
                                                                />
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ContactUs