import React from 'react'
import GlobalPresence from '../../Components/GlobalPresence/GlobalPresence'
import JoinCommunity from '../../Components/JoinCommunity/JoinCommunity'
import LetsConnect from '../../Components/LetsConnect/LetsConnect'
import GlobalBrands from '../../Components/GlobalBrands/GlobalBrands'
import InsightSlider from '../../Components/InsightSlider/InsightSlider'

const WarrantyExtensions = () => {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/extended-warranty-banner.jpeg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold text-capitalize'>Customized services to enhance planning security</h1>
              <p className="mt-5 text-warning" style={{fontSize:'30px'}}>
              Extended Warranty
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section>
        <div className="container-fluid mt-5 p-5 text-muted bg-light">
          <div className="row">
            <div className="col-lg-4 mt-lg-5">
              <h3 className='px-4 text-center text-lg-start text-capitalize'>
                Rapid service and extended security post-manufacturer warranty</h3>
            </div>
            <div className="col-lg-4 mt-5 fs-5">
              <p>The breakdown of critical IT infrastructure can severely disrupt your company's operations. While manufacturers cover standard repairs during the warranty period, this may not suffice for critical business systems. As the leading third-party maintenance provider in German-speaking countries and across Europe, we deliver fast, dependable, and maximum security for your IT systems, even after the manufacturer's warranty has lapsed</p>
            </div>
            <div className="col-lg-4 mt-lg-5 fs-5">
              <p>Leverage our independent expertise across all major brands, including HPE ProLiant, Dell/EMC PowerEdge, Fujitsu Primergy, or IBM/Lenovo X Series. You have the flexibility to tailor the scope of our services, whether integrated into your hardware maintenance or as a standalone service. Our offerings extend far beyond the standard manufacturer's warranty, providing enhanced flexibility and security for your IT infrastructure</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid py-5 text-muted">
          <h1 className="text-center text-capitalize">Service-level agreements.</h1>
          <p className="mt-3 fs-5 text-center">Choose the right scope of services for your business.</p>
          <div className='row lead'>
            <div class="col-12">
              <ul class="text-center list-unstyled">
                <li className='py-2'>Service availability of 5x13 hours or 7x24 hours.</li>
                <li className='py-2'>Telephone response time of either 15 or 60 minutes.</li>
                <li className='py-2'>On-site technician arrival within 2 hours, 4 hours, or Next Business Day (NBD).</li>
                <li className='py-2'>Adaptable contract terms</li>
              </ul>
            </div>


          </div>


        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5" style={{color:'white',height:'400px',background:`url('images/warranty-ext.png')`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-lg-12 px-3 mt-lg-5 mt-0">
              <h3 className='fw-bold text-center text-capitalize'>We provide support for the following brands, models, and systems</h3>
              <p className="fs-5 mt-2 text-center text-capitalize">We provide extended warranties for both new and used IT systems from all manufacturers.</p>
            </div>
            {/* <div className="col-lg-6 p-5">
              <img src='images/warranty-ext.png' />
            </div> */}
          </div>
        </div>
      </section>
      <GlobalPresence />
      <JoinCommunity />
      <InsightSlider />
      <LetsConnect />
    </React.Fragment>

  )
}

export default WarrantyExtensions