import React, { useState } from 'react'
import SliderBullets from '../SliderBullets/SliderBullets'
import { insight_slider_content, insight_slider_transform, slider_transform } from '../../Services/ConstantVariables'

const InsightSlider = () => {
    const [activeBullet, setActiveBullet] = useState(0)

    const onBulletClick = (index) => {
        setActiveBullet(index)
        console.log("insight_slider_transform[index]", insight_slider_transform[index]);
        const element = document.getElementsByClassName('swiper-wrapper-insight')[0]
        element.style.transform = `translateX(${insight_slider_transform[index]}) translateY(0px) translateZ(0px)`;
        element.style.transitionDuration = '300ms';
        console.log("element", element);
    }
    return (
        <div className="swiper-container blog grid-view">
            <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events case-studies-swiper pb-12 swiper-backface-hidden">
                <div
                    className="swiper-wrapper swiper-wrapper-insight mb-5"
                // style={{ transform: "translate3d(0px, 0px, 0px)" }}
                >
                    {
                        insight_slider_content?.map(element => (
                            <div
                                className="swiper-slide swiper-slide-active text-muted"
                                style={{ width: 369, marginRight: 30, height:500 }}
                            >
                                <a href="/blog/leverage-service-global-to-keep-your-business-up-and-running-dispatch-on-demand/" />
                                <div className="card shadow-lg hover-scale insight-card-heigth bg-transparent shadow-none h-100">
                                    <a href="/blog/leverage-service-global-to-keep-your-business-up-and-running-dispatch-on-demand/" />
                                    <article>
                                        <a href="/blog/leverage-service-global-to-keep-your-business-up-and-running-dispatch-on-demand/" />
                                        <figure className="rounded mb-2 p-2">
                                            <img src={element.image} height={200} width={350} alt="" srcset="" />
                                        </figure>
                                        <div className="post-header px-3 text-start d-flex">
                                            <h5 className="post-title h3  blogThreeLines insight-card-text-height">
                                                <a
                                                    className="link-dark text-decoration-none fs-5 fw-bold text-muted"
                                                    href="/blog/leverage-service-global-to-keep-your-business-up-and-running-dispatch-on-demand/"
                                                >
                                                    {element.content}
                                                </a>
                                            </h5>
                                        </div>
                                        <div className="post-footer">
                                            <ul
                                                className="post-meta text-start ps-3 pt-5 pb-3 d-flex gap-3 mt-5"
                                                style={{ borderTop: "1px solid rgb(197, 214, 227)" }}
                                            >
                                                <li className="post-date list-unstyled insight-slider-list">
                                                    <i className="uil uil-calendar-alt  text-warning fs-5" />
                                                    <span className='text-muted'>{element.date}</span>
                                                </li>
                                                <span>|</span>
                                                <li className="post-comments list-unstyled insight-slider-list">
                                                    <a aria-current="page" className="text-decoration-none" href="/">
                                                        <i className="uil uil-user pe-2 text-warning fs-5" />
                                                        <span className='pe-3'>{element.name}</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
                    <SliderBullets pagination={5} pageSize={2} onBulletClick={onBulletClick} activeBullet={activeBullet} />
                </div>
            </div>
        </div>

    )
}

export default InsightSlider