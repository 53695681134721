import React from "react";
import JoinCommunity from "../../Components/JoinCommunity/JoinCommunity";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";
import GlobalPresence from "../../Components/GlobalPresence/GlobalPresence";
import InsightSlider from "../../Components/InsightSlider/InsightSlider";

const ValueAddedServices = () => {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/value-added-services-bnr.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold text-capitalize'>Increase focus on your main operations by utilizing
                  value-added services</h1>
              <p className="mt-5 text-warning text-capitalize" style={{fontSize:'30px'}}>
              We specialize in optimizing your IT infrastructure, offering a
                  single point of contact for everything from data center to
                  building facilities. Our range of refurbished hardware
                  provides a sustainable alternative to new products,
                  professionally configured and readily available.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper bg-soft-primary p-5  mt-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 position-relative order-lg-2 py-5">
              <div className="shape bg-soft-primary rounded-circle rellax w-20 h-20" />
              <img src="images/analytics-reporting.jpg" />
            </div>
            <div className="col-lg-6 text-muted px-5">
              <h3 className="mb-3 text-center text-lg-start">Reporting and Analysis</h3>
              <p className="fs-5 text-center text-lg-start">
                We offer both active and passive monitoring services, ensuring
                continuous analysis of your systems through regular checks.
              </p>
              <ul className="icon-list bullet-bg bullet-soft-blue mb-0 mt-3 list-unstyled">
                <li className="d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2 rounded-pill bg-light" />
                  </span>
                  <span className="fs-5">Complete support for top manufacturers</span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    We offer flexible solutions to meet your specific needs and
                    preferences
                  </span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    Seamless integration and adaptable reporting solutions
                    tailored to your requirements
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper text-muted bg-light p-5  mt-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 position-relative py-5">
              <div className="shape bg-soft-primary rounded-circle rellax w-20 h-20" />
              <img src="images/it-infta.jpg" />
            </div>
            <div className="col-lg-6 px-5">
              <h3 className="mb-3 text-center text-lg-start text-capitalize">Concerted lineup of services</h3>
              <div className="fs-5 text-center text-lg-start">We'll oversee the IT infrastructure and concerted lineup of services as given below :</div>
              <ul className="icon-list bullet-bg bullet-soft-primary mb-0 mt-3 list-unstyled">
                <li className="d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    We'll deploy your IT systems and guarantee accessibility.
                  </span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">Handle relocations to new facilities.</span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    Ensure proper disposal and recycling of your outdated
                    equipment.
                  </span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">Improve and customize your hardware and software.</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper bg-soft-primary text-muted p-5 mt-5">
        <div className="container">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-6 position-relative order-lg-2">
              <div className="shape bg-soft-primary rounded-circle rellax w-20 h-20" />
              <img src="images/helping-hand.jpeg" />
            </div>
            <div className="col-lg-6 px-5">
              <h3 className="my-3 text-center text-lg-start text-capitalize">
                Supporting your data center needs.
              </h3>
              <p className="fs-5 text-center text-lg-start">
                Our on-site experts are deployed as needed to save you time,
                effort, and money, ensuring flexibility.
              </p>
              <ul className="icon-list bullet-bg bullet-soft-blue mb-0 mt-3 list-unstyled">
                <li className="d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">Administrative infrastructure duties.</span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    Round-the-clock support from our floor service specialists
                  </span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    Comprehensive agreement offering discounts of up to 50%.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper text-muted p-5 mt-5 bg-light">
        <div className="container">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-6 position-relative">
              <div className="shape bg-soft-primary rounded-circle rellax w-20 h-20" />
              <img src="images/it-infta.jpg" />
            </div>
            <div className="col-lg-6 px-5">
              <h3 className="my-3 text-center text-lg-start text-capitalize">
                Ready for all the emergencies{" "}
              </h3>
              <div className="fs-5 text-center text-lg-start">
                Our TG NES.i emergency replacement system is readily available
                and swiftly deployed on-site to guarantee rapid recovery in case
                of system failure.
              </div>
              <ul className="icon-list bullet-bg bullet-soft-primary mb-0 mt-3 list-unstyled">
                <li className="d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    Tailored needs assessment and transparent expenses.
                  </span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">
                    Implementation of an emergency replacement system.
                  </span>
                </li>
                <li className="mt-3 d-flex flex-row">
                  <span>
                    <i className="uil uil-check text-warning fs-4 mx-2" />
                  </span>
                  <span className="fs-5">Delivery within 12-hour timeframe</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper">
        <div className="container-fluid mt-5 p-5 text-muted">
          <div className="row">
            <div className="col">
              <h3 className="mb-3 text-center text-capitalize">
                All-inclusive package with managed services.
              </h3>
              <h5 className="fs-5 mb-9 text-center">
                Delivering unparalleled provision, meticulous maintenance, and
                uncompromising security measures to ensure optimal performance
                and safeguard your assets.
              </h5>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-6 col-lg-4 my-3">
              <div className="card main-card shadow-lg text-muted h-100">
              <div className="card-body">
              <div className="text-center maintain-icon">
                <i className="bi bi-display text-warning fs-2 mx-2" />
              </div>
              <h5 className="my-2 text-center fw-bold mt-3">Continuous monitoring of your systems</h5>
              <p className="mb-3 fs-5">
                Stay ahead with proactive IT monitoring, keeping all aspects of
                your systems in view. Gain real-time insights to anticipate and
                address issues before they impact operations, ensuring
                uninterrupted productivity and peace of mind.
              </p>
              </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 my-3">
            <div className="card main-card shadow-lg text-muted h-100">
              <div className="card-body">
              <div className="text-center maintain-icon">
                <i className="bi bi-eye text-warning fs-2 mx-2" />
              </div>
              <h5 className="my-2 text-center fw-bold mt-3">Complete and comprehensive offerings</h5>
              <p className="mb-3 fs-5">
                We offer comprehensive network services, encompassing
                installation, moves, adds, changes (IMAC), and server
                maintenance. Our expertise extends across the entire server
                chain, ensuring seamless operations and optimal performance.
              </p>
            </div></div></div>
            <div className="col-md-6 col-lg-4 my-3">
            <div className="card main-card shadow-lg text-muted h-100">
              <div className="card-body">
              <div className="text-center maintain-icon">
                <i className="bi bi-bar-chart-line-fill text-warning fs-2 mx-2" />
              </div>
              <h5 className="my-2 text-center fw-bold mt-3">Full takeover of all services.</h5>
              <p className="mb-3 fs-5">
                Our floor and support services are customized to meet your
                specific requirements, ensuring seamless integration and optimal
                support tailored precisely to your organization's needs.
              </p>
            </div></div></div>
            <div className="col-md-6 col-lg-4 my-3">
            <div className="card main-card shadow-lg text-muted h-100">
              <div className="card-body">
              <div className="text-center maintain-icon">
                <i className="bi bi-arrow-repeat text-warning fs-2 mx-2" />
              </div>
              <h5 className="my-2 text-center fw-bold mt-3">Full protection with rapid recovery</h5>
              <p className="mb-3 fs-5">
                Ensure optimal business protection with our emergency
                replacement system, offering swift response and reliable
                safeguarding to minimize disruptions and maintain continuity in
                critical situations.
              </p>
            </div></div></div>
            <div className="col-md-6 col-lg-4 my-3">
            <div className="card main-card shadow-lg text-muted h-100">
              <div className="card-body">
              <div className="text-center maintain-icon">
                <i className="bi bi-cart-plus text-warning fs-2 mx-2" />
              </div>
              <h5 className="my-2 text-center fw-bold mt-3">High quality, low costs</h5>
              <p className="mb-3 fs-5">
                Our refurbished hardware offers sustainability, affordability,
                and environmental friendliness compared to new equipment,
                professionally restored for optimal performance and
                cost-effectiveness.
              </p>
            </div></div></div>
            <div className="col-md-6 col-lg-4 my-3">
            <div className="card main-card shadow-lg text-muted h-100">
              <div className="card-body">
              <div className="text-center maintain-icon">
                <i className="bi bi-briefcase-fill text-warning fs-2 mx-2" />
              </div>
              <h5 className="my-2 text-center fw-bold mt-3">Complete network solutions for your business.</h5>
              <p className="mb-3 fs-5">
                Empower your business with robust networks, enhancing agility,
                security, and scalability. Our solutions provide the foundation
                for efficient operations, ensuring seamless growth and
                safeguarding against potential threats.
              </p>
            </div></div></div>
          </div>
        </div>
      </section>
      <GlobalPresence/>
      <JoinCommunity/>
      <InsightSlider/>
      <LetsConnect />
    </React.Fragment>
  );
};

export default ValueAddedServices;
