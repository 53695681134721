import React from "react";
import TalkToAnExpert from "../../Components/TalkToAnExpert/TalkToAnExpert";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";
import JoinCommunity from "../../Components/JoinCommunity/JoinCommunity";
import LatestInsights from "../../Components/LatestInsights/LatestInsights";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";

const EnterpriseIntegrationServices = () => {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/integration-services-banner.webp)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Enterprise Integration Services</h1>
              <p className="mt-5 text-warning text-capitalize" style={{fontSize:'30px'}}>
              Connected world within an Enterprise
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section className="wrapper mt-5 p-5 bg-light text-muted">
        <div className="container pt-10 pt-md-10 pb-10 pb-md-12">
          <div className="row gy-6">
            <h4 className="mt-5 mt-md-5 mb-0 text-center">
              Enterprise Integration Services
            </h4>
            <p className="fs-5 mt-3 text-center">
            DataCentrix™'s integration specialists handle data flow and security
              between client systems and web services, supporting digital
              initiatives such as workflows, customer experience, and edge
              computing.
            </p>
          </div>
        </div>
      </section>
      <section
        id="snippet-2"
        className="wrapper mt-5 p-5 text-muted"
      >
        <div className="container pt-10 pt-md-12 pb-12 pb-md-13">
          <h3 className="mb-10 text-center text-capitalize">
          DataCentrix™ offers diverse levels of Enterprise Integration for our clients.
          </h3>
          <div className="row gx-lg-8 gx-xl-12 gy-6 process-wrapper line mt-5">
            <div className="col-md-6 col-lg-3 my-2">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                <div className="text-center">
                <span className="number btn btn-warning">01</span>
              </div>
              <h5 className="my-3 text-center fw-bold">Experiment Level</h5>
              <p className="">
                Integration of innovation and digital products that enables
                seamless collaboration to{" "}
                <strong>enhance customer experience.</strong>
              </p>

                </div>
              </div>
              
            </div>
            <div className="col-md-6 col-lg-3 my-2">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                <div className="text-center">
                <span className="number btn btn-warning">02</span>
              </div>
              <h5 className="my-3 text-center fw-bold">Process Level</h5>
              <p className="">
                Integrating business processes and functions within the
                organization enables seamless execution, notifying and alerting
                stakeholders for efficient business process execution.
              </p>
                </div>
              </div>
              
              
            </div>
            <div className="col-md-6 col-lg-3 my-2">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                <div className="text-center">
                <span className="number btn btn-warning">03</span>
              </div>
              <h5 className="my-3 text-center fw-bold">Application Level</h5>
              <p>
                Facilitates data integration across databases, file systems, web
                services, SaaS, and various applications, ensuring seamless
                connectivity and accessibility
              </p>
                </div>
              </div>
        
             
             
             
            </div>
            <div className="col-md-6 col-lg-3 my-2">
              <div className="card shadow-lg text-muted h-100 hover-metic">
                <div className="card-body">
                <div className="text-center">
                <span className="number btn btn-warning">04</span>
              </div>
              <h5 className="my-3 text-center fw-bold">Partner Level</h5>
              <p>
                <strong>Facilitates integration</strong> of the partner
                ecosystem with the client's system landscape.
              </p>
                </div>
              </div>
              
              
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper mt-5 p-5 text-muted">
        <div className="container pt-9 pt-lg-14 pb-8 pb-lg-14">
          <div className="row gx-lg-8 gx-xl-10">
            <div className="col-lg-4 col-12 mt-lg-5 mt-0">
              <figure className="rounded shadow mb-6 mb-md-0">
                <img
                  src="images/app-integration.jpg"
                  height={400}
                  width={350}
                  alt=""
                  srcset=""
                />
              </figure>
            </div>
            <div className="col-lg-8 col-12 px-lg-5 px-0">
              <h3 className="mb-5 text-center text-lg-start mt-5 mt-lg-0 text-capitalize">
                Our approach towards Enterprise Integration Projects
              </h3>
              <div className="row gy-6 gx-xxl-8 process-wrapper">
                <div className="col-md-6 my-3">
                  <div className="card shadow-lg h-100 text-muted">
                  <div className="card-body">
                  <h5 className="mb-1 text-center fw-bold">Strategy</h5>
                  <p className="mb-0 fs-5">
                    Understand client needs and goals, strategize, and develop
                    tailored solutions to meet business objectives effectively,
                    ensuring alignment with organizational strategy.
                  </p>
                  </div>
                  </div>
                </div>
                <div className="col-md-6 my-3">
                <div className="card shadow-lg h-100 text-muted">
                  <div className="card-body">
                  <h5 className="mb-1 text-center fw-bold">Assessment</h5>
                  <p className="mb-0 fs-5">
                    Evaluate the existing integration status, pinpoint areas for
                    enhancement, and devise a precise action plan in line with
                    the clients' business objectives and strategies.
                  </p>
                  </div>
                  </div>
                </div>
                <div className="col-md-6 my-3">
                <div className="card shadow-lg h-100 text-muted">
                  <div className="card-body">
                  <h5 className="mb-1 text-center fw-bold">Adoption</h5>
                  <p className="mb-0 fs-5">
                    Create an architectural blueprint, outline the scope, draft
                    functional and technical specifications, and present the
                    implementation plan for approval, ensuring alignment with
                    the clients' requirements and objectives.
                  </p>
                  </div>
                  </div>
                </div>
                <div className="col-md-6 my-3">
                <div className="card shadow-lg h-100 text-muted">
                  <div className="card-body">
                  <h5 className="mb-1 text-center fw-bold">Run Operations</h5>
                  <p className="mb-0 fs-5">
                    Provide ongoing support for live integrations by
                    maintaining, managing, and periodically enhancing the scope
                    to ensure continuous improvement aligned with evolving
                    business needs and technological advancements.
                  </p>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <JoinCommunity />
      <LatestInsights />
      <LetsConnect />
    </React.Fragment>
  );
};

export default EnterpriseIntegrationServices;
