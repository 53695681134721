import React from "react";
import LetsConnect from "../../Components/LetsConnect/LetsConnect";
import InsightSlider from "../../Components/InsightSlider/InsightSlider";
import GlobalBrands from "../../Components/GlobalBrands/GlobalBrands";

function WorkAsService() {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/work-as-service-banner.webp)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold  text-capitalize'>Workplace as a Service</h1>
              <h1 className='fw-bold  text-capitalize'>A secure virtual workspace seamlessly integrating your workforce</h1>
              <p className="mt-5 text-warning  text-capitalize" style={{fontSize:'30px'}}>
              <strong>DataCentrix™</strong> Workplace Services ensures optimal work environments for client staff, fostering high productivity with cutting-edge technology and top-notch support services, including deskside support, remote device monitoring and management, and last-mile field services
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section className="wrapper mt-5 p-5 bg-light text-muted">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-10 col-lg-9 col-xxl-8 mx-auto">
              <h3 className="ls-sm mb-9 px-xl-11  text-capitalize">
                Workplace as a Service encompasses three essential components:
              </h3>
            </div>
          </div>
          <div className="row gx-lg-8 gx-xl-12 gy-8 mt-5">
            <div className="col-md-6 col-lg-4 offset-lg-1">
              <div className="d-flex flex-row align-items-center">
                <div>
                  <i class="bi bi-megaphone-fill text-warning fs-4 mx-2"></i>
                </div>
                <div>
                  <h5 className="fs-5 ls-sm my-2 my-lg-0">
                    Unified Communication Aa A Service (UCAAS)
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="d-flex flex-row align-items-center">
                <div>
                  <i class="bi bi-shield-fill-check text-warning fs-4 mx-2"></i>
                </div>
                <div>
                  <h4 className="fs-5 ls-sm my-2 my-lg-0">Safe Workplace</h4>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="d-flex flex-row align-items-center">
                <div>
                  <i class="bi bi-hand-index-fill text-warning fs-4 mx-2"></i>
                </div>
                <div>
                  <h4 className="fs-5 ls-sm my-2 my-lg-0">Self Service</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper p-5 text-muted bg-light">
        <div className="container">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-6 position-relative">
              <div className="shape bg-soft-primary rounded-circle rellax w-20 h-20" />
              <img src='images/cloud-connectivity.jpeg' alt="" />
            </div>
            <div className="col-lg-6 px-lg-5 px-0">
              <h4 className="mb-3 text-center text-lg-start mt-5 mt-lg-0">
              Unified Communication Aa A Service (UCAAS)
              </h4>
              <p className="fs-5">
                We provide a world-class unified communications model encompassing Audio/Video/Web conferencing, conference room design and management, enterprise telephony, unified and instant messaging, and mobility enabled by BYOD. These services are crucial in hybrid working models, and Iron has developed its own platform to facilitate them for our clients.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper p-5 text-muted bg-light">
        <div className="container">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-6 position-relative order-lg-2">
              <div className="shape bg-soft-primary rounded-circle rellax w-20 h-20" />
              <img src='images/safe-workplace.webp' />
            </div>
            <div className="col-lg-6 px-lg-5 px-0">
              <h4 className="mb-3 text-center text-lg-start mt-5 mt-lg-0">Safe Workplace</h4>
              <p className="fs-5">
                Physical and cyber security are paramount for a secure workplace. We provide services by implementing processes and systemic controls for visitor management, access control to buildings and conference rooms, environmental health and safety measures, threat monitoring and management, system security, video surveillance and intelligence, and end-user device security.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper p-5 text-muted bg-light">
        <div className="container">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-6 position-relative">
              <div className="shape bg-soft-primary rounded-circle rellax w-20 h-20" />
              <img src='images/Self-service-img.jpg' />
            </div>
            <div className="col-lg-6 px-lg-5 px-0">
              <h4 className="mb-3 text-center text-lg-start mt-5 mt-lg-0">Self Service</h4>
              <p className="fs-5">
                Enhance employee services with Knowledge Management Systems, addressing FAQs, processes, and standard operating procedures (SOPs). Through self-service options, the workforce accesses what they need, when they need it, using employee workflows and embedding intelligence into all interactions to break down silos and boost productivity.
              </p>
            </div>
          </div>
        </div>
      </section>
      <LetsConnect />
    </React.Fragment>
  );
}

export default WorkAsService;
