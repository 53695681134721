import React from 'react'
import JoinCommunity from '../../Components/JoinCommunity/JoinCommunity'
import GlobalBrands from '../../Components/GlobalBrands/GlobalBrands'
import GlobalPresence from '../../Components/GlobalPresence/GlobalPresence'
import InsightSlider from '../../Components/InsightSlider/InsightSlider'
import LetsConnect from '../../Components/LetsConnect/LetsConnect'

const FloorAndSupportServices = () => {
  return (
    <React.Fragment>
      <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/support-i.png)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold text-capitalize'>Floor services for seamless data center operation</h1>
              <p className="mt-5 text-warning text-capitalize" style={{fontSize:'30px'}}>
              Benefit from invaluable support for your data centers. Our expert assistance ensures smooth operations, optimal performance, and peace of mind. From proactive monitoring to emergency response, we're here to keep your data centers running seamlessly
              </p>
            </div>
          </div>
        </div>
      </section>
      <GlobalBrands />
      <section className="wrapper mt-5 p-5 text-muted bg-light">
        <div className="container">
          <div className="row align-items-center just-content-center">
            <div className="col-lg-6">
              <h3 className="mb-3 text-center text-lg-start text-capitalize">On-site support services tailored to your needs</h3>
            </div>
            <div className="col-lg-6 mt-3 mt-lg-0">
              <p className='fs-5'>Our team provides versatile support at your data center, freeing up your own staff. From manual tasks like cabling to hardware/software monitoring, we ensure smooth operations and assist with external service provider coordination.</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-2 mt-lg-5 p-5 text-muted">
          <div className="row">
            <h3 className="text-center my-2 text-capitalize">Boost efficiency with our supportive services</h3>
            {/* <p className='lead text-muted'>Efficient floor services tailored to your data center</p> */}
            <div className="row mt-5">
              <div className="col-lg-4 col-md-6 my-3">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className='text-center'>
                      <i class="bi bi-hand-index-thumb text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className='text-center my-2'>Effortless time-saving solutions</h5>
                      <p className='fs-5'>Eliminate the need for trained IT staff at every branch, freeing up administrators to focus on core business operations</p>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-4 col-md-6 my-3">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className='text-center'>
                      <i class="bi bi-piggy-bank text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className='text-center my-2 fw-bold'>Savings</h5>
                      <p className='fs-5'>Take advantage of our tailored offers for attractive pricing. Order floor services as needed for added flexibility and convenience</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 my-3">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className='text-center'>
                      <i class="bi bi-geo-alt text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className='text-center my-2 fw-bold'>On-site expertise</h5>
                      <p className='fs-5'>Our specialists are your reliable partners and support your IT team on site.</p>
                    </div>
                  </div>
                </div></div>
              <div className="col-lg-4 col-md-6 my-3">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className='text-center'>
                      <i class="bi bi-clock text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className='text-center my-2 fw-bold'>24/7 rapid response</h5>
                      <p className='fs-5'>If you need to quickly maintain, deploy, fix or shut down your equipment, you can request our professional technicians around the clock to support your in-house IT team.</p>
                    </div>
                  </div>
                </div></div>
              <div className="col-lg-4 col-md-6 my-3">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className='text-center'>
                      <i class="bi bi-eye-fill text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className='text-center my-2 fw-bold'>Proactive precautions</h5>
                      <p className='fs-5'>Our floor service specialists can monitor your data center, providing maintenance, support, and crisis management. Enhance these services with remote administration, installation, and troubleshooting for comprehensive support.</p>
                    </div>
                  </div>
                </div></div>
              <div className="col-lg-4 col-md-6 my-3">
                <div className="card shadow-lg text-muted h-100 main-card">
                  <div className="card-body">
                    <div className='text-center'>
                      <i class="bi bi-eye-fill text-warning fs-2 mx-2"></i>
                    </div>
                    <div>
                      <h5 className='text-center my-2 fw-bold'>Tailored flexibility and personalized service</h5>
                      <p className='fs-5'>We collaborate with our customers to create a cost plan aligned with their operational needs, enabling flexible responses to specific requirements.</p>
                    </div>
                  </div></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5 p-5 text-muted">
          <div className="row">
            <h3 className="text-center my-2 text-capitalize">Floor services: Versatile support solutions tailored to your data center's unique needs and requirements</h3>
            {/* <p className='lead text-muted'>Efficient floor services tailored to your data center</p> */}
            <div className="row mt-3">
              <div className="col-lg-3 col-md-6 my-2">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body">
                    <div>
                      <h5 className='text-center my-2 fw-bold mt-3'>Escort Service</h5>
                      <p className='fs-5'>We provide escort services for unauthorized external service providers within your data center</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 my-2">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body">
                    <div>
                      <h5 className='text-center my-2 fw-bold mt-3'>Checks</h5>
                      <p className='fs-5'>We conduct regular inspections to promptly detect visible or audible faults</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 my-2">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body">
                    <div>
                      <h5 className='text-center my-2 fw-bold mt-3'>Cabling service</h5>
                      <p className='fs-5'>We offer implementation of either top-of-rack or structured cabling solutions for your data center</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 my-2">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body">
                    <div>
                      <h5 className='text-center my-2 fw-bold mt-3'>Preventive Measures</h5>
                      <p className='fs-5'>Ensure full protection for your high-performance systems with our comprehensive range of services, including remote administration, installation, and troubleshooting</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 my-2">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body">
                    <div>
                      <h5 className='text-center my-2 fw-bold mt-3'>Installation and dismantling</h5>
                      <p className='fs-5'>We dismantle old server systems and arrange collection and disposal of equipment. Additionally, we prepare new systems according to your specifications</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 my-2">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body">
                    <div>
                      <h5 className='text-center my-2 fw-bold mt-3'>Documentation</h5>
                      <p className='fs-5'>We meticulously document cabling and hardware configurations for thorough organization and future reference</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 my-2">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body">
                    <div>
                      <h5 className='text-center my-2 fw-bold mt-3'>Replacement</h5>
                      <p className='fs-5'>We can replace your hardware.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 my-2">
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body">
                    <div>
                      <h5 className='text-center my-2 fw-bold mt-3'>Relocation</h5>
                      <p className='fs-5'>We guarantee seamless relocation of hardware components within your data center for uninterrupted operations</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <GlobalPresence />
      <JoinCommunity />
      <InsightSlider />
      <LetsConnect />
    </React.Fragment>
  )
}

export default FloorAndSupportServices