import React from "react";
import LetsConnect from "../LetsConnect/LetsConnect";
import JoinCommunity from "../JoinCommunity/JoinCommunity";
import GlobalBrands from "../GlobalBrands/GlobalBrands";
function ModernDeviceManagement() {
  return (
    <React.Fragment>
        <section className=''>
        <div className="container-fluid home-service-global-container" style={{background:`url(images/modern-device-management-banner.png)`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center bottom'}}>
          <div className="row mt-5">
            <div className="col-12 text-center text-white fw-bold">
              <h1 className='fw-bold'>Modern Device Management</h1>
              <p className="mt-5 text-warning  text-capitalize" style={{fontSize:'30px'}}>
              For an enhanced experience and full control over your IT devices
              </p>
            </div>
          </div>
        </div>
      </section>
        <GlobalBrands/>
        <section id="snippet-2" className="wrapper bg-light mt-5 p-5 text-muted">
          <div className="container pt-10 pt-md-12 pb-12 pb-md-13">
            <h3 className="mb-3 text-center  text-capitalize">
            Global Field Maintenance and Technical Support Solutions
            </h3>
            <p className="mb-8 text-center fs-5">
            The DataCentrix™ portal platform empowers you to deliver top-notch support, maintaining the industry-standard 4-Hour SLA for your customers. ISG’s Technical Support Services grant customers access to experienced service professionals and technical support specialists.
            </p>
            <div className="row gx-lg-8 gx-xl-12 gy-6 process-wrapper line text-center">
              <div className="col-md-6 col-lg-3">
                {" "}
                <span className="icon btn btn-circle btn-lg btn-soft-blue pe-none mb-4">
                  <i className="uil uil-file-edit-alt text-warning fs-2 mx-2" />
                </span>
                <h5 className="mb-1">Web-Based Case Management System</h5>
              </div>
              <div className="col-md-6 col-lg-3">
                {" "}
                <span className="icon btn btn-circle btn-lg btn-soft-blue pe-none mb-4">
                  <i className="uil uil-setting text-warning fs-2 mx-2" />
                </span>
                <h5 className="mb-1">Remote OEM Technical Assistance</h5>
              </div>
              <div className="col-md-6 col-lg-3">
                {" "}
                <span className="icon btn btn-circle btn-lg btn-soft-blue pe-none mb-4">
                  <i className="uil uil-cloud-data-connection text-warning fs-2 mx-2" />
                </span>
                <h5 className="mb-1">Remote System Diagnostics</h5>
              </div>
              <div className="col-md-6 col-lg-3">
                {" "}
                <span className="icon btn btn-circle btn-lg btn-soft-blue pe-none mb-4">
                  <i className="uil uil-refresh text-warning fs-2 mx-2" />
                </span>
                <h5 className="mb-1">Maintenance, Repairs, and Returns Services</h5>
              </div>
            </div>
          </div>
        </section>
        <section className="wrapper mt-5 p-5 text-muted">
          <div className="container">
            <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
              <div className="col-lg-6">
                <h3 className="mb-3">
                Managed Supply Chain Services
                </h3>
                <p className="fs-5">
                DataCentrix™ offers a comprehensive range of procurement and supply chain services, including order fulfillment, logistics management, delivery, managed export compliance, and returns programs. With global expertise and resources, we manage all your supply chain requirements across the globe for various product types.
                </p>
                <p className="fs-5">
                Utilizing our robust vendor relationships with top IT vendors, we guarantee the availability of sourced equipment precisely when and where you require it. With a network spanning over 600+ International Warehouse Stocking Locations, we can provide 4-hour service to most regions worldwide. ISG has meticulously navigated the legal and regulatory landscapes of each country, handling all necessary paperwork for seamless execution of logistics and services.
                </p>
              </div>
              <div className="col-lg-6 position-relative">
                <div className="shape bg-soft-primary rounded-circle rellax w-20 h-20" />
                <img src='images/supply-chani-management.webp' />
              </div>
            </div>
          </div>
        </section>
        <section className="wrapper mt-5 p-5 text-muted bg-light">
          <div className="container">
            <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center mb-6 mb-md-0">
              <div className="col-lg-6 position-relative">
                <div className="shape bg-soft-primary rounded-circle rellax w-20 h-20" />
                <img src='images/remote-infrastructure-management.png' />
              </div>
              <div className="col-lg-6 mt-5 mt-lg-0">
                <h3 className="mb-6">
                  Remote IT Infrastructure Management Services
                </h3>
                <p className="fs-5">
                  <strong>
                  For Network Management, Server Management, Storage Management, Workstation Management, Application Support, Database Management, and Security Management.
                  </strong>
                </p>
                <p className="fs-5">
                We elevate Monitoring-as-a-Service (MaaS) to the pinnacle with our Remote Infrastructure Management (RIMaaS) at DataCentrix™. With RIMaaS, you can relax, confident that your IT infrastructure is operating seamlessly across the board. With a robust foundation, 24x7 monitoring, and a tiered support escalation process, you can focus on your next IT project without fretting over the last.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section
          id="snippet-2"
          className="wrapper mt-5 p-5 text-muted"
        >
          <div className="container pt-10 pt-md-12 pb-12 pb-md-13">
            <h3 className="mb-3 text-center">Our Process</h3>
            <p className="fs-5 mb-8 text-center">
            We ensure stress-free device management by maintaining full control of the process.
            </p>
            <div className="row gx-lg-8 gx-xl-12 gy-6 process-wrapper line mt-5">
              <div className="col-md-6 col-lg-3">
                {" "}
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body">
                <div className="text-center">
                  <span className="number text-warning fs-3 border border-warning p-2">01</span>
                </div>
                <h5 className="my-3 text-center fw-bold  text-capitalize">Last-Mile Coordination</h5>
                <p className="fs-5 mb-0">
                Scoping, Knowledge Acquisition, Project Coordination, Hands & Eye Support.
                </p>
                </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                {" "}
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body">
                <div className="text-center">
                  <span className="number text-warning fs-3 border border-warning p-2">02</span>
                </div>
                <h5 className="my-3 text-center fw-bold">Remote Monitoring</h5>
                <p className="fs-5 mb-0">
                Proactive Performance Monitoring, Threshold Alerts, Dashboard Reporting.
                </p>
                </div></div>
              </div>
              <div className="col-md-6 col-lg-3">
                {" "}
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body">
                <div className="text-center">
                  <span className="number text-warning fs-3 border border-warning p-2">03</span>
                </div>
                <h5 className="my-3 text-center fw-bold">Level 1 Support</h5>
                <p className="fs-5 mb-0">
                Incident Handling, Escalation Desk, Knowledge Base.
                </p>
                </div></div>
              </div>
              <div className="col-md-6 col-lg-3">
                {" "}
                <div className="card shadow-lg text-muted h-100 hover-metic">
                  <div className="card-body">
                <div className="text-center">
                  <span className="number text-warning fs-3 border border-warning p-2">04</span>
                </div>
                <h5 className="my-3 text-center fw-bold">Level 2 &amp; Level 3 Support</h5>
                <p className="fs-5 mb-0">
                Issue Resolution, Change Oversight, Performance Oversight.
                </p>
                </div></div>
              </div>
            </div>
          </div>
        </section>
        <section className="mt-5 p-5 text-muted">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h3 className="text-muted mb-3 text-center">
                What We Do?
              </h3>
              <h5 className="mb-10 px-xl-10 text-center">
              Our service is meticulously crafted to align with your unique requirements.
              </h5>
            </div>
          </div>
          <div className="position-relative">
            <div
              className="shape rounded-circle bg-soft-blue rellax w-16 h-16"
              style={{
                bottom: "-0.5rem",
                right: "-2.2rem",
                zIndex: 0,
                transform: "translate3d(0px, 6px, 0px)",
              }}
            />
            <div
              className="shape bg-dot blue rellax w-16 h-17"
              style={{
                top: "-0.5rem",
                left: "-2.5rem",
                zIndex: 0,
                transform: "translate3d(0px, 2px, 0px)",
              }}
            />
            <div className="row gx-md-5 gy-5 d-flex mt-5">
              <div className="col-md-6 col-xl-3 d-flex">
                <div className="card shadow-lg w-100 text-muted hover-metic">
                  <div className="card-body">
                    <div className="text-center">
                     <i class="bi bi-person-badge text-warning fs-2 mx-2"></i>
                     </div>
                    <h5 className="text-center mt-2 fw-bold">End-User</h5>
                    <ul className="icon-list bullet-bg bullet-soft-blue mt-4 mb-0 list-unstyled">
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">24×7 Service Help</span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Desktop </span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Support Application</span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Packaging</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3 d-flex">
                <div className="card shadow-lg w-100 text-muted hover-metic">
                  <div className="card-body">
                  <div className="text-center">
                  <i class="bi bi-database-check text-warning fs-2 mx-2"></i>
                  </div>
                    <h5 className="text-center mt-2 fw-bold">Data Center Management</h5>
                    <ul className="icon-list bullet-bg bullet-soft-blue mt-4 mb-0 list-unstyled">
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Servers &amp; Storage</span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">OS </span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Databases</span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Messaging Systems</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3 d-flex">
                <div className="card shadow-lg w-100 text-muted hover-metic">
                  <div className="card-body">
                  <div className="text-center">
                  <i class="bi bi-reception-4 text-warning fs-2 mx-2"></i>
                  </div>
                    <h5 className="text-center mt-2 fw-bold">Network Management</h5>
                    <ul className="icon-list bullet-bg bullet-soft-blue mt-4 mb-0 list-unstyled">
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Routers</span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Switches </span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Devices</span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Network Links</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3 d-flex">
                <div className="card shadow-lg w-100 text-muted hover-metic">
                  <div className="card-body">
                  <div className="text-center">
                  <i class="bi bi-unlock-fill text-warning fs-2 mx-2"></i>
                  </div>
                    <h5 className="text-center mt-2 fw-bold">Security Management</h5>
                    <ul className="icon-list bullet-bg bullet-soft-blue mt-4 mb-0 list-unstyled">
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Firewalls</span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Virtual Private Networks</span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">Public Key Infrastructure </span>
                      </li>
                      <li className="mt-3 d-flex flex-row">
                        <span>
                          <i className="uil uil-check text-warning fs-4 mx-2" />
                        </span>
                        <span className="fs-5">AAA Tools </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>
        <JoinCommunity />
        <LetsConnect />
    </React.Fragment>
  );
}

export default ModernDeviceManagement;
