import React, { useState } from 'react'
import SliderBullets from '../SliderBullets/SliderBullets'
import { service_slider_content, service_slider_transform, slider_transform } from '../../Services/ConstantVariables'

const ServiceSlider = () => {
    const [activeBullet, setActiveBullet] = useState(0)

    const onBulletClick = (index) => {
        setActiveBullet(index)
        const element = document.getElementsByClassName('swiper-wrapper-service')[0]
        element.style.transform = `translateX(${service_slider_transform[index]}) translateY(0px) translateZ(0px)`;
        element.style.transitionDuration = '300ms';
    }
    return (
        <section className="wrapper px-5 px-md-0 ">
            <div className="pt-15 pb-0 pb-md-0 ">
                <div className="swiper-container testimonialSlides grid-view mb-5 justify-content-end">
                    <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events d-flex case-studies-swiper pb-12 pt-5 mt-n5 swiper-backface-hidden">
                        <div
                            className="swiper-wrapper swiper-wrapper-service mb-5"
                            style={{
                                transitionDuration: "0ms",
                                transform: "translate3d(492.375px, 0px, 0px)"
                            }}
                        >
                            {service_slider_content?.map((element) => (
                                <div
                                    className="swiper-slide d-flex h-auto swiper-slide-active"
                                    style={{ width: "278.25px", marginRight: 50 }}
                                >
                                    <div className="item d-flex h-100" style={{ textAlign: "left" }}>
                                        <div className="card bg-gray-g">
                                            <div className="quoteIcon mt-n5 ms-8">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="53.73"
                                                    height="40.184"
                                                    viewBox="0 0 67.73 54.184"
                                                >
                                                    <defs>
                                                        <linearGradient
                                                            id="linear-gradient"
                                                            x1="0.207"
                                                            y1="0.112"
                                                            x2="0.92"
                                                            y2="0.863"
                                                            gradientUnits="objectBoundingBox"
                                                        >
                                                            <stop offset={0} stopColor="#0e9aea" />
                                                            <stop offset={1} stopColor="#0966c3" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path
                                                        id="Icon_metro-quote"
                                                        data-name="Icon metro-quote"
                                                        d="M8.355,36.732V63.824H35.447V36.732H21.9s0-13.546,13.546-13.546V9.64S8.355,9.64,8.355,36.732Zm67.73-13.546V9.64s-27.092,0-27.092,27.092V63.824H76.085V36.732H62.539S62.539,23.186,76.085,23.186Z"
                                                        transform="translate(-8.355 -9.64)"
                                                        fill="#ffc107"
                                                    />
                                                </svg>
                                            </div>
                                            <div className="card-body">
                                                <blockquote className="border-0 mb-0">
                                                    <p className='text-muted fs-5'>
                                                        {element.content}
                                                    </p>
                                                    <div className="blockquote-details d-flex align-items-center">
                                                        <img className={'services-slider-img'} src={element.image} alt="" srcset="" />
                                                        <div className="info ps-0">
                                                            <h5
                                                                className="mb-1 ps-3 text-muted"
                                                                style={{ color: "rgb(0, 41, 82)" }}
                                                            >
                                                                {element.name}
                                                            </h5>
                                                            <p className="mb-0 ps-3 text-muted" style={{ fontSize: "0.8rem" }}>
                                                                {element.designation}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </blockquote>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
                            <SliderBullets pagination={4} pageSize={2} onBulletClick={onBulletClick} activeBullet={activeBullet} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceSlider