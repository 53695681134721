import React from "react";

const WhoAreWe = () => {
  return (
    <section className="who_we_are_container wraper bg-light p-5 text-muted">
      <div>
        <div className="container py-lg-5 pb-2">
          <div className="row gx-lg-8 gx-xl-12 gy-12 align-items-center mb-10 mb-md-13">
            <div className="col-lg-6 position-relative d-none d-lg-block p-5">
              <div className="row">
                <img src='images/who-we-are-image.jpg' />
              </div>
            </div>
            <div class="col-lg-6 py-5">
              <h2 class="experience_text  mb-3 text-center text-lg-start">
                Benefit from over 10+ years of expertise
              </h2>
              <h3 class="mb-3 text-center text-lg-start">Who Are We?</h3>
              <p class="fs-5">
                At DataCentrix™, we're catalysts for digital transformation and
                innovation acceleration. As a leading technology and digital
                engineering firm, we offer expertise spanning various
                industries.
              </p>
              <p class="fs-5 mb-6">
                We provide tailored talent services and expertise to drive our
                partners towards their business objectives. Committed to
                fostering growth and resilience in the dynamic digital sphere,
                we stand by our partners every step of the way.
              </p>
              <div class="row gy-3 mt-3">
                <div class="col-xl-12">
                  <ul class="who_we_are_list icon-list bullet-bg bullet-soft-blue mb-0 text-muted">
                    <li className="d-flex flex-row">
                      <span>
                        <i class="uil uil-arrow-up-right text-warning fs-4 mx-2"></i>
                      </span>
                      <span>
                        Global Program Management, Local Project Delivery.
                      </span>
                    </li>
                    <li className="mt-3 d-flex flex-row">
                      <span>
                        <i class="uil uil-arrow-up-right text-warning fs-4 mx-2"></i>
                      </span>
                      <span>
                        Holistic Talent Acquisition for Business Success.
                      </span>
                    </li>
                    <li class="mt-3 d-flex flex-row">
                      <span>
                        <i class="uil uil-arrow-up-right text-warning fs-4 mx-2"></i>
                      </span>
                      <span>
                        Enhancing Service Quality Through Transparency Platform.
                      </span>
                    </li>
                    <li class="mt-3 d-flex flex-row">
                      <span>
                        <i class="uil uil-arrow-up-right text-warning fs-4 mx-2"></i>
                      </span>
                      <span>
                        Comprehensive Expertise for Technological and Operational Excellence.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoAreWe;
